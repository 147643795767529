// @flow
import React from 'react';
import { Icon } from 'semantic-ui-react';

import { PrimaryButton } from '../../../presentational/buttons/PrimaryButton';
import { Spacer } from '../../../presentational/spacing/Spacer';

export const AddNewEstimateButton = ({ onClick }) => (
  <PrimaryButton
    circular={true}
    compact={true}
    onClick={onClick}
    size="medium"
    style={{
      marginTop: '1rem',
    }}
    text={
      <>
        <Icon fitted={true} name="add circle" />
        <Spacer x={5} />
        Add Estimate
      </>
    }
  />
);
