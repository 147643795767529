// @flow

import React, { useContext, useEffect } from 'react';
import { useFormikContext } from 'formik';

import { SelectField } from '../../../formik-utils/FormFields';
import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';
import {
  BASIC_CLUSTER_TYPE,
  CLUSTER_SLA_BACKEND_NAME,
  CLUSTER_TYPE_BACKEND_NAME,
} from '../../../constants';
import { ClusterContext } from '../../../contexts/ClusterContext';
import { getDropdownOptions } from '../../stream-governance/utils';
import { Tooltip } from '../../presentational/Tooltip';

const getTooltipBasedOnClusterType = (values) => {
  const clusterType = values?.[CLUSTER_TYPE_BACKEND_NAME];

  const tooltipText =
    clusterType === BASIC_CLUSTER_TYPE
      ? 'For Higher SLA, upgrade to a different cluster type.'
      : 'Lower SLA recommended for non-production workloads. \nHigher SLA recommended for production workloads.';

  return <Tooltip flowing={false} text={tooltipText} />;
};

export const ClusterSlaContainer = ({ disabled }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const cluster = useContext(ClusterContext);
  const dataUniverse = cluster?.cluster_configs?.cluster_sla_configs ?? [];
  const clusterSlaConfig = CLUSTER_DETAILS_CONFIG_MAP.get(CLUSTER_SLA_BACKEND_NAME);

  useEffect(() => {
    const currentSLAOption = values[clusterSlaConfig.backendFieldName];
    const clusterSlaOptions = getDropdownOptions(
      cluster?.cluster_configs?.cluster_sla_configs ?? [],
      values,
      clusterSlaConfig,
      false,
      true,
      false,
      true
    );
    if (!clusterSlaOptions.map((x) => x.name).includes(currentSLAOption)) {
      setFieldValue(CLUSTER_SLA_BACKEND_NAME, null).then(() => {});
      setFieldTouched(CLUSTER_SLA_BACKEND_NAME, true).then(() => {});
    }
    if (clusterSlaOptions.length === 1) {
      setFieldValue(CLUSTER_SLA_BACKEND_NAME, clusterSlaOptions[0].name).then(() => {});
    }
  }, [
    cluster?.cluster_configs?.cluster_sla_configs,
    clusterSlaConfig,
    setFieldTouched,
    setFieldValue,
    values,
  ]);

  const clusterSlaOptions = getDropdownOptions(
    dataUniverse,
    values,
    clusterSlaConfig,
    false,
    true,
    false,
    true
  );

  const tooltip = getTooltipBasedOnClusterType(values);

  return (
    <SelectField
      addLabel={true}
      disabled={disabled}
      fieldDisplayName={clusterSlaConfig.displayName}
      fieldName={clusterSlaConfig.backendFieldName}
      options={clusterSlaOptions}
      placeholder={null}
      tooltip={tooltip}
    />
  );
};
