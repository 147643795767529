// @flow
import { Dropdown } from 'semantic-ui-react';
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

export const LogoutDropdownMenuItem = () => {
  const { oktaAuth } = useOktaAuth();
  return (
    <Dropdown.Item>
      <a
        href="/"
        onClick={async () => {
          oktaAuth.tokenManager.clear();
        }}
      >
        Logout
      </a>
    </Dropdown.Item>
  );
};
