// @flow
import React from 'react';

import { StyledPopup } from '../../../common-utils/styledComponents';
import { formatFloat1DP, formatFloat2DP } from '../grid-utils/renderers';
import { convertArrayOfValueLabelsToObject } from '../../../common-utils/utils';

export const EffectiveDiscountExplainerRenderer = (props, isForCNBC) => {
  if (props.value === null) {
    return null;
  }
  const effectiveDiscount = props.value;
  const explainers = props?.data?.[`${props.colDef.field}_explainers`] ?? [];
  const explainersObj = convertArrayOfValueLabelsToObject(explainers);

  const totalCommitExcludingSupport = Number(explainersObj?.cluster_spend_dollars ?? 0);
  const totalCommitExcludingSupportDiscounted = Number(
    explainersObj?.cluster_spend_dollars_discounted ?? 0
  );
  const credits = Number(explainersObj?.credits ?? 0);
  const bursts = Number(explainersObj?.bursts ?? 0);
  const totalInvoiceToCustomer = Number(explainersObj?.total_invoice_to_customer ?? 0);
  const effectiveDiscountWithoutCNBC = Number(explainersObj?.effective_discount_without_CNBC ?? 0);
  const backCalculatedCommitAtList = Number(explainersObj?.back_calculated_commit_at_list ?? 0);
  const effectiveDiscountWithoutCNBCInverse = Number(
    explainersObj?.effective_discount_without_CNBC_inverse ?? 0
  );

  const effectiveDiscountStringToShow =
    effectiveDiscount === 'N/A' ? effectiveDiscount : `${effectiveDiscount}%`;

  return (
    <StyledPopup
      content={
        <>
          {effectiveDiscount !== 'N/A' && (
            <>
              <p>
                Estimated Cluster, SG and Flink Spend = $
                {formatFloat1DP(totalCommitExcludingSupport)}
              </p>
              <p>
                Estimated Cluster, SG and Flink Spend Discounted = $
                {formatFloat1DP(totalCommitExcludingSupportDiscounted)}
              </p>

              {isForCNBC && <p>Total Credits = ${formatFloat1DP(credits)}</p>}

              {isForCNBC && (
                <p>Total invoiced to customer = ${formatFloat1DP(totalInvoiceToCustomer)}</p>
              )}

              <p>
                Effective Discount without considering Credits and Bursts is calculated as ( 1- (
                {formatFloat1DP(totalCommitExcludingSupportDiscounted)}/
                {formatFloat1DP(totalCommitExcludingSupport)}) * 100.00 )
              </p>

              <p>
                This comes out to be {formatFloat2DP(effectiveDiscountWithoutCNBC)}
                %.
              </p>

              {isForCNBC && <p>Burst Capacity = ${formatFloat1DP(bursts)}</p>}

              {isForCNBC && (
                <p>
                  Actual Commit amount at List ( (Estimated Cluster, SG and Flink Spend Discounted +
                  Bursts)/(1-Effective Discount without considering Credits and Bursts) ) = $
                  {formatFloat1DP(backCalculatedCommitAtList)}
                </p>
              )}

              {isForCNBC && effectiveDiscountWithoutCNBCInverse !== 0 && (
                <p>
                  Effective Discount after considering Credits and Bursts is calculated as ( 1- (
                  {formatFloat1DP(totalInvoiceToCustomer)}/
                  {formatFloat1DP(backCalculatedCommitAtList)}) * 100.00 )
                </p>
              )}

              {isForCNBC && effectiveDiscountWithoutCNBCInverse !== 0 && (
                <p>
                  This comes out to be {formatFloat2DP(effectiveDiscount)}
                  %.
                </p>
              )}

              {isForCNBC && effectiveDiscountWithoutCNBCInverse === 0 && (
                <p>
                  The original discount was 100% and hence we could not back calculate the List
                  amount. So, the effective discount is treated as 100%
                </p>
              )}
            </>
          )}
          {effectiveDiscount === 'N/A' && (
            <>
              <p>
                Effective discount is not applicable because total cluster spend after discount is
                zero.
              </p>
            </>
          )}
        </>
      }
      data-testid="effective-discount-explainer"
      hoverable={true}
      position="top center"
      trigger={
        <div style={{ cursor: 'pointer' }}>
          <span>{effectiveDiscountStringToShow}</span>
        </div>
      }
      wide="very"
    />
  );
};
