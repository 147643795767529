// @flow
import { useParams } from 'react-router-dom';
import React, { useCallback, useMemo, useState } from 'react';

import { DataFetcherContainer } from '../components/presentational/DataFetcherContainer';
import { useGetEstimateLevelViewQuery } from '../service-definitions/estimates';
import { QueryError } from '../components/presentational/QueryError';
import { EstimateRequestModal } from '../components/estimate-sharing/EstimateRequestModal';

import { EstimateContext } from './EstimateContext';

export const EstimateContextProvider = ({ children }) => {
  const { estimateId } = useParams();
  const [isRequestAccessModalOpen, setIsRequestAccessModalOpen] = useState(true);

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId },
          {
            skip: !estimateId,
          },
        ],

        [estimateId]
      )}
      dataFetchingFunction={useCallback(useGetEstimateLevelViewQuery, [])}
      throwOnError={false}
    >
      {(data, error) => {
        if (error) {
          if (error?.status === 403) {
            return (
              <EstimateRequestModal
                estimateId={estimateId}
                isOpen={isRequestAccessModalOpen}
                setOpen={setIsRequestAccessModalOpen}
              />
            );
          } else {
            return <QueryError error={error} />;
          }
        }
        return <EstimateContext.Provider value={data}>{children}</EstimateContext.Provider>;
      }}
    </DataFetcherContainer>
  );
};
