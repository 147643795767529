// @flow
import { Image } from 'semantic-ui-react';
import React from 'react';

import { StyledAppName, StyledMenuItem } from '../../common-utils/styledComponents';
import { getLinkForHomePage } from '../links/utils';

export const AppNameMenuItem = () => (
  <a href={getLinkForHomePage()}>
    <StyledMenuItem>
      <Image size="mini" src="/images/logo-light.svg" />
      <StyledAppName>Cloud Commit Estimator</StyledAppName>
    </StyledMenuItem>
  </a>
);
