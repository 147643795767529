// @flow
import React from 'react';

import { StyledSpan } from '../../../common-utils/styledComponents';
import { LIMIT_INDICATOR_FOR_FETCH_ALL_ON_HOME_PAGE } from '../../../constants';

export const getHierarchyResultSetInfoMessage = (limitConfigured, isFilterApplied) => {
  if (isFilterApplied) {
    return `Showing all accounts that matched the filter criteria sorted by last updated`;
  } else {
    const limitText = limitConfigured && limitConfigured > 0 ? `upto ${limitConfigured}` : 'all';
    return `Showing ${limitText} accounts sorted by last updated`;
  }
};

const constructOpportunitiesTree = (organizations) => {
  const tree = {};
  organizations.forEach((opportunity) => {
    const oppId = opportunity.id;
    const oppName = opportunity.name;
    tree[oppId] = {
      oppId,
      oppName,
      open: false,
      estimates: opportunity.estimates,
    };
  });
  return tree;
};

const constructOrganizationsTree = (organizations) => {
  const tree = {};
  organizations.forEach((organization) => {
    const orgId = organization.id;
    const orgName = organization.name;
    tree[orgId] = {
      orgId,
      orgName,
      open: false,
      opportunities: constructOpportunitiesTree(organization.opportunities),
    };
  });
  return tree;
};

export const constructHierarchyTreeFromAccounts = (accounts) => {
  const tree = {};
  accounts.forEach((account) => {
    const accountId = account.id;
    const accountName = account.name;
    tree[accountId] = {
      accountId,
      accountName,
      open: false,
      organizations: constructOrganizationsTree(account.organizations),
    };
  });
  return tree;
};

const constructOpenCloseTreeBasedOnTheGivenSearchTerm = (accounts, searchTerm) => {
  const toRet = { ...constructOpenCloseTreeFromAccountsAndSearchTerm(accounts, null) };

  const accountIdsList = Object.keys(toRet);
  accountIdsList.forEach((accountId) => {
    const account = toRet[accountId];
    const organizationsIdsList = Object.keys(account.organizations);
    organizationsIdsList.forEach((organizationId) => {
      const organization = account.organizations[organizationId];
      const doesCurrOrganizationIncludesSearchTerm =
        organization.orgId.toLowerCase().includes(searchTerm) ||
        organization.orgName.toLowerCase().includes(searchTerm);

      if (doesCurrOrganizationIncludesSearchTerm) {
        account.open = true;
      }

      // Opportunities
      const opportunitiesIdsList = Object.keys(organization.opportunities);
      opportunitiesIdsList.forEach((opportunityId) => {
        const opportunity = organization.opportunities[opportunityId];
        const doesCurrOpportunityIncludesSearchTerm =
          opportunity.oppId.toLowerCase().includes(searchTerm) ||
          opportunity.oppName.toLowerCase().includes(searchTerm);

        if (doesCurrOpportunityIncludesSearchTerm) {
          account.open = true;
          organization.open = true;
        }

        const estimates = opportunity.estimates;
        estimates.forEach((estimate) => {
          const doesCurrEstimateIncludesSearchTerm = estimate.name
            .toLowerCase()
            .includes(searchTerm);
          if (doesCurrEstimateIncludesSearchTerm) {
            account.open = true;
            organization.open = true;
            opportunity.open = true;
          }
        });
      });
    });
  });

  return toRet;
};

export const constructOpenCloseTreeFromGivenAccounts = (accounts) =>
  accounts.reduce((accumulator, currentAccount) => {
    accumulator[currentAccount.id] = {
      open: false,
      organizations: constructOrganizationsTree(currentAccount.organizations),
    };
    return accumulator;
  }, {});

export const constructOpenCloseTreeFromAccountsAndSearchTerm = (accounts, searchTerm) => {
  if (searchTerm && searchTerm.length > 0) {
    return constructOpenCloseTreeBasedOnTheGivenSearchTerm(accounts, searchTerm.toLowerCase());
  }

  return constructOpenCloseTreeFromGivenAccounts(accounts);
};

export const getIfShowingPartialResults = (searchTerm, limit) => {
  // Note:
  // When the query term is provided, we show ALL results for that query term
  // When user clicks on Fetch All, limit will be -1
  return searchTerm === '' && limit !== LIMIT_INDICATOR_FOR_FETCH_ALL_ON_HOME_PAGE;
};

export const getAccountsForTheGivenPage = (accounts, activePage, accountsPerPage) => {
  // Note: Array slice returns an empty array when the accounts array is an empty array
  return accounts.slice(
    activePage * accountsPerPage - accountsPerPage,
    activePage * accountsPerPage
  );
};

export const highlightMatchingPortionInTextAndReturnSpan = (givenText, subStringToHighlight) => {
  const parts = givenText.split(new RegExp(`(${subStringToHighlight})`, 'gi'));
  return (
    <StyledSpan>
      {parts.map((part, i) =>
        part.toLowerCase() === subStringToHighlight.toLowerCase() ? (
          <mark key={i}>{part}</mark>
        ) : (
          part
        )
      )}
    </StyledSpan>
  );
};
