// @flow
import React, { useContext } from 'react';
import { getIn, useFormikContext } from 'formik';
import { Grid } from 'semantic-ui-react';

import { NegativeMessageContainer } from '../components/presentational/messages/NegativeMessageContainer';
import { Spacer } from '../components/presentational/spacing/Spacer';
import { StyledGridRow } from '../common-utils/styledComponents';

import { FieldArrayGridConfigItemContext, getFieldName } from './FieldArrayGrid';

export const FieldArrayErrorMessagesContainer = ({ arrayColumnConfig }) => {
  const { errors } = useFormikContext();
  const { pathToFollowInValues, index } = useContext(FieldArrayGridConfigItemContext);

  const errorsInConfig = getIn(errors, pathToFollowInValues.join('.'), null);
  const hasConfigLevelError = typeof errorsInConfig === 'string';
  const configLevelError = hasConfigLevelError ? errorsInConfig : '';

  return (
    <>
      {hasConfigLevelError && <NegativeMessageContainer header={configLevelError} />}

      {arrayColumnConfig.map((columnConfig) => {
        const fieldName = getFieldName(pathToFollowInValues, index, columnConfig.backendFieldName);
        const error = getIn(errors, fieldName, null);
        return error ? (
          <>
            <Spacer y={12} />
            <StyledGridRow>
              <Grid.Column>
                <NegativeMessageContainer header={error} key={columnConfig.backendFieldName} />
              </Grid.Column>
            </StyledGridRow>
          </>
        ) : null;
      })}
    </>
  );
};
