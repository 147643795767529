// @flow
import React, { useState } from 'react';

import { StyledTab } from '../../common-utils/styledComponents';

export const TabsContainer = ({ tabPanes }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = (e, { activeIndex }) => {
    setActiveTabIndex(activeIndex);
  };

  return (
    <StyledTab
      activeIndex={activeTabIndex}
      menu={{ secondary: true, pointing: true }}
      onTabChange={handleTabChange}
      panes={tabPanes}
    />
  );
};
