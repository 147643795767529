// @flow

export const TCO_RISK_TOOLTIP = `The TCO contribution estimate provided for 'Risk' is intended to ensure that this cost is considered when a customer is determining their actual (or potential) TCO relative to our estimate. 

A 'Risk' cost could be anything from the cost associated with data breaches (such as: imposed penalties, insurance premium changes, customer churn, stock price impact), to the cost of lost market share due to a slow implementation or unforeseen complications, or the more mundane cost related to business impacts caused by solution unavailability.

Our 'Risk' estimate is only based on the mundane business cost of downtime, it is calculated in two sections (Unplanned Downtime Hours + Planned Downtime Hours) using a 'Cost per Business Downtime Hour (see TCO Details above)'.

The Unplanned Downtime Hours use the number of downtime hours which is estimated by the difference in expected availability with an OSK (99%) or MSK (99.5%) and the CFLT Solution (99.5%, 99.9%, 99.99%) and the assumption that only 25% of those hours actually impact the business.

The Planned Downtime Hours use an assumption that without a KORA type architecture OSK (12 hours/year) and MSK (10 hours/year) will need times to perform maintenance/upgrades etc., where a CC solution will only need 4 hours/year of planned downtime.`;

export const TCO_INFRASTRUCTURE_TOOLTIP = `The TCO contribution estimate provided for 'Infrastructure' is the rollup of the Compute+Storage+Network cost estimates associated with each defined Cluster.`;

export const TCO_OPEX_TOOLTIP =
  "The TCO contribution estimate provided for 'Operating Expenses' is the rollup of the Operations + Support below";

export const TCO_OPERATIONS_TOOLTIP = `The TCO contribution estimate provided for 'Operations' is intended to ensure that this cost is considered when a customer is determining their actual (or potential) TCO relative to our estimate. 

An 'Operations' cost could be anything related to the people needed to plan, design, run, maintain their Kafka solution: their salary, hiring, training, benefits, equity, office space, equipment etc.

Our 'Operations' estimate is based on a handfull of basic assumptions, a need for two types of people (Tier 1-2, and Tier 3), that these people cost more than their salary to an organization (i.e. their salary + extra for all their other related costs), that their salaries will vary between AMER, EMEA, and APAC (see Region above in TCO Details), and that if you flag something as being Mission Critical it will need more people than if it isn't.

The estimate for the number of Support and Senior people required is tied to the Ingress Throughput (more throughput = more people needed), and it ranges from ~0.2 FTE to ~21 FTE depending on throughput and Mission Critical flag. 

The extra cost above salary is set at 50% (i.e. $100K salaried person costs ~$150K after you add in all the other costs, equity, benefits, training, hiring, etc.)

The regional salaries used are: 

APAC (Support:$70K Senior:$94,667) 
EMEA (Support:$100K Senior:$150K) 
AMER (Support:$150K Senior:$250K) 

Good Kafka people are expensive, yes a customer can get good technical people for less but they'll need more of them.`;

export const TCO_SUPPORT_TOOLTIP = `The TCO contribution estimate provided for 'Support' is intended to ensure that this cost is considered when a customer is determining their actual (or potential) TCO relative to our estimate. 

A 'Support' cost could be anything from a support fee paid to a vendor or additional costs related to a 3rd Party support contract or their cost of building and maintaining a Monitoring Solution for their Kafka environment - or any collection of additional costs beyond the people costs to design/run/maintain their environment.

Our 'Support' estimate is based on two basic elements, a 3% of the estimated Compute+Storage+Network costs, and a Monitoring estimate that changes as the throughput grows but results in a cost in the 5% to 11% range of Compute+Storage+Network.`;

export const TCO_COMPUTE_TOOLTIP = `At it's heart the 'Compute' cost is an initial estimate where the number of brokers is determined by a throughput derived regression using the X.Large node type, 

but the cost per node is selected based on the CSP and partition count an XL, or 2XL, or 4XL can handle.

This estimated number of brokers is refined depending on if you used a S-AZ or M-AZ for the cluster, or if you turned on 'Tiered Storage' above, or what you set the 'Over Provision Percentage' to.

As you leverage this estimated cost with a customer and eventually arrive at a validated Compute Cost, use that validated cost in your final proposal.`;

export const TCO_NETWORK_TOOLTIP = `At its heart the 'Network' cost is an initial estimate where the volume of data traffic moving across AZ boundaries is estimated based on Ingress, Egress, and Replication. 

While the cost is simply a cost per GB times the estimated volume of data traffic.

This estimated volume of data traffic is refined depending on if you used a S-AZ or M-AZ for the cluster, or if you turned on 'Fetch From Follower' above, or if it's an OSK or MSK comparison.

As you leverage this estimated cost with a customer and eventually arrive at a validated Network Cost, use that validated cost in your final proposal.`;

export const TCO_STORAGE_TOOLTIP = `At its heart the 'Storage' cost is an initial estimate where the amount of storage required is estimated based on Ingress, Retention Days, and Replication Factor. 

The cost varies depending on the amount of NAT versus Object Storage that is required.

This amount of storage required is refined depending on what you set the 'Over Provision Percentage' or 'Storage Utilization Buffer' to. 

While the cost is refined based on if you turned on 'Tiered Storage', what you set 'Local Retention (hours)' above to, and the CSP

As you leverage this estimated cost with a customer and eventually arrive at a validated Storage Cost, use that validated cost in your final proposal.`;
