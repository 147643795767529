// @flow
import React, { useContext } from 'react';

import { EstimateContext } from '../contexts/EstimateContext';
import { getIfCustomerViewIsEnabled } from '../common-utils/utils';
import { WarningMessageContainer } from '../components/presentational/messages/WarningMessageContainer';

const getTotalCommitAfterDiscount = (estimateData) => {
  const afterDiscountArr = estimateData.commit_summary.after_discount;
  const totalCommitDiscountedRow = afterDiscountArr.filter((x) => x.field === 'Total Commit');
  return Number(totalCommitDiscountedRow[0].value);
};

export const TCVApprovalRequiredContainer = () => {
  const estimateData = useContext(EstimateContext);
  const isCustomerViewEnabled = getIfCustomerViewIsEnabled(estimateData?.inputs);
  const totalCommitAfterDiscount = getTotalCommitAfterDiscount(estimateData);
  const isTCVAboveThreshold = totalCommitAfterDiscount >= 500000;

  const shouldShowWarning = isTCVAboveThreshold && !isCustomerViewEnabled;

  if (!shouldShowWarning) {
    return null;
  }

  return (
    <WarningMessageContainer
      link="https://confluentinc.atlassian.net/wiki/spaces/SE/pages/2784330125/SE+Solution+Reviews"
      linkText="Verify Policy"
      message="SE Leadership Approval Required. "
    />
  );
};
