// @flow

import {
  BASIC_CLUSTER_TYPE,
  CLUSTER_PARTITIONS_LIMITS_MAP,
  RATE_CARD_VERSION_4_11_2024,
  STANDARD_CLUSTER_TYPE,
} from '../constants';

export const convertConfigArrayToMap = (arr) => {
  const map = new Map();

  for (const elem of arr) {
    map.set(elem.backendFieldName, elem);
  }

  return map;
};

export const getValidationSchemaFromColsConfig = (colsConfig) => {
  const validationSchemaObj = {};

  for (const col of colsConfig) {
    const backendFieldName = col.backendFieldName;
    validationSchemaObj[backendFieldName] = col.validation;
  }

  return validationSchemaObj;
};

export const createFormNameToFieldsMap = (arr) => {
  const map = new Map();

  for (const elem of arr) {
    const belongingForm = elem.belongingForm;
    const existingValue = map.get(belongingForm);
    if (!existingValue) {
      map.set(belongingForm, []);
    }

    map.get(belongingForm).push(elem);
  }

  return map;
};

export const getIfPartitionsConfiguredAreWithinLimits = (clusterType, rateCardVersion, value) => {
  let partitionLimit = CLUSTER_PARTITIONS_LIMITS_MAP.get(clusterType);

  // todo::Needs to be read from BE after the BE is ready with the change where the limits are passed from BE
  if (rateCardVersion === RATE_CARD_VERSION_4_11_2024) {
    if (clusterType === BASIC_CLUSTER_TYPE) {
      partitionLimit = 1500;
    }
    if (clusterType === STANDARD_CLUSTER_TYPE) {
      partitionLimit = 2500;
    }
  }

  const isError = value > partitionLimit;
  return { partitionLimit, isError };
};
