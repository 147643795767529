// @flow
import { useParams } from 'react-router-dom';
import React, { useContext } from 'react';

import { CopyRenderer } from '../grid-utils/renderers';
import { useCopyFlinkPoolMutation } from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { FLINK_POOL_ID } from '../../../constants';
import { toastError, toastSuccess } from '../../presentational/notifications/utils';

export const FlinkPoolCopyRenderer = ({ data }) => {
  const { estimateId } = useParams();

  const estimate = useContext(EstimateContext);
  const [copyFlinkPool] = useCopyFlinkPoolMutation();

  return (
    <CopyRenderer
      onClickHandler={async () => {
        const { error } = await copyFlinkPool({
          estimateId,
          flinkPoolId: data[FLINK_POOL_ID],
          estimateVersion: estimate?.inputs?.estimate_version,
        });

        if (error) {
          toastError(error);
        } else {
          toastSuccess('Successfully copied the flink pool!');
        }
      }}
    />
  );
};
