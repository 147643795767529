// @flow
import React from 'react';
import { Icon } from 'semantic-ui-react';

import { StyledPopup } from '../../common-utils/styledComponents';

export const IconWithPopup = ({ iconName, popupContent, onClick }) => {
  return (
    <StyledPopup
      content={popupContent}
      flowing={true}
      hoverable={true}
      offset={[0, 0]}
      on={['hover']}
      positionFixed={true}
      trigger={
        <span>
          <Icon color="grey" name={iconName} onClick={onClick} style={{ cursor: 'pointer' }} />
        </span>
      }
    />
  );
};
