// @flow
import React from 'react';

import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';
import { CLUSTER_PARTITIONS_BACKEND_NAME } from '../../../constants';
import { TextField } from '../../../formik-utils/FormFields';

export const ClusterPartitionsContainer = ({ disabled }) => {
  const clusterPartitionsConfig = CLUSTER_DETAILS_CONFIG_MAP.get(CLUSTER_PARTITIONS_BACKEND_NAME);

  return (
    <TextField
      disabled={disabled}
      fieldDisplayName={clusterPartitionsConfig.displayName}
      fieldName={clusterPartitionsConfig.backendFieldName}
      icon={clusterPartitionsConfig.icon}
      showErrorsOnlyWhenTouched={false}
      type={clusterPartitionsConfig.inputType}
    />
  );
};
