// @flow
import React, { useContext } from 'react';

import { TCO_REGION_BACKEND_FIELD_NAME } from '../../../constants';
import { SelectField } from '../../../formik-utils/FormFields';
import { TCO_ESTIMATE_LEVEL_INPUTS_CONFIG_MAP } from '../../../configuration/tco-estimate-inputs';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { getDropdownOptionsFromArray } from '../../../common-utils/utils';

export const TCORegionContainer = () => {
  const estimateData = useContext(EstimateContext);

  const { backendFieldName, displayName, pathToFollowInValues, tooltip } =
    TCO_ESTIMATE_LEVEL_INPUTS_CONFIG_MAP.get(TCO_REGION_BACKEND_FIELD_NAME);

  const availableRegions = estimateData?.estimate_tco_config?.regions ?? [];
  const regionOptions = getDropdownOptionsFromArray(availableRegions);

  return (
    <SelectField
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      options={regionOptions}
      pathToFollowInValues={pathToFollowInValues}
      tooltip={tooltip}
    />
  );
};
