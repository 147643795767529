// @flow
import { boolean, number, string } from 'yup';
import React from 'react';

import { DATE_INPUT_TYPE, SELECT_INPUT_TYPE } from '../formik-utils/consts';
import {
  DEAL_END_DATE_BACKEND_NAME,
  DEAL_START_DATE_BACKEND_NAME,
  DEAL_TYPE_BACKEND_NAME,
  MARKETPLACE_BACKEND_NAME,
  MARKETPLACE_DISPLAY_NAME,
  PAYMENT_SCHEDULE_BACKEND_NAME,
  PAYMENT_SCHEDULE_DISPLAY_NAME,
  RATE_CARD_BACKEND_NAME,
  RATE_CARD_DISPLAY_NAME,
  SUPPORT_TIER_BACKEND_NAME,
  SUPPORT_TIER_DISPLAY_NAME,
  DEAL_TYPE_DISPLAY_NAME,
  DEAL_START_DATE_DISPLAY_NAME,
  DEAL_END_DATE_DISPLAY_NAME,
  CUSTOM_DISCOUNTS_ENABLED_BACKEND_NAME,
  CUSTOM_DISCOUNTS_ENABLED_DISPLAY_NAME,
  CREDITS_AND_BURSTS_ENABLED_DISPLAY_NAME,
  CREDITS_AND_BURSTS_ENABLED_BACKEND_NAME,
  GLOBAL_DISCOUNT_APPLIED_DISPLAY_NAME,
  GLOBAL_DISCOUNT_APPLIED_BACKEND_NAME,
} from '../constants';
import { convertLocalToUTCDate, convertUTCToLocalDate } from '../common-utils/utils';
import { StyledExternalLink } from '../components/presentational/ExternalLink';

import { convertConfigArrayToMap } from './utils';

export const discountValidation = number()
  .label('Discount')
  .typeError('You must specify a number')
  .min(0)
  .max(100)
  .required();

export const ESTIMATE_COMMIT_DETAILS_CONFIG = [
  {
    displayName: DEAL_START_DATE_DISPLAY_NAME,
    backendFieldName: DEAL_START_DATE_BACKEND_NAME,
    validation: string().label(DEAL_START_DATE_DISPLAY_NAME).required(),
    inputType: DATE_INPUT_TYPE,
    inputTransformationFunc: convertUTCToLocalDate,
    transformationFunctionToSendToBackEnd: convertLocalToUTCDate,
  },
  {
    displayName: DEAL_END_DATE_DISPLAY_NAME,
    backendFieldName: DEAL_END_DATE_BACKEND_NAME,
    validation: string().label(DEAL_END_DATE_DISPLAY_NAME).required(),
    inputType: DATE_INPUT_TYPE,
    inputTransformationFunc: convertUTCToLocalDate,
    transformationFunctionToSendToBackEnd: convertLocalToUTCDate,
  },
  {
    displayName: DEAL_TYPE_DISPLAY_NAME,
    backendFieldName: DEAL_TYPE_BACKEND_NAME,
    validation: string().label(DEAL_TYPE_DISPLAY_NAME).required(),
    inputType: SELECT_INPUT_TYPE,
    defaultValue: 'New Commit',
  },
  {
    displayName: MARKETPLACE_DISPLAY_NAME,
    backendFieldName: MARKETPLACE_BACKEND_NAME,
    validation: string().label(MARKETPLACE_DISPLAY_NAME).required(),
    inputType: SELECT_INPUT_TYPE,
    defaultValue: 'None',
  },
  {
    displayName: PAYMENT_SCHEDULE_DISPLAY_NAME,
    backendFieldName: PAYMENT_SCHEDULE_BACKEND_NAME,
    validation: string().label(PAYMENT_SCHEDULE_DISPLAY_NAME).required(),
    inputType: SELECT_INPUT_TYPE,
    defaultValue: 'Prepaid',
  },
  {
    displayName: RATE_CARD_DISPLAY_NAME,
    backendFieldName: RATE_CARD_BACKEND_NAME,
    validation: string().label(RATE_CARD_DISPLAY_NAME).required(),
    inputType: SELECT_INPUT_TYPE,
    defaultValue: '8/1',
    tooltip: (
      <StyledExternalLink
        href="https://confluentinc.atlassian.net/wiki/spaces/PM/pages/3344084523/Commit+Estimator+Rate+Cards"
        text=" Details"
      />
    ),
  },
  {
    displayName: SUPPORT_TIER_DISPLAY_NAME,
    backendFieldName: SUPPORT_TIER_BACKEND_NAME,
    validation: string().label(SUPPORT_TIER_DISPLAY_NAME).required(),
    inputType: SELECT_INPUT_TYPE,
    defaultValue: 'Free',
  },
  {
    displayName: GLOBAL_DISCOUNT_APPLIED_DISPLAY_NAME,
    backendFieldName: GLOBAL_DISCOUNT_APPLIED_BACKEND_NAME,
    validation: discountValidation,
    inputType: SELECT_INPUT_TYPE,
    icon: 'percent',
    inputTransformationFunc: (x) => Number(parseFloat(x).toFixed(8)),
    defaultValue: 0,
  },
  {
    displayName: CUSTOM_DISCOUNTS_ENABLED_DISPLAY_NAME,
    backendFieldName: CUSTOM_DISCOUNTS_ENABLED_BACKEND_NAME,
    validation: boolean().label(CUSTOM_DISCOUNTS_ENABLED_DISPLAY_NAME),
    inputType: SELECT_INPUT_TYPE,
    defaultValue: false,
  },
  {
    displayName: CREDITS_AND_BURSTS_ENABLED_DISPLAY_NAME,
    backendFieldName: CREDITS_AND_BURSTS_ENABLED_BACKEND_NAME,
    validation: boolean().label(CREDITS_AND_BURSTS_ENABLED_DISPLAY_NAME),
    inputType: SELECT_INPUT_TYPE,
    defaultValue: false,
  },
];

export const ESTIMATE_COMMIT_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  ESTIMATE_COMMIT_DETAILS_CONFIG
);
