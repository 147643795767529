// @flow

import { Link } from 'react-router-dom';
import React from 'react';
import { Button } from 'semantic-ui-react';

export const LinkButton = ({ text, disabled, pathname }) => (
  <Button
    as={Link}
    compact={true}
    content={text}
    data-testid="link-button"
    disabled={disabled}
    primary={true}
    size="tiny"
    to={{
      pathname: pathname,
    }}
  />
);

// todo:: Investigate why we cant use StyledButton here instead of Button
