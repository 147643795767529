// @flow
import styled from 'styled-components';
import { LineChart } from 'recharts';

export const StyledLineChart = styled(LineChart)`
  div {
    background-color: ${({ theme }) => theme.clusterGraph?.backgroundColor};
    color: ${({ theme }) => theme.clusterGraph?.color};
  }
`;
