// @flow
import { useSelector } from 'react-redux';
import React from 'react';

import { LoadingIndicator } from './LoadingIndicator';

export const ShowLoaderIfAnyQueryIsPending = () => {
  const isSomeQueryPending = useSelector((state) => {
    return (
      Object.values(state.estimatesApi.queries).some((query) => query?.status === 'pending') ||
      Object.values(state.estimatesApi.mutations).some((query) => query?.status === 'pending')
    );
  });

  return <>{isSomeQueryPending && <LoadingIndicator />}</>;
};
