// @flow
import { getIn, useFormikContext } from 'formik';
import React from 'react';

import { NegativeMessageContainer } from '../presentational/messages/NegativeMessageContainer';

export const SharingConfigLevelErrorMessagesContainer = () => {
  const { errors } = useFormikContext();

  const errorsInConfig = getIn(errors, 'shared_with_emails', null);

  const hasConfigLevelError = typeof errorsInConfig === 'string';
  const configLevelError = hasConfigLevelError ? errorsInConfig : '';

  return <>{hasConfigLevelError && <NegativeMessageContainer header={configLevelError} />}</>;
};
