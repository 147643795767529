// @flow
import { Formik, connect, setIn } from 'formik';
import { object, number, string } from 'yup';
import React from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';

import { StyledPopup } from '../../../common-utils/styledComponents';
import { SelectField, TextField } from '../../../formik-utils/FormFields';
import { TEXT_NUMBER_INPUT_TYPE } from '../../../formik-utils/consts';
import { MAX_INPUT_VALUE_FOR_ANY_FIELD } from '../../../constants';

import { getMonthName } from './utils';

const operatorOptions = [
  {
    key: 1,
    name: '+',
    display_name: '+',
  },
  {
    key: 2,
    name: '*',
    display_name: '*',
  },
  {
    key: 3,
    name: '-',
    display_name: '-',
  },
  {
    key: 4,
    name: '/',
    display_name: '/',
  },
];
const handleFocus = (e) => e.target.select();

const ClusterMonthlyUsageRampCalculator = ({
  totalNumberOfMonths,
  formik,
  usageMetric,
  monthlyEstimatesFieldName,
}) => {
  const initialValues = {
    startMonth: 1,
    endMonth: totalNumberOfMonths,
    startValue: 0,
    operator: '+',
    operand: 0,
  };

  const validationSchema = object({
    startMonth: number().label('Starting Month').min(1).max(totalNumberOfMonths).required(),
    endMonth: number().label('Ending Month').min(1).max(totalNumberOfMonths).required(),
    startValue: number()
      .min(0)
      .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
      .label('Starting Value')
      .required()
      .typeError('You must specify a number'),
    operator: string().label('Operator').required(),
    operand: number()
      .label('Operand')
      .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
      .required()
      .typeError('You must specify a number'),
  });

  const monthOptions = [...Array(totalNumberOfMonths)].map((el, i) => {
    return {
      name: i + 1,
      display_name: i + 1,
    };
  });

  return (
    <Formik
      autoComplete="off"
      initialValues={initialValues}
      onSubmit={async (values, { setFieldValue }) => {
        // todo:: Analyze what breaks if this is not set.
        for (const field in values) {
          setFieldValue(field, values[field]);
        }

        const newMonthValuesObjToSet = {};

        let newValue = parseFloat(values.startValue);
        const operand = parseFloat(values.operand);
        const startMonth = parseInt(values.startMonth, 10);
        const endMonth = parseInt(values.endMonth, 10);
        const reverse = startMonth > endMonth;

        for (
          let i = startMonth - 1;
          reverse ? i >= endMonth - 1 : i < endMonth;
          reverse ? i-- : i++
        ) {
          const inputFieldName = `${monthlyEstimatesFieldName}[months][${getMonthName(
            i + 1
          )}].${usageMetric}`;

          newMonthValuesObjToSet[inputFieldName] = Number(newValue);

          switch (values.operator) {
            case '+':
              newValue += operand;
              break;
            case '-':
              newValue -= operand;
              break;
            case '*':
              newValue *= operand;
              break;
            case '/':
              newValue /= operand;
              break;
            default:
          }
        }

        // set everything in one shot
        let newValuesWithChangedMonthValues = {
          ...formik.values,
        };

        for (const [key, value] of Object.entries(newMonthValuesObjToSet)) {
          newValuesWithChangedMonthValues = {
            ...setIn(newValuesWithChangedMonthValues, `${key}`, value),
          };
        }

        await formik.setValues(newValuesWithChangedMonthValues, false);
      }}
      validationSchema={validationSchema}
    >
      {(clusterMonthlyUsageInputCalculatorFormik) => {
        return (
          <StyledPopup
            flowing={true}
            on="click"
            trigger={<Icon id="calculator" name="calculator" style={{ cursor: 'pointer' }} />}
          >
            <form autoComplete="off">
              <Grid>
                <Grid.Row>
                  <Grid.Column textAlign="center" width={2}>
                    <SelectField
                      addErrorMessage={false}
                      addPlaceHolder={false}
                      basic={true}
                      compact={true}
                      fieldDisplayName={<>Start&nbsp;Month</>} // we don't want it to wrap
                      fieldName="startMonth"
                      options={monthOptions}
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="center" width={2}>
                    <SelectField
                      addErrorMessage={false}
                      addPlaceHolder={false}
                      basic={true}
                      compact={true}
                      fieldDisplayName={<>End&nbsp;Month</>} // we don't want it to wrap
                      fieldName="endMonth"
                      options={monthOptions}
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="center" width={4}>
                    <TextField
                      fieldDisplayName="Start Value"
                      fieldName="startValue"
                      fluid={true}
                      onFocus={handleFocus}
                      type={TEXT_NUMBER_INPUT_TYPE}
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="center" width={2}>
                    <SelectField
                      addErrorMessage={false}
                      addPlaceHolder={false}
                      basic={true}
                      compact={true}
                      fieldDisplayName="Operator"
                      fieldName="operator"
                      options={operatorOptions}
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="center" width={4}>
                    <TextField
                      fieldDisplayName="Operand"
                      fieldName="operand"
                      fluid={true}
                      onFocus={handleFocus}
                      type={TEXT_NUMBER_INPUT_TYPE}
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="center" verticalAlign="bottom" width={1}>
                    <Button
                      compact={true}
                      content="Apply"
                      onClick={() => {
                        clusterMonthlyUsageInputCalculatorFormik.submitForm();
                      }}
                      primary={true}
                      size="tiny"
                      type="button"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </form>
          </StyledPopup>
        );
      }}
    </Formik>
  );
};

export default connect(ClusterMonthlyUsageRampCalculator);
