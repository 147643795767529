// @flow
import React from 'react';
import { Grid } from 'semantic-ui-react';

import { FieldArrayStyledGridRow } from '../../../formik-utils/FieldArrayGrid';

import { DependentDimensionsDropdownContainer } from './DependentDimensionsDropdownContainer';
import { DimensionsConfigUpdateButtonContainer } from './DimensionsConfigUpdateButtonContainer';
import { DimensionsConfigDeleteButtonContainer } from './DimensionsConfigDeleteButtonContainer';

export const MetricsLevelDiscountsDimensionsConfigurationItem = () => {
  return (
    <>
      <FieldArrayStyledGridRow>
        <DependentDimensionsDropdownContainer />
        <Grid.Column width={1}>
          <DimensionsConfigUpdateButtonContainer />
        </Grid.Column>
        <Grid.Column width={1}>
          <DimensionsConfigDeleteButtonContainer />
        </Grid.Column>
      </FieldArrayStyledGridRow>
    </>
  );
};
