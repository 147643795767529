// @flow
import React from 'react';

import { DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_FIELD_NAME } from '../../../../constants';
import { TextField } from '../../../../formik-utils/FormFields';
import { DEDICATED_CLUSTER_USAGE_SURVEY_INPUTS_CONFIG_MAP } from '../../../../configuration/dedicated-cluster-usage-survey-inputs';

export const DedicatedSurveyReasonInputContainer = () => {
  const { backendFieldName, displayName } = DEDICATED_CLUSTER_USAGE_SURVEY_INPUTS_CONFIG_MAP.get(
    DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_FIELD_NAME
  );

  return (
    <TextField
      addLabel={false}
      disableOnFormErrors={false}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={true}
    />
  );
};
