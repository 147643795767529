// @flow
import React from 'react';
import { useFormikContext } from 'formik';

import {
  CLUSTER_RETENTION_BACKEND_NAME,
  CLUSTER_RETENTION_INFINITE_BACKEND_NAME,
} from '../../../constants';
import { TextField } from '../../../formik-utils/FormFields';
import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';

export const ClusterRetentionContainer = ({ disabled }) => {
  const { values } = useFormikContext();
  const clusterRetentionConfig = CLUSTER_DETAILS_CONFIG_MAP.get(CLUSTER_RETENTION_BACKEND_NAME);

  return (
    <TextField
      disabled={values[CLUSTER_RETENTION_INFINITE_BACKEND_NAME] || disabled}
      fieldDisplayName={clusterRetentionConfig.displayName}
      fieldName={clusterRetentionConfig.backendFieldName}
      icon={clusterRetentionConfig.icon}
      type={clusterRetentionConfig.inputType}
    />
  );
};
