// @flow

export const getYearNumberToUseBasedOnLimits = (targetYearIndex, totalYears) => {
  if (targetYearIndex > totalYears) {
    return 1;
  }

  if (targetYearIndex < 1) {
    return totalYears;
  }

  return targetYearIndex;
};
