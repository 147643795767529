// @flow
import React from 'react';

import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';
import { CLUSTER_EXISTING_STORAGE_BACKEND_NAME } from '../../../constants';
import { TextField } from '../../../formik-utils/FormFields';

export const ClusterExistingStorageContainer = ({ disabled }) => {
  const { backendFieldName, displayName, icon, inputType } = CLUSTER_DETAILS_CONFIG_MAP.get(
    CLUSTER_EXISTING_STORAGE_BACKEND_NAME
  );

  return (
    <TextField
      disabled={disabled}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      icon={icon}
      showErrorsOnlyWhenTouched={false}
      type={inputType}
    />
  );
};
