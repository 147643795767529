// @flow
import React, { useCallback, useContext, useMemo } from 'react';

import {
  CUSTOM_DISCOUNTS_JSON_BACKEND_NAME,
  METRICS_LEVEL_DISCOUNTS_CONFIG_ARRAY_BACKEND_NAME,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_ARRAY_BACKEND_NAME,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME,
  RATE_CARD_BACKEND_NAME,
} from '../../../constants';
import {
  FieldArrayGrid,
  FieldArrayGridConfigItemContext,
} from '../../../formik-utils/FieldArrayGrid';
import { DataFetcherContainer } from '../../presentational/DataFetcherContainer';
import { MetricsLevelDiscountsDimensionsConfigurationItem } from '../dimensions-configuration/MetricsLevelDiscountsDimensionsConfigurationItem';
import { getDependentDimensionsColumnConfig } from '../utils';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { useGetDependentDimensionsQuery } from '../../../service-definitions/estimates';

export const MetricsDimensionsListContainer = ({ metricDimensionRefs, readOnlyMode }) => {
  const estimate = useContext(EstimateContext);
  const { rowValues, index } = useContext(FieldArrayGridConfigItemContext);
  const metricName = rowValues[METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME];

  const pathToFollowInValues = [
    CUSTOM_DISCOUNTS_JSON_BACKEND_NAME,
    `${METRICS_LEVEL_DISCOUNTS_CONFIG_ARRAY_BACKEND_NAME}[${index}]["${METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_ARRAY_BACKEND_NAME}"]`,
  ];

  const rateCardVersion = estimate.inputs[RATE_CARD_BACKEND_NAME];

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          {
            metricName: metricName,
            rateCardVersion: rateCardVersion,
          },
          {
            skip: !metricName,
          },
        ],
        [rateCardVersion, metricName]
      )}
      dataFetchingFunction={useCallback(useGetDependentDimensionsQuery, [])}
    >
      {(dependentDimensions) => {
        const dependentDimensionsToUse = dependentDimensions?.dependent_dimensions ?? [];
        const columnConfig = getDependentDimensionsColumnConfig(
          dependentDimensionsToUse,
          metricName
        );

        metricDimensionRefs.current.dimensionColumnsConfiguration = columnConfig;

        return (
          <>
            <FieldArrayGrid
              addDividerBetweenRows={false}
              columnsConfiguration={columnConfig}
              disabled={readOnlyMode}
              functionToExecuteOnceArrayHelpersAreAvailable={(arrayHelpers) => {
                metricDimensionRefs.current.dimensionArrayHelpers = arrayHelpers;
              }}
              gridName="New Dimensions Configuration For the above metric"
              needsAddButton={false}
              pathToFollowInValues={pathToFollowInValues}
              verticalSpaceBetweenHeadersAndRows={5}
            >
              {(arrayHelpers, row, isCurrentlyBeingEdited, index, allRows, readOnlyMode) => {
                return (
                  <FieldArrayGridConfigItemContext.Provider
                    value={{
                      allRows,
                      arrayHelpers,
                      columnsConfiguration: columnConfig,
                      estimate,
                      index,
                      isCurrentlyBeingEdited,
                      pathToFollowInValues: pathToFollowInValues,
                      rowValues: row,
                      readOnlyMode,
                      parentContext: rowValues,
                      metricDimensionRefs: metricDimensionRefs,
                    }}
                  >
                    <MetricsLevelDiscountsDimensionsConfigurationItem />
                  </FieldArrayGridConfigItemContext.Provider>
                );
              }}
            </FieldArrayGrid>
          </>
        );
      }}
    </DataFetcherContainer>
  );
};
