// @flow
import React, { useContext } from 'react';

import { GLOBAL_DISCOUNT_APPLIED_BACKEND_NAME } from '../../constants';
import { TextField } from '../../formik-utils/FormFields';
import { getIfCustomerViewIsEnabled } from '../../common-utils/utils';
import { ESTIMATE_COMMIT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-commit-details';
import { TEXT_NUMBER_INPUT_TYPE } from '../../formik-utils/consts';
import { EstimateContext } from '../../contexts/EstimateContext';

export const DiscountAppliedContainer = () => {
  const estimateData = useContext(EstimateContext);

  const discountAppliedConfig = ESTIMATE_COMMIT_DETAILS_CONFIG_MAP.get(
    GLOBAL_DISCOUNT_APPLIED_BACKEND_NAME
  );

  return (
    <TextField
      disabled={getIfCustomerViewIsEnabled(estimateData?.inputs)}
      fieldDisplayName={discountAppliedConfig.displayName}
      fieldName={discountAppliedConfig.backendFieldName}
      icon={discountAppliedConfig.icon}
      type={TEXT_NUMBER_INPUT_TYPE}
    />
  );
};
