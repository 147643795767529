// @flow
import React, { useCallback, useMemo } from 'react';
import { Bar } from 'recharts';

import { DataFetcherContainer } from '../../presentational/DataFetcherContainer';
import { useGetClusterTCOViewDataQuery } from '../../../service-definitions/estimates';
import { convertArrayOfValueLabelsToObject } from '../../../common-utils/utils';
import { getColorForTheGivenYearForTCOGraph } from '../clusters-consumption-graph/utils';

import { TCOViewSummaryContainer } from './TCOViewSummaryContainer';

const tcoSummaryDataAndBarsExtractionFunc = (data, totalNumberOfYearsInEstimate) => {
  const tcoSummaryData = {};

  for (const [key, value] of Object.entries(data?.tco_summary ?? {})) {
    tcoSummaryData[key] = convertArrayOfValueLabelsToObject(value);
  }

  const years = Array.from({ length: totalNumberOfYearsInEstimate }, (_, i) => `YEAR ${i + 1}`);

  const BarsToDisplay = (
    <>
      {years.map((yearName, index) => {
        return (
          <Bar
            dataKey={yearName}
            fill={getColorForTheGivenYearForTCOGraph(index)}
            key={yearName}
            stackId="a"
          />
        );
      })}
    </>
  );

  return { tcoSummaryData, BarsToDisplay };
};

export const ClusterLevelTCOViewsDataWrapper = ({ estimateId, clusterId, disabled = false }) => {
  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId, clusterId: clusterId },
          {
            skip: !estimateId || !clusterId,
          },
        ],
        [estimateId, clusterId]
      )}
      dataFetchingFunction={useCallback(useGetClusterTCOViewDataQuery, [])}
    >
      {(data) => {
        return (
          <TCOViewSummaryContainer
            data={data}
            disabled={disabled}
            isForCluster={true}
            tcoSummaryDataAndBarsExtractionFunc={tcoSummaryDataAndBarsExtractionFunc}
          />
        );
      }}
    </DataFetcherContainer>
  );
};
