// @flow
import { Grid } from 'semantic-ui-react';
import React from 'react';
import { useFormikContext } from 'formik';

import { StyledSpan } from '../../common-utils/styledComponents';
import { AddButton } from '../presentational/buttons/AddButton';
import { Spacer } from '../presentational/spacing/Spacer';

export const AddEmailContainer = ({ arrayHelpers }) => {
  const { isValid } = useFormikContext();
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <AddButton
            disabled={!isValid}
            onClick={() => {
              arrayHelpers.push({
                email_id: '',
                role: 'editor',
              });
            }}
          />
          <Spacer x={4} />
          <StyledSpan className="ui tiny header">Add Email</StyledSpan>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
