// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import { TabsContainer } from '../../presentational/TabsContainer';
import { isAnyOfTheClusterRelatedFormsEdited } from '../cluster-details-inputs/utils';
import { getIfCustomerViewIsEnabled } from '../../../common-utils/utils';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { ClusterLevelTCOViewsDataWrapper } from '../../views/tco-views/ClusterLevelTCOViewsDataWrapper';

import { ClusterTotalSpendSummaryContainer } from './ClusterTotalSpendSummaryContainer';
import { ClusterConfigurationSummaryContainer } from './ClusterConfigurationSummaryContainer';
import { ClusterMonthlySpendSummaryContainer } from './ClusterMonthlySpendSummaryContainer';

export const ClusterSummaryContainer = () => {
  const { values, initialValues, errors } = useFormikContext();
  const isAnyErrorPresent = Object.keys(errors).length > 0;
  const disabled = isAnyOfTheClusterRelatedFormsEdited(values, initialValues) || isAnyErrorPresent;
  const { estimateId, clusterId } = useParams();

  const estimateData = useContext(EstimateContext);

  const tabPanes = [
    {
      menuItem: 'Total Spend',
      render: () => <ClusterTotalSpendSummaryContainer disabled={disabled} />,
    },
    {
      menuItem: 'Monthly Spend',
      render: () => <ClusterMonthlySpendSummaryContainer disabled={disabled} />,
    },
    {
      menuItem: 'Configuration',
      render: () => <ClusterConfigurationSummaryContainer disabled={disabled} />,
    },
  ];

  if (!getIfCustomerViewIsEnabled(estimateData?.inputs)) {
    tabPanes.push({
      menuItem: 'TCO (MSK/OSK)',
      render: () => (
        <ClusterLevelTCOViewsDataWrapper
          clusterId={clusterId}
          disabled={disabled}
          estimateId={estimateId}
        />
      ),
    });
  }

  return <TabsContainer tabPanes={tabPanes} />;
};
