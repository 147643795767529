// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { ESTIMATE_COMMIT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-commit-details';
import {
  MARKETPLACE_BACKEND_NAME,
  MARKETPLACE_NONE,
  PAYMENT_SCHEDULE_BACKEND_NAME,
  PAYMENT_SCHEDULE_PREPAID_BACKEND_NAME,
} from '../../constants';
import { SelectField } from '../../formik-utils/FormFields';
import { EstimateContext } from '../../contexts/EstimateContext';
import { getDropdownOptionsFromArray } from '../../common-utils/utils';

const calculatePaymentScheduleValue = (value, values) => {
  if (value !== MARKETPLACE_NONE) {
    return PAYMENT_SCHEDULE_PREPAID_BACKEND_NAME;
  } else {
    return values[PAYMENT_SCHEDULE_BACKEND_NAME];
  }
};

export const MarketplaceContainer = () => {
  const estimateData = useContext(EstimateContext);

  const marketPlaces = estimateData.commit_config.marketplaces;
  const marketPlacesOptions = getDropdownOptionsFromArray(marketPlaces);
  const marketplaceConfig = ESTIMATE_COMMIT_DETAILS_CONFIG_MAP.get(MARKETPLACE_BACKEND_NAME);

  const { values, setFieldValue } = useFormikContext();

  return (
    <SelectField
      fieldDisplayName={marketplaceConfig.displayName}
      fieldName={marketplaceConfig.backendFieldName}
      onChange={(e, value) => {
        setFieldValue(PAYMENT_SCHEDULE_BACKEND_NAME, calculatePaymentScheduleValue(value, values));
      }}
      options={marketPlacesOptions}
    />
  );
};
