// @flow
import React, { useContext } from 'react';
import { getIn, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { ClusterInputTable } from '../../cluster/cluster-input-table/ClusterInputTable';
import { FLINK_POOL_DETAILS_FORM, FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG } from '../../../constants';
import { isFormEditedBasedOnCurrentValues } from '../../../common-utils/utils';
import { FLINK_POOL_DETAILS_FORM_NAMES_TO_FIELDS_MAP } from '../../../configuration/flink-pool-details';
import { toastError } from '../../presentational/notifications/utils';
import { useUpdateFlinkPoolMutation } from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';

export const FlinkPoolMonthlyUsageContainer = () => {
  const { estimateId, flinkPoolId } = useParams();
  const [updateFlinkPool] = useUpdateFlinkPoolMutation();
  const estimate = useContext(EstimateContext);

  const rowsConfig = [
    {
      backendName: 'is_enabled',
      displayName: 'Active',
      isBooleanField: true,
    },
    {
      backendName: 'cfu_count',
      displayName: 'CFUs',
    },
  ];

  const { values, initialValues } = useFormikContext();

  const shouldBeDisabled = isFormEditedBasedOnCurrentValues(
    values,
    initialValues,
    FLINK_POOL_DETAILS_FORM_NAMES_TO_FIELDS_MAP.get(FLINK_POOL_DETAILS_FORM)
  );

  return (
    <PaddedAndRaisedSegment disabled={shouldBeDisabled}>
      <ClusterInputTable
        disabled={shouldBeDisabled}
        keyNameInValues={FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG}
        rowsConfig={rowsConfig}
        tableName="Flink Pool Monthly Usage"
        updateFunc={async () => {
          const valuesToSendToBackend = getIn(values, FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG);
          const flinkPoolInputs = Object.values(valuesToSendToBackend.months);
          const payload = {
            id: flinkPoolId,
            estimate_id: estimateId,
            estimate_version: estimate?.inputs?.estimate_version,
            flink: { inputs: flinkPoolInputs },
          };
          const { error } = await updateFlinkPool({
            estimateId,
            flinkPoolId,
            payload,
          });
          if (error) {
            toastError(error);
          }
        }}
      />
    </PaddedAndRaisedSegment>
  );
};
