// @flow
import React, { useContext } from 'react';

import { EstimateContext } from '../../../contexts/EstimateContext';
import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';
import {
  CLUSTER_RETENTION_BACKEND_NAME,
  CLUSTER_RETENTION_INFINITE_BACKEND_NAME,
} from '../../../constants';
import { CheckBoxField } from '../../../formik-utils/FormFields';

export const ClusterRetentionInfiniteContainer = ({ disabled }) => {
  const estimate = useContext(EstimateContext);

  const clusterRetentionInfiniteConfig = CLUSTER_DETAILS_CONFIG_MAP.get(
    CLUSTER_RETENTION_INFINITE_BACKEND_NAME
  );

  return (
    <CheckBoxField
      disabled={disabled}
      fieldDisplayName={clusterRetentionInfiniteConfig.displayName}
      fieldName={clusterRetentionInfiniteConfig.backendFieldName}
      icon={clusterRetentionInfiniteConfig.icon}
      onChange={(event, value, setFieldValue, setFieldTouched, values, checked) => {
        if (checked) {
          setFieldValue(CLUSTER_RETENTION_BACKEND_NAME, estimate.deal_duration.deal_duration_days);
        }
      }}
    />
  );
};
