// @flow
import React from 'react';
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';

import { DownloadButton } from './buttons/DownloadButton';
import { Spacer } from './spacing/Spacer';

export const validateColumns = (columns) => {
  for (const column of columns) {
    const keysInColumn = Object.keys(column);
    if (!keysInColumn.includes('id') || !keysInColumn.includes('name')) {
      throw 'name and id are required keys in column';
    }
  }
};

const convertToCSV = (records, columns) => {
  validateColumns(columns);

  const newRecords = Array.isArray(records)
    ? records.map((record) => {
        if (record != null && typeof record === 'object') {
          // reconstructing object so that each value is stringified before unparse
          return Object.keys(record).reduce((acc, key) => {
            acc[key] = typeof record[key] === 'string' ? record[key] : JSON.stringify(record[key]);
            return acc;
          }, {});
        }
        return record;
      })
    : records;

  try {
    const headers = unparse({
      fields: columns.map((col) => col.name),
      data: [],
    });
    // use { header : false } as second argument if no need for header
    const vals = unparse(
      {
        data: newRecords,
        fields: columns.map((col) => col.id),
      },
      {
        header: false,
      }
    );
    return headers + vals;
  } catch (e) {
    return '';
  }
};

const handleDownload = (data, title, columns) => {
  const convertedBlobPart = convertToCSV(data, columns);
  convertedBlobPart && saveAs(new Blob([convertedBlobPart], { type: 'text/csv' }), `${title}.csv`);
};

export const TableToCsvDownloader = ({ data, title, columns }) => {
  return (
    <>
      <Spacer x={8} />
      <DownloadButton
        onClick={() => {
          handleDownload(data, title, columns);
        }}
      />
    </>
  );
};
