// @flow
import React from 'react';

import {
  constructDataForMonthlySummaryUI,
  getColumnDefsForMonthlySummaryBasedOnGivenData,
} from '../views/utils';
import { PaddedAndRaisedSegment } from '../presentational/PaddedAndRaisedSegment';
import { DataGrid } from '../views/grid-utils/DataGrid';
import { ESTIMATE_MONTHLY_SPEND_SUMMARY } from '../../constants';

export const MonthlySpendSummaryContainer = ({ monthlySpendSummary, disabled = false }) => {
  const columnDefs = getColumnDefsForMonthlySummaryBasedOnGivenData(monthlySpendSummary);
  const tableData = constructDataForMonthlySummaryUI(monthlySpendSummary);
  const columnNamesToPin = ['Total', 'Cost (with burst capacity)', 'Cost'];

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <DataGrid
        columnDefs={columnDefs}
        label={ESTIMATE_MONTHLY_SPEND_SUMMARY}
        pinnedRowsSelectorFunc={(row) => columnNamesToPin.includes(row.Field)}
        sizeColumnsToFitInitially={true}
        tableData={tableData}
      />
    </PaddedAndRaisedSegment>
  );
};
