// @flow
import { Link } from 'react-router-dom';
import React from 'react';

import { getLinkForEstimate, getLinkForHomePage } from '../links/utils';
import { StyledSpan } from '../../common-utils/styledComponents';

export const getEstimateSections = (estimateData) => {
  const estimateId = estimateData.inputs.id;
  const estimateName = estimateData.inputs.name;

  return [
    <a href={getLinkForHomePage()} key="home">
      Home
    </a>,
    <StyledSpan key="estimate"> Estimate </StyledSpan>,
    <Link key="estimateName" to={getLinkForEstimate(estimateId)}>
      {estimateName}
    </Link>,
  ];
};
