// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ConfirmModal } from '../../../presentational/modals/ConfirmModal';
import { useDeleteEstimateMutation } from '../../../../service-definitions/estimates';
import { toastError, toastSuccess } from '../../../presentational/notifications/utils';

export const EstimateDeleteWarningModal = ({
  isOpen,
  onClose,
  estimateName,
  estimateId,
  estimateVersion,
}) => {
  const [deleteEstimate] = useDeleteEstimateMutation();
  const { push } = useHistory();

  const header = `Delete Estimate ${estimateName}`;
  const headerBody = <p>Are you sure you want to delete this Estimate?</p>;

  const onClickHandlerForOK = async () => {
    const body = { estimate_version: estimateVersion };
    const { error } = await deleteEstimate({
      estimateId: estimateId,
      body,
    });

    if (error) {
      toastError(error);
    } else {
      toastSuccess('Estimate Delete was successful!');
      push('/');
    }
  };

  return (
    <ConfirmModal
      body={headerBody}
      header={header}
      isOpen={isOpen}
      onClickHandlerForCancel={onClose}
      onClickHandlerForOK={onClickHandlerForOK}
    />
  );
};
