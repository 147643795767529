// @flow

import { Modal } from 'semantic-ui-react';
import React from 'react';

import { StyledModal } from '../../../common-utils/styledComponents';

export const BaseModal = ({
  body,
  header,
  isOpen,
  actions,
  closeWhenClickedOutside = false,
  onClose,
  fullScreen = true,
  centered = null,
}) => (
  <StyledModal
    centered={centered}
    onClick={(e) => e.stopPropagation()}
    onClose={() => {
      if (closeWhenClickedOutside) {
        onClose();
      }
    }}
    open={isOpen}
    size={fullScreen ? 'fullscreen' : null}
  >
    <Modal.Header>{header}</Modal.Header>
    <Modal.Content>
      <p>{body}</p>
    </Modal.Content>
    <Modal.Actions>{actions}</Modal.Actions>
  </StyledModal>
);
