// @flow
import React, { useContext } from 'react';

import { EstimateContext } from '../../contexts/EstimateContext';
import { UserContext } from '../../contexts/UserContext';
import { getIfUserIsAdminAndCustomerViewIsDisabled } from '../../common-utils/utils';
import {
  ChildRendererWithoutHyphen,
  CurrencyFourDPRenderer,
  CurrencyZeroDPRenderer,
  MarginRenderer,
} from '../views/grid-utils/renderers';
import {
  HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
  HEADER_SUFFIX_FOR_MARGIN_COLUMNS,
} from '../../constants';
import { constructTableDataFromRows } from '../views/grid-utils/utils';
import { PaddedAndRaisedSegment } from '../presentational/PaddedAndRaisedSegment';
import { DataGrid } from '../views/grid-utils/DataGrid';

const isTotalBannerRow = (row) =>
  row.isFullWidthRow === true && row.banner === 'Total' && row['Rate Card Item'] === 'Total';

export const TotalSpendSummaryContainer = ({
  disabled,
  inputDataSourceFormattedAndOrdered,
  gridLabel,
  removeTotalBannerRow = false,
  removeZeroRows = true,
}) => {
  const estimate = useContext(EstimateContext);
  const user = useContext(UserContext);

  const columnDefs = [
    {
      field: 'Rate Card Item',
      cellRenderer: ChildRendererWithoutHyphen,
      minWidth: 300,
    },
    {
      field: 'Unit Price (List)',
      cellRenderer: CurrencyFourDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
    {
      field: 'Unit Price (Discounted)',
      cellRenderer: CurrencyFourDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
    {
      field: 'Total Spend (List)',
      cellRenderer: CurrencyZeroDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
    {
      field: 'Total Spend (Discounted)',
      cellRenderer: CurrencyZeroDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
  ];

  if (getIfUserIsAdminAndCustomerViewIsDisabled(user, estimate)) {
    columnDefs.splice(3, 0, {
      field: 'Unit Cost',
      cellRenderer: CurrencyFourDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    });
    columnDefs.push({
      field: 'Total Cost',
      cellRenderer: CurrencyZeroDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    });
    columnDefs.push({
      field: 'Margin',
      cellRenderer: MarginRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_MARGIN_COLUMNS,
    });
  }

  let tableData = constructTableDataFromRows(
    inputDataSourceFormattedAndOrdered,
    columnDefs,
    true,
    'Rate Card Item'
  );

  if (removeZeroRows) {
    if (getIfUserIsAdminAndCustomerViewIsDisabled(user, estimate)) {
      tableData = tableData.filter((x) => {
        return (
          x['Rate Card Item'] === 'Total' ||
          Number(x['Total Spend (List)']) !== 0 ||
          Number(x['Total Cost']) !== 0
        );
      });
    } else {
      tableData = tableData.filter((x) => {
        return x['Rate Card Item'] === 'Total' || Number(x['Total Spend (List)']) !== 0;
      });
    }
  }

  if (removeTotalBannerRow) {
    tableData = tableData.filter((row) => !isTotalBannerRow(row));
  }

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <DataGrid
        addDataAsChildrenInCaseOfMapData={true}
        bannerFieldNameInColDefsForFullWidthRows="Rate Card Item"
        columnDefs={columnDefs}
        defaultColDef={{
          sortable: false,
          filter: false,
          resizable: true,
        }}
        inputDataSource={inputDataSourceFormattedAndOrdered}
        label={gridLabel}
        pinnedRowsSelectorFunc={(row) =>
          row['Rate Card Item'] === 'Total' && row.isFullWidthRow !== true
        }
        sizeColumnsToFitInitially={true}
        tableData={tableData}
      />
    </PaddedAndRaisedSegment>
  );
};
