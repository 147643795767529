// @flow
import React from 'react';

import { DEDICATED_SURVEY_INPUTS_RENEWAL_BACKEND_FIELD_NAME } from '../../../../constants';
import { BooleanSelectField } from '../../../../formik-utils/FormFields';
import { DEDICATED_CLUSTER_USAGE_SURVEY_INPUTS_CONFIG_MAP } from '../../../../configuration/dedicated-cluster-usage-survey-inputs';

export const DedicatedSurveyRenewalContainer = () => {
  const { backendFieldName, displayName, multiple } =
    DEDICATED_CLUSTER_USAGE_SURVEY_INPUTS_CONFIG_MAP.get(
      DEDICATED_SURVEY_INPUTS_RENEWAL_BACKEND_FIELD_NAME
    );

  return (
    <BooleanSelectField
      addLabel={false}
      disableOnFormErrors={false}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={true}
      multiple={multiple}
    />
  );
};
