// @flow

import { array, mixed, string } from 'yup';

import {
  DEDICATED_SURVEY_INPUTS_INDUSTRY_BACKEND_FIELD_NAME,
  DEDICATED_SURVEY_INPUTS_INDUSTRY_DISPLAY_NAME,
  DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_FIELD_NAME,
  DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_NAME,
  DEDICATED_SURVEY_INPUTS_OTHER_REASON_DISPLAY_NAME,
  DEDICATED_SURVEY_INPUTS_REASON_BACKEND_FIELD_NAME,
  DEDICATED_SURVEY_INPUTS_REASON_DISPLAY_NAME,
  DEDICATED_SURVEY_INPUTS_RENEWAL_BACKEND_FIELD_NAME,
  DEDICATED_SURVEY_INPUTS_RENEWAL_DISPLAY_NAME,
  DEDICATED_SURVEY_INPUTS_THEATRE_BACKEND_FIELD_NAME,
  DEDICATED_SURVEY_INPUTS_THEATRE_DISPLAY_NAME,
} from '../constants';

import { convertConfigArrayToMap } from './utils';

export const DEDICATED_CLUSTER_USAGE_SURVEY_INPUTS_CONFIG = [
  {
    displayName: DEDICATED_SURVEY_INPUTS_RENEWAL_DISPLAY_NAME,
    backendFieldName: DEDICATED_SURVEY_INPUTS_RENEWAL_BACKEND_FIELD_NAME,
    validation: string().label(DEDICATED_SURVEY_INPUTS_RENEWAL_DISPLAY_NAME).required(),
    multiple: false,
  },
  {
    displayName: DEDICATED_SURVEY_INPUTS_THEATRE_DISPLAY_NAME,
    backendFieldName: DEDICATED_SURVEY_INPUTS_THEATRE_BACKEND_FIELD_NAME,
    validation: string().label(DEDICATED_SURVEY_INPUTS_THEATRE_DISPLAY_NAME).required(),
    multiple: false,
  },
  {
    displayName: DEDICATED_SURVEY_INPUTS_INDUSTRY_DISPLAY_NAME,
    backendFieldName: DEDICATED_SURVEY_INPUTS_INDUSTRY_BACKEND_FIELD_NAME,
    validation: string().label(DEDICATED_SURVEY_INPUTS_INDUSTRY_DISPLAY_NAME).required(),
    multiple: false,
  },
  {
    displayName: DEDICATED_SURVEY_INPUTS_REASON_DISPLAY_NAME,
    backendFieldName: DEDICATED_SURVEY_INPUTS_REASON_BACKEND_FIELD_NAME,
    validation: array(string().label(DEDICATED_SURVEY_INPUTS_REASON_DISPLAY_NAME).required())
      .label(DEDICATED_SURVEY_INPUTS_REASON_DISPLAY_NAME)
      .min(1)
      .required()
      .default([]),
    multiple: true,
  },
  {
    displayName: DEDICATED_SURVEY_INPUTS_OTHER_REASON_DISPLAY_NAME,
    backendFieldName: DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_FIELD_NAME,

    validation: mixed().when(DEDICATED_SURVEY_INPUTS_REASON_BACKEND_FIELD_NAME, {
      is: (reasons) => {
        return reasons && reasons.includes(DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_NAME);
      },
      then: string()
        .label(DEDICATED_SURVEY_INPUTS_OTHER_REASON_DISPLAY_NAME)
        .required()
        .min(10)
        .max(200)
        .matches(
          /^[a-zA-Z0-9-_ .]+$/,
          'Only alphabets, numbers, periods, hyphens and underscores are allowed in Reason '
        ),
      otherwise: mixed().notRequired(),
    }),
  },
];

export const DEDICATED_CLUSTER_USAGE_SURVEY_INPUTS_CONFIG_MAP = convertConfigArrayToMap(
  DEDICATED_CLUSTER_USAGE_SURVEY_INPUTS_CONFIG
);
