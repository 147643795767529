// @flow
import React from 'react';

import {
  FLINK_POOL_CONFIGURATION_SUMMARY,
  FLINK_POOL_ID,
  FLINK_POOL_ID_BACKEND_NAME,
  FLINK_POOL_NAME,
  FLINK_POOL_NAME_BACKEND_NAME,
  FLINK_POOL_PROVIDER,
  FLINK_POOL_PROVIDER_BACKEND_NAME,
  FLINK_POOL_REGION,
  FLINK_POOL_REGION_BACKEND_NAME,
} from '../../../constants';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { StyledH3 } from '../../presentational/headings/StyledH3';
import { DataGrid } from '../../views/grid-utils/DataGrid';
import { DEFAULT_DEFAULT_COLUMN_DEFINITION } from '../../views/grid-utils/constants';
import { Number2DPRenderer } from '../../views/grid-utils/renderers';
import { FlinkPoolNameRenderer } from '../../views/flink-pools-summary/FlinkPoolNameRenderer';

export const FlinkPoolConfigurationsSummaryContainer = ({
  flinkPoolConfigsArray,
  addLinkToFlinkPoolName = true,
  disabled = false,
}) => {
  const inputDataSource = flinkPoolConfigsArray;
  const areAnyFlinkPoolsConfigured = inputDataSource.length > 0;

  const columnDefs = [
    {
      field: FLINK_POOL_ID,
      backEndFieldName: FLINK_POOL_ID_BACKEND_NAME,
      hide: true,
    },
    {
      field: FLINK_POOL_NAME,
      cellRenderer: addLinkToFlinkPoolName ? FlinkPoolNameRenderer : null,
      backEndFieldName: FLINK_POOL_NAME_BACKEND_NAME,
    },
    {
      field: FLINK_POOL_PROVIDER,
      backEndFieldName: FLINK_POOL_PROVIDER_BACKEND_NAME,
    },
    {
      field: FLINK_POOL_REGION,
      backEndFieldName: FLINK_POOL_REGION_BACKEND_NAME,
    },
    {
      field: 'Average CFUs',
      backEndFieldName: 'average_cfus',
      headerName: 'Average CFUs',
      cellRenderer: Number2DPRenderer,
    },
  ];

  const defaultColDef = {
    ...DEFAULT_DEFAULT_COLUMN_DEFINITION,
    wrapHeaderText: true,
  };

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      {!areAnyFlinkPoolsConfigured ? (
        <StyledH3 as="h3" text="Add a flink pool to see results" />
      ) : (
        <DataGrid
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          downloadAllCols={false}
          inputDataSource={inputDataSource}
          label={FLINK_POOL_CONFIGURATION_SUMMARY}
          sizeColumnsToFitInitially={true}
          tooltipShowDelay={50}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
