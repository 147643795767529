// @flow
import React from 'react';

import { FLINK_POOL_ID, FLINK_POOL_NAME } from '../../../constants';
import { DeleteRenderer } from '../grid-utils/renderers';

import FlinkPoolDeleteWarningModal from './FlinkPoolDeleteWarningModal';

export const FlinkPoolDeleteRenderer = ({ data }) => {
  return (
    <DeleteRenderer>
      {(isDeleteModalOpen, setDeleteModalOpen) => {
        return (
          <FlinkPoolDeleteWarningModal
            flinkPoolId={data[FLINK_POOL_ID]}
            flinkPoolName={data[FLINK_POOL_NAME]}
            isOpen={isDeleteModalOpen}
            setOpen={setDeleteModalOpen}
          />
        );
      }}
    </DeleteRenderer>
  );
};
