// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridDeleteButton,
} from '../../../formik-utils/FieldArrayGrid';
import { useDeleteCustomDiscountsConfigLevelDiscountMutation } from '../../../service-definitions/estimates';
import { DEFAULT_VALUE_FOR_DB_ROW_ID, ROW_ID } from '../../../constants';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { getIfAllMetricsAreFrozen } from '../utils';

const getIfDimensionConfigDeleteButtonShouldBeDisabled = (readOnlyMode, rowValues, formik) => {
  if (readOnlyMode) {
    return true;
  }

  if (!rowValues?.isRowFrozen) {
    return false;
  }

  if (!getIfAllMetricsAreFrozen(formik.values)) {
    return true;
  }

  return !formik.isValid;
};

export const DimensionsConfigDeleteButtonContainer = () => {
  const [deleteMLDFunc] = useDeleteCustomDiscountsConfigLevelDiscountMutation();
  const { estimateId } = useParams();
  const { rowValues, readOnlyMode } = useContext(FieldArrayGridConfigItemContext);
  const estimate = useContext(EstimateContext);
  const formik = useFormikContext();

  const payload = {
    estimate_id: estimateId,
    estimate_version: estimate?.inputs?.estimate_version,
    ...rowValues,
    id: rowValues[ROW_ID] === DEFAULT_VALUE_FOR_DB_ROW_ID ? '-1' : rowValues[ROW_ID],
  };

  const deleteMLDFuncParams = {
    payload: payload,
    estimateId: estimateId,
    metricsDiscountId: rowValues?.metric_discount_id ?? -1,
  };

  return (
    <FieldArrayGridDeleteButton
      deleteFunc={deleteMLDFunc}
      deleteFuncParams={deleteMLDFuncParams}
      disabled={getIfDimensionConfigDeleteButtonShouldBeDisabled(readOnlyMode, rowValues, formik)}
    />
  );
};
