// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ConfirmModal } from '../presentational/modals/ConfirmModal';
import { useRequestEstimateMutation } from '../../service-definitions/estimates';
import { toastError, toastSuccess } from '../presentational/notifications/utils';

export const EstimateRequestModal = ({ isOpen, setOpen, estimateId }) => {
  const header = `You do not have access to this Estimate`;
  const [requestEstimate] = useRequestEstimateMutation();
  const { push } = useHistory();

  return (
    <ConfirmModal
      body={<p>Request access?</p>}
      centered={false}
      header={header}
      isOpen={isOpen}
      okButtonText="Request"
      onClickHandlerForCancel={() => {
        setOpen(false);
        push('/');
      }}
      onClickHandlerForOK={async () => {
        const { error } = await requestEstimate({ estimateId });
        if (error) {
          toastError(error);
        } else {
          toastSuccess(
            'Requested for access! A slack notification was sent to the owner regarding your request!'
          );
        }
        setOpen(false);
        push('/');
      }}
    />
  );
};
