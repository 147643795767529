// @flow
import React, { useContext } from 'react';

import { ESTIMATE_COMMIT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-commit-details';
import { CUSTOM_DISCOUNTS_ENABLED_BACKEND_NAME } from '../../constants';
import { BooleanSelectField } from '../../formik-utils/FormFields';
import { getIfUserIsAdminBasedOnGivenDetails } from '../../common-utils/utils';
import { UserContext } from '../../contexts/UserContext';

export const CustomDiscountsEnabledContainer = () => {
  const user = useContext(UserContext);
  const isUserAdmin = getIfUserIsAdminBasedOnGivenDetails(user);

  const customDiscountsEnabledConfig = ESTIMATE_COMMIT_DETAILS_CONFIG_MAP.get(
    CUSTOM_DISCOUNTS_ENABLED_BACKEND_NAME
  );

  return (
    <BooleanSelectField
      disabled={!isUserAdmin}
      fieldDisplayName={customDiscountsEnabledConfig.displayName}
      fieldName={customDiscountsEnabledConfig.backendFieldName}
    />
  );
};
