// @flow
import { Menu } from 'semantic-ui-react';
import React from 'react';

import { CaretRightIcon } from '../icons/CaretRightIcon';
import { CaretLeftIcon } from '../icons/CaretLeftIcon';
import { StyledYearSelectorMenu } from '../../../common-utils/styledComponents';

import { getYearNumberToUseBasedOnLimits } from './utils';

export const YearSelector = ({ selectedYearIndex, totalYears, onChange, disabled }) => {
  if (totalYears <= 0) {
    throw 'totalYears should be a positive integer';
  }

  if (selectedYearIndex > totalYears) {
    throw 'selectedYear must be less than totalYears!';
  }

  return (
    <StyledYearSelectorMenu compact={true}>
      <Menu.Item
        disabled={disabled}
        onClick={() => {
          const previousYear = getYearNumberToUseBasedOnLimits(selectedYearIndex - 1, totalYears);
          onChange(previousYear);
        }}
      >
        <CaretLeftIcon />
      </Menu.Item>

      <Menu.Item>
        Year {selectedYearIndex} / {totalYears}
      </Menu.Item>

      <Menu.Item
        disabled={disabled}
        onClick={() => {
          const nextYear = getYearNumberToUseBasedOnLimits(selectedYearIndex + 1, totalYears);
          onChange(nextYear);
        }}
      >
        <CaretRightIcon />
      </Menu.Item>
    </StyledYearSelectorMenu>
  );
};
