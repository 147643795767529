// @flow
import React from 'react';
import { CartesianGrid, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import {
  getInitialDataSetProps,
  getLegend,
  getYAxis,
  tooltipCurrencyFormatter,
} from '../clusters-consumption-graph/utils';
import { convertObjectToContainKeysInCapitals } from '../../../common-utils/utils';

import { StyledBarChart } from './StyledBarChart';

const makeKeysUpperCaseAndAddDataSetNameToTheData = (dataToUse) => {
  const formattedData = convertObjectToContainKeysInCapitals(dataToUse);

  for (const [key] of Object.entries(formattedData)) {
    formattedData[key].name = key;
  }

  return formattedData;
};

export const TCOGraphContainer = ({ tcoSummaryData, BarsToDisplay }) => {
  const formattedData = makeKeysUpperCaseAndAddDataSetNameToTheData(tcoSummaryData);

  const [dataSetProps, setDatasetProps] = React.useState(
    getInitialDataSetProps(Object.keys(formattedData))
  );

  return (
    <ResponsiveContainer height={600}>
      <StyledBarChart
        data={Object.values(formattedData)}
        height={600}
        margin={{
          top: 25,
          right: 5,
          left: 15,
          bottom: 20,
        }}
      >
        <XAxis dataKey="name" height={110} />
        {getYAxis()}
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip formatter={tooltipCurrencyFormatter} />
        <>{BarsToDisplay}</>
        {getLegend(dataSetProps, setDatasetProps)}
      </StyledBarChart>
    </ResponsiveContainer>
  );
};

// Note: This file cannot be refactored and broken down into components because of a limitation of recharts
// That's the reason we have used functions
