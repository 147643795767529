// @flow
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Image } from 'semantic-ui-react';
import { useCookies } from 'react-cookie';

import { THEME_DARK } from '../constants';
import { StyledContainer } from '../common-utils/styledComponents';
import { Spacer } from '../components/presentational/spacing/Spacer';
import { PrimaryButton } from '../components/presentational/buttons/PrimaryButton';

const AuthLogout = () => {
  const [cookies] = useCookies(['theme']);
  const { oktaAuth, authState } = useOktaAuth();

  if (authState?.isPending) {
    return null;
  }

  return (
    <StyledContainer style={{ paddingTop: '100px' }} textAlign="center">
      <Image
        centered={true}
        size="small"
        src={cookies.theme === THEME_DARK ? '/images/logo-light.svg' : '/images/logo-dark.svg'}
      />
      <Spacer y={40} />
      <PrimaryButton
        onClick={async () => {
          oktaAuth.signInWithRedirect();
        }}
        text="Login with Okta"
      />
    </StyledContainer>
  );
};

export default AuthLogout;
