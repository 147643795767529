// @flow
import React from 'react';

import { StyledButton } from '../../../common-utils/styledComponents';

export const ResetFormButton = ({ disabled, onClick }) => {
  return (
    <>
      <StyledButton
        circular={true}
        color="red"
        data-testid="reset-form-button"
        disabled={disabled}
        icon="cancel"
        onClick={onClick}
        size="small"
      />
    </>
  );
};

// todo::Assign orange color to Warning and use it across the board; Similarly for info, danger and success
