// @flow
import { Icon } from 'semantic-ui-react';
import React from 'react';

export const CopyIcon = ({ onClick, disabled }) => (
  <Icon
    color="grey"
    data-testid="copy-icon"
    disabled={disabled}
    name="copy outline"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  />
);
