// @flow
import { DepthAwareChildRenderer } from '../grid-utils/renderers';

import { TCOTooltipWithDepthAwareChildRenderer } from './TCOTooltipWithDepthAwareChildRenderer';
import { TCO_VIEW_COLUMN_TO_TOOLTIPS_MAP } from './constants';

export const TCOTableFieldsRenderer = (props, isForCluster) => {
  const fieldName = props.data.field;
  const vendor = props?.data?.rowHeaderTrail?.[0] ?? 'NA';
  const tooltip = isForCluster
    ? TCO_VIEW_COLUMN_TO_TOOLTIPS_MAP?.[fieldName]
    : TCO_VIEW_COLUMN_TO_TOOLTIPS_MAP?.[fieldName]?.[vendor];

  if (tooltip) {
    return TCOTooltipWithDepthAwareChildRenderer(props, tooltip);
  }

  return DepthAwareChildRenderer(props);
};
