// @flow
import React, { useContext, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { cloneDeep, pickBy } from 'lodash';

import { DataGrid } from '../grid-utils/DataGrid';
import { TCO_SUMMARY } from '../../../constants';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { CurrencyZeroDPRenderer } from '../grid-utils/renderers';
import { constructTableDataForViewBasedOnTheNewRecursiveFormat } from '../utils';
import { ClusterTCOInputsTopLevelContainer } from '../../tco/cluster-inputs/ClusterTCOInputsTopLevelContainer';

import { TCOGraphContainer } from './TCOGraphContainer';
import { TCOFilterComponent } from './TCOFilterComponent';
import { TCOTableFieldsRenderer } from './TCOTableFieldsRenderer';

const getColDefs = (totalNumberOfYearsInEstimate, isForCluster) => {
  const yearCols = [];

  for (let i = 1; i <= totalNumberOfYearsInEstimate; i++) {
    yearCols.push({
      field: `Year ${i}`,
      backEndFieldName: `Year ${i}`,
      cellRenderer: CurrencyZeroDPRenderer,
      sortable: false,
      filter: false,
    });
  }

  return [
    {
      field: 'field',
      backEndFieldName: 'field',
      minWidth: 240,
      sortable: false,
      filter: false,
      cellRenderer: (props) => TCOTableFieldsRenderer(props, isForCluster),
      dependentFields: ['depth', 'isAHeaderLevelRow', 'rowHeaderTrail'],
    },
    ...yearCols,
    {
      field: 'Total',
      backEndFieldName: 'Total',
      sortable: false,
      filter: false,
      cellRenderer: CurrencyZeroDPRenderer,
    },
  ];
};

const getDataToConsiderBasedOnAppliedFiltersIfAny = (tcoSelectedOptions, data, isForCluster) => {
  const dataToConsider = cloneDeep(data);
  if (tcoSelectedOptions.length > 0) {
    // First, in tco_summary, filter out only those keys which are selected
    dataToConsider.tco_summary = pickBy(dataToConsider.tco_summary, (value, key) =>
      tcoSelectedOptions.includes(key.toUpperCase())
    );

    // Next, filter children
    if (isForCluster) {
      dataToConsider.tco.children = dataToConsider.tco.children.filter((x) =>
        tcoSelectedOptions.map((x) => `${x} INFRASTRUCTURE`).includes(x.row_header.toUpperCase())
      );
    } else {
      dataToConsider.tco.children = dataToConsider.tco.children.filter((x) =>
        tcoSelectedOptions.includes(x.row_header.toUpperCase())
      );
    }
  }

  return dataToConsider;
};

export const TCOViewSummaryContainer = ({
  data,
  disabled,
  tcoSummaryDataAndBarsExtractionFunc,
  isForCluster = false,
}) => {
  // Before anything, get the list of TCO options selected and filter data accordingly
  const [tcoSelectedFilters, setTCOSelectedFilters] = useState([]);

  const dataToConsider = getDataToConsiderBasedOnAppliedFiltersIfAny(
    tcoSelectedFilters,
    data,
    isForCluster
  );

  const tcoData = dataToConsider?.tco ?? {};
  const estimate = useContext(EstimateContext);
  const totalNumberOfYearsInEstimate = estimate?.deal_duration?.deal_duration_years ?? 0;

  const columnNamesToBeMappedToColumnValues = (tcoData?.columns ?? []).map((x) => x.column_value);

  const tableData = constructTableDataForViewBasedOnTheNewRecursiveFormat(
    tcoData?.children ?? [],
    columnNamesToBeMappedToColumnValues
  );

  const columnDefs = getColDefs(totalNumberOfYearsInEstimate, isForCluster);

  const { tcoSummaryData, BarsToDisplay } = tcoSummaryDataAndBarsExtractionFunc(
    dataToConsider,
    totalNumberOfYearsInEstimate
  );

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <Grid>
        <Grid.Column floated="right" width={4}>
          <TCOFilterComponent
            handleChange={(e, { value }) => {
              setTCOSelectedFilters(value);
            }}
          />
        </Grid.Column>
      </Grid>

      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={4}>
            <TCOGraphContainer BarsToDisplay={BarsToDisplay} tcoSummaryData={tcoSummaryData} />
          </Grid.Column>
          <Grid.Column width={12}>
            {isForCluster && (
              <Grid.Row>
                <ClusterTCOInputsTopLevelContainer />
              </Grid.Row>
            )}

            <Grid.Row>
              <DataGrid
                columnDefs={columnDefs}
                inputDataSource={tableData}
                label={TCO_SUMMARY}
                maxWidth="2800px"
                sizeColumnsToFitInitially={true}
              />
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </PaddedAndRaisedSegment>
  );
};
