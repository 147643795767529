// @flow

import React from 'react';
import { Grid } from 'semantic-ui-react';

import { StyledGridRow, StyledPagination } from '../../../common-utils/styledComponents';

import { ellipsisItemIcon, lastItemIcon, nextItemIcon, prevItemIcon, firstItemIcon } from './icons';

export const PaginationContainer = ({
  totalNumberOfRecords,
  numberOfRecordsPerPage,
  activePage,
  onPageChange,
}) => {
  const handlePageChange = (e, { activePage }) => {
    onPageChange(activePage);
  };

  const numOfPages = Math.ceil(totalNumberOfRecords / numberOfRecordsPerPage);

  return (
    numOfPages > 1 && (
      <Grid>
        <StyledGridRow>
          <Grid.Column textAlign="left">
            <StyledPagination
              activePage={activePage}
              boundaryRange={0}
              data-testid="pagination-container"
              ellipsisItem={ellipsisItemIcon}
              firstItem={firstItemIcon}
              lastItem={lastItemIcon}
              nextItem={nextItemIcon}
              onPageChange={handlePageChange}
              prevItem={prevItemIcon}
              siblingRange={3}
              totalPages={numOfPages}
            />
          </Grid.Column>
        </StyledGridRow>
      </Grid>
    )
  );
};
