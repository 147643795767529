// @flow
import React, { useContext } from 'react';
import { shuffle } from 'lodash';

import {
  DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_NAME,
  DEDICATED_SURVEY_INPUTS_REASON_BACKEND_FIELD_NAME,
} from '../../../../constants';
import { SelectField } from '../../../../formik-utils/FormFields';
import { DEDICATED_CLUSTER_USAGE_SURVEY_INPUTS_CONFIG_MAP } from '../../../../configuration/dedicated-cluster-usage-survey-inputs';
import { getDropdownOptionsFromLabelAndValuesArray } from '../../../../common-utils/utils';
import { DedicatedSurveyInputsContext } from '../../../../contexts/DedicatedSurveyInputsContext';

const getShuffledOptionsInFEFormat = (surveyFieldsInputData) => {
  const options = surveyFieldsInputData?.reason_list ?? [];
  const optionsOtherThanOther = options.filter(
    (x) => x.value !== DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_NAME
  );
  const optionsInFEFormat = getDropdownOptionsFromLabelAndValuesArray(optionsOtherThanOther);
  return [
    ...shuffle(optionsInFEFormat),
    {
      name: DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_NAME,
      display_name: DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_NAME,
    },
  ];
};

export const DedicatedSurveyReasonSelectionContainer = () => {
  const { surveyFieldsInputData } = useContext(DedicatedSurveyInputsContext);
  const dropdownOptions = getShuffledOptionsInFEFormat(surveyFieldsInputData);

  const { backendFieldName, displayName, multiple } =
    DEDICATED_CLUSTER_USAGE_SURVEY_INPUTS_CONFIG_MAP.get(
      DEDICATED_SURVEY_INPUTS_REASON_BACKEND_FIELD_NAME
    );

  return (
    <SelectField
      addLabel={false}
      disableOnFormErrors={false}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={true}
      multiple={multiple}
      options={dropdownOptions}
    />
  );
};
