// @flow
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

import { StyledMenuItem } from '../../common-utils/styledComponents';

export const WelcomeMessageMenuItem = () => {
  const { authState } = useOktaAuth();
  const userName = authState?.idToken?.claims?.name;
  return <StyledMenuItem>Welcome, {userName}</StyledMenuItem>;
};
