// @flow
import { useParams } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';

import { DataFetcherContainer } from '../components/presentational/DataFetcherContainer';
import { useGetFlinkPoolQuery } from '../service-definitions/estimates';

import { FlinkPoolContext } from './FlinkPoolContext';

export const FlinkPoolContextProvider = ({ children }) => {
  const { estimateId, flinkPoolId } = useParams();

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId, flinkPoolId: flinkPoolId },
          {
            skip: !estimateId || !flinkPoolId,
          },
        ],
        [flinkPoolId, estimateId]
      )}
      dataFetchingFunction={useCallback(useGetFlinkPoolQuery, [])}
    >
      {(data) => {
        return <FlinkPoolContext.Provider value={data}>{children}</FlinkPoolContext.Provider>;
      }}
    </DataFetcherContainer>
  );
};
