// @flow
import React from 'react';

import { TCO_OVER_PROVISION_PERCENTAGE_BACKEND_FIELD_NAME } from '../../../constants';
import { TextField } from '../../../formik-utils/FormFields';
import { TEXT_NUMBER_INPUT_TYPE } from '../../../formik-utils/consts';
import { TCO_ESTIMATE_LEVEL_INPUTS_CONFIG_MAP } from '../../../configuration/tco-estimate-inputs';

export const OverProvisionPercentageContainer = () => {
  const { backendFieldName, displayName, icon, pathToFollowInValues, tooltip } =
    TCO_ESTIMATE_LEVEL_INPUTS_CONFIG_MAP.get(TCO_OVER_PROVISION_PERCENTAGE_BACKEND_FIELD_NAME);

  return (
    <TextField
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      icon={icon}
      pathToFollowInValues={pathToFollowInValues}
      tooltip={tooltip}
      type={TEXT_NUMBER_INPUT_TYPE}
    />
  );
};
