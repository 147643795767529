// @flow
import { Grid } from 'semantic-ui-react';
import React from 'react';
import { useFormikContext } from 'formik';

import { StyledGridRow } from '../../../common-utils/styledComponents';
import { Spacer } from '../../presentational/spacing/Spacer';
import {
  TCO_INPUTS_JSON_BACKEND_NAME,
  TCO_CLUSTER_TIERED_STORAGE_BACKEND_FIELD_NAME,
} from '../../../constants';

import { MissionCriticalInputContainer } from './MissionCriticalInputContainer';
import { FollowerFetchTCOInputContainer } from './FollowerFetchTCOInputContainer';
import { TieredStorageTCOInputContainer } from './TieredStorageTCOInputContainer';
import { LocalRetentionContainer } from './LocalRetentionContainer';

export const TCOClusterLevelInputsGrid = () => {
  const { values } = useFormikContext();

  return (
    <Grid columns={5} stackable={true} verticalAlign="middle">
      <Spacer y={12} />
      <StyledGridRow>
        <Grid.Column>
          <MissionCriticalInputContainer />
        </Grid.Column>
        <Grid.Column>
          <FollowerFetchTCOInputContainer />
        </Grid.Column>
        <Grid.Column>
          <TieredStorageTCOInputContainer />
        </Grid.Column>
        <>
          {values[TCO_INPUTS_JSON_BACKEND_NAME][TCO_CLUSTER_TIERED_STORAGE_BACKEND_FIELD_NAME] ===
            true && (
            <Grid.Column>
              <LocalRetentionContainer />
            </Grid.Column>
          )}
        </>
      </StyledGridRow>
    </Grid>
  );
};
