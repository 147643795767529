// @flow
import React, { useContext } from 'react';
import { startCase } from 'lodash';

import { DataGrid } from '../grid-utils/DataGrid';
import { ChildRenderer, CurrencyZeroDPRenderer, MarginRenderer } from '../grid-utils/renderers';
import { ESTIMATE_ANNUAL_SUMMARIES } from '../../../constants';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { EstimateContext } from '../../../contexts/EstimateContext';

import { EffectiveDiscountExplainerRenderer } from './EffectiveDiscountExplainerRenderer';

const FIELD_COL_WIDTH = 510;
const TOTAL_COL_WIDTH = 110;
const YEAR_COL_WIDTH = 110;

const FieldsRenderer = (props) => {
  const lowerCaseField = props.data.field.toLowerCase();

  if (lowerCaseField.includes('margin')) {
    return MarginRenderer(props);
  }

  if (lowerCaseField === 'Effective Discount (excludes Support)'.toLowerCase()) {
    return EffectiveDiscountExplainerRenderer(props, false);
  }

  if (
    lowerCaseField === 'Effective Discount with Credits and Bursts (excludes Support)'.toLowerCase()
  ) {
    return EffectiveDiscountExplainerRenderer(props, true);
  }

  return CurrencyZeroDPRenderer(props);
};

const getColDefs = (totalNumberOfYearsInEstimate) => {
  const yearCols = [];

  for (let i = 1; i <= totalNumberOfYearsInEstimate; i++) {
    yearCols.push({
      field: `Year ${i}`,
      backEndFieldName: `Year ${i}`,
      minWidth: YEAR_COL_WIDTH,
      cellRenderer: FieldsRenderer,
      sortable: false,
      dependentFields: [`Year ${i}_explainers`],
    });
  }

  return [
    {
      field: 'field',
      backEndFieldName: 'field',
      minWidth: FIELD_COL_WIDTH,
      sortable: false,
      cellRenderer: ChildRenderer,
    },
    ...yearCols,
    {
      field: 'Total',
      backEndFieldName: 'Total',
      minWidth: TOTAL_COL_WIDTH,
      sortable: false,
      cellRenderer: FieldsRenderer,
      dependentFields: ['is_child', 'Total_explainers'],
    },
  ];
};

const getGridWidth = (totalNumberOfYearsInEstimate) =>
  FIELD_COL_WIDTH + YEAR_COL_WIDTH + TOTAL_COL_WIDTH * totalNumberOfYearsInEstimate + 50;

export const AnnualSummaryContainer = ({ disabled }) => {
  const estimate = useContext(EstimateContext);
  const totalNumberOfYearsInEstimate = estimate?.deal_duration?.deal_duration_years ?? 0;
  const inputDataSource = estimate.annual_summaries;
  const inputDataSourceInFrontEndFormat = {};

  for (const [key, rows] of Object.entries(inputDataSource)) {
    const formattedRows = [];

    rows.forEach((row) => {
      const formattedRow = { ...row };
      formattedRow[row.total_value.label] = row.total_value.value;
      formattedRow[`${row.total_value.label}_explainers`] = row.total_value.explainers;
      row.yearly_values.forEach((yearlyValue) => {
        formattedRow[yearlyValue.label] = yearlyValue.value;
        formattedRow[`${yearlyValue.label}_explainers`] = yearlyValue.explainers;
      });
      formattedRows.push(formattedRow);
    });

    inputDataSourceInFrontEndFormat[startCase(key.split('_').join(' '))] = formattedRows;
  }

  const columnDefs = getColDefs(totalNumberOfYearsInEstimate);
  const gridWidth = getGridWidth(totalNumberOfYearsInEstimate);

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <DataGrid
        addDataAsChildrenInCaseOfMapData={false}
        columnDefs={columnDefs}
        inputDataSource={inputDataSourceInFrontEndFormat}
        label={ESTIMATE_ANNUAL_SUMMARIES}
        width={`${gridWidth + 40}px`}
      />
    </PaddedAndRaisedSegment>
  );
};
