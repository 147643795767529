// @flow
import React from 'react';

import { StyledContainer } from '../../common-utils/styledComponents';

import { NegativeMessageContainer } from './messages/NegativeMessageContainer';

export const QueryError = ({ error }) => {
  return (
    <StyledContainer>
      <NegativeMessageContainer
        body={
          <>
            <p>Status: {error?.status ?? 'NA'}</p>
            <p>Error: {error?.data?.error ?? 'NA'}</p>
            <p>Please contact #cloud-commitment-estimator channel</p>
          </>
        }
        header="An Unexpected Error Occurred"
      />
    </StyledContainer>
  );
};
