// @flow
import React, { useContext } from 'react';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridSelectField,
} from '../../../formik-utils/FieldArrayGrid';
import { STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME } from '../../../constants';
import { STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG_MAP } from '../../../configuration/stream-governance';
import { getDropdownOptions } from '../utils';

export const PackageNameContainer = ({ dataUniverse }) => {
  const { rowValues } = useContext(FieldArrayGridConfigItemContext);

  const packageNameConfig = STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG_MAP.get(
    STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME
  );

  const packageNameOptions = getDropdownOptions(dataUniverse, rowValues, packageNameConfig);

  return (
    <FieldArrayGridSelectField columnConfig={packageNameConfig} options={packageNameOptions} />
  );
};
