// @flow

import React from 'react';

import { StyledGridFullWidthRow } from '../../../common-utils/styledComponents';

export const constructTableDataFromRows = (
  rows,
  columnDefs,
  addDataAsChildrenInCaseOfMapData,
  bannerFieldNameInColDefsForFullWidthRows
) => {
  // Rows can either be an array or a Map
  if (Array.isArray(rows)) {
    return constructTableDataFromRowsArray(rows, columnDefs);
  }
  return constructTableDataFromRowsMap(
    rows,
    columnDefs,
    addDataAsChildrenInCaseOfMapData,
    bannerFieldNameInColDefsForFullWidthRows
  );
};

const addDependentCols = (currRowObj, row, dependentFields) => {
  for (const dep of dependentFields) {
    currRowObj[dep] = row[dep];
  }
};

const inflateObjectWithFieldRelatedInfo = (
  currRowObj,
  row,
  field,
  backEndFieldName,
  dependentFields
) => {
  currRowObj[field] = row[backEndFieldName];
  // If there are any dependent fields, copy them over too
  addDependentCols(currRowObj, row, dependentFields);
};

export const constructTableDataFromRowsArray = (rows, columnDefs) => {
  const tableData = [];
  rows.forEach((row) => {
    const currRowObj = {};
    for (const col of columnDefs) {
      if (col.children && Array.isArray(col.children) && col.children.length > 0) {
        for (const child of col.children) {
          inflateObjectWithFieldRelatedInfo(
            currRowObj,
            row,
            child.field,
            child?.backEndFieldName ?? child.field,
            child?.dependentFields ?? []
          );
        }
      } else {
        inflateObjectWithFieldRelatedInfo(
          currRowObj,
          row,
          col.field,
          col?.backEndFieldName ?? col.field,
          col?.dependentFields ?? []
        );
      }
    }
    tableData.push(currRowObj);
  });
  return tableData;
};

export const constructTableDataFromRowsMap = (
  rowsMap,
  columnDefs,
  addDataAsChildrenInCaseOfMapData,
  bannerFieldNameInColDefsForFullWidthRows
) => {
  const tableData = [];
  for (const [grouping, rowsInGrouping] of Object.entries(rowsMap)) {
    if (Array.isArray(rowsInGrouping)) {
      tableData.push({
        isFullWidthRow: true,
        banner: grouping,
        [bannerFieldNameInColDefsForFullWidthRows]: grouping,
      });
      const rows = constructTableDataFromRowsArray(rowsInGrouping, columnDefs);
      if (addDataAsChildrenInCaseOfMapData) {
        for (const row of rows) {
          row.is_child = true;
        }
      }
      tableData.push(...rows);
    } else {
      tableData.push({
        isFullWidthRow: true,
        banner: grouping,
        [bannerFieldNameInColDefsForFullWidthRows]: grouping,
      });
      tableData.push(
        ...constructTableDataFromRowsMap(
          rowsInGrouping,
          columnDefs,
          addDataAsChildrenInCaseOfMapData,
          bannerFieldNameInColDefsForFullWidthRows
        )
      );
    }
  }
  return tableData;
};

export const getOnFirstDataRenderedFuncBasedOnGridRef = (gridRef, isHeightPassedInByUser) => {
  return () => {
    gridRef.current.api.sizeColumnsToFit();
    if (!isHeightPassedInByUser) {
      gridRef.current.api.setDomLayout('autoHeight');
    }
  };
};

export const onFirstDataRenderedDefault = (params) => {
  params.columnApi.autoSizeAllColumns();
  params.api.setDomLayout('autoHeight');
};

export const onFirstDataRenderedDefaultWithoutAutoHeight = (params) => {
  params.columnApi.autoSizeAllColumns();
};

export const showOrHideAllOtherColGroups = (colGroupOpenedEvent, visiblility) => {
  const allColumns = colGroupOpenedEvent.api.getColumnDefs();
  allColumns.forEach((currCol) => {
    if (currCol.headerName !== colGroupOpenedEvent.columnGroup.colGroupDef.headerName) {
      currCol.children.forEach((child) => {
        if (child.field !== 'Field') {
          colGroupOpenedEvent.columnApi.setColumnVisible(child.field, visiblility);
        }
      });
    }
  });
};

export const isFullWidthRowFuncDefault = (params) => params.rowNode.data.isFullWidthRow === true;

export const fullWidthCellRendererDefault = ({ node }) => {
  return <StyledGridFullWidthRow>{node.data.banner}</StyledGridFullWidthRow>;
};

export const getOnFirstDataRenderedFunc = (
  sizeColumnsToFitInitially,
  gridRef,
  isHeightPassedInByUser
) => {
  let onFirstDataRenderedFunc = isHeightPassedInByUser
    ? onFirstDataRenderedDefaultWithoutAutoHeight
    : onFirstDataRenderedDefault;

  if (sizeColumnsToFitInitially) {
    onFirstDataRenderedFunc = getOnFirstDataRenderedFuncBasedOnGridRef(
      gridRef,
      isHeightPassedInByUser
    );
  }
  return onFirstDataRenderedFunc;
};
