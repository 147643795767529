// @flow
import React, { useContext } from 'react';

import { Spacer } from '../presentational/spacing/Spacer';
import { EstimateContext } from '../../contexts/EstimateContext';
import { StyledLastUpdatedMessage } from '../../common-utils/styledComponents';
import { convertUTCTimeStampToHumanReadableTime } from '../../common-utils/utils';

export const LastUpdatedMessage = () => {
  const estimateData = useContext(EstimateContext);
  const updatedAt = estimateData.inputs.updated_at;
  const updatedAtFormatted = convertUTCTimeStampToHumanReadableTime(updatedAt);

  return (
    <StyledLastUpdatedMessage>
      Last Updated ( in UTC ):
      <Spacer x={0.25} />
      {updatedAtFormatted}
    </StyledLastUpdatedMessage>
  );
};
