// @flow

import React, { useContext, useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';

import {
  LIMIT_INDICATOR_FOR_FETCH_ALL_ON_HOME_PAGE,
  MAXIMUM_NUMBER_OF_ACCOUNTS_PER_PAGE_ON_HOME_PAGE,
} from '../../../constants';
import { Spacer } from '../../presentational/spacing/Spacer';
import { PaginationContainer } from '../../presentational/pagination/PaginationContainer';
import { HomePageLegend } from '../HomePageLegend';
import { StyledEstimateHierarchyList } from '../../../common-utils/styledComponents';
import { StyledH4 } from '../../presentational/headings/StyledH4';
import { OpenCloseHierarchyTreeContext } from '../../../contexts/OpenCloseHierarchyTreeContext';

import { EstimateHierarchyAccountListItem } from './EstimateHierarchyAccountListItem';
import {
  constructHierarchyTreeFromAccounts,
  constructOpenCloseTreeFromAccountsAndSearchTerm,
  getAccountsForTheGivenPage,
  getHierarchyResultSetInfoMessage,
  getIfShowingPartialResults,
} from './utils';
import { FetchAllButton } from './FetchAllButton';
import { ExpandAndCollapseContainer } from './ExpandAndCollapseContainer';

export const EstimateHierarchy = ({ accounts, limit = null, searchTerm = '', onLimitChange }) => {
  const [activePage, setActivePage] = useState(1);
  const numberOfAccountsPerPage = MAXIMUM_NUMBER_OF_ACCOUNTS_PER_PAGE_ON_HOME_PAGE;
  const accountsInPage = getAccountsForTheGivenPage(accounts, activePage, numberOfAccountsPerPage);

  const [tree, setTree] = useState(constructHierarchyTreeFromAccounts(accountsInPage));
  const accountIdsList = accountsInPage.map((account) => account.id);
  const { updateOpenCloseTree, setDefaultsForNewAccounts } = useContext(
    OpenCloseHierarchyTreeContext
  );

  useEffect(() => {
    setActivePage(1);
  }, [accounts, numberOfAccountsPerPage]);

  useEffect(() => {
    const accountsInPage = getAccountsForTheGivenPage(accounts, 1, numberOfAccountsPerPage);
    setTree(constructHierarchyTreeFromAccounts(accountsInPage));
  }, [accounts, numberOfAccountsPerPage]);

  useEffect(() => {
    if (searchTerm == null || searchTerm.length === 0) {
      setDefaultsForNewAccounts(accounts);
    } else {
      const newOpenCloseTree = constructOpenCloseTreeFromAccountsAndSearchTerm(
        accounts,
        searchTerm
      );
      updateOpenCloseTree(newOpenCloseTree);
    }
  }, [accounts, searchTerm, setDefaultsForNewAccounts, updateOpenCloseTree]);

  if (accountIdsList.length === 0) {
    return <StyledH4 text="No results found!" />;
  }

  const resultSetInfoMessage = getHierarchyResultSetInfoMessage(limit, searchTerm !== '');
  const areWeShowingPartialResults = getIfShowingPartialResults(searchTerm, limit);

  return (
    <>
      <Grid>
        <Grid.Column floated="left" width={6}>
          <StyledH4 inline={true} text={resultSetInfoMessage} />
          <Spacer x={10} />
          {areWeShowingPartialResults && (
            <FetchAllButton
              onClick={() => {
                onLimitChange(LIMIT_INDICATOR_FOR_FETCH_ALL_ON_HOME_PAGE);
              }}
            />
          )}
        </Grid.Column>
        <Grid.Column floated="right" verticalAlign="middle" width={5}>
          <ExpandAndCollapseContainer />
          <Spacer x={24} />
          <HomePageLegend />
        </Grid.Column>
      </Grid>

      <Spacer y={2} />

      <StyledEstimateHierarchyList divided={true} selection={true}>
        {accountIdsList.map((accountId) => {
          return (
            <EstimateHierarchyAccountListItem
              accountId={accountId}
              key={accountId}
              searchTerm={searchTerm}
              tree={tree}
            />
          );
        })}
      </StyledEstimateHierarchyList>

      <Spacer y={3} />

      <PaginationContainer
        activePage={activePage}
        numberOfRecordsPerPage={numberOfAccountsPerPage}
        onPageChange={(page) => {
          setActivePage(page);
          const accountsInPage = getAccountsForTheGivenPage(
            accounts,
            page,
            numberOfAccountsPerPage
          );
          setTree(constructHierarchyTreeFromAccounts(accountsInPage));
        }}
        totalNumberOfRecords={accounts.length}
      />
    </>
  );
};
