// @flow
import React from 'react';

import { StyledPopup, StyledSpan } from '../../../common-utils/styledComponents';
import { DepthAwareChildRenderer } from '../grid-utils/renderers';

export const TCOTooltipWithDepthAwareChildRenderer = (props, tooltipText) => {
  return (
    <StyledPopup
      content={
        <StyledSpan style={{ whiteSpace: 'pre-wrap', float: 'left' }}>{tooltipText}</StyledSpan>
      }
      hoverable={true}
      position="top center"
      trigger={DepthAwareChildRenderer(props)}
      wide="very"
    />
  );
};
