// @flow

import { DISABLED, ENABLED } from '../../../constants';

export const DEFAULT_FILTER_PARAMS = {
  buttons: ['reset', 'apply'],
  debounceMs: 200,
};

export const DEFAULT_DEFAULT_COLUMN_DEFINITION = {
  sortable: true,
  filter: true,
  resizable: true,
  filterParams: DEFAULT_FILTER_PARAMS,
};

export const DEFAULT_COLUMN_DEFINITION_FOR_SIMPLIFIED_GRID = {
  sortable: false,
  filter: false,
  resizable: true,
  suppressMovable: true,
  flex: 1,
};

export const ENABLED_DISABLED_FILTER_PARAMS = {
  ...DEFAULT_FILTER_PARAMS,
  filterOptions: [
    'empty',
    {
      displayKey: ENABLED,
      displayName: ENABLED,
      test: (filterValue, cellValue) => cellValue != null && cellValue === true,
      hideFilterInput: true,
    },
    {
      displayKey: DISABLED,
      displayName: DISABLED,
      test: (filterValue, cellValue) => cellValue != null && cellValue === false,
      hideFilterInput: true,
    },
  ],
  suppressAndOrCondition: true,
};
