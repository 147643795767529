// @flow
import React, { useCallback, useMemo } from 'react';
import { Bar } from 'recharts';

import { DataFetcherContainer } from '../../presentational/DataFetcherContainer';
import { useGetEstimateTCOViewDataQuery } from '../../../service-definitions/estimates';

import { TCOViewSummaryContainer } from './TCOViewSummaryContainer';

export const EstimateLevelTCOViewsDataWrapper = ({ estimateId, disabled = false }) => {
  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId },
          {
            skip: !estimateId,
          },
        ],
        [estimateId]
      )}
      dataFetchingFunction={useCallback(useGetEstimateTCOViewDataQuery, [])}
    >
      {(data) => {
        const tcoSummaryDataAndBarsExtractionFunc = (data) => {
          const tcoSummaryData = data?.tco_summary ?? {};
          const BarsToDisplay = (
            <>
              <Bar dataKey="INFRASTRUCTURE" fill="#FFC300" stackId="a" />
              <Bar dataKey="OPEX" fill="#50C878" stackId="a" />
              <Bar dataKey="RISK" fill="#87ceeb" stackId="a" />
            </>
          );
          return { tcoSummaryData, BarsToDisplay };
        };

        return (
          <TCOViewSummaryContainer
            data={data}
            disabled={disabled}
            tcoSummaryDataAndBarsExtractionFunc={tcoSummaryDataAndBarsExtractionFunc}
          />
        );
      }}
    </DataFetcherContainer>
  );
};
