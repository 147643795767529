// @flow
import React, { useContext } from 'react';

import { EstimateContext } from '../../contexts/EstimateContext';
import { StyledSpan } from '../../common-utils/styledComponents';
import { FlinkPoolContext } from '../../contexts/FlinkPoolContext';

import { SecondaryTopBar } from './SecondaryTopBar';
import { getEstimateSections } from './utils';

export const FlinkPoolBreadcrumbs = () => {
  const estimate = useContext(EstimateContext);
  const flinkPoolDetails = useContext(FlinkPoolContext);
  const flinkPoolName = flinkPoolDetails?.flink_pool_name;

  return (
    <SecondaryTopBar
      sectionsToShow={[
        ...getEstimateSections(estimate),
        <StyledSpan key="flink-pool"> Flink Pool </StyledSpan>,
        <StyledSpan key="flink-pool-name"> {flinkPoolName} </StyledSpan>,
      ]}
    />
  );
};
