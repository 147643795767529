// @flow
import { Grid } from 'semantic-ui-react';
import React from 'react';

import { StyledGridRow } from '../../../common-utils/styledComponents';
import { Spacer } from '../../presentational/spacing/Spacer';

import { CSPDiscountAppliedContainer } from './CSPDiscountAppliedContainer';
import { CostPerBusinessDownTimeHourContainer } from './CostPerBusinessDownTimeHourContainer';
import { OverProvisionPercentageContainer } from './OverProvisionPercentageContainer';
import { StorageUtilizationBufferContainer } from './StorageUtilizationBufferContainer';
import { TCORegionContainer } from './TCORegionContainer';

export const TCOEstimateLevelInputsGrid = () => (
  <Grid columns={5} divided={true}>
    <Spacer y={12} />
    <StyledGridRow>
      <Grid.Column>
        <CSPDiscountAppliedContainer />
      </Grid.Column>
      <Grid.Column>
        <CostPerBusinessDownTimeHourContainer />
      </Grid.Column>
      <Grid.Column>
        <OverProvisionPercentageContainer />
      </Grid.Column>
      <Grid.Column>
        <StorageUtilizationBufferContainer />
      </Grid.Column>
      <Grid.Column>
        <TCORegionContainer />
      </Grid.Column>
    </StyledGridRow>
  </Grid>
);
