// @flow

import React from 'react';
import { Icon } from 'semantic-ui-react';

import { StyledButton } from '../../../common-utils/styledComponents';

export const ShareButton = ({ disabled, onClick, floated = 'right', size = 'mini' }) => (
  <StyledButton
    compact={true}
    data-testid="share-button"
    disabled={disabled}
    floated={floated}
    icon={<Icon name="share alternate" />}
    onClick={onClick}
    primary={true}
    size={size}
    type="button"
  />
);
