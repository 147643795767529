// @flow
import { Grid } from 'semantic-ui-react';
import React, { useContext } from 'react';

import { StyledGridRow } from '../../common-utils/styledComponents';
import {
  getIfCustomDiscountsEnabledORInCustomerView,
  getIfCustomerViewIsEnabled,
} from '../../common-utils/utils';
import { EstimateContext } from '../../contexts/EstimateContext';

import { DealStartDateContainer } from './DealStartDateContainer';
import { DealEndDateContainer } from './DealEndDateContainer';
import { DealTypeContainer } from './DealTypeContainer';
import { MarketplaceContainer } from './MarketplaceContainer';
import { PaymentScheduleContainer } from './PaymentScheduleContainer';
import { RateCardsContainer } from './RateCardsContainer';
import { DiscountAppliedContainer } from './DiscountAppliedContainer';
import { SupportTiersContainer } from './SupportTiersContainer';
import { RenewalWarningContainer } from './RenewalWarningContainer';
import { DiscountWarningContainer } from './DiscountWarningContainer';
import { CustomDiscountsEnabledContainer } from './CustomDiscountsEnabledContainer';
import { CreditsAndBurstsEnabledContainer } from './CreditsAndBurstsEnabledContainer';

export const EstimateCommitDetailsGrid = () => {
  const estimate = useContext(EstimateContext);

  return (
    <Grid>
      <StyledGridRow columns={3} paddingTop="1rem">
        <Grid.Column>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <DealStartDateContainer />
              </Grid.Column>
              <Grid.Column>
                <DealEndDateContainer />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column>
          <DealTypeContainer />
        </Grid.Column>
        <Grid.Column>
          <MarketplaceContainer />
        </Grid.Column>
      </StyledGridRow>
      <StyledGridRow columns={3} paddingTop="1rem">
        <Grid.Column>
          <PaymentScheduleContainer />
        </Grid.Column>
        <Grid.Column>
          <RateCardsContainer />
        </Grid.Column>
        <Grid.Column
          style={{
            visibility: getIfCustomDiscountsEnabledORInCustomerView(estimate.inputs)
              ? 'hidden'
              : '',
          }}
        >
          <DiscountAppliedContainer />
        </Grid.Column>
      </StyledGridRow>
      <StyledGridRow columns={3} paddingTop="1rem">
        <Grid.Column>
          <SupportTiersContainer />
        </Grid.Column>
        <Grid.Column verticalAlign="middle">
          <RenewalWarningContainer />
        </Grid.Column>
        <Grid.Column verticalAlign="middle">
          <DiscountWarningContainer />
        </Grid.Column>
      </StyledGridRow>
      <StyledGridRow columns={3} paddingTop="1rem">
        {!getIfCustomerViewIsEnabled(estimate?.inputs) && (
          <Grid.Column>
            <CustomDiscountsEnabledContainer />
          </Grid.Column>
        )}
        <Grid.Column>
          <CreditsAndBurstsEnabledContainer />
        </Grid.Column>
      </StyledGridRow>
    </Grid>
  );
};
