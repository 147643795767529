// @flow
import React, { useContext } from 'react';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridSelectField,
} from '../../formik-utils/FieldArrayGrid';
import { CREDITS_AND_BURSTS_YEAR_NUMBER_BACKEND_NAME } from '../../constants';
import { CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG_MAP } from '../../configuration/credits-and-bursts';

const getYearDropdownOptions = (estimate) => {
  const years = Array.from(
    { length: estimate?.deal_duration?.deal_duration_years ?? 0 },
    (_, i) => `Year ${i + 1}`
  );

  return years.map((x) => ({
    name: Number(x.replace('Year ', '')),
    display_name: x,
  }));
};

export const YearContainer = () => {
  const { estimate } = useContext(FieldArrayGridConfigItemContext);
  const yearDropdownOptions = getYearDropdownOptions(estimate);
  const cnbcYearConfig = CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG_MAP.get(
    CREDITS_AND_BURSTS_YEAR_NUMBER_BACKEND_NAME
  );

  return <FieldArrayGridSelectField columnConfig={cnbcYearConfig} options={yearDropdownOptions} />;
};
