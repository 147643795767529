// @flow
import React, { useContext } from 'react';

import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { DataGrid } from '../../views/grid-utils/DataGrid';
import {
  constructDataForMonthlySummaryUI,
  getColumnDefsForMonthlySummaryBasedOnGivenData,
} from '../../views/utils';
import { ESTIMATE_STREAM_GOVERNANCE_MONTHLY_SPEND_SUMMARY } from '../../../constants';
import { EstimateContext } from '../../../contexts/EstimateContext';

export const StreamGovernanceMonthlySummaryContainer = ({ monthlySpendData }) => {
  const estimate = useContext(EstimateContext);
  const isSGConfigured = estimate?.stream_governance?.is_sg_configured ?? false;
  const annualSummaries = monthlySpendData?.annual_summaries ?? null;

  let data = [];
  if (isSGConfigured) {
    data = [annualSummaries];
  }

  const columnDefs = getColumnDefsForMonthlySummaryBasedOnGivenData(data);
  const tableData = constructDataForMonthlySummaryUI(data);

  return (
    <PaddedAndRaisedSegment>
      <DataGrid
        columnDefs={columnDefs}
        label={ESTIMATE_STREAM_GOVERNANCE_MONTHLY_SPEND_SUMMARY}
        noDataMessage='Please add the configuration using "Add Stream Governance Config" button above to see the spend.'
        sizeColumnsToFitInitially={true}
        tableData={tableData}
      />
    </PaddedAndRaisedSegment>
  );
};
