// @flow

import { boolean, number } from 'yup';
import React from 'react';

import { CHECKBOX_INPUT_TYPE } from '../formik-utils/consts';
import {
  MAX_INPUT_VALUE_FOR_ANY_FIELD,
  TCO_CLUSTER_FOLLOWER_FETCH_BACKEND_FIELD_NAME,
  TCO_CLUSTER_FOLLOWER_FETCH_DISPLAY_NAME,
  TCO_CLUSTER_LOCAL_RETENTION_BACKEND_FIELD_NAME,
  TCO_CLUSTER_LOCAL_RETENTION_DISPLAY_NAME,
  TCO_CLUSTER_MISSION_CRITICAL_BACKEND_FIELD_NAME,
  TCO_CLUSTER_MISSION_CRITICAL_DISPLAY_NAME,
  TCO_CLUSTER_TIERED_STORAGE_BACKEND_FIELD_NAME,
  TCO_CLUSTER_TIERED_STORAGE_DISPLAY_NAME,
  TCO_INPUTS_JSON_BACKEND_NAME,
} from '../constants';
import { Tooltip } from '../components/presentational/Tooltip';

import { convertConfigArrayToMap } from './utils';

export const TCO_CLUSTER_LEVEL_INPUTS_CONFIG = [
  {
    displayName: TCO_CLUSTER_MISSION_CRITICAL_DISPLAY_NAME,
    backendFieldName: TCO_CLUSTER_MISSION_CRITICAL_BACKEND_FIELD_NAME,
    icon: null,
    validation: boolean().label(TCO_CLUSTER_MISSION_CRITICAL_DISPLAY_NAME).required(),
    inputType: CHECKBOX_INPUT_TYPE,
    pathToFollowInValues: [TCO_INPUTS_JSON_BACKEND_NAME],
    tooltip: (
      <Tooltip
        flowing={false}
        text="Mission criticality means that real-time data processing is vital for your use case; any downtime could significantly impact your business."
      />
    ),
  },
  {
    displayName: TCO_CLUSTER_FOLLOWER_FETCH_DISPLAY_NAME,
    backendFieldName: TCO_CLUSTER_FOLLOWER_FETCH_BACKEND_FIELD_NAME,
    icon: null,
    validation: boolean().label(TCO_CLUSTER_FOLLOWER_FETCH_DISPLAY_NAME).required(),
    inputType: CHECKBOX_INPUT_TYPE,
    pathToFollowInValues: [TCO_INPUTS_JSON_BACKEND_NAME],
    tooltip: (
      <Tooltip
        flowing={false}
        text="To help us estimate network costs, has the customer already implemented or plans to implement a system that leverages the Fetch-from-Follower capability?"
      />
    ),
  },
  {
    displayName: TCO_CLUSTER_TIERED_STORAGE_DISPLAY_NAME,
    backendFieldName: TCO_CLUSTER_TIERED_STORAGE_BACKEND_FIELD_NAME,
    icon: null,
    validation: boolean().label(TCO_CLUSTER_TIERED_STORAGE_DISPLAY_NAME).required(),
    inputType: CHECKBOX_INPUT_TYPE,
    pathToFollowInValues: [TCO_INPUTS_JSON_BACKEND_NAME],
    tooltip: (
      <Tooltip
        flowing={false}
        text="To help estimate storage costs accurately, has the customer already implemented or plan to implement a system that leverages Tiered Storage?"
      />
    ),
  },
  {
    displayName: TCO_CLUSTER_LOCAL_RETENTION_DISPLAY_NAME,
    backendFieldName: TCO_CLUSTER_LOCAL_RETENTION_BACKEND_FIELD_NAME,
    validation: number()
      .min(0)
      .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
      .label(TCO_CLUSTER_LOCAL_RETENTION_DISPLAY_NAME)
      .required()
      .typeError('You must specify a number'),
    pathToFollowInValues: [TCO_INPUTS_JSON_BACKEND_NAME],
    tooltip: (
      <Tooltip
        flowing={false}
        text={`If the customer has (or will) used the Tiered Storage capability, please adjust this field to reflect the amount of time (in hours) the customer wants to keep their event log data local to their Brokers, i.e. before the event log data is moved to the next storage tier. \n\nDefault is 4 hours`}
      />
    ),
  },
];

export const TCO_CLUSTER_LEVEL_INPUTS_CONFIG_MAP = convertConfigArrayToMap(
  TCO_CLUSTER_LEVEL_INPUTS_CONFIG
);
