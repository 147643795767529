// @flow
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { CurrencyZeroDPRenderer, PercentageRenderer } from '../grid-utils/renderers';
import { DataGrid } from '../grid-utils/DataGrid';
import {
  CLUSTER_AVAILABILITY,
  CLUSTER_AVAILABILITY_IN_SMALL_CASE,
  CLUSTER_ENABLED,
  CLUSTER_ENABLED_BACKEND_NAME,
  CLUSTER_ID,
  CLUSTER_ID_BACKEND_NAME,
  CLUSTER_NAME_BACKEND_NAME,
  CLUSTER_NETWORKING,
  CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
  CLUSTER_PROVIDER,
  CLUSTER_PROVIDER_BACKEND_NAME,
  CLUSTER_REGION,
  CLUSTER_REGION_BACKEND_NAME,
  CLUSTER_SLA_BACKEND_NAME,
  CLUSTER_SLA_DISPLAY_NAME,
  CLUSTER_TOTAL_SPEND_AT_LIST,
  CLUSTER_TOTAL_SPEND_AT_LIST_BACKEND_NAME,
  CLUSTER_TOTAL_SPEND_DISCOUNTED,
  CLUSTER_TOTAL_SPEND_DISCOUNTED_BACKEND_NAME,
  CLUSTER_TYPE_BACKEND_NAME,
  HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
  NAME,
  TYPE,
} from '../../../constants';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { ENABLED_DISABLED_FILTER_PARAMS } from '../grid-utils/constants';
import { PrimaryButton } from '../../presentational/buttons/PrimaryButton';
import { isAnythingEditedOnEstimatePage } from '../utils';

import { ClusterDeleteRenderer } from './ClusterDeleteRenderer';
import { ClusterCopyRenderer } from './ClusterCopyRenderer';
import { ClusterAddModal } from './ClusterAddModal';
import { ClusterEnabledCellRenderer } from './ClusterEnabledCellRenderer';
import { ClusterNameRendererWithValidation } from './ClusterNameRendererWithValidation';

export const ClustersSummaryContainer = () => {
  const { estimateId } = useParams();
  const [isClusterAddModalOpen, setClusterAddModalOpen] = useState(false);
  const { values, initialValues, isValid } = useFormikContext();
  const estimate = useContext(EstimateContext);

  const disabled = isAnythingEditedOnEstimatePage(values, initialValues);

  const columnDefs = [
    {
      field: CLUSTER_ID,
      backEndFieldName: CLUSTER_ID_BACKEND_NAME,
      hide: true,
    },
    {
      field: NAME,
      cellRenderer: ClusterNameRendererWithValidation,
      backEndFieldName: CLUSTER_NAME_BACKEND_NAME,
    },
    {
      field: CLUSTER_ENABLED,
      filter: true,
      filterParams: ENABLED_DISABLED_FILTER_PARAMS,
      cellRenderer: ClusterEnabledCellRenderer,
      backEndFieldName: CLUSTER_ENABLED_BACKEND_NAME,
      maxWidth: 140,
    },
    {
      field: TYPE,
      backEndFieldName: CLUSTER_TYPE_BACKEND_NAME,
    },
    {
      field: CLUSTER_NETWORKING,
      backEndFieldName: CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
    },
    {
      field: CLUSTER_PROVIDER,
      backEndFieldName: CLUSTER_PROVIDER_BACKEND_NAME,
    },
    {
      field: CLUSTER_REGION,
      backEndFieldName: CLUSTER_REGION_BACKEND_NAME,
      tooltipField: CLUSTER_REGION,
      maxWidth: 180,
    },
    {
      field: CLUSTER_AVAILABILITY,
      backEndFieldName: CLUSTER_AVAILABILITY_IN_SMALL_CASE,
    },
    {
      field: CLUSTER_SLA_DISPLAY_NAME,
      backEndFieldName: CLUSTER_SLA_BACKEND_NAME,
      cellRenderer: PercentageRenderer,
    },
    {
      field: 'validation_error',
      backEndFieldName: 'validation_error',
      hide: true,
    },
    {
      field: CLUSTER_TOTAL_SPEND_AT_LIST,
      backEndFieldName: CLUSTER_TOTAL_SPEND_AT_LIST_BACKEND_NAME,
      cellRenderer: CurrencyZeroDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
    {
      field: CLUSTER_TOTAL_SPEND_DISCOUNTED,
      backEndFieldName: CLUSTER_TOTAL_SPEND_DISCOUNTED_BACKEND_NAME,
      cellRenderer: CurrencyZeroDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
    {
      field: '',
      filter: false,
      cellRenderer: ClusterCopyRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
    {
      field: '',
      filter: false,
      cellRenderer: ClusterDeleteRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
  ];

  const inputDataSource = estimate?.cluster_summary ?? [];
  const areAnyClustersConfigured = inputDataSource.length > 0;

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <PrimaryButton
        disabled={disabled || !isValid || !estimateId}
        onClick={() => {
          setClusterAddModalOpen(true);
        }}
        size="mini"
        text="Add Cluster"
      />

      <ClusterAddModal isOpen={isClusterAddModalOpen} setOpen={setClusterAddModalOpen} />

      {areAnyClustersConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          downloadAllCols={false}
          inputDataSource={inputDataSource}
          label="Clusters Summary"
          sizeColumnsToFitInitially={true}
          tooltipShowDelay={0}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
