// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { Spacer } from '../../presentational/spacing/Spacer';
import { ResetFormButton } from '../../presentational/buttons/ResetFormButton';
import { SaveFormButton } from '../../presentational/buttons/SaveFormButton';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { toastError } from '../../presentational/notifications/utils';
import { useUpdateClusterMutation } from '../../../service-definitions/estimates';
import { CLUSTER_TCO_INPUT_FORM } from '../../../constants';
import {
  fetchFormValues,
  isFormEdited,
  resetValues,
} from '../../cluster/cluster-details-inputs/utils';
import { CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP } from '../../../configuration/cluster-details';
import { isAnythingEditedOnClusterPageBesidesTheGivenForm } from '../../views/utils';

import { TCOClusterLevelInputsGrid } from './TCOClusterLevelInputsGrid';

export const ClusterTCOInputsTopLevelContainer = () => {
  const { estimateId, clusterId } = useParams();
  const estimate = useContext(EstimateContext);
  const { values, isValid, initialValues, resetForm } = useFormikContext();
  const [updateClusterTCOInputDetails] = useUpdateClusterMutation();

  const disabled = isAnythingEditedOnClusterPageBesidesTheGivenForm(
    values,
    initialValues,
    CLUSTER_TCO_INPUT_FORM
  );

  const isTheFormEdited = isFormEdited(
    values,
    initialValues,
    CLUSTER_TCO_INPUT_FORM,
    CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP
  );

  const shouldSaveResetBeDisabled = !isValid || disabled || !isTheFormEdited;

  return (
    <>
      <PaddedAndRaisedSegment disabled={disabled}>
        <TCOClusterLevelInputsGrid />
        <Spacer y={25} />

        <ResetFormButton
          disabled={shouldSaveResetBeDisabled}
          onClick={() => {
            resetValues(
              values,
              initialValues,
              resetForm,
              CLUSTER_TCO_INPUT_FORM,
              CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP
            );
          }}
        />

        <Spacer x={2} />

        <SaveFormButton
          disabled={shouldSaveResetBeDisabled}
          onClick={async () => {
            const tcoInputs = fetchFormValues(
              values,
              CLUSTER_TCO_INPUT_FORM,
              CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP
            );

            const payload = {
              id: clusterId,
              estimate_id: estimateId,
              estimate_version: estimate?.inputs?.estimate_version,
              ...tcoInputs,
            };

            const { error } = await updateClusterTCOInputDetails({
              estimateId,
              clusterId,
              payload,
            });
            if (error) {
              toastError(error);
            }
          }}
        />
      </PaddedAndRaisedSegment>
    </>
  );
};
