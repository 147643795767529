// @flow
import React from 'react';

import { ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-account-details';
import { SFDC_ACCOUNT_ID_BACKEND_NAME } from '../../constants';
import { TextField } from '../../formik-utils/FormFields';

export const EstimateAccountIDContainer = ({ disableOnFormErrors }) => {
  const estimateAccountIDConfig = ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP.get(
    SFDC_ACCOUNT_ID_BACKEND_NAME
  );
  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      fieldDisplayName={estimateAccountIDConfig.displayName}
      fieldName={estimateAccountIDConfig.backendFieldName}
      icon={estimateAccountIDConfig.icon}
    />
  );
};
