// @flow

import React from 'react';

import { StyledLabel, StyledSegment } from '../../common-utils/styledComponents';

export const PaddedAndRaisedSegment = ({
  children,
  color = 'blue',
  disabled = false,
  label = null,
  attached = null,
}) => (
  <StyledSegment color={color} disabled={disabled} padded={true} raised={true}>
    {label && <StyledLabel attached={attached}>{label}</StyledLabel>}
    {children}
  </StyledSegment>
);
