// @flow

import { useFormikContext } from 'formik';
import React, { useContext } from 'react';

import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';
import { CLUSTER_REGION_BACKEND_NAME } from '../../../constants';
import { getDropdownOptions } from '../../stream-governance/utils';
import { SelectField } from '../../../formik-utils/FormFields';
import { ClusterContext } from '../../../contexts/ClusterContext';
import { getFormattedRegionsUniverse } from '../../../common-utils/utils';

export const ClusterRegionsContainer = ({ disabled }) => {
  const { values } = useFormikContext();
  const clusterRegionsConfig = CLUSTER_DETAILS_CONFIG_MAP.get(CLUSTER_REGION_BACKEND_NAME);

  const cluster = useContext(ClusterContext);
  const dataUniverse = cluster?.cluster_configs?.region_details;

  const dataUniverseFormatted = getFormattedRegionsUniverse(dataUniverse);

  const clusterRegionsOptions = getDropdownOptions(
    dataUniverseFormatted,
    values,
    clusterRegionsConfig,
    false,
    true,
    false,
    false,
    [],
    true
  );

  return (
    <SelectField
      disabled={disabled}
      fieldDisplayName={clusterRegionsConfig.displayName}
      fieldName={clusterRegionsConfig.backendFieldName}
      options={clusterRegionsOptions}
      placeholder={null}
    />
  );
};
