// @flow
import React, { useContext } from 'react';

import { ESTIMATE_COMMIT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-commit-details';
import { SUPPORT_TIER_BACKEND_NAME } from '../../constants';
import { SelectField } from '../../formik-utils/FormFields';
import { getDropdownOptionsFromArray } from '../../common-utils/utils';
import { EstimateContext } from '../../contexts/EstimateContext';

export const SupportTiersContainer = () => {
  const estimateData = useContext(EstimateContext);
  const supportTiers = estimateData.commit_config.support_tiers;
  const supportTiersOptions = getDropdownOptionsFromArray(supportTiers);
  const supportTiersConfig = ESTIMATE_COMMIT_DETAILS_CONFIG_MAP.get(SUPPORT_TIER_BACKEND_NAME);

  return (
    <SelectField
      fieldDisplayName={supportTiersConfig.displayName}
      fieldName={supportTiersConfig.backendFieldName}
      options={supportTiersOptions}
    />
  );
};
