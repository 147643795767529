// @flow
import React, { useContext } from 'react';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridTextField,
} from '../../../formik-utils/FieldArrayGrid';
import {
  STREAM_GOVERNANCE_ADVANCED_PACKAGE_NAME,
  STREAM_GOVERNANCE_NUMBER_OF_SCHEMAS_BACKEND_NAME,
  STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME,
} from '../../../constants';
import { STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG_MAP } from '../../../configuration/stream-governance';
import { StyledSpan } from '../../../common-utils/styledComponents';

export const SchemasCountContainer = () => {
  const { rowValues } = useContext(FieldArrayGridConfigItemContext);

  const selectedPackageName = rowValues[STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME];

  const schemaCountConfig = STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG_MAP.get(
    STREAM_GOVERNANCE_NUMBER_OF_SCHEMAS_BACKEND_NAME
  );

  const isAdvancedPackageSelected =
    selectedPackageName &&
    selectedPackageName.toLowerCase() === STREAM_GOVERNANCE_ADVANCED_PACKAGE_NAME.toLowerCase();

  if (isAdvancedPackageSelected) {
    return <StyledSpan className="ui tiny header">Schemas are free in Advanced</StyledSpan>;
  }

  return <FieldArrayGridTextField columnConfig={schemaCountConfig} />;
};
