// @flow
import { useParams } from 'react-router-dom';
import React from 'react';

import { LinkRenderer } from '../grid-utils/renderers';
import { FLINK_POOL_ID, FLINK_POOL_NAME } from '../../../constants';
import { getLinkForFlinkPool } from '../../links/utils';

export const FlinkPoolNameRenderer = ({ data }) => {
  const { estimateId } = useParams();
  return (
    <LinkRenderer
      pathName={getLinkForFlinkPool(estimateId, data[FLINK_POOL_ID])}
      text={data[FLINK_POOL_NAME]}
    />
  );
};
