// @flow

import React, { useContext } from 'react';

import { CookieContext } from '../../contexts/CookieContext';
import { THEME_DARK, THEME_LIGHT } from '../../constants';
import { StyledMenuItem } from '../../common-utils/styledComponents';
import { SunIcon } from '../presentational/icons/SunIcon';
import { MoonIcon } from '../presentational/icons/MoonIcon';

export const DarkOrLightModeContainer = () => {
  const { cookies, setCookie } = useContext(CookieContext);
  const theme = cookies?.theme ?? THEME_LIGHT;

  const sunClickHandler = () => {
    setCookie('theme', THEME_LIGHT, {
      path: '/',
    });
  };

  const moonClickHandler = () => {
    setCookie('theme', THEME_DARK, {
      path: '/',
    });
  };

  const iconToShow =
    theme === THEME_DARK ? (
      <SunIcon onClick={sunClickHandler} />
    ) : (
      <MoonIcon onClick={moonClickHandler} />
    );

  return <StyledMenuItem>{iconToShow}</StyledMenuItem>;
};
