// @flow

import { ALL, ALL_IN_SMALL_CASE } from '../../constants';

const getDisplayName = (colValue, upperCaseDisplayName, displayNameCol, displayNamesMap) => {
  if (displayNameCol) {
    return displayNamesMap.get(colValue);
  }

  return upperCaseDisplayName ? colValue.toUpperCase() : colValue;
};

const getDisplayNamesMap = (displayNameCol, dataUniverseCopy, columnConfig) => {
  const displayNamesMap = new Map();

  if (displayNameCol) {
    for (const row of dataUniverseCopy) {
      displayNamesMap.set(row[columnConfig.backendFieldName], row[displayNameCol]);
    }
  }
  return displayNamesMap;
};

export const getDropdownOptions = (
  dataUniverse,
  values,
  columnConfig,
  upperCaseName = false,
  upperCaseDisplayName = false,
  containsAllSpecialValue = false,
  sortValues = false,
  sortOrder = [],
  sortValuesBasedOnDisplayName = false
) => {
  const parentFieldsInGroup = columnConfig?.parentFieldsInGroup ?? [];

  let dataUniverseCopy = [...dataUniverse];

  for (const field of parentFieldsInGroup) {
    const rowValueForField = values[field];
    if (rowValueForField) {
      // When the dataset contains ALL, we should not apply the filter because ALL means no filter
      if (containsAllSpecialValue && rowValueForField === ALL_IN_SMALL_CASE) {
        continue;
      }

      dataUniverseCopy = dataUniverseCopy.filter(
        (x) => x[field].toLowerCase() === rowValueForField.toLowerCase()
      );
    }
  }

  // When a DisplayNameColumn is provided, it meas that the user wants to use that column as the display name
  const displayNamesMap = getDisplayNamesMap(
    columnConfig.displayNameCol,
    dataUniverseCopy,
    columnConfig
  );

  const optionsValues = [...dataUniverseCopy].map((x) => x[columnConfig.backendFieldName]);

  if (sortValues) {
    optionsValues.sort();
  }

  if (sortOrder.length > 0) {
    optionsValues.sort(
      (firstElem, secondElem) => sortOrder.indexOf(firstElem) - sortOrder.indexOf(secondElem)
    );
  }

  let setOfDistinctValues = new Set(optionsValues);

  if (containsAllSpecialValue && setOfDistinctValues.size > 1) {
    setOfDistinctValues = new Set([ALL_IN_SMALL_CASE, ...setOfDistinctValues]);
  }

  const toRet = [...setOfDistinctValues].map((x) => ({
    name: upperCaseName ? x.toUpperCase() : x,
    display_name:
      x === ALL_IN_SMALL_CASE
        ? ALL
        : getDisplayName(x, upperCaseDisplayName, columnConfig.displayNameCol, displayNamesMap),
  }));

  if (sortValuesBasedOnDisplayName) {
    toRet.sort((first, second) => {
      return first.display_name > second.display_name
        ? 1
        : second.display_name > first.display_name
        ? -1
        : 0;
    });
  }

  return toRet;
};
