// @flow

import React, { useState } from 'react';

import { StyledAccordion } from '../../../common-utils/styledComponents';

export const handleAccordionTitleClick = (
  e,
  data,
  openAccordionIndexesArray,
  setOpenAccordionIndexesArray
) => {
  const { index } = data;
  let newState;
  if (openAccordionIndexesArray.includes(index)) {
    newState = openAccordionIndexesArray.filter((i) => i !== index);
  } else {
    newState = [...openAccordionIndexesArray, index];
  }
  setOpenAccordionIndexesArray(newState);
};

export const AccordionsList = ({ panels }) => {
  const [openAccordionIndexesArray, setOpenAccordionIndexesArray] = useState([
    ...Array(panels.length).keys(),
  ]);

  return (
    <StyledAccordion
      activeIndex={openAccordionIndexesArray}
      exclusive={false}
      onTitleClick={(e, data) =>
        handleAccordionTitleClick(e, data, openAccordionIndexesArray, setOpenAccordionIndexesArray)
      }
      panels={panels}
    />
  );
};
