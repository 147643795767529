// @flow
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DataFetcherContainer } from '../../presentational/DataFetcherContainer';
import { useGetFlinkPoolMonthlySpendSummaryQuery } from '../../../service-definitions/estimates';
import { MonthlySpendSummaryContainer } from '../../common/MonthlySpendSummaryContainer';

export const FlinkPoolMonthlySpendSummaryContainer = ({ disabled }) => {
  const { estimateId, flinkPoolId } = useParams();

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId, flinkPoolId: flinkPoolId },
          {
            skip: !estimateId || !flinkPoolId,
          },
        ],
        [flinkPoolId, estimateId]
      )}
      dataFetchingFunction={useCallback(useGetFlinkPoolMonthlySpendSummaryQuery, [])}
    >
      {(data) => {
        const monthlySpendSummary = [data.monthly_spend];
        return (
          <MonthlySpendSummaryContainer
            disabled={disabled}
            monthlySpendSummary={monthlySpendSummary}
          />
        );
      }}
    </DataFetcherContainer>
  );
};
