// @flow
import styled from 'styled-components';
import { BarChart } from 'recharts';

export const StyledBarChart = styled(BarChart)`
  div {
    background-color: ${({ theme }) => theme.clusterGraph?.backgroundColor};
    color: ${({ theme }) => theme.clusterGraph?.color};
  }
`;
