// @flow
import React from 'react';

import { TextField } from '../../../formik-utils/FormFields';
import { CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_CONFIG } from '../../../configuration/custom-discounts';
import { TEXT_NUMBER_INPUT_TYPE } from '../../../formik-utils/consts';

export const GlobalDiscountsTextFieldContainer = ({ readOnlyMode }) => {
  const globalDiscountConfig = CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_CONFIG;

  return (
    <TextField
      addLabel={false}
      disabled={readOnlyMode}
      fieldDisplayName={globalDiscountConfig.displayName}
      fieldName={globalDiscountConfig.backendFieldName}
      icon={globalDiscountConfig.icon}
      type={TEXT_NUMBER_INPUT_TYPE}
    />
  );
};
