// @flow
import React from 'react';

import { StyledButton } from '../../../common-utils/styledComponents';

export const SaveFormButton = ({ disabled, onClick }) => {
  return (
    <>
      <StyledButton
        circular={true}
        data-testid="save-form-button"
        disabled={disabled}
        icon="check"
        onClick={onClick}
        primary={true}
        size="small"
      />
    </>
  );
};
