// @flow
import React, { useContext } from 'react';

import { DEDICATED_SURVEY_INPUTS_INDUSTRY_BACKEND_FIELD_NAME } from '../../../../constants';
import { SelectField } from '../../../../formik-utils/FormFields';
import { DEDICATED_CLUSTER_USAGE_SURVEY_INPUTS_CONFIG_MAP } from '../../../../configuration/dedicated-cluster-usage-survey-inputs';
import { getDropdownOptionsFromArray } from '../../../../common-utils/utils';
import { DedicatedSurveyInputsContext } from '../../../../contexts/DedicatedSurveyInputsContext';

export const DedicatedSurveyIndustryContainer = () => {
  const { surveyFieldsInputData } = useContext(DedicatedSurveyInputsContext);

  const { backendFieldName, displayName, multiple } =
    DEDICATED_CLUSTER_USAGE_SURVEY_INPUTS_CONFIG_MAP.get(
      DEDICATED_SURVEY_INPUTS_INDUSTRY_BACKEND_FIELD_NAME
    );

  const dropdownOptions = getDropdownOptionsFromArray(surveyFieldsInputData?.industry_list ?? []);

  return (
    <SelectField
      addLabel={false}
      disableOnFormErrors={false}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={true}
      multiple={multiple}
      options={dropdownOptions}
    />
  );
};
