// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridDeleteButton,
} from '../../../formik-utils/FieldArrayGrid';
import { useDeleteCustomDiscountsMetricsLevelDiscountMutation } from '../../../service-definitions/estimates';
import { METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_ID_BACKEND_NAME } from '../../../constants';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { getIfAllMetricsAreFrozen } from '../utils';

const getIfMetricNameDeleteButtonShouldBeDisabled = (readOnlyMode, rowValues, formik) => {
  if (readOnlyMode) {
    return true;
  }

  if (rowValues.dimensions_config_array.length === 0) {
    return false;
  }

  if (!formik.isValid) {
    return true;
  }

  return !getIfAllMetricsAreFrozen(formik.values);
};

export const MetricNameDeleteButtonContainer = () => {
  const { estimateId } = useParams();
  const estimate = useContext(EstimateContext);

  const formik = useFormikContext();
  const { rowValues, readOnlyMode } = useContext(FieldArrayGridConfigItemContext);

  const [deleteCustomDiscountsMetricsLevelDiscount] =
    useDeleteCustomDiscountsMetricsLevelDiscountMutation();

  const payload = {
    estimate_id: estimateId,
    estimate_version: estimate?.inputs?.estimate_version,
    metric_id: Number(rowValues[METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_ID_BACKEND_NAME]),
  };

  const deleteCustomDiscountsMetricsLevelDiscountParams = {
    payload: payload,
    estimateId: estimateId,
  };

  return (
    <FieldArrayGridDeleteButton
      deleteFunc={deleteCustomDiscountsMetricsLevelDiscount}
      deleteFuncParams={deleteCustomDiscountsMetricsLevelDiscountParams}
      disabled={getIfMetricNameDeleteButtonShouldBeDisabled(readOnlyMode, rowValues, formik)}
    />
  );
};
