// @flow
import React from 'react';

import { StyledSpan } from '../../common-utils/styledComponents';
import { HIERARCHY_LEVELS_TO_ICON_COLORS_MAP } from '../../constants';
import { Spacer } from '../presentational/spacing/Spacer';
import { FolderIcon } from '../presentational/icons/FolderIcon';

const HierarchyIconWithText = ({ level }) => {
  const color = HIERARCHY_LEVELS_TO_ICON_COLORS_MAP.get(level);

  return (
    <>
      <FolderIcon color={color} />
      <StyledSpan>{level}</StyledSpan>
      <Spacer x={12} />
    </>
  );
};

export const HomePageLegend = () => {
  return (
    <>
      <HierarchyIconWithText level="Account" />
      <HierarchyIconWithText level="Organization" />
      <HierarchyIconWithText level="Opportunity" />
    </>
  );
};
