// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG } from '../../configuration/credits-and-bursts';
import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridUpdateButton,
} from '../../formik-utils/FieldArrayGrid';
import {
  useCreateCreditsAndBurstsConfigurationMutation,
  useUpdateCreditsAndBurstsConfigurationMutation,
} from '../../service-definitions/estimates';
import { DEFAULT_VALUE_FOR_DB_ROW_ID, ROW_ID } from '../../constants';
import { EstimateContext } from '../../contexts/EstimateContext';

export const UpdateButtonContainer = () => {
  const { estimateId } = useParams();
  const { rowValues } = useContext(FieldArrayGridConfigItemContext);
  const estimate = useContext(EstimateContext);

  const [updateCNBCConfig] = useUpdateCreditsAndBurstsConfigurationMutation();
  const [createCNBCConfig] = useCreateCreditsAndBurstsConfigurationMutation();

  // Note: Update does not need the FE ID which is -1
  const rowValuesCopied = { ...rowValues };
  delete rowValuesCopied.id;

  const updateFuncParams = {
    estimateId: estimateId,
    payload: {
      ...rowValuesCopied,
      estimate_version: estimate?.inputs?.estimate_version,
    },
    cnbcRowId: rowValues[ROW_ID],
  };

  return (
    <FieldArrayGridUpdateButton
      columnsConfig={CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG}
      errorMessage="There was an error in updating the Credits and Bursts configuration, please contact #cloud-commitment-estimator channel!"
      updateFunc={
        rowValues[ROW_ID] === DEFAULT_VALUE_FOR_DB_ROW_ID ? createCNBCConfig : updateCNBCConfig
      }
      updateFuncParams={updateFuncParams}
    />
  );
};
