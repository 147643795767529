// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridDeleteButton,
} from '../../formik-utils/FieldArrayGrid';
import { useDeleteCreditsAndBurstsConfigurationMutation } from '../../service-definitions/estimates';
import { ROW_ID } from '../../constants';
import { EstimateContext } from '../../contexts/EstimateContext';

export const DeleteButtonContainer = () => {
  const { estimateId } = useParams();
  const { rowValues } = useContext(FieldArrayGridConfigItemContext);
  const [deleteCNBCConfiguration] = useDeleteCreditsAndBurstsConfigurationMutation();
  const estimate = useContext(EstimateContext);

  const deleteFuncParams = {
    payload: {
      estimate_version: estimate?.inputs?.estimate_version,
    },
    estimateId: estimateId,
    cnbcRowId: rowValues[ROW_ID],
  };
  return (
    <FieldArrayGridDeleteButton
      deleteFunc={deleteCNBCConfiguration}
      deleteFuncParams={deleteFuncParams}
    />
  );
};
