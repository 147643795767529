// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import { FLINK_POOL_ENABLED_BACKEND_NAME } from '../../../constants';
import { CheckBoxField } from '../../../formik-utils/FormFields';
import { toastError } from '../../presentational/notifications/utils';
import {
  useDisableFlinkPoolMutation,
  useEnableFlinkPoolMutation,
} from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { FLINK_POOL_DETAILS_CONFIG_MAP } from '../../../configuration/flink-pool-details';

export const FlinkPoolEnabledContainer = ({
  flinkPoolId = null,
  checked = null,
  addLabel = true,
}) => {
  const { isValid } = useFormikContext();
  const estimateData = useContext(EstimateContext);
  const [enableFlinkPool] = useEnableFlinkPoolMutation();
  const [disableFlinkPool] = useDisableFlinkPoolMutation();
  const { estimateId, flinkPoolId: flinkPoolComingFromParams } = useParams();
  const flinkPoolIdToUse = flinkPoolId ? flinkPoolId : flinkPoolComingFromParams;

  const { backendFieldName, displayName, icon } = FLINK_POOL_DETAILS_CONFIG_MAP.get(
    FLINK_POOL_ENABLED_BACKEND_NAME
  );

  const checkedProps = checked != null ? { checked } : {};
  return (
    <CheckBoxField
      addLabel={addLabel}
      disabled={!isValid}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      icon={icon}
      onChange={async (event, value, setFieldValue, setFieldTouched, values, checked) => {
        const payload = {
          id: flinkPoolIdToUse,
          estimate_id: estimateId,
          estimate_version: estimateData?.inputs?.estimate_version,
        };

        const funcToCall = checked ? enableFlinkPool : disableFlinkPool;

        const { error } = await funcToCall({
          estimateId,
          flinkPoolId: flinkPoolIdToUse,
          payload,
        });

        if (error) {
          toastError(error);
        }
      }}
      {...checkedProps}
    />
  );
};
