// @flow

import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    width: fit-content;
  }
  .Toastify__toast--error {
    border: 2px solid #eb5757;
    background: #f2e6e6 !important;
  }
`;
