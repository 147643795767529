// @flow
import { useParams } from 'react-router-dom';
import React, { useContext } from 'react';

import { CopyRenderer } from '../grid-utils/renderers';
import { useCopyClusterMutation } from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { CLUSTER_ID } from '../../../constants';
import { toastError, toastSuccess } from '../../presentational/notifications/utils';

export const ClusterCopyRenderer = ({ data }) => {
  const { estimateId } = useParams();

  const estimate = useContext(EstimateContext);
  const [copyCluster] = useCopyClusterMutation();

  return (
    <CopyRenderer
      onClickHandler={async () => {
        const { error } = await copyCluster({
          estimateId,
          clusterId: data[CLUSTER_ID],
          estimateVersion: estimate?.inputs?.estimate_version,
        });

        if (error) {
          toastError(error);
        } else {
          toastSuccess('Successfully copied the cluster!');
        }
      }}
    />
  );
};
