// @flow
import React, { useContext } from 'react';
import { List } from 'semantic-ui-react';

import { FolderIcon } from '../../presentational/icons/FolderIcon';
import { HIERARCHY_LEVELS_TO_ICON_COLORS_MAP } from '../../../constants';
import { OpenCloseHierarchyTreeContext } from '../../../contexts/OpenCloseHierarchyTreeContext';

import { highlightMatchingPortionInTextAndReturnSpan } from './utils';
import { EstimateHierarchyOpportunityListItem } from './EstimateHierarchyOpportunityListItem';

export const EstimateHierarchyOrganizationListItem = ({
  account,
  accountId,
  organizationId,
  tree,
  searchTerm,
}) => {
  const organization = tree[accountId].organizations[organizationId];
  const { orgName } = organization;
  const opportunitiesIdsList = Object.keys(organization.opportunities);

  const { getIfOrganizationIsOpened, toggleOpenValueAtPathInOpenCloseTree } = useContext(
    OpenCloseHierarchyTreeContext
  );

  const isOrgOpened = getIfOrganizationIsOpened(accountId, organizationId);

  return (
    <List.List key={organizationId}>
      <List.Item
        key={organizationId}
        onClick={(e) => {
          e.stopPropagation();
          toggleOpenValueAtPathInOpenCloseTree(accountId, organizationId, null);
        }}
        style={{ cursor: 'pointer' }}
      >
        <FolderIcon
          color={HIERARCHY_LEVELS_TO_ICON_COLORS_MAP.get('Organization')}
          opened={isOrgOpened}
        />
        <List.Content>
          <List.Header>
            {highlightMatchingPortionInTextAndReturnSpan(orgName, searchTerm)}
          </List.Header>
          <List.Description>
            {highlightMatchingPortionInTextAndReturnSpan(organizationId, searchTerm)}
          </List.Description>
          {isOrgOpened &&
            opportunitiesIdsList.map((oppId) => {
              return (
                <EstimateHierarchyOpportunityListItem
                  account={account}
                  accountId={accountId}
                  key={oppId}
                  opportunityId={oppId}
                  organization={organization}
                  organizationId={organizationId}
                  searchTerm={searchTerm}
                  tree={tree}
                />
              );
            })}
        </List.Content>
      </List.Item>
    </List.List>
  );
};
