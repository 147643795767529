// @flow

import React from 'react';

import { StyledButton } from '../../../common-utils/styledComponents';

export const PrimaryButton = ({
  size,
  text,
  onClick,
  disabled = false,
  circular = false,
  compact = false,
  floated = null,
}) => (
  <StyledButton
    circular={circular}
    compact={compact}
    data-testid="primary-button"
    disabled={disabled}
    floated={floated}
    onClick={onClick}
    primary={true}
    size={size}
    type="button"
  >
    {text}
  </StyledButton>
);
