// @flow
import React from 'react';
import { string } from 'yup';

import {
  ESTIMATE_NAME_BACKEND_NAME,
  ESTIMATE_NAME_DISPLAY_NAME,
  IS_SFDC_INTEGRATION_LIVE,
  SFDC_ACCOUNT_ID_BACKEND_NAME,
  SFDC_ACCOUNT_ID_DISPLAY_NAME,
  SFDC_ACCOUNT_NAME_BACKEND_NAME,
  SFDC_ACCOUNT_NAME_DISPLAY_NAME,
  SFDC_OPPORTUNITY_ID_BACKEND_NAME,
  SFDC_OPPORTUNITY_ID_DISPLAY_NAME,
  SFDC_OPPORTUNITY_NAME_BACKEND_NAME,
  SFDC_OPPORTUNITY_NAME_DISPLAY_NAME,
  SFDC_ORGANISATION_NAME_BACKEND_NAME,
  SFDC_ORGANISATION_NAME_DISPLAY_NAME,
  SFDC_ORGANIZATION_ID_BACKEND_NAME,
  SFDC_ORGANIZATION_ID_DISPLAY_NAME,
  SFDC_QUOTE_NAME_BACKEND_NAME,
  SFDC_QUOTE_NAME_DISPLAY_NAME,
} from '../constants';
import { TEXT_INPUT_TYPE } from '../formik-utils/consts';
import { Tooltip } from '../components/presentational/Tooltip';

import { convertConfigArrayToMap } from './utils';

const sfdcOpportunityIdValidation = string()
  .label(SFDC_OPPORTUNITY_ID_DISPLAY_NAME)
  .required()
  .matches(new RegExp('^006[a-zA-Z0-9]*$'), {
    message: 'Invalid Opportunity ID format',
  })
  .length(18, 'Opportunity ID has to be of length 18');

export const ESTIMATE_ACCOUNT_DETAILS_CONFIG = [
  {
    displayName: ESTIMATE_NAME_DISPLAY_NAME,
    backendFieldName: ESTIMATE_NAME_BACKEND_NAME,
    icon: null,
    validation: string().label(ESTIMATE_NAME_DISPLAY_NAME).required(),
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: SFDC_ACCOUNT_ID_DISPLAY_NAME,
    backendFieldName: SFDC_ACCOUNT_ID_BACKEND_NAME,
    validation: string().label(SFDC_ACCOUNT_ID_DISPLAY_NAME).required(),
    icon: { name: 'folder', color: 'blue' },
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: SFDC_ACCOUNT_NAME_DISPLAY_NAME,
    backendFieldName: SFDC_ACCOUNT_NAME_BACKEND_NAME,
    validation: string().label(SFDC_ACCOUNT_NAME_DISPLAY_NAME).required(),
    icon: { name: 'folder', color: 'blue' },
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: SFDC_ORGANIZATION_ID_DISPLAY_NAME,
    backendFieldName: SFDC_ORGANIZATION_ID_BACKEND_NAME,
    validation: string().label(SFDC_ORGANIZATION_ID_DISPLAY_NAME).required(),
    icon: { name: 'folder', color: 'teal' },
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: SFDC_ORGANISATION_NAME_DISPLAY_NAME,
    backendFieldName: SFDC_ORGANISATION_NAME_BACKEND_NAME,
    validation: string().label(SFDC_ORGANISATION_NAME_DISPLAY_NAME).required(),
    icon: { name: 'folder', color: 'teal' },
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: SFDC_OPPORTUNITY_ID_DISPLAY_NAME,
    backendFieldName: SFDC_OPPORTUNITY_ID_BACKEND_NAME,
    validation: sfdcOpportunityIdValidation,
    icon: { name: 'folder', color: 'grey' },
    tooltip: (
      <Tooltip
        link="https://confluentinc.atlassian.net/wiki/spaces/CS/pages/2762843438/Adding+Opportunity+ID+in+the+Cloud+Estimator"
        text="Adding Opportunity ID in the Cloud Estimator"
      />
    ),
    initialTouched: true,
    inputType: TEXT_INPUT_TYPE,
  },
  {
    displayName: SFDC_OPPORTUNITY_NAME_DISPLAY_NAME,
    backendFieldName: SFDC_OPPORTUNITY_NAME_BACKEND_NAME,
    validation: string().label(SFDC_OPPORTUNITY_NAME_DISPLAY_NAME).required(),
    icon: { name: 'folder', color: 'grey' },
    inputType: TEXT_INPUT_TYPE,
  },
];

if (IS_SFDC_INTEGRATION_LIVE) {
  ESTIMATE_ACCOUNT_DETAILS_CONFIG.push({
    displayName: SFDC_QUOTE_NAME_DISPLAY_NAME,
    backendFieldName: SFDC_QUOTE_NAME_BACKEND_NAME,
    validation: string().label(SFDC_QUOTE_NAME_DISPLAY_NAME).matches(new RegExp('^Q-[0-9]+$'), {
      excludeEmptyString: true,
      message:
        'Quote Name, if provided, should start with "Q-" and should at least contain one digit. Post that should only contain digits',
    }),
    inputType: TEXT_INPUT_TYPE,
  });
}
export const ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  ESTIMATE_ACCOUNT_DETAILS_CONFIG
);
