// @flow
import React, { useContext } from 'react';
import { Formik } from 'formik';

import { EstimateContext } from '../../contexts/EstimateContext';
import { FlinkPoolContext } from '../../contexts/FlinkPoolContext';
import { getFormikFormInputsFromColConfigAndInputSource } from '../../common-utils/utils';
import { FLINK_POOL_TOP_LEVEL_CONFIG } from '../../configuration/flink-pool-top-level-config';
import { StyledContainer } from '../../common-utils/styledComponents';
import { Form } from '../../formik-utils/formikSUIWrapper';
import { ValidateFormOnMount } from '../../formik-utils/ValidateFormOnMount';
import FormListener from '../../formik-utils/FormListener';
import { PromptIfFormHasUnSavedChanges } from '../presentational/PromptIfFormHasUnSavedChanges';
import { Spacer } from '../presentational/spacing/Spacer';
import { FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG } from '../../constants';

import { FlinkPoolAccordionsContainer } from './FlinkPoolAccordionsContainer';
import { FlinkPoolEnabledContainer } from './flink-pool-details-inputs-section/FlinkPoolEnabledContainer';
import { FlinkPoolDeleteContainer } from './FlinkPoolDeleteContainer';

export const FlinkPoolTopLevelContainerForm = () => {
  const estimate = useContext(EstimateContext);
  const flinkPoolDetails = useContext(FlinkPoolContext);
  const flinkPoolInputsSource = {
    ...flinkPoolDetails.inputs,
    id: flinkPoolDetails.flink_pool_id,
    name: flinkPoolDetails.flink_pool_name,
    is_enabled: flinkPoolDetails.is_enabled,
    [FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG]: flinkPoolDetails.flink,
  };

  const { initialValues, initialTouched, validationSchema } =
    getFormikFormInputsFromColConfigAndInputSource(
      FLINK_POOL_TOP_LEVEL_CONFIG,
      flinkPoolInputsSource,
      {
        estimate,
        flinkPoolDetails,
      }
    );

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnBlur={true}
        validateOnChange={false}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form autoComplete="off">
            <ValidateFormOnMount />
            <FormListener formik={formik} />
            <PromptIfFormHasUnSavedChanges />
            <FlinkPoolEnabledContainer />
            <FlinkPoolDeleteContainer />
            <Spacer y={10} />
            <FlinkPoolAccordionsContainer />
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};
