// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { EstimateContext } from '../../../contexts/EstimateContext';
import {
  fetchFormValues,
  isFormEdited,
  resetValues,
} from '../../cluster/cluster-details-inputs/utils';
import { FLINK_POOL_DETAILS_FORM, FLINK_POOL_MONTHLY_INPUTS_FORM } from '../../../constants';
import { ResetFormButton } from '../../presentational/buttons/ResetFormButton';
import { Spacer } from '../../presentational/spacing/Spacer';
import { SaveFormButton } from '../../presentational/buttons/SaveFormButton';
import { useUpdateFlinkPoolMutation } from '../../../service-definitions/estimates';
import { FLINK_POOL_DETAILS_FORM_NAMES_TO_FIELDS_MAP } from '../../../configuration/flink-pool-details';
import { toastError } from '../../presentational/notifications/utils';

import { FlinkPoolProviderContainer } from './FlinkPoolProviderContainer';
import { FlinkPoolNameContainer } from './FlinkPoolNameContainer';
import { FlinkPoolRegionsContainer } from './FlinkPoolRegionsContainer';

export function FlinkPoolDetailsContainer() {
  const { values, isValid, initialValues, resetForm, validateForm } = useFormikContext();
  const { estimateId, flinkPoolId } = useParams();
  const [updateFlinkPool] = useUpdateFlinkPoolMutation();
  const estimate = useContext(EstimateContext);

  const isFlinkPoolInputsFormEdited = isFormEdited(
    values,
    initialValues,
    FLINK_POOL_DETAILS_FORM,
    FLINK_POOL_DETAILS_FORM_NAMES_TO_FIELDS_MAP
  );

  const disabled = isFormEdited(
    values,
    initialValues,
    FLINK_POOL_MONTHLY_INPUTS_FORM,
    FLINK_POOL_DETAILS_FORM_NAMES_TO_FIELDS_MAP
  );

  const shouldSaveResetBeDisabled = !isValid || disabled || !isFlinkPoolInputsFormEdited;
  const shouldResetBeDisabled = disabled || !isFlinkPoolInputsFormEdited;

  return (
    <>
      <PaddedAndRaisedSegment disabled={disabled}>
        <span style={{ float: 'left' }}>
          <ResetFormButton
            disabled={shouldResetBeDisabled}
            onClick={() => {
              resetValues(
                values,
                initialValues,
                resetForm,
                FLINK_POOL_DETAILS_FORM,
                FLINK_POOL_DETAILS_FORM_NAMES_TO_FIELDS_MAP
              );
            }}
          />
          <Spacer x={1} />
          <SaveFormButton
            disabled={shouldSaveResetBeDisabled}
            onClick={async () => {
              const errors = await validateForm(values);
              if (Object.keys(errors).length > 0) {
                return;
              }
              const formValuesToSend = fetchFormValues(
                values,
                FLINK_POOL_DETAILS_FORM,
                FLINK_POOL_DETAILS_FORM_NAMES_TO_FIELDS_MAP
              );
              const payload = {
                id: flinkPoolId,
                estimate_id: estimateId,
                estimate_version: estimate?.inputs?.estimate_version,
                name: formValuesToSend.name,
                inputs: {
                  ...formValuesToSend,
                },
              };
              const { error } = await updateFlinkPool({
                estimateId,
                flinkPoolId,
                payload,
              });
              if (error) {
                toastError(error);
              }
            }}
          />
        </span>
        <Spacer y={60} />
        <Grid columns={2} divided={true}>
          <Grid.Row>
            <Grid.Column width={4}>
              <Grid columns={1} divided={true}>
                <Grid.Row>
                  <Grid.Column>
                    <FlinkPoolNameContainer disabled={disabled} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width={12}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <FlinkPoolProviderContainer disabled={disabled} />
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <FlinkPoolRegionsContainer disabled={disabled} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </PaddedAndRaisedSegment>
    </>
  );
}
