// @flow
import React, { useContext } from 'react';

import {
  ESTIMATE_STREAM_GOVERNANCE_PER_ENV_SPEND_SUMMARY,
  HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
} from '../../../constants';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { CurrencyFourDPRenderer, CurrencyZeroDPRenderer } from '../../views/grid-utils/renderers';
import { DataGrid } from '../../views/grid-utils/DataGrid';
import { StreamGovernanceContext } from '../../../contexts/StreamGovernanceContext';

export const StreamGovernancePerEnvSpendSummaryContainer = () => {
  const streamGovernanceData = useContext(StreamGovernanceContext);
  const spends = streamGovernanceData?.spends;

  let inputDataSource = spends?.spend_per_env_array ?? [];
  if (inputDataSource.length > 0) {
    inputDataSource = [
      ...inputDataSource,
      {
        environment_name: 'Total',
        price_description: 'Total across all environments',
        total_spend: spends?.total_spend ?? 0,
        total_spend_discounted: spends?.total_spend_discounted ?? 0,
      },
    ];
  }

  const columnDefs = [
    {
      field: 'Environment Name',
      backEndFieldName: 'environment_name',
    },
    {
      field: 'Package',
      backEndFieldName: 'package',
    },
    {
      field: 'Price Description',
      backEndFieldName: 'price_description',
      minWidth: 300,
    },
    {
      field: 'Unit Price (List)',
      backEndFieldName: 'unit_price',
      cellRenderer: CurrencyFourDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
    {
      field: 'Unit Price (Discounted)',
      backEndFieldName: 'unit_price_discounted',
      cellRenderer: CurrencyFourDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
    {
      field: 'Total Spend (List)',
      backEndFieldName: 'total_spend',
      cellRenderer: CurrencyZeroDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
    {
      field: 'Total Spend (Discounted)',
      backEndFieldName: 'total_spend_discounted',
      cellRenderer: CurrencyZeroDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
  ];

  return (
    <PaddedAndRaisedSegment>
      <DataGrid
        columnDefs={columnDefs}
        inputDataSource={inputDataSource}
        label={ESTIMATE_STREAM_GOVERNANCE_PER_ENV_SPEND_SUMMARY}
        noDataMessage='Please add the configuration using "Add Stream Governance Config" button above to see the spend.'
        pinnedRowsSelectorFunc={(row) => row['Environment Name'] === 'Total'}
        sizeColumnsToFitInitially={true}
      />
    </PaddedAndRaisedSegment>
  );
};
