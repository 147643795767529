// @flow
import React from 'react';

import { FieldArrayGridTextField } from '../../../formik-utils/FieldArrayGrid';
import { STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG_MAP } from '../../../configuration/stream-governance';
import { STREAM_GOVERNANCE_ENV_BACKEND_NAME } from '../../../constants';

export const EnvironmentNameContainer = () => {
  const envNameConfig = STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG_MAP.get(
    STREAM_GOVERNANCE_ENV_BACKEND_NAME
  );

  return <FieldArrayGridTextField columnConfig={envNameConfig} />;
};
