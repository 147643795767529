// @flow
import React from 'react';

import { StyledPopup } from '../../../common-utils/styledComponents';

export const PeakLessThanAvgPopup = () => (
  <StyledPopup
    content="Peak is less than Avg!"
    flowing={true}
    hoverable={true}
    offset={[0, 0]}
    open={true}
    position="right center"
    trigger={<div />}
  />
);
