// @flow
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DataFetcherContainer } from '../../presentational/DataFetcherContainer';
import { useGetClusterMonthlySpendSummaryQuery } from '../../../service-definitions/estimates';
import { MonthlySpendSummaryContainer } from '../../common/MonthlySpendSummaryContainer';

export const ClusterMonthlySpendSummaryContainer = ({ disabled }) => {
  const { estimateId, clusterId } = useParams();
  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId, clusterId: clusterId },
          {
            skip: !estimateId || !clusterId,
          },
        ],
        [clusterId, estimateId]
      )}
      dataFetchingFunction={useCallback(useGetClusterMonthlySpendSummaryQuery, [])}
    >
      {(data) => {
        const monthlySpendSummary = [data.monthly_spend];
        return (
          <MonthlySpendSummaryContainer
            disabled={disabled}
            monthlySpendSummary={monthlySpendSummary}
          />
        );
      }}
    </DataFetcherContainer>
  );
};
