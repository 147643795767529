// @flow

import {
  TCO_INFRASTRUCTURE,
  TCO_MSK_COMPUTE,
  TCO_MSK_NETWORK,
  TCO_MSK_STORAGE,
  TCO_OPERATING_EXPENSES,
  TCO_OPERATIONS,
  TCO_OSK_COMPUTE,
  TCO_OSK_NETWORK,
  TCO_OSK_STORAGE,
  TCO_RISK,
  TCO_SUPPORT,
  TCO_VENDOR_CONFLUENT,
  TCO_VENDOR_MSK,
  TCO_VENDOR_OSK,
} from '../../../constants';

import {
  TCO_COMPUTE_TOOLTIP,
  TCO_INFRASTRUCTURE_TOOLTIP,
  TCO_NETWORK_TOOLTIP,
  TCO_OPERATIONS_TOOLTIP,
  TCO_OPEX_TOOLTIP,
  TCO_RISK_TOOLTIP,
  TCO_STORAGE_TOOLTIP,
  TCO_SUPPORT_TOOLTIP,
} from './tooltip-texts/constants';

export const TCO_VIEW_COLUMN_TO_TOOLTIPS_MAP = {
  [TCO_INFRASTRUCTURE]: {
    [TCO_VENDOR_MSK]: TCO_INFRASTRUCTURE_TOOLTIP,
    [TCO_VENDOR_OSK]: TCO_INFRASTRUCTURE_TOOLTIP,
  },
  [TCO_OPERATING_EXPENSES]: {
    [TCO_VENDOR_MSK]: TCO_OPEX_TOOLTIP,
    [TCO_VENDOR_OSK]: TCO_OPEX_TOOLTIP,
    [TCO_VENDOR_CONFLUENT]: TCO_OPEX_TOOLTIP,
  },
  [TCO_OPERATIONS]: {
    [TCO_VENDOR_MSK]: TCO_OPERATIONS_TOOLTIP,
    [TCO_VENDOR_OSK]: TCO_OPERATIONS_TOOLTIP,
    CONFLUENT: TCO_OPERATIONS_TOOLTIP,
  },
  [TCO_SUPPORT]: {
    [TCO_VENDOR_MSK]: TCO_SUPPORT_TOOLTIP,
    [TCO_VENDOR_OSK]: TCO_SUPPORT_TOOLTIP,
  },
  [TCO_RISK]: {
    [TCO_VENDOR_MSK]: TCO_RISK_TOOLTIP,
    [TCO_VENDOR_OSK]: TCO_RISK_TOOLTIP,
    [TCO_VENDOR_CONFLUENT]: TCO_RISK_TOOLTIP,
  },
  [TCO_MSK_COMPUTE]: TCO_COMPUTE_TOOLTIP,
  [TCO_OSK_COMPUTE]: TCO_COMPUTE_TOOLTIP,
  [TCO_MSK_NETWORK]: TCO_NETWORK_TOOLTIP,
  [TCO_OSK_NETWORK]: TCO_NETWORK_TOOLTIP,
  [TCO_MSK_STORAGE]: TCO_STORAGE_TOOLTIP,
  [TCO_OSK_STORAGE]: TCO_STORAGE_TOOLTIP,
};
