// @flow
import { useParams } from 'react-router-dom';
import React from 'react';

import { ClusterNameLinkRenderer } from './ClusterNameLinkRenderer';

export const ClusterNameRenderer = ({ data }) => {
  const { estimateId } = useParams();

  return <ClusterNameLinkRenderer data={data} estimateId={estimateId} />;
};
