// @flow
import React from 'react';

import { ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-account-details';
import { SFDC_QUOTE_NAME_BACKEND_NAME } from '../../constants';
import { TextField } from '../../formik-utils/FormFields';

export const EstimateQuoteLineIDContainer = ({ disableOnFormErrors }) => {
  const { backendFieldName, displayName, icon } = ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP.get(
    SFDC_QUOTE_NAME_BACKEND_NAME
  );
  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      icon={icon}
    />
  );
};
