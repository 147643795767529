// @flow
import React from 'react';

import { DataGrid } from '../grid-utils/DataGrid';
import {
  CLUSTER_DEPLOYMENT,
  CLUSTER_ID,
  CLUSTER_ID_BACKEND_NAME,
  CLUSTER_NAME,
  CLUSTER_NAME_BACKEND_NAME,
  CLUSTER_NETWORKING,
  CLUSTER_NETWORKING_TYPE,
  CLUSTER_PROVIDER,
  CLUSTER_REGION,
  CLUSTER_REGION_BACKEND_NAME,
  CLUSTER_RETENTION_BACKEND_NAME,
  CLUSTER_TYPE,
  CLUSTER_TYPE_BACKEND_NAME,
  ESTIMATE_CLUSTER_CONFIGURATION_SUMMARY,
  NAME,
  TYPE,
} from '../../../constants';
import { ClusterNameRenderer } from '../clusters-summary/ClusterNameRenderer';
import { NumberRoundedRenderer, Number2DPRenderer } from '../grid-utils/renderers';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { StyledH3 } from '../../presentational/headings/StyledH3';
import { DEFAULT_DEFAULT_COLUMN_DEFINITION } from '../grid-utils/constants';

export const ClusterConfigurationsSummaryContainer = ({
  clusterConfigsArray,
  addLinkToClusterName = true,
  disabled = false,
}) => {
  const inputDataSource = clusterConfigsArray;
  const areAnyClustersConfigured = inputDataSource.length > 0;

  const columnDefs = [
    {
      field: CLUSTER_ID,
      backEndFieldName: CLUSTER_ID_BACKEND_NAME,
      hide: true,
    },
    {
      field: NAME,
      headerTooltip: CLUSTER_NAME,
      cellRenderer: addLinkToClusterName ? ClusterNameRenderer : null,
      backEndFieldName: CLUSTER_NAME_BACKEND_NAME,
    },
    {
      field: CLUSTER_PROVIDER,
      headerTooltip: CLUSTER_PROVIDER,
      backEndFieldName: 'cloud_provider',
    },
    {
      field: TYPE,
      headerTooltip: CLUSTER_TYPE,
      backEndFieldName: CLUSTER_TYPE_BACKEND_NAME,
    },
    {
      field: CLUSTER_DEPLOYMENT,
      headerTooltip: CLUSTER_DEPLOYMENT,
      backEndFieldName: 'deployment',
    },
    {
      field: CLUSTER_REGION,
      headerTooltip: CLUSTER_REGION,
      backEndFieldName: CLUSTER_REGION_BACKEND_NAME,
    },
    {
      field: CLUSTER_NETWORKING,
      headerTooltip: CLUSTER_NETWORKING_TYPE,
      backEndFieldName: 'networking',
    },
    {
      headerName: 'Avg Throughput (MB/s)',
      children: [
        {
          headerName: 'Write',
          headerTooltip: 'Write',
          field: 'Avg Write Throughput (MB/s)',
          backEndFieldName: 'average_write',
          cellRenderer: Number2DPRenderer,
        },
        {
          headerName: 'Read',
          headerTooltip: 'Read',
          field: 'Avg Read Throughput (MB/s)',
          backEndFieldName: 'average_read',
          cellRenderer: Number2DPRenderer,
        },
      ],
    },
    {
      field: 'Retention',
      headerTooltip: 'Retention in days',
      backEndFieldName: CLUSTER_RETENTION_BACKEND_NAME,
    },
    {
      headerName: 'Avg Storage (GB)',
      children: [
        {
          headerName: 'User Visible',
          headerTooltip: 'User Visible',
          field: 'Avg Storage (GB) - User Visible',
          backEndFieldName: 'average_storage_user_visible',
          cellRenderer: NumberRoundedRenderer,
        },
        {
          headerName: 'After Repl.',
          headerTooltip: 'After Replication',
          field: 'Avg Storage (GB) - After Replication',
          backEndFieldName: 'average_storage_after_repl',
          cellRenderer: NumberRoundedRenderer,
        },
      ],
    },
    {
      field: 'CKUs',
      headerTooltip: 'CKUs',
      backEndFieldName: 'average_ckus',
      headerName: 'CKUs',
      cellRenderer: NumberRoundedRenderer,
    },
    {
      field: 'ECKUs',
      headerTooltip: 'ECKUs',
      backEndFieldName: 'average_eckus',
      headerName: 'ECKUs',
      cellRenderer: NumberRoundedRenderer,
    },
    {
      headerName: 'Connect',
      children: [
        {
          field: 'Connector Tasks',
          headerName: 'Tasks',
          headerTooltip: 'Tasks',
          backEndFieldName: 'average_connect_task',
          cellRenderer: NumberRoundedRenderer,
        },
        {
          field: 'Connector Throughput',
          headerName: 'Throughput',
          headerTooltip: 'Avg Throughput in (MB/s)',
          backEndFieldName: 'average_connect_throughput',
          cellRenderer: Number2DPRenderer,
        },
      ],
    },
    {
      field: 'CSUs',
      headerTooltip: 'KSQL CSUs',
      backEndFieldName: 'average_ksql_csus',
      headerName: 'CSUs',
      cellRenderer: NumberRoundedRenderer,
    },
    {
      field: 'Links',
      headerTooltip: 'Cluster Links',
      backEndFieldName: 'average_cluster_links',
      cellRenderer: NumberRoundedRenderer,
    },
  ];

  const defaultColDef = {
    ...DEFAULT_DEFAULT_COLUMN_DEFINITION,
    wrapHeaderText: true,
  };

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      {!areAnyClustersConfigured ? (
        <StyledH3 as="h3" text="Add a cluster to see results" />
      ) : (
        <DataGrid
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          displayResizingColsOption={true}
          downloadAllCols={false}
          inputDataSource={inputDataSource}
          label={ESTIMATE_CLUSTER_CONFIGURATION_SUMMARY}
          tooltipShowDelay={50}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
