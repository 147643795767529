// @flow
import React, { useContext } from 'react';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridSelectField,
} from '../../../formik-utils/FieldArrayGrid';
import { STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_BACKEND_NAME } from '../../../constants';
import { STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG_MAP } from '../../../configuration/stream-governance';
import { getDropdownOptions } from '../utils';

export const RegionNameContainer = ({ dataUniverse }) => {
  const { rowValues } = useContext(FieldArrayGridConfigItemContext);

  const regionNameConfig = STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG_MAP.get(
    STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_BACKEND_NAME
  );

  const regionNameOptions = getDropdownOptions(dataUniverse, rowValues, regionNameConfig);

  return <FieldArrayGridSelectField columnConfig={regionNameConfig} options={regionNameOptions} />;
};
