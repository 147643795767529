// @flow
import React from 'react';
import { useCookies } from 'react-cookie';

import { CookieContext } from './CookieContext';

export const CookieContextProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(['theme']);

  return <CookieContext.Provider value={{ cookies, setCookie }}>{children}</CookieContext.Provider>;
};
