// @flow
import React, { useContext } from 'react';

import { IconWithPopup } from '../../presentational/IconWithPopup';
import { Spacer } from '../../presentational/spacing/Spacer';
import { OpenCloseHierarchyTreeContext } from '../../../contexts/OpenCloseHierarchyTreeContext';

export const ExpandAndCollapseContainer = () => {
  const { openAllNodesOfOpenCloseTree, closeAllNodesOfOpenCloseTree } = useContext(
    OpenCloseHierarchyTreeContext
  );

  return (
    <>
      <IconWithPopup
        data-testid="expand-all"
        iconName="plus"
        onClick={() => {
          openAllNodesOfOpenCloseTree();
        }}
        popupContent="Expand All"
      />

      <Spacer x={12} />

      <IconWithPopup
        data-testid="collapse-all"
        iconName="minus"
        onClick={() => {
          closeAllNodesOfOpenCloseTree();
        }}
        popupContent="Collapse All"
      />
    </>
  );
};
