// @flow

import React, { useCallback, useMemo } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { object } from 'yup';

import { useGetClusterDedicatedSurveyInputsQuery } from '../../../service-definitions/estimates';
import { StyledContainer } from '../../../common-utils/styledComponents';
import { DataFetcherContainer } from '../../presentational/DataFetcherContainer';
import { ConfirmModal } from '../../presentational/modals/ConfirmModal';
import { getValidationSchemaFromColsConfig } from '../../../configuration/utils';
import { DEDICATED_CLUSTER_USAGE_SURVEY_INPUTS_CONFIG } from '../../../configuration/dedicated-cluster-usage-survey-inputs';
import { DedicatedSurveyInputsContext } from '../../../contexts/DedicatedSurveyInputsContext';

import { DedicatedSurveyQuestionsContainer } from './dedicated-survey/DedicatedSurveyQuestionsContainer';

export const ClusterDedicatedSurveyInputsModal = ({
  onClickHandlerForCancel,
  onClickHandlerForOK,
  isOpen,
}) => {
  const { estimateId, clusterId } = useParams();
  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId, clusterId: clusterId },
          {
            refetchOnMountOrArgChange: true,
            skip: !estimateId || !clusterId || !isOpen,
          },
        ],
        [estimateId, clusterId, isOpen]
      )}
      dataFetchingFunction={useCallback(useGetClusterDedicatedSurveyInputsQuery, [])}
    >
      {(data) => {
        // todo::Move this to outside by changing all hooks to Variables
        if (!isOpen) {
          return null;
        }

        const initialValues = data?.survey?.survey_response ?? {};

        const validationSchema = object({
          ...getValidationSchemaFromColsConfig(DEDICATED_CLUSTER_USAGE_SURVEY_INPUTS_CONFIG),
        });

        const surveyFieldsData = { surveyFieldsInputData: data?.survey?.survey_fields ?? {} };

        return (
          <StyledContainer>
            <Formik
              autoComplete="off"
              initialValues={initialValues}
              onSubmit={async (values) => {
                onClickHandlerForOK(values);
              }}
              validateOnMount={true}
              validationSchema={validationSchema}
            >
              {(dedicatedSurveyInputsFormik) => (
                <ConfirmModal
                  body={
                    <DedicatedSurveyInputsContext.Provider value={surveyFieldsData}>
                      <DedicatedSurveyQuestionsContainer />
                    </DedicatedSurveyInputsContext.Provider>
                  }
                  cancelButtonNegative={true}
                  centered={false}
                  disabled={!dedicatedSurveyInputsFormik.isValid}
                  header="Please fill out this short survey for using a Dedicated Cluster"
                  isOpen={isOpen}
                  okButtonNegative={false}
                  okButtonText="I am done, proceed with saving the cluster changes!"
                  onClickHandlerForCancel={() => {
                    dedicatedSurveyInputsFormik.resetForm();
                    onClickHandlerForCancel();
                  }}
                  onClickHandlerForOK={async () => {
                    await dedicatedSurveyInputsFormik.submitForm();
                  }}
                />
              )}
            </Formik>
          </StyledContainer>
        );
      }}
    </DataFetcherContainer>
  );
};
