// @flow
import React from 'react';

import { ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-account-details';
import { SFDC_ORGANISATION_NAME_BACKEND_NAME } from '../../constants';
import { TextField } from '../../formik-utils/FormFields';

export const EstimateOrganisationNameContainer = ({ disableOnFormErrors }) => {
  const estimateOrganisationNameConfig = ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP.get(
    SFDC_ORGANISATION_NAME_BACKEND_NAME
  );
  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      fieldDisplayName={estimateOrganisationNameConfig.displayName}
      fieldName={estimateOrganisationNameConfig.backendFieldName}
      icon={estimateOrganisationNameConfig.icon}
    />
  );
};
