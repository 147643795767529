// @flow
import React, { useCallback, useContext, useMemo } from 'react';

import { DataFetcherContainer } from '../components/presentational/DataFetcherContainer';
import { useGetConnectorsQuery } from '../service-definitions/estimates';
import { RATE_CARD_BACKEND_NAME } from '../constants';

import { ConnectorsContext } from './ConnectorsContext';
import { EstimateContext } from './EstimateContext';

export const ConnectorsContextProvider = ({ children }) => {
  const estimate = useContext(EstimateContext);
  const rateCardVersion = estimate.inputs[RATE_CARD_BACKEND_NAME];

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { rateCardVersion },
          {
            skip: !rateCardVersion,
          },
        ],
        [rateCardVersion]
      )}
      dataFetchingFunction={useCallback(useGetConnectorsQuery, [])}
    >
      {(connectorsList) => {
        return (
          <ConnectorsContext.Provider value={connectorsList}>{children}</ConnectorsContext.Provider>
        );
      }}
    </DataFetcherContainer>
  );
};
