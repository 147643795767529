// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import AppRoutes from './AppRoutes';
import 'semantic-ui-css/semantic.css';
import { CookieContextProvider } from './contexts/CookieContextProvider';
import { StyledToastContainer } from './components/presentational/notifications/StyledToastContainer';
import { oktaAuthConfig } from './authentication/OKTAConfig';

const oktaAuth = new OktaAuth(oktaAuthConfig);

const App = () => {
  React.useLayoutEffect(() => {
    document.getElementById('root-loader')?.remove();
  }, []);

  const history = useHistory();

  const customAuthHandler = () => {
    history.push('/logout');
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <>
      <CookieContextProvider>
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={customAuthHandler}
          restoreOriginalUri={restoreOriginalUri}
        >
          <StyledToastContainer />
          <AppRoutes />
        </Security>
      </CookieContextProvider>
    </>
  );
};

export default App;
