// @flow
import { Breadcrumb } from 'semantic-ui-react';
import React from 'react';

import { Spacer } from '../presentational/spacing/Spacer';
import { StyledSpan } from '../../common-utils/styledComponents';

export const Breadcrumbs = ({ sections }) => {
  return (
    <>
      <Breadcrumb>
        {sections.map((section, index, arr) => {
          const isLastIndex = index === arr.length - 1;
          return (
            <>
              <Spacer x={0.5} />
              <Breadcrumb.Section key={index}>{section}</Breadcrumb.Section>
              <Spacer x={0.5} />
              {!isLastIndex && <Breadcrumb.Divider content={<StyledSpan>/</StyledSpan>} />}
            </>
          );
        })}
      </Breadcrumb>
    </>
  );
};
