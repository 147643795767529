// @flow
import React, { useContext, useState } from 'react';

import { ClusterSummaryContainer } from '../cluster-summaries/ClusterSummaryContainer';
import { ClusterUsageTabsContainer } from '../cluster-monthly-inputs/ClusterUsageTabsContainer';
import { ClusterDetailsContainer } from '../cluster-details-inputs/ClusterDetailsContainer';
import { ClusterCalculationsContainer } from '../cluster-calculations/ClusterCalculationsContainer';
import { getAccordianPanelDetailsObject } from '../../../common-utils/utils';
import { AccordionsList } from '../../presentational/accordions/AccordionsList';
import { YearSelectionContext } from '../YearSelectionContext';
import { EstimateContext } from '../../../contexts/EstimateContext';

export const ClusterAccordionsContainer = () => {
  const estimate = useContext(EstimateContext);
  const totalYears = estimate?.deal_duration?.deal_duration_years ?? 0;
  const [selectedYear, setSelectedYear] = useState(1);

  const accordionPanels = [
    getAccordianPanelDetailsObject('Cluster Details', <ClusterDetailsContainer />),
    getAccordianPanelDetailsObject('Cluster Usage', <ClusterUsageTabsContainer />),
    getAccordianPanelDetailsObject('Cluster Calculations', <ClusterCalculationsContainer />),
    getAccordianPanelDetailsObject('Cluster Summary', <ClusterSummaryContainer />),
  ];

  return (
    <YearSelectionContext.Provider
      value={{
        totalYears,
        selectedYear,
        setSelectedYear,
      }}
    >
      <AccordionsList panels={accordionPanels} />;
    </YearSelectionContext.Provider>
  );
};
