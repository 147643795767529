// @flow

export const isAnyErrorPresentInClusterConfigurationBasedOnClusterSummaryResponse = (estimate) => {
  const clusterSummary = estimate?.cluster_summary ?? [];
  for (const row of clusterSummary) {
    if (row.validation_error) {
      return true;
    }
  }

  return false;
};
