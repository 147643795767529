// @flow
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import React from 'react';

import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { LinkButton } from '../../presentational/buttons/LinkButton';

export const AddStreamGovernanceSegment = ({ disabled, Header }) => {
  const { estimateId } = useParams();
  const { isValid } = useFormikContext();

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      {Header}
      <LinkButton
        disabled={!isValid || !estimateId}
        pathname={`/estimate/${estimateId}/stream-governance`}
        text="Add Stream Governance Configuration"
      />
    </PaddedAndRaisedSegment>
  );
};
