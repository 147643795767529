// @flow
import React, { useRef } from 'react';
import { Grid } from 'semantic-ui-react';

import { MetricNameDropdownContainer } from './MetricNameDropdownContainer';
import { MetricNameDeleteButtonContainer } from './MetricNameDeleteButtonContainer';
import { MetricsDimensionsListContainer } from './MetricsDimensionsListContainer';
import { MetricNameAddButtonContainer } from './MetricNameAddButtonContainer';

export const MetricsLevelDiscountsMetricConfigurationItem = ({ readOnlyMode }) => {
  const metricDimensionRefs = useRef({
    dimensionArrayHelpers: null,
    dimensionColumnsConfiguration: null,
  });

  return (
    <>
      <Grid.Row>
        <Grid.Column width={3}>
          <MetricNameDropdownContainer />
        </Grid.Column>
        <Grid.Column width={1}>
          <MetricNameDeleteButtonContainer />
        </Grid.Column>
        <Grid.Column
          style={{
            marginLeft: '-2rem',
          }}
          width={1}
        >
          <MetricNameAddButtonContainer metricDimensionRefs={metricDimensionRefs} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column>
          <MetricsDimensionsListContainer
            metricDimensionRefs={metricDimensionRefs}
            readOnlyMode={readOnlyMode}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
