// @flow
import React from 'react';
import { Form as SemanticForm } from 'semantic-ui-react';
import { useFormikContext, Field as FormikField, getIn } from 'formik';

import { BOOLEAN_SELECT_INPUT_TYPE, CHECKBOX_INPUT_TYPE, TEXT_NUMBER_INPUT_TYPE } from './consts';

/* !!!!!! Note: This file is required for Semantic UI to work well with React - Picked from some thread on formik !!!! */

export const Form = (props) => {
  const { handleSubmit } = useFormikContext();
  return <SemanticForm onSubmit={handleSubmit} {...props} />;
};

export const Field = ({ component, ...fieldProps }) => {
  const { formik, ...rest } = fieldProps;
  return (
    <FormikField {...rest}>
      {({
        field: { errors: test, value, onBlur, onChange, ...field },
        form: { errors, setFieldValue, setFieldTouched, handleBlur, values },
        ...props
      }) => {
        const isFieldDisabled = () => {
          if (fieldProps.disableOnFormErrors === false) {
            return fieldProps?.disabled ?? false;
          } else if (
            fieldProps.disableOnFormErrors === true ||
            fieldProps.disableOnFormErrors === 'undefined'
          ) {
            return true;
          } else if (fieldProps.disabled) {
            return fieldProps.disabled;
          } else {
            return (
              Object.keys(errors).length !== 0 &&
              !getIn(errors, fieldProps.name) &&
              !fieldProps.disableOnFormErrors
            );
          }
        };
        let valueProps = typeof value === 'boolean' ? { checked: value } : { value: value ?? '' };

        if (rest.type === BOOLEAN_SELECT_INPUT_TYPE) {
          valueProps = { value: value };
        }

        return React.createElement(component, {
          ...field,
          ...props,
          ...valueProps,
          ...rest,
          disabled: isFieldDisabled(),
          onChange: async (e, { value: newValue, checked }) => {
            setFieldTouched(fieldProps.name, true);
            if (rest.type === TEXT_NUMBER_INPUT_TYPE) {
              setFieldValue(
                fieldProps.name,
                (parseFloat(newValue) || parseInt(newValue, 10)) ?? ''
              );
            } else if (rest.type === CHECKBOX_INPUT_TYPE) {
              setFieldValue(fieldProps.name, checked || false);
            } else if (rest.type === BOOLEAN_SELECT_INPUT_TYPE) {
              setFieldValue(fieldProps.name, newValue);
            } else {
              setFieldValue(fieldProps.name, newValue || '');
            }
            // call custom on change handler
            if (fieldProps.onChange) {
              fieldProps.onChange(e, newValue, setFieldValue, setFieldTouched, values, checked);
            }
          },
          onBlur: handleBlur,
        });
      }}
    </FormikField>
  );
};
