// @flow
import React from 'react';

import { LinkRenderer } from '../grid-utils/renderers';
import { CLUSTER_ID, NAME } from '../../../constants';

export const ClusterNameLinkRenderer = ({ data, estimateId }) => (
  <LinkRenderer
    pathName={`/estimate/${estimateId}/cluster/${data[CLUSTER_ID]}`}
    text={data[NAME]}
  />
);
