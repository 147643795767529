// @flow

import { getIn } from 'formik';
import { isEqual } from 'lodash';

import { CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG } from '../../../constants';

const isConnectorBeingEdited = (connectorIndex, initialValues, values) => {
  const keyNameInValuesToUseForThisConnector = `${CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG}.connectors[${connectorIndex}]`;
  const connectorOriginalData = getIn(initialValues, keyNameInValuesToUseForThisConnector);
  const connectorCurrentData = getIn(values, keyNameInValuesToUseForThisConnector);
  return !isEqual(connectorOriginalData, connectorCurrentData);
};

export const isAnyOtherConnectorBeingEdited = (
  totalNumberOfConnectors,
  initialValues,
  values,
  connectorIndex
) => {
  let isAnyConnectorBeingEditedCurrently = false;
  for (let i = 0; i <= totalNumberOfConnectors - 1; i++) {
    if (i === connectorIndex) {
      continue;
    }
    if (isConnectorBeingEdited(i, initialValues, values)) {
      isAnyConnectorBeingEditedCurrently = true;
      break;
    }
  }
  return isAnyConnectorBeingEditedCurrently;
};
