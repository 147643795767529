// @flow
import React from 'react';
import { useFormikContext } from 'formik';

import { PaddedAndRaisedSegment } from '../presentational/PaddedAndRaisedSegment';
import { isAnythingEditedOnEstimatePageBesidesTheGivenForm } from '../views/utils';
import { ESTIMATE_CUSTOM_DISCOUNTS_DETAILS_FORM } from '../../constants';

import { MetricsLevelDiscountsTopLevelContainer } from './metrics-level-configuration/MetricsLevelDiscountsTopLevelContainer';
import { GlobalDiscountContainer } from './global-discount/GlobalDiscountContainer';

export const CustomDiscountsTopLevelContainer = ({ readOnlyMode }) => {
  const { values, initialValues } = useFormikContext();

  const disabled = isAnythingEditedOnEstimatePageBesidesTheGivenForm(
    values,
    initialValues,
    ESTIMATE_CUSTOM_DISCOUNTS_DETAILS_FORM
  );

  const readOnlyModeToUse = readOnlyMode || disabled;

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <GlobalDiscountContainer readOnlyMode={readOnlyModeToUse} />
      <MetricsLevelDiscountsTopLevelContainer readOnlyMode={readOnlyModeToUse} />
    </PaddedAndRaisedSegment>
  );
};

// todo::Remove RO Mode completely and just call it disabled everywhere...
