// @flow
import React, { createContext, useContext } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { FieldArray, getIn, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import { StyledGridRow, StyledSpan } from '../common-utils/styledComponents';
import { AddButton } from '../components/presentational/buttons/AddButton';
import { Spacer } from '../components/presentational/spacing/Spacer';
import { toastError } from '../components/presentational/notifications/utils';
import {
  ROW_ID,
  DEFAULT_VALUE_FOR_DB_ROW_ID,
  IS_ROW_FROZEN,
  IS_ROW_OPENED_FOR_EDITING,
  RATE_CARD_BACKEND_NAME,
} from '../constants';
import { UpdateFormButton } from '../components/presentational/buttons/UpdateFormButton';
import { getFormattedRateCardVersion } from '../common-utils/utils';

import { SelectField, TextField } from './FormFields';

export const getStylesForInProgressRow = () => ({
  borderLeft: '5px solid teal',
  borderRadius: '10px',
  backgroundColor: '#fbbd081f',
});

export const setRowIsOpenedForEditing = (pathToFollowInValues, index, formik) => {
  formik.setFieldValue(getFieldName(pathToFollowInValues, index, IS_ROW_OPENED_FOR_EDITING), true);
};

export const getFieldName = (pathToFollowInValues, index, colBackendName) =>
  `${pathToFollowInValues.join('.')}[${index}].${colBackendName}`;

export const getIndexOfInProgressRowFromGivenArray = (rows) => {
  for (let i = 0; i <= rows.length - 1; i++) {
    const row = rows[i];

    if (Boolean(row?.isRowOpenedForEditing) === true) {
      return i;
    }
  }

  return null;
};

const getIfAllRowsAreFrozen = (rows) => rows.every((x) => x?.isRowFrozen === true);

const AddRowButton = ({
  arrayHelpers,
  indexOfInProgressRow,
  columnsConfiguration,
  gridName,
  disabled,
}) => {
  const { isValid } = useFormikContext();

  return (
    <>
      <Spacer y={10} />
      <StyledGridRow>
        <Grid.Column verticalAlign="middle" width={4}>
          <AddButton
            disabled={disabled || !isValid || indexOfInProgressRow != null}
            onClick={() => {
              const newRow = {
                [IS_ROW_FROZEN]: false,
                [IS_ROW_OPENED_FOR_EDITING]: true,
                [ROW_ID]: DEFAULT_VALUE_FOR_DB_ROW_ID,
              };
              for (const colConfig of columnsConfiguration) {
                newRow[colConfig.backendFieldName] = colConfig.colDefaultValue;
              }
              return arrayHelpers.push(newRow);
            }}
          />
          <Spacer x={4} />
          <StyledSpan className="ui tiny header">Add {gridName}</StyledSpan>
        </Grid.Column>
      </StyledGridRow>
    </>
  );
};

const Headers = ({ columnsConfiguration }) => {
  return (
    <>
      <Spacer y={10} />
      <StyledGridRow>
        {columnsConfiguration.map((colConfig) => {
          return (
            <>
              {colConfig.hidden === false && (
                <Grid.Column key={colConfig.backendFieldName} width={colConfig.columnWidth}>
                  <StyledSpan className="ui tiny header">{colConfig.displayName}</StyledSpan>
                </Grid.Column>
              )}
            </>
          );
        })}
      </StyledGridRow>
    </>
  );
};

export const getIfRowIsCurrentlyBeingEdited = (indexOfInProgressRow, indexOfRowPassed) =>
  indexOfInProgressRow == null ? false : indexOfInProgressRow === indexOfRowPassed;

export const FieldArrayGridDeleteButton = ({
  deleteFunc = null,
  onClickHandler = null,
  deleteFuncParams = null,
  disabled = null,
}) => {
  const formik = useFormikContext();
  const { index, rowValues, arrayHelpers, readOnlyMode } = useContext(
    FieldArrayGridConfigItemContext
  );

  const { estimateId } = useParams();

  const disabledDefaultValue = getIfDeleteButtonNeedsToBeDisabled(formik, rowValues, readOnlyMode);
  // If the caller chooses to pass in a value, then give the complete control to him
  const disabledToUse = disabled ? disabled : disabledDefaultValue;

  const onClickHandlerFuncDefault = async () => {
    await handleDeleteClick(
      index,
      rowValues,
      estimateId,
      deleteFunc,
      arrayHelpers,
      deleteFuncParams
    );
  };

  return (
    <Button
      circular={true}
      className="negative"
      compact={true}
      data-testid="delete-button"
      disabled={disabledToUse}
      icon="delete"
      onClick={onClickHandler || onClickHandlerFuncDefault}
      primary={true}
      size="small"
    />
  );
};

export const FieldArrayGridUpdateButton = ({
  updateFunc,
  errorMessage,
  columnsConfig,
  updateFuncParams = null,
}) => {
  const { estimateId } = useParams();
  const formik = useFormikContext();
  const { index, rowValues, arrayHelpers, readOnlyMode, pathToFollowInValues } = useContext(
    FieldArrayGridConfigItemContext
  );

  const disabled = getIfUpdateButtonShouldBeDisabled(formik, rowValues, readOnlyMode);

  const onClickHandler = async () => {
    await handleUpdateClick(
      rowValues,
      estimateId,
      updateFunc,
      arrayHelpers,
      index,
      formik,
      pathToFollowInValues,
      errorMessage,
      columnsConfig,
      updateFuncParams
    );
  };

  return <UpdateFormButton disabled={disabled} onClick={onClickHandler} />;
};

const getReadOnlyModeBasedOnCurrentIndexAndInProgressRowIndex = (
  indexOfInProgressRow,
  indexOfRowPassed
) => (indexOfInProgressRow == null ? false : indexOfInProgressRow !== indexOfRowPassed);

export const isAnyErrorPresentInFieldArrayGrid = (
  errors,
  pathToFollowInValues,
  index,
  columnsConfig
) => {
  const errorsInConfig = getIn(errors, pathToFollowInValues.join('.'), null);
  const hasConfigLevelError = typeof errorsInConfig === 'string';

  if (hasConfigLevelError) {
    return true;
  }

  for (const columnConfig of columnsConfig) {
    const fieldName = getFieldName(pathToFollowInValues, index, columnConfig.backendFieldName);

    const error = getIn(errors, fieldName, null);
    if (error) {
      return true;
    }
  }
  return false;
};

export const FieldArrayGrid = ({
  pathToFollowInValues,
  columnsConfiguration,
  gridName,
  children,
  addHeaders = true,
  disabled = false,
  needsAddButton = true,
  functionToExecuteOnceArrayHelpersAreAvailable = null,
  verticalSpaceBetweenHeadersAndRows = 20,
  addDividerBetweenRows = true,
  addRowButtonDisabled = false,
}) => {
  const { values: formikValues } = useFormikContext();

  return (
    <Grid
      style={{
        paddingLeft: '1.5rem',
        paddingTop: '0rem',
      }}
    >
      <FieldArray
        name={pathToFollowInValues.join('.')}
        render={(arrayHelpers) => {
          if (functionToExecuteOnceArrayHelpersAreAvailable) {
            functionToExecuteOnceArrayHelpersAreAvailable(arrayHelpers);
          }

          const rows = getIn(formikValues, pathToFollowInValues.join('.'), []);
          const areAllRowsFrozen = getIfAllRowsAreFrozen(rows);
          const indexOfInProgressRow = getIndexOfInProgressRowFromGivenArray(rows);

          return (
            <>
              {rows.length > 0 && addHeaders && (
                <>
                  <Headers columnsConfiguration={columnsConfiguration} />
                  <Spacer y={verticalSpaceBetweenHeadersAndRows} />
                </>
              )}
              {rows.map((row, index) => {
                const isCurrentlyBeingEdited = getIfRowIsCurrentlyBeingEdited(
                  indexOfInProgressRow,
                  index
                );

                const readOnlyMode = getReadOnlyModeBasedOnCurrentIndexAndInProgressRowIndex(
                  indexOfInProgressRow,
                  index
                );

                return (
                  <React.Fragment key={`${index}${row.dbRowId}`}>
                    {children(
                      arrayHelpers,
                      row,
                      isCurrentlyBeingEdited,
                      index,
                      rows,
                      disabled || readOnlyMode
                    )}
                    {addDividerBetweenRows && <div className="ui divider" />}
                  </React.Fragment>
                );
              })}
              {(rows.length === 0 || (rows.length > 0 && areAllRowsFrozen)) && needsAddButton && (
                <>
                  <Spacer y={30} />
                  <AddRowButton
                    arrayHelpers={arrayHelpers}
                    columnsConfiguration={columnsConfiguration}
                    disabled={disabled || addRowButtonDisabled}
                    gridName={gridName}
                    indexOfInProgressRow={indexOfInProgressRow}
                  />
                  <Spacer y={30} />
                </>
              )}
            </>
          );
        }}
      />
    </Grid>
  );
};

export const getIfUpdateButtonShouldBeDisabled = (formik, rowValues, readOnlyMode) => {
  // If we are in readOnlyMode, then, return true to disable it un-conditionally
  if (readOnlyMode) {
    return true;
  }

  // IF there is any issue in the form, return true to disable it
  if (!formik.isValid) {
    return true;
  }

  // If the row is opened for editing, return false to enable it
  if (rowValues?.[IS_ROW_OPENED_FOR_EDITING]) {
    return false;
  }

  // If the row is frozen, then return true to disable it
  return Boolean(rowValues?.[IS_ROW_FROZEN]);
};

export const getIfDeleteButtonNeedsToBeDisabled = (formik, rowValues, readOnlyMode) => {
  // If we are in readOnlyMode, then, return true to disable it un-conditionally
  if (readOnlyMode) {
    return true;
  }

  // if the row is not frozen yet, then, DO NOT disable
  if (!rowValues?.[IS_ROW_FROZEN]) {
    return false;
  }

  // if the row is open for editing, then, DO NOT disable
  if (rowValues?.[IS_ROW_OPENED_FOR_EDITING]) {
    return false;
  }

  // If form is valid, then return false to enable
  // else return true to disable
  return !formik.isValid;
};

export const handleUpdateClick = async (
  rowValues,
  estimateId,
  updateFunc,
  arrayHelpers,
  index,
  formik,
  pathToFollowInValues,
  errorMessage,
  columnsConfig,
  updateFuncParams = null
) => {
  const { errors, values } = formik;

  const isAnyErrorPresent = isAnyErrorPresentInFieldArrayGrid(
    errors,
    pathToFollowInValues,
    index,
    columnsConfig
  );

  if (isAnyErrorPresent) {
    return;
  }

  const payload = {
    row: rowValues,
  };

  // If the user has passed in params explicitly, use those instead of the default one
  let updateFuncParamsToUse;

  if (updateFuncParams != null) {
    updateFuncParamsToUse = { ...updateFuncParams };
  } else {
    updateFuncParamsToUse = {
      estimateId: estimateId,
      payload,
      rateCardVersion: getFormattedRateCardVersion(values[RATE_CARD_BACKEND_NAME]),
    };
  }

  const { data, error } = await updateFunc({ ...updateFuncParamsToUse });

  if (error) {
    toastError(error, errorMessage);
  } else {
    arrayHelpers.replace(index, {
      ...rowValues,
      [IS_ROW_FROZEN]: true,
      [IS_ROW_OPENED_FOR_EDITING]: false,
      [ROW_ID]: data.id,
    });
  }
};

export const handleDeleteClick = async (
  index,
  rowValues,
  estimateId,
  deleteFunc,
  arrayHelpers,
  deleteFuncParams = null
) => {
  const dbRowId = rowValues[ROW_ID];

  if (dbRowId === DEFAULT_VALUE_FOR_DB_ROW_ID) {
    arrayHelpers.remove(index);
    return;
  }

  const payload = {
    ...rowValues,
  };

  const deleteFuncDefaultParams = {
    payload,
    estimateId: estimateId,
  };

  const { error } = await deleteFunc(deleteFuncParams || deleteFuncDefaultParams);

  if (error) {
    toastError(
      error,
      'There was an error in deleting the config, please contact #cloud-commitment-estimator channel!'
    );
  } else {
    arrayHelpers.remove(index);
  }
};

export const FieldArrayGridSelectField = ({
  columnConfig,
  options,
  disableWhenSet = false,
  disabled = null,
  onChange = null,
}) => {
  const formik = useFormikContext();
  const { pathToFollowInValues, index, readOnlyMode, rowValues } = useContext(
    FieldArrayGridConfigItemContext
  );

  const defaultOnChangeHandler = async () => {
    await resetDependentFields(columnConfig, formik, pathToFollowInValues, index);
    setRowIsOpenedForEditing(pathToFollowInValues, index, formik);
  };

  return (
    <SelectField
      // addErrorMessage={false}
      addLabel={false}
      disableOnFormErrors={false}
      // If the caller sets disabled, then give complete control to the caller
      disabled={
        disabled
          ? disabled
          : readOnlyMode ||
            isAnyOfDependenciesNotSet(columnConfig, rowValues) ||
            (disableWhenSet && rowValues[columnConfig.backendFieldName] != null)
      }
      fieldDisplayName={columnConfig.displayName}
      fieldName={getFieldName(pathToFollowInValues, index, columnConfig.backendFieldName)}
      // If the caller provides onChange, then give complete control to the caller
      onChange={onChange ? onChange : defaultOnChangeHandler}
      options={options}
      placeholder={columnConfig.displayName}
    />
  );
};

export const isAnyOfDependenciesNotSet = (columnConfig, rowValues) => {
  const enableOnlyIfSetArr = columnConfig?.enableOnlyIfSet ?? [];

  let isAnyOfDependenciesNotSet = false;

  for (const dep of enableOnlyIfSetArr) {
    if (rowValues?.[dep] == null) {
      isAnyOfDependenciesNotSet = true;
    }
  }

  return isAnyOfDependenciesNotSet;
};

const resetDependentFields = async (columnConfig, formik, pathToFollowInValues, index) => {
  const dependentFieldsArr = columnConfig?.dependentFields ?? [];

  for (const dep of dependentFieldsArr) {
    await formik.setFieldValue(getFieldName(pathToFollowInValues, index, dep), null);
  }
};

export const FieldArrayGridTextField = ({ columnConfig, disableOnFormErrors = false }) => {
  const formik = useFormikContext();
  const { pathToFollowInValues, index, readOnlyMode, rowValues } = useContext(
    FieldArrayGridConfigItemContext
  );

  const fieldName = getFieldName(pathToFollowInValues, index, columnConfig.backendFieldName);

  return (
    <TextField
      // addErrorMessage={false}
      addLabel={false}
      disableOnFormErrors={disableOnFormErrors}
      disabled={readOnlyMode || isAnyOfDependenciesNotSet(columnConfig, rowValues)}
      fieldDisplayName={columnConfig.displayName}
      fieldName={fieldName}
      icon={columnConfig.icon}
      onChange={async () => {
        await resetDependentFields(columnConfig, formik, pathToFollowInValues, index);
        setRowIsOpenedForEditing(pathToFollowInValues, index, formik);
      }}
      placeholder={columnConfig.displayName}
      type={columnConfig.type}
    />
  );
};

export const FieldArrayStyledGridRow = ({ children }) => {
  const { isCurrentlyBeingEdited } = useContext(FieldArrayGridConfigItemContext);

  let style = isCurrentlyBeingEdited ? getStylesForInProgressRow() : {};
  style = {
    ...style,
    paddingTop: '1rem',
    paddingBottom: '1rem',
  };
  return (
    <>
      <StyledGridRow style={style}>{children}</StyledGridRow>
    </>
  );
};

export const FieldArrayGridConfigItemContext = createContext({});
