// @flow
import { addMethod, array, object, string } from 'yup';

import { toastError, toastSuccess } from '../presentational/notifications/utils';

addMethod(string, 'isConfluentDomain', function (errorMessage) {
  return this.test(`test-email-domain`, errorMessage, function (value) {
    const { path, createError } = this;
    const domainName = (value?.split('@')?.[1] ?? '').toLowerCase();
    return domainName === 'confluent.io' || createError({ path, message: errorMessage });
  });
});

export const validationSchema = object({
  shared_with_emails: array()
    .of(
      object({
        email_id: string()
          .email('Please enter a valid confluent email address')
          .isConfluentDomain('Please enter a valid confluent email address')
          .required('please enter a valid email address'),
      })
    )
    .test('thereAreSomeDuplicateEmails', 'There are duplicate emails. Please check!', function () {
      const emails = this.parent.shared_with_emails.map((x) => x.email_id);
      return new Set(emails).size === emails.length;
    }),
});

export const handleShareSubmit = async (values, shareEstimate, estimateId) => {
  const body = {
    share_info: values.shared_with_emails,
  };
  const { error } = await shareEstimate({
    estimateId,
    body,
  });

  // todo::If this pattern is the same across all usages, then think about extracting it into a common function
  if (error) {
    toastError(error, 'Sharing failed. Please double check the email id(s) entered!');
  } else {
    toastSuccess('Estimated shared successfully! The recipient(s) were notified on Slack!');
  }
};
