// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { ESTIMATE_COMMIT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-commit-details';
import {
  DEAL_TYPE_BACKEND_NAME,
  DEAL_TYPE_RENEWAL,
  RATE_CARD_BACKEND_NAME,
  RATE_CARD_VERSION_4_11_2024,
} from '../../constants';
import { SelectField } from '../../formik-utils/FormFields';
import { EstimateContext } from '../../contexts/EstimateContext';
import { getDropdownOptionsFromArray } from '../../common-utils/utils';

export const DealTypeContainer = () => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const estimateData = useContext(EstimateContext);
  const dealTypes = estimateData.commit_config.deal_types;
  const dealTypesOptions = getDropdownOptionsFromArray(dealTypes);
  const dealTypeConfig = ESTIMATE_COMMIT_DETAILS_CONFIG_MAP.get(DEAL_TYPE_BACKEND_NAME);

  return (
    <SelectField
      fieldDisplayName={dealTypeConfig.displayName}
      fieldName={dealTypeConfig.backendFieldName}
      onChange={async (e, value) => {
        if (
          value === DEAL_TYPE_RENEWAL &&
          values[RATE_CARD_BACKEND_NAME] === RATE_CARD_VERSION_4_11_2024
        ) {
          await setFieldValue(RATE_CARD_BACKEND_NAME, '');
          await setFieldTouched(RATE_CARD_BACKEND_NAME, true);
        }
      }}
      options={dealTypesOptions}
    />
  );
};
