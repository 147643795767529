// @flow
import React from 'react';

import { ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-account-details';
import { SFDC_OPPORTUNITY_ID_BACKEND_NAME } from '../../constants';
import { TextField } from '../../formik-utils/FormFields';

export const EstimateOpportunityIDContainer = ({ disableOnFormErrors }) => {
  const estimateOpportunityIDConfig = ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP.get(
    SFDC_OPPORTUNITY_ID_BACKEND_NAME
  );
  return (
    <>
      <TextField
        disableOnFormErrors={disableOnFormErrors}
        fieldDisplayName={estimateOpportunityIDConfig.displayName}
        fieldName={estimateOpportunityIDConfig.backendFieldName}
        icon={estimateOpportunityIDConfig.icon}
        tooltip={estimateOpportunityIDConfig.tooltip}
      />
    </>
  );
};
