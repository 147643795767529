// @flow

import React from 'react';

import { StyledButton } from '../../../common-utils/styledComponents';

export const ModalCancelButton = ({
  disabled = false,
  onClick,
  content = 'No',
  cancelButtonNegative,
}) => (
  <StyledButton
    content={content}
    data-testid="modal-cancel-button"
    disabled={disabled}
    negative={cancelButtonNegative}
    onClick={onClick}
    primary={!cancelButtonNegative}
    size="tiny"
  />
);
