// @flow
import React, { useContext } from 'react';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridUpdateButton,
} from '../../../formik-utils/FieldArrayGrid';
import { STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG } from '../../../configuration/stream-governance';
import {
  DEFAULT_VALUE_FOR_DB_ROW_ID,
  ESTIMATE_VERSION_BACKEND_NAME,
  ROW_ID,
  STREAM_GOVERNANCE_DB_ROW_ID_BACKEND_NAME,
} from '../../../constants';
import {
  useCreateStreamGovernanceConfigurationMutation,
  useUpdateStreamGovernanceConfigurationMutation,
} from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';

export const UpdateButtonContainer = () => {
  const estimate = useContext(EstimateContext);
  const { rowValues } = useContext(FieldArrayGridConfigItemContext);

  const [updateSGConfig] = useUpdateStreamGovernanceConfigurationMutation();
  const [createSGConfig] = useCreateStreamGovernanceConfigurationMutation();

  // Note: Update does not need the FE ID which is -1
  const rowValuesCopied = { ...rowValues };
  delete rowValuesCopied.id;

  const payload = {
    ...rowValuesCopied,
    [ESTIMATE_VERSION_BACKEND_NAME]: estimate?.inputs?.estimate_version ?? -1,
  };

  return (
    <FieldArrayGridUpdateButton
      columnsConfig={STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG}
      errorMessage="There was an error in updating the Stream Governance configuration, please contact #cloud-commitment-estimator channel!"
      updateFunc={
        rowValues[ROW_ID] === DEFAULT_VALUE_FOR_DB_ROW_ID ? createSGConfig : updateSGConfig
      }
      updateFuncParams={{
        ...rowValues,
        estimateId: estimate?.inputs?.id,
        streamGovernanceId: rowValues[STREAM_GOVERNANCE_DB_ROW_ID_BACKEND_NAME],
        payload,
      }}
    />
  );
};
