// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';
import { CLUSTER_ENABLED_BACKEND_NAME } from '../../../constants';
import { CheckBoxField } from '../../../formik-utils/FormFields';
import { toastError } from '../../presentational/notifications/utils';
import {
  useDisableClusterMutation,
  useEnableClusterMutation,
} from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';

export const ClusterEnabledContainer = ({ clusterId = null, checked = null, addLabel = true }) => {
  const { isValid } = useFormikContext();
  const estimateData = useContext(EstimateContext);
  const [enableCluster] = useEnableClusterMutation();
  const [disableCluster] = useDisableClusterMutation();
  const { estimateId, clusterId: clusterIdComingFromParams } = useParams();
  const clusterIdToUse = clusterId ? clusterId : clusterIdComingFromParams;

  const clusterNameConfig = CLUSTER_DETAILS_CONFIG_MAP.get(CLUSTER_ENABLED_BACKEND_NAME);

  const checkedProps = checked != null ? { checked } : {};
  return (
    <CheckBoxField
      addLabel={addLabel}
      disabled={!isValid}
      fieldDisplayName={clusterNameConfig.displayName}
      fieldName={clusterNameConfig.backendFieldName}
      icon={clusterNameConfig.icon}
      onChange={async (event, value, setFieldValue, setFieldTouched, values, checked) => {
        const payload = {
          id: clusterIdToUse,
          estimate_id: estimateId,
          estimate_version: estimateData?.inputs?.estimate_version,
        };

        const funcToCall = checked ? enableCluster : disableCluster;

        const { error } = await funcToCall({
          estimateId,
          clusterId: clusterIdToUse,
          payload,
        });

        if (error) {
          toastError(error);
        }
      }}
      {...checkedProps}
    />
  );
};
