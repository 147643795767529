// @flow
import React from 'react';

import { TextField } from '../../formik-utils/FormFields';

export const SharedEmailNameContainer = ({ index }) => {
  return (
    <TextField
      addLabel={false}
      errorMessagePosition="bottom center"
      fieldDisplayName="Email"
      fieldName={`shared_with_emails.${index}.email_id`}
      fluid={true}
      onChange={null}
    />
  );
};
