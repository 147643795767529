// @flow
import React from 'react';

import { StyledButton } from '../../../common-utils/styledComponents';

export const DownloadButton = ({ disabled = false, onClick }) => {
  return (
    <StyledButton
      circular={true}
      compact={true}
      data-testid="download-button"
      disabled={disabled}
      icon="download"
      onClick={onClick}
      positive={true}
      size="mini"
      type="button"
    />
  );
};
