// @flow

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import {
  CONFLICTING_UPDATE_ERROR_MESSAGE,
  DEFAULT_FE_ERROR_MESSAGE,
  ERROR_MESSAGES_MAPPING_FOR_409,
} from '../../../constants';

export const getErrorStatusAndDetailFromErrorObject = (error) => {
  const errorStatus = error?.status;
  const errorDetail = error?.data?.errors?.[0]?.detail ?? null;
  return { errorStatus, errorDetail };
};

const getErrorStringContent = (error, content) => {
  const { errorStatus, errorDetail } = getErrorStatusAndDetailFromErrorObject(error);
  const contentToUse = content;

  if (errorStatus === 409) {
    if (!errorDetail) {
      // If 409 is the status, then, if errorDetail is not sent, then, blindly show Conflicting Update Message
      return CONFLICTING_UPDATE_ERROR_MESSAGE;
    }

    // If errorDetail is sent, then show the corresponding error
    // Note: If a re-mapping is not defined, then, we show the existing error message as is
    return ERROR_MESSAGES_MAPPING_FOR_409?.[errorDetail] ?? errorDetail;
  }

  return contentToUse;
};

export const toastError = (
  error,
  content = DEFAULT_FE_ERROR_MESSAGE,
  position = 'top-left',
  autoClose = false
) => {
  // todo::Fix the error message creation logic properly later by considering various scenarios
  const contentToUse = getErrorStringContent(error, content);

  toast.error(contentToUse, {
    position: position,
    autoClose: autoClose ? 3000 : false,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const toastSuccess = (
  content = 'Successfully Saved The Changes!',
  autoClose = true,
  position = 'top-left'
) => {
  toast.success(content, {
    position: position,
    autoClose: autoClose ? 3000 : false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
