// @flow
import { Icon } from 'semantic-ui-react';
import React from 'react';

export const MoonIcon = ({ onClick }) => (
  <Icon
    color="white"
    data-testid="moon-icon"
    inverted={true}
    name="moon"
    onClick={onClick}
    size="large"
    style={{ cursor: 'pointer' }}
  />
);
