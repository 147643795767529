// @flow
import React from 'react';
import { useFormikContext } from 'formik';
import Prompt from 'react-router-dom/es/Prompt';

export const PromptIfFormHasUnSavedChanges = ({ isOpen = true }) => {
  const formik = useFormikContext();
  return (
    <Prompt
      message="Are you sure you want to leave? You have unsaved changes and they will be lost!"
      when={isOpen && formik.dirty}
    />
  );
};
