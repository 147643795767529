// @flow
import { configureStore } from '@reduxjs/toolkit';

import { estimatesApi } from './service-definitions/estimates';

export const store = configureStore({
  reducer: {
    [estimatesApi.reducerPath]: estimatesApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(estimatesApi.middleware),
});
