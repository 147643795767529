// @flow

import React, { useState } from 'react';

import { StyledAccordion } from '../../../common-utils/styledComponents';

const handleExclusiveAccordionTitleClick = (e, data, openAccordionIndex, setOpenAccordionIndex) => {
  const { index } = data;
  const newIndex = openAccordionIndex === index ? -1 : index;
  setOpenAccordionIndex(newIndex);
};

export const ExclusiveAccordionsList = ({ panels }) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState(0);

  return (
    <StyledAccordion
      activeIndex={openAccordionIndex}
      exclusive={true}
      fluid={true}
      onTitleClick={(e, data) =>
        handleExclusiveAccordionTitleClick(e, data, openAccordionIndex, setOpenAccordionIndex)
      }
      panels={panels}
      styled={true}
    />
  );
};
