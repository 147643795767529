// @flow
import { Dropdown } from 'semantic-ui-react';
import React from 'react';

import { StyledDropdown } from '../../common-utils/styledComponents';
import { ContentIcon } from '../presentational/icons/ContentIcon';

import { ExternalLinkDropdownItem } from './ExternalLinkDropdownItem';
import { LogoutDropdownMenuItem } from './LogoutDropdownMenuItem';

export const Hamburger = () => {
  return (
    <StyledDropdown icon={<ContentIcon />} item={true} simple={true}>
      <Dropdown.Menu>
        <ExternalLinkDropdownItem href="https://go/commitestimatorwiki" text="About" />
        <ExternalLinkDropdownItem
          href="https://confluentinc.atlassian.net/wiki/spaces/CS/pages/2762843455/Commit+Estimator+-+Tips+and+FAQs"
          text="F.A.Q"
        />
        <ExternalLinkDropdownItem
          href="https://forms.gle/NzefNNm1kTQTBGn16"
          text="Submit Feedback"
        />
        <LogoutDropdownMenuItem />
      </Dropdown.Menu>
    </StyledDropdown>
  );
};
