// @flow
import React from 'react';
import styled, { keyframes } from 'styled-components';

// todo:: These styles are duplicated here and in index.html. Remove the duplication....
const stretchDelay = keyframes`
  0%,
  40%,
  100% {
    transform: scaleY(.4);
  }
  20% {
    transform: scaleY(1);
  }
`;

const Root = styled.div`
  margin: auto;
  width: 50rem;
  height: 2rem;
  text-align: center;
  font-size: 5rem;

  > div {
    background-color: #45c6e8ff;
    height: 200%;
    width: 1rem;
    margin: 0 0.1rem;
    display: inline-block;
    animation: ${stretchDelay} 1.2s infinite ease-in-out;
  }

  > div:nth-child(2) {
    animation-delay: -1.1s;
  }

  > div:nth-child(3) {
    animation-delay: -1s;
  }

  > div:nth-child(4) {
    animation-delay: -0.9s;
  }

  > div:nth-child(5) {
    animation-delay: -0.8s;
  }
`;

export const InlineLoader = ({ className }) => {
  return (
    <Root className={className} data-testid="inline-loader">
      <div />
      <div />
      <div />
      <div />
      <div />
    </Root>
  );
};
