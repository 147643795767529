// @flow

import React from 'react';

import { FlinkPoolContextProvider } from '../../contexts/FlinkPoolContextProvider';
import { FlinkPoolBreadcrumbs } from '../breadcrumbs/FlinkPoolBreadcrumbs';
import { ShowLoaderIfAnyQueryIsPending } from '../presentational/ShowLoaderIfAnyQueryIsPending';
import { UserContextProvider } from '../../contexts/UserContextProvider';
import { EstimateContextProvider } from '../../contexts/EstimateContextProvider';

import { FlinkPoolTopLevelContainerForm } from './FlinkPoolTopLevelContainerForm';

export const FlinkPoolTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <EstimateContextProvider>
          <FlinkPoolContextProvider>
            <FlinkPoolBreadcrumbs />
            <FlinkPoolTopLevelContainerForm />
          </FlinkPoolContextProvider>
        </EstimateContextProvider>
      </UserContextProvider>
    </>
  );
};
