// @flow
import React from 'react';
import { useFormikContext } from 'formik';

import { TabsContainer } from '../../presentational/TabsContainer';
import { isAnyOfTheFlinkPoolRelatedFormsEdited } from '../../cluster/cluster-details-inputs/utils';

import { FlinkPoolTotalSpendSummaryContainer } from './FlinkPoolTotalSpendSummaryContainer';
import { FlinkPoolMonthlySpendSummaryContainer } from './FlinkPoolMonthlySpendSummaryContainer';
import { FlinkPoolConfigurationSummaryContainer } from './FlinkPoolConfigurationSummaryContainer';

export const FlinkPoolSummaryContainer = () => {
  const { values, initialValues } = useFormikContext();
  const disabled = isAnyOfTheFlinkPoolRelatedFormsEdited(values, initialValues);

  const tabPanes = [
    {
      menuItem: 'Total Spend',
      render: () => <FlinkPoolTotalSpendSummaryContainer disabled={disabled} />,
    },
    {
      menuItem: 'Monthly Spend',
      render: () => <FlinkPoolMonthlySpendSummaryContainer disabled={disabled} />,
    },
    {
      menuItem: 'Configuration',
      render: () => <FlinkPoolConfigurationSummaryContainer disabled={disabled} />,
    },
  ];

  return <TabsContainer tabPanes={tabPanes} />;
};
