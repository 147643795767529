// @flow
import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

import {
  AUG_1_RATE_CARD_VERSION,
  DEAL_TYPE_BACKEND_NAME,
  DEAL_TYPE_RENEWAL,
  GLOBAL_DISCOUNT_APPLIED_BACKEND_NAME,
  RATE_CARD_BACKEND_NAME,
} from '../../constants';
import { WarningMessageContainer } from '../presentational/messages/WarningMessageContainer';

export const RenewalWarningContainer = () => {
  const { values, errors } = useFormikContext();
  const [renewalErrorVisible, setRenewalErrorVisible] = useState(false);

  useEffect(() => {
    values[DEAL_TYPE_BACKEND_NAME] === DEAL_TYPE_RENEWAL &&
    values[RATE_CARD_BACKEND_NAME] === AUG_1_RATE_CARD_VERSION
      ? setRenewalErrorVisible(true)
      : setRenewalErrorVisible(false);
  }, [values]);

  return (
    <>
      {renewalErrorVisible && !errors[GLOBAL_DISCOUNT_APPLIED_BACKEND_NAME] && (
        <WarningMessageContainer
          link="https://confluentinc.atlassian.net/wiki/spaces/PM/pages/2182939664/Confluent+Cloud+Rate+Card+Policy/"
          message="Rate card change at renewal. Check renewal policy!"
        />
      )}
    </>
  );
};
