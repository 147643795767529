// @flow
import React from 'react';
import { Field, getIn } from 'formik';

import { StyledPopup } from '../common-utils/styledComponents';

export const FormikErrorMessage = ({
  className,
  name,
  position = 'right center',
  showErrorsOnlyWhenTouched = true,
}) => {
  return (
    <Field name={name}>
      {({ form }) => {
        const error = getIn(form.errors, name);
        const isFieldTouched = getIn(form.touched, name);
        let shouldShowError = error != null;
        if (showErrorsOnlyWhenTouched) {
          shouldShowError = shouldShowError && isFieldTouched;
        }

        return shouldShowError ? (
          <StyledPopup
            className={className}
            content={error}
            flowing={true}
            hoverable={true}
            offset={[-20, 10]}
            open={shouldShowError}
            popper={<div style={{ filter: 'none' }} />}
            position={position}
            trigger={<div />}
          />
        ) : null;
      }}
    </Field>
  );
};
