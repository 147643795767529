// @flow

import { useParams } from 'react-router-dom';
import React, { useContext } from 'react';

import { CheckBoxField } from '../../formik-utils/FormFields';
import { toastError } from '../presentational/notifications/utils';
import { EstimateContext } from '../../contexts/EstimateContext';
import { useUpdateEstimateMutation } from '../../service-definitions/estimates';

export const StreamGovernanceEnabledContainer = ({ checked = null, addLabel = true }) => {
  const { estimateId } = useParams();
  const estimate = useContext(EstimateContext);
  const [updateEstimateDetails] = useUpdateEstimateMutation();

  const checkedProps = checked != null ? { checked } : {};

  return (
    <CheckBoxField
      addLabel={addLabel}
      fieldDisplayName="Enable"
      fieldName="enable_stream_governance"
      onChange={async (event, value, setFieldValue, setFieldTouched, values, checked) => {
        const payload = {
          stream_governance_enabled: checked,
          estimate_version: estimate?.inputs?.estimate_version,
        };
        const { error } = await updateEstimateDetails({
          estimateId,
          payload,
        });
        if (error) {
          toastError(error);
        }
      }}
      {...checkedProps}
    />
  );
};
