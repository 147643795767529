// @flow
import { Table } from 'semantic-ui-react';
import React from 'react';

import { StyledTable } from '../../common-utils/styledComponents';

const StaticTableHeaderRow = ({ cells }) => (
  <Table.Row>
    {cells.map((cell, index) => (
      <Table.HeaderCell key={index}> {cell} </Table.HeaderCell>
    ))}
  </Table.Row>
);

const StaticTableDataRow = ({ cells }) => (
  <Table.Row>
    {cells.map((cell, index) => (
      <Table.Cell key={index}> {cell} </Table.Cell>
    ))}
  </Table.Row>
);

export const StaticTable = ({ tableContents }) => {
  const headerRow = tableContents.headerRow;
  return (
    <StyledTable
      celled={true}
      columns={headerRow.length}
      compact={true}
      padded="very"
      textAlign="left"
      verticalAlign="middle"
    >
      <Table.Header>
        <StaticTableHeaderRow cells={headerRow} />
      </Table.Header>
      <Table.Body>
        {tableContents.dataRows.map((dataRow, index) => (
          <StaticTableDataRow cells={dataRow} key={index} />
        ))}
      </Table.Body>
    </StyledTable>
  );
};
