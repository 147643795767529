// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import {
  AWS,
  AZURE,
  CLUSTER_PROVIDER_BACKEND_NAME,
  CLUSTER_REGION_BACKEND_NAME,
  GCP,
} from '../../../constants';
import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';
import { getDropdownOptions } from '../../stream-governance/utils';
import { SelectField } from '../../../formik-utils/FormFields';
import { ClusterContext } from '../../../contexts/ClusterContext';

import { disableFollowerFetchOption, useClusterInputsDependencies } from './utils';

export const ClusterProviderContainer = ({ disabled }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const clusterProviderConfig = CLUSTER_DETAILS_CONFIG_MAP.get(CLUSTER_PROVIDER_BACKEND_NAME);

  const cluster = useContext(ClusterContext);
  const dataUniverse = cluster?.cluster_configs?.cluster_params;

  useClusterInputsDependencies(
    clusterProviderConfig,
    dataUniverse,
    values,
    setFieldValue,
    setFieldTouched
  );

  const clusterProviderOptions = getDropdownOptions(
    dataUniverse,
    values,
    clusterProviderConfig,
    false,
    true,
    false,
    false,
    [AWS, AZURE, GCP]
  );

  return (
    <SelectField
      disabled={disabled}
      fieldDisplayName={clusterProviderConfig.displayName}
      fieldName={clusterProviderConfig.backendFieldName}
      onChange={() => {
        disableFollowerFetchOption(setFieldValue);
        // Clear out the region and force the user to select again
        setFieldTouched(CLUSTER_REGION_BACKEND_NAME, true);
        setFieldValue(CLUSTER_REGION_BACKEND_NAME, null);
      }}
      options={clusterProviderOptions}
      placeholder={null}
    />
  );
};
