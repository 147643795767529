// @flow

import React from 'react';

import { StyledButton } from '../../../common-utils/styledComponents';

export const ModalOKButton = ({ disabled = false, onClick, content = 'Ok', okButtonNegative }) => (
  <StyledButton
    content={content}
    data-testid="modal-ok-button"
    disabled={disabled}
    negative={okButtonNegative}
    onClick={onClick}
    primary={!okButtonNegative}
    size="tiny"
  />
);
