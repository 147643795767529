// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import { StyledPageNotFoundContainer, StyledSpan } from '../common-utils/styledComponents';

import { StyledH1 } from './presentational/headings/StyledH1';
import { getLinkForHomePage } from './links/utils';
import { StyledH4 } from './presentational/headings/StyledH4';
import { Spacer } from './presentational/spacing/Spacer';

export const NotFoundPage = () => (
  <StyledPageNotFoundContainer centered={true}>
    <StyledH1 text="404" />
    <StyledH4 text="Page Not Found!" />
    <Spacer y={20} />
    <StyledSpan>We are sorry, but the page you requested was not found.</StyledSpan>
    <Link to={getLinkForHomePage()}>Go To Home Page</Link>
  </StyledPageNotFoundContainer>
);
