// @flow
import React from 'react';

import { TCO_CLUSTER_TIERED_STORAGE_BACKEND_FIELD_NAME } from '../../../constants';
import { CheckBoxField } from '../../../formik-utils/FormFields';
import { TCO_CLUSTER_LEVEL_INPUTS_CONFIG_MAP } from '../../../configuration/tco-cluster-inputs';

export const TieredStorageTCOInputContainer = () => {
  const { backendFieldName, displayName, pathToFollowInValues, tooltip } =
    TCO_CLUSTER_LEVEL_INPUTS_CONFIG_MAP.get(TCO_CLUSTER_TIERED_STORAGE_BACKEND_FIELD_NAME);

  return (
    <CheckBoxField
      displayCheckBoxBelowLabel={true}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      pathToFollowInValues={pathToFollowInValues}
      tooltip={tooltip}
    />
  );
};
