// @flow
import React, { useContext } from 'react';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridTextField,
} from '../../../formik-utils/FieldArrayGrid';
import {
  STREAM_GOVERNANCE_ESSENTIALS_PACKAGE_NAME,
  STREAM_GOVERNANCE_NUMBER_OF_RULES_BACKEND_NAME,
  STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME,
} from '../../../constants';
import { STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG_MAP } from '../../../configuration/stream-governance';
import { StyledSpan } from '../../../common-utils/styledComponents';

export const RulesCountContainer = () => {
  const { rowValues } = useContext(FieldArrayGridConfigItemContext);

  const selectedPackageName = rowValues[STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME];

  const rulesCountConfig = STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG_MAP.get(
    STREAM_GOVERNANCE_NUMBER_OF_RULES_BACKEND_NAME
  );

  const isEssentialsPackageSelected =
    selectedPackageName &&
    selectedPackageName.toLowerCase() === STREAM_GOVERNANCE_ESSENTIALS_PACKAGE_NAME.toLowerCase();

  if (isEssentialsPackageSelected) {
    return <StyledSpan className="ui tiny header">N/A</StyledSpan>;
  }

  return <FieldArrayGridTextField columnConfig={rulesCountConfig} />;
};
