// @flow
import React, { useContext, useState } from 'react';
import { useFormikContext } from 'formik';

import { ESTIMATE_COMMIT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-commit-details';
import {
  DEAL_TYPE_BACKEND_NAME,
  DEAL_TYPE_RENEWAL,
  RATE_CARD_BACKEND_NAME,
  RATE_CARD_VERSION_4_11_2024,
} from '../../constants';
import { SelectField } from '../../formik-utils/FormFields';
import { EstimateContext } from '../../contexts/EstimateContext';

import EstimateRateCardChangeWarningModal from './EstimateRateCardChangeWarningModal';

export const RateCardsContainer = () => {
  const { values, initialValues } = useFormikContext();
  const estimateData = useContext(EstimateContext);
  const [isRCChangeWarningModalOpen, setRCChangeWarningModalOpen] = useState(false);
  const isRenewal = values[DEAL_TYPE_BACKEND_NAME] === DEAL_TYPE_RENEWAL;

  const rcVersions = estimateData.commit_config.rate_cards;
  const rcVersionsOptions = [];
  for (const rc of rcVersions) {
    if (isRenewal && rc.name === RATE_CARD_VERSION_4_11_2024) {
      continue;
    }

    const actionable = rc?.actionable ?? true;
    rcVersionsOptions.push({
      name: rc.name,
      display_name: rc.label,
      shouldNotBeSelectable: !actionable,
    });
  }

  const { backendFieldName, displayName, tooltip } =
    ESTIMATE_COMMIT_DETAILS_CONFIG_MAP.get(RATE_CARD_BACKEND_NAME);

  return (
    <>
      <EstimateRateCardChangeWarningModal
        isOpen={isRCChangeWarningModalOpen}
        setOpen={setRCChangeWarningModalOpen}
      />
      <SelectField
        fieldDisplayName={displayName}
        fieldName={backendFieldName}
        onChange={(event, value) => {
          if (value !== initialValues[RATE_CARD_BACKEND_NAME]) {
            setRCChangeWarningModalOpen(true);
          }
        }}
        options={rcVersionsOptions}
        tooltip={tooltip}
      />
    </>
  );
};
