// @flow
import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayStyledGridRow,
} from '../../formik-utils/FieldArrayGrid';
import { FieldArrayErrorMessagesContainer } from '../../formik-utils/FieldArrayErrorMessagesContainer';
import { CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG } from '../../configuration/credits-and-bursts';
import { IS_ROW_OPENED_FOR_EDITING } from '../../constants';

import { YearContainer } from './YearContainer';
import { CreditsAmountContainer } from './CreditsAmountContainer';
import { BurstsAmountContainer } from './BurstsAmountContainer';
import { UpdateButtonContainer } from './UpdateButtonContainer';
import { DeleteButtonContainer } from './DeleteButtonContainer';

export const CreditAndBurstsConfigurationItem = ({ readOnlyMode }) => {
  const { rowValues } = useContext(FieldArrayGridConfigItemContext);

  const shouldShowErrorMessagesAtTheBottom = !rowValues[IS_ROW_OPENED_FOR_EDITING] || readOnlyMode;

  return (
    <>
      <FieldArrayStyledGridRow>
        <Grid.Column width={2}>
          <YearContainer />
        </Grid.Column>
        <Grid.Column width={2}>
          <CreditsAmountContainer />
        </Grid.Column>
        <Grid.Column width={2}>
          <BurstsAmountContainer />
        </Grid.Column>
        <Grid.Column width={1}>
          <UpdateButtonContainer />
        </Grid.Column>
        <Grid.Column width={1}>
          <DeleteButtonContainer />
        </Grid.Column>
      </FieldArrayStyledGridRow>

      {shouldShowErrorMessagesAtTheBottom && (
        <FieldArrayErrorMessagesContainer
          arrayColumnConfig={CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG}
        />
      )}
    </>
  );
};
