// @flow
import { Grid } from 'semantic-ui-react';
import React from 'react';

import { ClusterAvailabilityConfigurationContainer } from './ClusterAvailabilityConfigurationContainer';
import { ClusterSlaContainer } from './ClusterSLAContainer';

export const ClusterAvailabilityAndSLAContainer = ({ disabled }) => {
  return (
    <>
      <Grid.Column width={3}>
        <ClusterAvailabilityConfigurationContainer disabled={disabled} />
      </Grid.Column>
      <Grid.Column width={2}>
        <ClusterSlaContainer disabled={disabled} />
      </Grid.Column>
    </>
  );
};
