// @flow
import React from 'react';

import { CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG_MAP } from '../../configuration/credits-and-bursts';
import { CREDITS_AND_BURSTS_BURSTS_AMOUNT_BACKEND_NAME } from '../../constants';
import { FieldArrayGridTextField } from '../../formik-utils/FieldArrayGrid';

export const BurstsAmountContainer = () => {
  const cnbcBurstsAmountConfig = CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG_MAP.get(
    CREDITS_AND_BURSTS_BURSTS_AMOUNT_BACKEND_NAME
  );

  return <FieldArrayGridTextField columnConfig={cnbcBurstsAmountConfig} />;
};
