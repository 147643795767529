// @flow
import React, { useContext } from 'react';
import { startCase } from 'lodash';

import { DataGrid } from '../grid-utils/DataGrid';
import { ChildRenderer, CurrencyZeroDPRenderer } from '../grid-utils/renderers';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { ESTIMATE_COMMIT_SUMMARY, HEADER_SUFFIX_FOR_DOLLAR_COLUMNS } from '../../../constants';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { DEFAULT_COLUMN_DEFINITION_FOR_SIMPLIFIED_GRID } from '../grid-utils/constants';
import { Spacer } from '../../presentational/spacing/Spacer';
import { StyledDiv } from '../../../common-utils/styledComponents';
import { TCVApprovalRequiredContainer } from '../../../triggers/TCVApprovalRequiredContainer';

export const CommitSummaryContainer = ({ disabled }) => {
  const estimateData = useContext(EstimateContext);
  const inputDataSource = estimateData.commit_summary;

  const inputDataSourceInFrontEndFormat = {};

  for (const [key, value] of Object.entries(inputDataSource)) {
    inputDataSourceInFrontEndFormat[startCase(key.split('_').join(' '))] = value;
  }

  const columnDefs = [
    {
      field: 'Field',
      backEndFieldName: 'field',
      minWidth: 500,
      cellRenderer: ChildRenderer,
      dependentFields: ['is_child'],
    },
    {
      field: 'Value',
      backEndFieldName: 'value',
      minWidth: 150,
      cellRenderer: CurrencyZeroDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
  ];

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <StyledDiv width="730px">
        <TCVApprovalRequiredContainer />
        <Spacer y={1} />
        <DataGrid
          bannerFieldNameInColDefsForFullWidthRows="Field"
          columnDefs={columnDefs}
          defaultColDef={DEFAULT_COLUMN_DEFINITION_FOR_SIMPLIFIED_GRID}
          inputDataSource={inputDataSourceInFrontEndFormat}
          label={ESTIMATE_COMMIT_SUMMARY}
          noHeader={true}
          width="730px"
        />
      </StyledDiv>
    </PaddedAndRaisedSegment>
  );
};
