// @flow
import React, { useEffect } from 'react';
import flatten from 'flat';
import { useFormikContext } from 'formik';

export const ValidateFormOnMount = () => {
  const { validateForm, setFieldTouched } = useFormikContext();

  useEffect(() => {
    validateForm().then((errors) => {
      for (const path of Object.keys(flatten(errors))) {
        setFieldTouched(path, true, false);
      }
    });
  }, [setFieldTouched, validateForm]);

  return <></>;
};
