// @flow
import { Icon } from 'semantic-ui-react';
import React from 'react';

export const SunIcon = ({ onClick }) => (
  <Icon
    color="white"
    data-testid="sun-icon"
    inverted={true}
    name="sun"
    onClick={onClick}
    size="large"
    style={{ cursor: 'pointer' }}
  />
);
