// @flow
import React, { useContext } from 'react';

import { DataGrid } from '../grid-utils/DataGrid';
import { ESTIMATE_FACT_TABLE_SUMMARY } from '../../../constants';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { getIfUserIsAdminAndCustomerViewIsDisabled } from '../../../common-utils/utils';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { UserContext } from '../../../contexts/UserContext';
import { Number2DPRenderer, Number4DPRenderer } from '../grid-utils/renderers';

export const CustomViewsSummaryContainer = ({ data, disabled }) => {
  const estimate = useContext(EstimateContext);
  const user = useContext(UserContext);

  const inputDataSource = data.custom_views;

  const columnDefs = [
    {
      field: 'Account Name',
      backEndFieldName: 'account_name',
    },
    {
      field: 'Account ID',
      backEndFieldName: 'account_id',
    },
    {
      field: 'Org Name',
      backEndFieldName: 'org_name',
    },
    {
      field: 'Org ID',
      backEndFieldName: 'org_id',
    },
    {
      field: 'Opportunity ID',
      backEndFieldName: 'opportunity_id',
    },
    {
      field: 'Cluster',
      backEndFieldName: 'cluster',
    },
    {
      field: 'Cluster Type',
      backEndFieldName: 'cluster_type',
    },
    {
      field: 'Availability Requirement',
      backEndFieldName: 'availability_requirement',
    },
    {
      field: 'Provider',
      backEndFieldName: 'provider',
    },
    {
      field: 'Region',
      backEndFieldName: 'region',
    },
    {
      field: 'Networking Type',
      backEndFieldName: 'networking_type',
    },
    {
      field: 'Product',
      backEndFieldName: 'product',
    },
    {
      field: 'Product Sub Category',
      backEndFieldName: 'product_sub_category',
    },
    {
      field: 'Year',
      backEndFieldName: 'year',
    },
    {
      field: 'Month',
      backEndFieldName: 'month',
    },
    {
      field: 'Absolute Month Index',
      backEndFieldName: 'absolute_month_index',
      sort: 'asc',
    },
    {
      field: 'Metric',
      backEndFieldName: 'metric',
    },
    {
      field: 'Unit Price(list)',
      backEndFieldName: 'unit_price_list',
      filter: false,
      cellRenderer: Number4DPRenderer,
    },
    {
      field: 'Unit Price(discounted)',
      backEndFieldName: 'unit_price_discounted',
      filter: false,
      cellRenderer: Number4DPRenderer,
    },
    {
      field: 'Total Commit (List)',
      backEndFieldName: 'total_commit_list',
      filter: false,
      cellRenderer: Number4DPRenderer,
    },
    {
      field: 'Total Commit (Discounted)',
      backEndFieldName: 'total_commit_discounted',
      filter: false,
      cellRenderer: Number4DPRenderer,
    },
    {
      field: 'Total Invoiced',
      backEndFieldName: 'total_invoiced',
      filter: false,
      cellRenderer: Number4DPRenderer,
    },
  ];

  if (getIfUserIsAdminAndCustomerViewIsDisabled(user, estimate)) {
    columnDefs.push({
      field: 'Unit Cost',
      backEndFieldName: 'unit_cost',
      filter: false,
      cellRenderer: Number4DPRenderer,
    });
    columnDefs.push({
      field: 'Cost',
      backEndFieldName: 'cost',
      filter: false,
      cellRenderer: Number4DPRenderer,
    });
    columnDefs.push({
      field: 'Quantity',
      backEndFieldName: 'quantity',
      filter: false,
      cellRenderer: Number2DPRenderer,
    });
  }

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <DataGrid
        columnDefs={columnDefs}
        height="400px"
        inputDataSource={inputDataSource}
        label={ESTIMATE_FACT_TABLE_SUMMARY}
        width="95%"
      />
    </PaddedAndRaisedSegment>
  );
};
