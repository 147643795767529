// @flow

import React, { useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { StyledH3 } from '../../presentational/headings/StyledH3';
import { StyledLabel } from '../../../common-utils/styledComponents';
import { Spacer } from '../../presentational/spacing/Spacer';
import { PrimaryButton } from '../../presentational/buttons/PrimaryButton';

import { GridDownloadButton } from './GridDownloadButton';
import {
  constructTableDataFromRows,
  fullWidthCellRendererDefault,
  getOnFirstDataRenderedFunc,
  isFullWidthRowFuncDefault,
  showOrHideAllOtherColGroups,
} from './utils';
import { DEFAULT_DEFAULT_COLUMN_DEFINITION } from './constants';

export const DataGrid = ({
  columnDefs,
  defaultColDef = DEFAULT_DEFAULT_COLUMN_DEFINITION,
  isFullWidthRow = isFullWidthRowFuncDefault,
  fullWidthCellRenderer = fullWidthCellRendererDefault,
  noHeader = false,
  width = null,
  height = null,
  label = null,
  inputDataSource = null,
  sizeColumnsToFitInitially = false,
  noDataMessage = 'No data yet!',
  pinnedRowsSelectorFunc = null,
  addDataAsChildrenInCaseOfMapData = false,
  bannerFieldNameInColDefsForFullWidthRows = 'field',
  tableData = constructTableDataFromRows(
    inputDataSource,
    columnDefs,
    addDataAsChildrenInCaseOfMapData,
    bannerFieldNameInColDefsForFullWidthRows
  ),
  downloadAllCols = true,
  displayResizingColsOption = false,
  ...rest
}) => {
  const theme = useTheme();
  const gridRef = useRef();
  const [colsAreSizedToFitScreen, setColsAreSizedToFitScreen] = useState(false);

  const onFirstDataRendered = getOnFirstDataRenderedFunc(
    sizeColumnsToFitInitially,
    gridRef,
    height
  );

  const isDataEmpty = tableData.length === 0;

  let pinnedTableData = null;
  let unPinnedTableData = null;

  if (pinnedRowsSelectorFunc) {
    pinnedTableData = tableData.filter((row) => pinnedRowsSelectorFunc(row));
    unPinnedTableData = tableData.filter((row) => !pinnedRowsSelectorFunc(row));
  }

  // todo:: Wrap the pin rows styling into a function of its own and then invoke it here
  const rowStyleFunc = (params) => {
    if (params.node.rowPinned === 'bottom') {
      return {
        backgroundColor: '#805b0014',
      };
    }

    if (params?.data?.isAHeaderLevelRow === true) {
      return {
        backgroundColor: '#00000014',
        fontWeight: 'bold',
      };
    }
  };

  return (
    <div
      className={theme?.grid?.className ?? 'ag-theme-alpine'}
      onClick={(e) => e.stopPropagation()}
      style={{
        marginTop: '1rem',
        width: width || null,
        height: height || null,
        paddingBottom: height ? '70px' : null,
        // When the grid height is set, add a padding so that the grid doesnot take the entire height
      }}
    >
      {isDataEmpty ? (
        <StyledH3 text={noDataMessage} />
      ) : (
        <>
          <StyledLabel>
            <>
              {label}
              <Spacer x={5} />
              <GridDownloadButton
                downloadAllCols={downloadAllCols}
                fileName={`${label.split(' ').join('_')}_export`}
                gridRef={gridRef}
              />
            </>
          </StyledLabel>

          {displayResizingColsOption && (
            <PrimaryButton
              floated="right"
              onClick={() => {
                if (colsAreSizedToFitScreen === false) {
                  gridRef.current.api.sizeColumnsToFit();
                  setColsAreSizedToFitScreen(true);
                } else {
                  gridRef.current.columnApi.autoSizeAllColumns();
                  setColsAreSizedToFitScreen(false);
                }
              }}
              size="mini"
              text={colsAreSizedToFitScreen ? 'Auto-Size All Columns' : 'Size Columns To Fit'}
            />
          )}
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            fullWidthCellRenderer={fullWidthCellRenderer}
            getRowStyle={rowStyleFunc}
            headerHeight={noHeader ? 0 : null}
            isFullWidthRow={isFullWidthRow}
            onColumnGroupOpened={(colGroupOpenedEvent) => {
              const isExpanded = colGroupOpenedEvent.columnGroup.expanded;
              showOrHideAllOtherColGroups(colGroupOpenedEvent, !isExpanded);
              gridRef.current.api.sizeColumnsToFit();
            }}
            onFirstDataRendered={onFirstDataRendered}
            pinnedBottomRowData={pinnedRowsSelectorFunc ? pinnedTableData : null}
            ref={gridRef}
            rowData={pinnedRowsSelectorFunc ? unPinnedTableData : tableData}
            unSortIcon={true}
            {...rest}
          />
        </>
      )}
    </div>
  );
};
