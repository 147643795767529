// @flow
import React from 'react';

export const CustomizedXAxisTick = ({ payload, x, y }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={20} fill="#999" textAnchor="end" transform="rotate(-30)" x={0} y={0}>
        {payload.value}
      </text>
    </g>
  );
};
