// @flow
import React, { useContext } from 'react';

import { DataGrid } from '../grid-utils/DataGrid';
import { CurrencyZeroDPRenderer, PercentageRenderer } from '../grid-utils/renderers';
import {
  ESTIMATE_SFDC_FIELDS_SUMMARY,
  GLOBAL_DISCOUNT,
  GLOBAL_DISCOUNT_APPLIED_DISPLAY_NAME,
  TOTAL_COMMITMENT,
} from '../../../constants';
import { getIfCustomerViewIsEnabled } from '../../../common-utils/utils';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { DEFAULT_COLUMN_DEFINITION_FOR_SIMPLIFIED_GRID } from '../grid-utils/constants';
import { EstimateContext } from '../../../contexts/EstimateContext';

const SFDCFieldsRenderer = (props) => {
  if (props.data.Field === TOTAL_COMMITMENT) {
    return CurrencyZeroDPRenderer(props);
  }

  if (props.data.Field === GLOBAL_DISCOUNT) {
    return PercentageRenderer(props);
  }

  return props.value;
};

export const SFDCFieldsContainer = ({ disabled }) => {
  const estimateData = useContext(EstimateContext);
  let inputDataSource = estimateData.sfdc_fields;

  // todo:: Should this logic reside on BE?
  if (!getIfCustomerViewIsEnabled(estimateData?.inputs)) {
    inputDataSource = inputDataSource.filter(
      (x) => x.field !== GLOBAL_DISCOUNT_APPLIED_DISPLAY_NAME
    );
  }

  const columnDefs = [
    {
      field: 'Field',
      backEndFieldName: 'field',
      minWidth: 400,
    },
    {
      field: 'Value',
      backEndFieldName: 'value',
      minWidth: 100,
      cellRenderer: SFDCFieldsRenderer,
    },
  ];

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <DataGrid
        columnDefs={columnDefs}
        defaultColDef={DEFAULT_COLUMN_DEFINITION_FOR_SIMPLIFIED_GRID}
        inputDataSource={inputDataSource}
        label={ESTIMATE_SFDC_FIELDS_SUMMARY}
        noHeader={true}
        width="665px"
      />
    </PaddedAndRaisedSegment>
  );
};
