// @flow
import React, { useContext, useState } from 'react';
import { Formik } from 'formik';

import { StyledContainer } from '../../common-utils/styledComponents';
import { ESTIMATE_TOP_LEVEL_CONFIG } from '../../configuration/estimate-top-level-config';
import { Form } from '../../formik-utils/formikSUIWrapper';
import { getFormikFormInputsFromColConfigAndInputSource } from '../../common-utils/utils';
import { EstimateContext } from '../../contexts/EstimateContext';
import {
  CREDITS_AND_BURSTS_JSON_BACKEND_NAME,
  CUSTOM_DISCOUNTS_JSON_BACKEND_NAME,
  ESTIMATE_CUSTOMER_VIEW_ENABLED_BACKEND_NAME,
  ESTIMATE_CUSTOMER_VIEW_ENABLED_DISPLAY_NAME,
  TCO_INPUTS_JSON_BACKEND_NAME,
} from '../../constants';
import { DeleteButton } from '../presentational/buttons/DeleteButton';
import { EstimateDeleteWarningModal } from '../home-page/hierarchy-view/estimates-grid/EstimateDeleteWarningModal';
import { CopyButton } from '../presentational/buttons/CopyButton';
import { toastError, toastSuccess } from '../presentational/notifications/utils';
import {
  useCopyEstimateMutation,
  useUpdateEstimateMutation,
} from '../../service-definitions/estimates';
import { getLinkForEstimate } from '../links/utils';
import { Spacer } from '../presentational/spacing/Spacer';
import { CheckBoxField } from '../../formik-utils/FormFields';
import { ShareButton } from '../presentational/buttons/ShareButton';
import { EstimateShareModal } from '../estimate-sharing/EstimateShareModal';

import EstimateAccordionsContainer from './EstimateAccordionsContainer';

const EstimateTopLevelContainerForm = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);

  const estimateData = useContext(EstimateContext);
  const [copyEstimate] = useCopyEstimateMutation();
  const [updateEstimateDetails] = useUpdateEstimateMutation();

  const estimateInputsSource = {
    ...estimateData.inputs,
    [CREDITS_AND_BURSTS_JSON_BACKEND_NAME]: {
      cnbc_configs: estimateData?.cnbc_inputs ?? [],
    },
    [CUSTOM_DISCOUNTS_JSON_BACKEND_NAME]: estimateData?.metrics_discount ?? {},
    [TCO_INPUTS_JSON_BACKEND_NAME]: estimateData?.inputs?.[TCO_INPUTS_JSON_BACKEND_NAME] ?? {},
  };

  const { initialValues, initialTouched, validationSchema } =
    getFormikFormInputsFromColConfigAndInputSource(
      ESTIMATE_TOP_LEVEL_CONFIG,
      estimateInputsSource,
      estimateData
    );

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {() => (
          <Form autoComplete="off">
            <DeleteButton
              onClick={() => {
                setDeleteModalOpen(true);
              }}
              size="large"
            />
            <CopyButton
              onClick={async () => {
                const { error, data } = await copyEstimate({
                  estimateId: estimateInputsSource.id,
                });
                if (error) {
                  toastError(error);
                } else {
                  toastSuccess(
                    <>
                      Estimate copied successfully. You can open it by clicking <Spacer x={5} />
                      <a href={getLinkForEstimate(data?.id)}>
                        <strong>here!!!</strong>
                      </a>
                    </>,
                    false
                  );
                }
              }}
            />
            <ShareButton
              onClick={() => {
                setShareModalOpen(true);
              }}
              size="large"
            />
            <CheckBoxField
              fieldDisplayName={ESTIMATE_CUSTOMER_VIEW_ENABLED_DISPLAY_NAME}
              fieldName={ESTIMATE_CUSTOMER_VIEW_ENABLED_BACKEND_NAME}
              onChange={async (event, value, setFieldValue, setFieldTouched, values, checked) => {
                const payload = {
                  [ESTIMATE_CUSTOMER_VIEW_ENABLED_BACKEND_NAME]: checked,
                  estimate_version: estimateInputsSource?.estimate_version,
                };

                const { error } = await updateEstimateDetails({
                  estimateId: estimateInputsSource?.id,
                  payload,
                });

                if (error) {
                  toastError(error);
                }
              }}
            />
            <EstimateDeleteWarningModal
              estimateId={estimateInputsSource.id}
              estimateName={estimateInputsSource.name}
              estimateVersion={estimateInputsSource.estimate_version}
              isOpen={isDeleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
            />
            <EstimateShareModal
              estimateId={estimateInputsSource.id}
              estimateName={estimateInputsSource.name}
              estimateVersion={estimateInputsSource.estimate_version}
              isOpen={isShareModalOpen}
              setOpen={setShareModalOpen}
            />
            <Spacer y={10} />
            <EstimateAccordionsContainer />
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};

export default EstimateTopLevelContainerForm;
