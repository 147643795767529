// @flow
import React from 'react';

import { ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-account-details';
import { SFDC_OPPORTUNITY_NAME_BACKEND_NAME } from '../../constants';
import { TextField } from '../../formik-utils/FormFields';

export const EstimateOpportunityNameContainer = ({ disableOnFormErrors }) => {
  const estimateOpportunityNameConfig = ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP.get(
    SFDC_OPPORTUNITY_NAME_BACKEND_NAME
  );
  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      fieldDisplayName={estimateOpportunityNameConfig.displayName}
      fieldName={estimateOpportunityNameConfig.backendFieldName}
      icon={estimateOpportunityNameConfig.icon}
    />
  );
};
