// @flow
import React from 'react';

export const CLUSTER_MAPPING_TABLE_FROM_OLD_TO_NEW = {
  headerRow: ['FROM: 8/1, 10/1, 3/5 (Base + Partition)', 'TO: 4/11 (eCKU based rate card)', 'Note'],
  dataRows: [
    [
      <>Basic, SZ, 99.5%</>,
      <>
        Basic, MZ, 99.5%
        <br />
        Min eCKU - 1
      </>,
      <>SZ → MZ</>,
    ],
    [
      <>
        Standard, SZ, 99.95%
        <br />
        Standard, MZ, 99.99%
      </>,
      <>
        Standard, MZ, 99.99%
        <br /> <strong>Min eCKUs - 2</strong>
      </>,
      <>
        SZ → MZ
        <br />
        Existing Standard SZ clusters will map to MZ, 99.99%
        <br />
        <strong>This means Standard pricing will remain $1.5/hr</strong>
      </>,
    ],
  ],
};

export const CLUSTER_MAPPING_TABLE_FROM_NEW_TO_OLD = {
  headerRow: ['FROM: 4/11 (eCKU based rate card)', 'TO: 8/1, 10/1, 3/5 (Base + Partition)', 'Note'],
  dataRows: [
    [
      <>
        Basic, MZ, 99.5%
        <br />
        Min eCKU - 1
      </>,
      <>Basic, SZ, 99.5%</>,
      <>MZ → SZ</>,
    ],
    [
      <>
        Standard, MZ, 99.9%
        <br />
        Standard, MZ, 99.99%
      </>,
      <>Standard, MZ, 99.99%</>,
      <>
        MZ is maintained
        <br />
        <strong>This means Standard 1 eCKU pricing will increase from $0.75/hr to $1.5/hr.</strong>
      </>,
    ],
  ],
};
