// @flow
import React from 'react';

import { Spacer } from '../spacing/Spacer';
import { StyledMessage } from '../../../common-utils/styledComponents';

export const NegativeMessageContainer = ({ header, body }) => {
  const shouldShowMessage = header && typeof header === 'string' && header.length > 0;
  return (
    shouldShowMessage && (
      <StyledMessage negative={true}>
        <StyledMessage.Header>{header}</StyledMessage.Header>
        {body && (
          <>
            <Spacer y={15} />
            {body}
          </>
        )}
      </StyledMessage>
    )
  );
};
