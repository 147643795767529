// @flow
import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';

import { FieldArrayStyledGridRow } from '../../../formik-utils/FieldArrayGrid';
import { StreamGovernanceContext } from '../../../contexts/StreamGovernanceContext';

import { EnvironmentNameContainer } from './EnvironmentNameContainer';
import { PackageNameContainer } from './PackageNameContainer';
import { ProviderNameContainer } from './ProviderNameContainer';
import { RegionNameContainer } from './RegionNameContainer';
import { SchemasCountContainer } from './SchemasCountContainer';
import { UpdateButtonContainer } from './UpdateButtonContainer';
import { DeleteButtonContainer } from './DeleteButtonContainer';
import { RulesCountContainer } from './RulesCountContainer';

export const StreamGovernanceConfigurationItem = () => {
  const streamGovernanceData = useContext(StreamGovernanceContext);
  const dataUniverse = streamGovernanceData?.sg_packages;

  return (
    <>
      <FieldArrayStyledGridRow>
        <Grid.Column width={2}>
          <EnvironmentNameContainer />
        </Grid.Column>
        <Grid.Column width={2}>
          <PackageNameContainer dataUniverse={dataUniverse} />
        </Grid.Column>
        <Grid.Column width={2}>
          <ProviderNameContainer dataUniverse={dataUniverse} />
        </Grid.Column>
        <Grid.Column width={3}>
          <RegionNameContainer dataUniverse={dataUniverse} />
        </Grid.Column>
        <Grid.Column verticalAlign="middle" width={2}>
          <SchemasCountContainer />
        </Grid.Column>
        <Grid.Column verticalAlign="middle" width={2}>
          <RulesCountContainer />
        </Grid.Column>
        <Grid.Column width={1}>
          <UpdateButtonContainer />
        </Grid.Column>
        <Grid.Column width={1}>
          <DeleteButtonContainer />
        </Grid.Column>
      </FieldArrayStyledGridRow>

      {/* <FieldArrayErrorMessagesContainer*/}
      {/*  arrayColumnConfig={STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG}*/}
      {/*  showOnlyConfigLevelErrors={true}*/}
      {/* />*/}
    </>
  );
};
