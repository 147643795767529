// @flow
import React, { useContext, useState } from 'react';
import { useFormikContext } from 'formik';
import { Grid } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';

import {
  CLUSTER_DETAILS_FORM,
  CLUSTER_RETENTION_BACKEND_NAME,
  CLUSTER_RETENTION_INFINITE_BACKEND_NAME,
  CLUSTER_TYPE_BACKEND_NAME,
  DEDICATED_CLUSTER_TYPE,
  DEDICATED_SURVEY_INPUTS_JSON_BACKEND_FIELD_NAME_FOR_UPDATE,
} from '../../../constants';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { ResetFormButton } from '../../presentational/buttons/ResetFormButton';
import { Spacer } from '../../presentational/spacing/Spacer';
import { SaveFormButton } from '../../presentational/buttons/SaveFormButton';
import { useUpdateClusterMutation } from '../../../service-definitions/estimates';
import { toastError } from '../../presentational/notifications/utils';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { RetentionApprovalRequiredContainer } from '../../../triggers/RetentionApprovalRequiredContainer';
import { UserContext } from '../../../contexts/UserContext';
import { getIfUserIsAdminBasedOnGivenDetails } from '../../../common-utils/utils';
import { CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP } from '../../../configuration/cluster-details';
import { isAnythingEditedOnClusterPageBesidesTheGivenForm } from '../../views/utils';

import { ClusterRetentionInfiniteContainer } from './ClusterRetentionInfiniteContainer';
import { ClusterRetentionContainer } from './ClusterRetentionContainer';
import { ClusterPartitionsContainer } from './ClusterPartitionsContainer';
import { ClusterNameContainer } from './ClusterNameContainer';
import { ClusterTypeContainer } from './ClusterTypeContainer';
import { ClusterProviderContainer } from './ClusterProviderContainer';
import { ClusterNetworkingTypeContainer } from './ClusterNetworkingTypeContainer';
import { ClusterFollowerFetchContainer } from './ClusterFollowerFetchContainer';
import { fetchFormValues, isFormEdited, resetValues, shouldShowFFOption } from './utils';
import { ClusterRegionsContainer } from './ClusterRegionsContainer';
import { ClusterExistingStorageContainer } from './ClusterExistingStorageContainer';
import { ClusterDedicatedSurveyInputsModal } from './ClusterDedicatedSurveyInputsModal';
import { ClusterAvailabilityAndSLAContainer } from './ClusterAvailabilityAndSLAContainer';

const submitForm = async (
  estimate,
  updateCluster,
  estimateId,
  clusterId,
  values,
  dedicatedSurveyInputs = null
) => {
  const formValuesToSend = fetchFormValues(
    values,
    CLUSTER_DETAILS_FORM,
    CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP
  );

  const payload = {
    id: clusterId,
    estimate_id: estimateId,
    estimate_version: estimate?.inputs?.estimate_version,
    name: formValuesToSend.name,
    inputs: {
      ...formValuesToSend,
      retention: {
        is_finite_retention: !formValuesToSend[CLUSTER_RETENTION_INFINITE_BACKEND_NAME],
        retention_days: formValuesToSend[CLUSTER_RETENTION_BACKEND_NAME],
      },
    },
  };

  if (values[CLUSTER_TYPE_BACKEND_NAME] === DEDICATED_CLUSTER_TYPE) {
    payload[DEDICATED_SURVEY_INPUTS_JSON_BACKEND_FIELD_NAME_FOR_UPDATE] = dedicatedSurveyInputs;
  }

  const { error } = await updateCluster({
    estimateId,
    clusterId,
    payload,
  });

  if (error) {
    toastError(error);
  }
};

export function ClusterDetailsContainer() {
  const [isDedicatedSurveyModalOpen, setDedicatedSurveyModalOpen] = useState(false);
  const { values, isValid, initialValues, resetForm, validateForm } = useFormikContext();
  const { estimateId, clusterId } = useParams();
  const [updateCluster] = useUpdateClusterMutation();
  const estimate = useContext(EstimateContext);
  const user = useContext(UserContext);
  const isUserAdmin = getIfUserIsAdminBasedOnGivenDetails(user);
  const disabled = isAnythingEditedOnClusterPageBesidesTheGivenForm(
    values,
    initialValues,
    CLUSTER_DETAILS_FORM
  );

  const isCurrentFormEdited = isFormEdited(
    values,
    initialValues,
    CLUSTER_DETAILS_FORM,
    CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP
  );

  const shouldSaveResetBeDisabled = !isValid || disabled || !isCurrentFormEdited;
  const shouldResetBeDisabled = disabled || !isCurrentFormEdited;

  const shouldShowFFOptionVariable = shouldShowFFOption(values, estimate, isUserAdmin);

  return (
    <>
      <PaddedAndRaisedSegment disabled={disabled}>
        <>
          <span style={{ float: 'left' }}>
            <ResetFormButton
              disabled={shouldResetBeDisabled}
              onClick={() => {
                resetValues(
                  values,
                  initialValues,
                  resetForm,
                  CLUSTER_DETAILS_FORM,
                  CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP
                );
              }}
            />
            <Spacer x={1} />

            <ClusterDedicatedSurveyInputsModal
              isOpen={isDedicatedSurveyModalOpen}
              onClickHandlerForCancel={() => {
                setDedicatedSurveyModalOpen(false);
              }}
              onClickHandlerForOK={async (inputsProvided) => {
                setDedicatedSurveyModalOpen(false);
                await submitForm(
                  estimate,
                  updateCluster,
                  estimateId,
                  clusterId,
                  values,
                  inputsProvided
                );
              }}
            />

            <SaveFormButton
              disabled={shouldSaveResetBeDisabled}
              onClick={async () => {
                const errors = await validateForm(values);
                if (Object.keys(errors).length > 0) {
                  return;
                }

                if (
                  values[CLUSTER_TYPE_BACKEND_NAME] === DEDICATED_CLUSTER_TYPE &&
                  values[CLUSTER_TYPE_BACKEND_NAME] !== initialValues[CLUSTER_TYPE_BACKEND_NAME]
                ) {
                  setDedicatedSurveyModalOpen(true);
                  return;
                }

                await submitForm(estimate, updateCluster, estimateId, clusterId, values);
              }}
            />
          </span>
          <Spacer y={60} />
          <Grid columns={2} divided={true}>
            <Grid.Row>
              <Grid.Column width={4}>
                <Grid columns={1} divided={true}>
                  <Grid.Row>
                    <Grid.Column>
                      <ClusterNameContainer disabled={disabled} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column width={12}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <ClusterTypeContainer disabled={disabled} />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <ClusterProviderContainer disabled={disabled} />
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <ClusterRegionsContainer disabled={disabled} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <ClusterAvailabilityAndSLAContainer disabled={disabled} />
                    <Grid.Column width={5}>
                      <ClusterNetworkingTypeContainer disabled={disabled} />
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <ClusterPartitionsContainer disabled={disabled} />
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <ClusterRetentionContainer disabled={disabled} />
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <ClusterRetentionInfiniteContainer disabled={disabled} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <ClusterExistingStorageContainer disabled={disabled} />
                    </Grid.Column>
                    <Grid.Column width={4}>
                      {shouldShowFFOptionVariable && (
                        <ClusterFollowerFetchContainer disabled={disabled} />
                      )}
                    </Grid.Column>
                    <Grid.Column width={1} />
                    <Grid.Column width={2} />
                    <Grid.Column width={4}>
                      <RetentionApprovalRequiredContainer />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      </PaddedAndRaisedSegment>
    </>
  );
}
