// @flow
import React, { useContext } from 'react';

import { EstimateContext } from '../../contexts/EstimateContext';

import { SecondaryTopBar } from './SecondaryTopBar';
import { getEstimateSections } from './utils';

export const EstimateBreadcrumbs = () => {
  const estimateData = useContext(EstimateContext);
  return <SecondaryTopBar sectionsToShow={getEstimateSections(estimateData)} />;
};
