// @flow
import styled from 'styled-components';
import {
  Header,
  Button,
  Accordion,
  Popup,
  Modal,
  Tab,
  Container,
  Message,
  Segment,
  Label,
  Menu,
  MenuItem,
  Dropdown,
  Input,
  Checkbox,
  List,
  Pagination,
  GridRow,
  Table,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import { FormikErrorMessage } from '../formik-utils/FormikErrorMessage';
import { EstimateHierarchy } from '../components/home-page/hierarchy-view/EstimateHierarchy';
import { Breadcrumbs } from '../components/breadcrumbs/Breadcrumbs';

export const StyledHeader = styled(Header)`
  color: ${({ theme }) => theme.header?.color};
`;

export const StyledButton = styled(Button)`
  &&&:disabled {
    background: gray;
  }
`;

export const StyledAccordion = styled(Accordion)`
  .dropdown.icon {
    cursor: pointer;
    color: ${({ theme }) => theme.accordion?.color};
  }

  .ui.basic.label {
    cursor: pointer;
  }

  &&&.ui.accordion.title {
    cursor: default;
  }

  &&&.ui.styled.accordion {
    background-color: ${({ theme }) => theme.accordion?.backgroundColor};
    border: ${({ theme }) => theme.accordion?.border};
  }

  &&&.ui.styled.accordion>.title: first-child {
    border-top: none;
  }

  &&&.ui.styled.accordion > .title {
    border-top-color: ${({ theme }) => theme.accordion?.borderColor};
  }
`;

export const StyledPopup = styled(Popup)`
  &&&.ui.popup {
    background-color: ${({ theme }) => theme.popup?.backgroundColor};
    border-color: ${({ theme }) => theme.popup?.borderColor};
    color: ${({ theme }) => theme.popup?.color};
  }
  &&&.ui.popup:before {
    background-color: ${({ theme }) => theme.popup?.backgroundColor};
    box-shadow: ${({ theme }) => theme.popup?.boxShadow};
  }
`;

export const StyledModal = styled(Modal)`
  &&&.ui.modal {
    border-radius: 0.4rem;
    border-color: ${({ theme }) => theme.modal?.backgroundColor};
  }

  .header,
  .content,
  .actions {
    background-color: ${({ theme }) => theme.modal?.backgroundColor};
    color: ${({ theme }) => theme.modal?.color};
    border-color: ${({ theme }) => theme.modal?.borderColor};
  }

  &&&.ui.modal,
  &&&.ui.active.modal &&&.ui.fullscreen.modal {
    margin-top: 5rem !important;
    top: auto !important;
    right: auto !important;
    transform-origin: center !important;
    transition: all ease 0.5s;
  }
`;

export const StyledTab = styled(Tab)`
  &&& div.ui.secondary.menu .item {
    color: ${({ theme }) => theme.tab?.color};
  }

  &&& div.ui.secondary.pointing.menu .active.item {
    border-bottom-color: ${({ theme }) => theme.tab?.borderColorActiveItem};
  }

  &&& div.ui.secondary.pointing.menu {
    border-bottom-color: ${({ theme }) => theme.tab?.borderColor};
  }
`;

export const StyledSpan = styled.span`
  color: ${({ theme }) => theme.span?.color};
`;

export const StyledContainer = styled(Container)`
  &&& {
    width: 98%;
    margin-top: 1em;
  }
`;

export const StyledPageNotFoundContainer = styled(Container)`
  &&& {
    width: 98%;
    margin-top: 1em;
    color: #173361;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
    height: 80vh;
    h1 {
      font-size: 100px;
      margin-bottom: 1px;
      display: block;
    }

    h4 {
      font-weight: 500;
      font-size: 28px;
      margin-bottom: 10px;
    }

    a {
      margin-top: 50px;
      font-size: 28px;
    }
  }
`;

export const StyledMessage = styled(Message)`
  &&&.ui.message {
    background-color: ${({ theme }) => theme.message?.backgroundColor};
    color: ${({ theme }) => theme.message?.color};
  }
`;

export const StyledSegment = styled(Segment)`
  &&&.segment {
    height: 100%;
    background-color: ${({ theme }) => theme.segment?.backgroundColor};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  }
`;

export const StyledSegmentWithBorderColor = styled(StyledSegment)`
  &&&.segment {
    border-color: ${({ theme }) => theme.segment?.borderColor};
  }
`;

export const StyledLabel = styled(Label)`
  &&&.ui.label {
    background-color: ${({ theme }) =>
      theme.label?.backgroundColorInput ? theme.label?.backgroundColorInput : '#FFFFFF'};
    color: ${({ theme }) => theme.label?.color};
    padding-left: 5px;
  }

  &&&.ui.basic.label {
    border: 0px solid rgba(34, 36, 38, 0.15);
    user-select: none;
    font-size: ${(props) => props.fontSize || '1.2em'};
    background-color: ${({ theme }) => theme.label?.backgroundColor};
    color: ${({ theme }) => theme.label?.color};
  }

  &&&.ui.attached.label {
    background-color: ${({ theme }) =>
      theme.label?.backgroundColorAttached ? theme.label?.backgroundColorAttached : '#EEEEEE'};
    color: ${({ theme }) => theme.label?.color};
    padding-left: 10px;
    font-size: 0.91em;
  }
`;

export const StyledYearSelectorMenu = styled(Menu)`
  &&& .active.item {
    color: ${({ theme }) => theme.tab?.color};
  }

  &&&.ui.menu {
    font-size: 0.8rem;
    border-width: ${({ theme }) => theme.yearSelector?.borderWidth};
  }

  &&&.ui.menu {
    border-radius: 0.4rem;
  }

  &&&.ui.menu.item {
    padding-left: 0.8em;
    padding-right: 0.8em;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
  }

  &&&.ui.menu div {
    background-color: ${({ theme }) =>
      theme.yearSelector?.backgroundColor
        ? theme.yearSelector?.backgroundColor
        : 'rgba(0, 0, 0, .05)'};
    color: ${({ theme }) => theme.yearSelector?.color};
  }

  &&&.ui.menu a {
    background-color: ${({ theme }) => theme.yearSelector?.navigationBackgroundColor};
    color: ${({ theme }) => theme.yearSelector?.color};
  }
`;

export const StyledLastUpdatedMessage = styled.div`
  color: #888;
`;

export const StyledMenuItem = styled(MenuItem)`
  &&& {
    color: ${(props) => props.color || 'white'};
  }
`;

export const StyledAppName = styled.p`
  font-size: 1.15em;
  padding-left: 8px;
  padding-top: 3px;
  cursor: pointer;
  color: #e6f5fb;
`;

export const StyledDropdown = styled(Dropdown)`
  &&&.ui.selection.dropdown {
    min-width: 4em;
    color: ${({ theme }) => theme.dropDown?.color};
    background-color: ${({ theme }) => theme.dropDown?.backgroundColor};
    border-color: ${({ theme }) => theme.dropDown?.borderColor};
  }

  &&&.ui.selection.dropdown .menu > .item {
    background-color: ${({ theme }) => theme.dropDown?.backgroundColor};
    color: ${({ theme }) => theme.dropDown?.color};
    border-top: 1px;
  }

  // highlight for selected item in the dropdown
  &&&.ui.dropdown .menu .selected.item {
    background-color: ${({ theme }) => theme.dropDown?.backgroundColorSelectedItem};
  }

  &&&.ui.dropdown .menu .item:hover {
    background-color: ${({ theme }) => theme.dropDown?.backgroundColorSelectedItem};
  }

  &&&.ui.selection.visible.dropdown > .text:not(.default) {
    color: ${({ theme }) => theme.dropDown?.color};
  }

  &&&.ui.selection.active.dropdown .menu {
    background-color: ${({ theme }) => theme.dropDown?.backgroundColor};
    border-color: ${({ theme }) => theme.dropDown?.borderColor};
  }

  &&&.dropdown.item .menu {
    background-color: ${({ theme }) => theme.dropDown?.backgroundColorSelectedItem};
  }
`;

export const StyledTopBarMenu = styled(Menu)`
  &&& {
    margin: 0;
    border-radius: 0;
    border: 0;
    background-color: #142b52;
  }
  &&&.ui.secondary.pointing.menu.item {
    color: ${({ theme }) => theme.tab?.color};
  }
`;

export const StyledInput = styled(Input)`
  &&& input[type='number'] {
    color: ${({ theme }) => theme.input?.color};
    background-color: ${({ theme }) => theme.input?.backgroundColor};
    border-color: ${({ theme }) => theme.input?.borderColor};
  }

  &&& input[type='text'] {
    color: ${({ theme }) => theme.input?.color};
    background-color: ${({ theme }) => theme.input?.backgroundColor};
    border-color: ${({ theme }) => theme.input?.borderColor};
  }

  &&& input::-webkit-outer-spin-button,
  &&& input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const StyledInputWithAccessibility = styled(StyledInput)`
  &&& :disabled,
  &&&.ui.disabled.input {
    opacity: 1;
    background-color: #f8f8f8;
  }
`;

export const StyledDropdownWithAccessibility = styled(StyledDropdown)`
  &&&.disabled {
    opacity: 1;
    background-color: #f8f8f8;
  }

  &&&.disabled i.dropdown.icon {
    visibility: hidden;
  }
`;

export const StyledFormikErrorMessage = styled(FormikErrorMessage)`
  &&&.ui.popup {
    padding: 12px;
    border-radius: 30px;
    font-size: 0.75rem;
    z-index: 99999;
    background-color: ${({ theme }) =>
      theme.formikErrorMessage?.backgroundColor
        ? theme.formikErrorMessage?.backgroundColor
        : '#ffecec!important'};
    color: ${({ theme }) => theme.formikErrorMessage?.color};
    border-width: ${({ theme }) => theme.formikErrorMessage?.borderWidth};
    border-color: ${({ theme }) =>
      theme.formikErrorMessage?.borderColor ? theme.formikErrorMessage?.borderColor : '#ff9e9e'};
  }
  &&&.ui.popup:before {
    z-index: 999999;
    background-color: ${({ theme }) =>
      theme.formikErrorMessage?.backgroundColor
        ? theme.formikErrorMessage?.backgroundColor
        : '#ffecec!important'};
    border-top-width: ${({ theme }) => theme.formikErrorMessage?.borderWidth};
    border-left-width: ${({ theme }) => theme.formikErrorMessage?.borderWidth};
  }
`;

export const StyledDatePickerWrapper = styled.div`
  .field {
    width: 100%;
  }

  input {
    background-color: ${({ theme }) => theme.datePicker?.backgroundColor};
    color: ${({ theme }) => theme.datePicker?.color};
  }

  .ui.segment {
    background-color: ${({ theme }) => theme.datePicker?.backgroundColorDatePicker};
    color: ${({ theme }) => theme.datePicker?.color};
    border-color: ${({ theme }) => theme.datePicker?.backgroundColor};
  }

  .clndr-cell {
    background-color: ${({ theme }) => theme.datePicker?.backgroundColor};
  }

  .clndr-days {
    color: ${({ theme }) => theme.datePicker?.color};
  }

  i.icon.angle.double.left:before,
  i.icon.angle.double.right:before,
  i.icon.angle.left:before,
  i.icon.angle.right:before {
    color: ${({ theme }) => theme.datePicker?.color};
  }

  .ui.icon.input > i.icon {
    color: ${({ theme }) => theme.datePicker?.color};
  }

  .ui.icon.input > input {
    font-size: 0.9em;
    padding-right: 0em !important;
    padding-left: 0.8em !important;
  }
`;

export const StyledDatepickerWithAccessibility = styled(SemanticDatepicker)`
  &&& :disabled,
  &&&.ui.disabled.input {
    opacity: 1;
    background-color: #f8f8f8;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  /* Switch On */
  &&&.ui.toggle.checkbox input:checked ~ .box:after,
  &&&.ui.toggle.checkbox input:checked ~ label:after {
    left: 1.5rem;
    height: 1rem;
    width: 1rem;
  }

  /* Switch Off */
  &&&.ui.toggle.checkbox input ~ .box:after,
  &&&.ui.toggle.checkbox input ~ label:after {
    left: 0rem;
    height: 1rem;
    width: 1rem;
  }

  /* Switch Background Off */
  &&&.ui.toggle.checkbox input:checked ~ label:before {
    background-color: ${({ theme }) => theme.toggle?.backgroundColorOn};
  }

  /* Switch Background On */
  &&&.ui.toggle.checkbox label:before {
    background-color: ${({ theme }) => theme.toggle?.backgroundColorOff};
  }

  /* background Dimensions */
  &&&.ui.toggle.checkbox label:before {
    height: 1rem;
    width: 2.5rem;
  }

  &&&.ui.fitted.toggle.checkbox {
    width: 3.5rem;
    height: 0rem;
    padding-top: 3px;
    vertical-align: middle;
    padding-left: 6px;
  }
`;

export const StyledCheckboxWithAccessibility = styled(StyledCheckbox)`
  &&& :disabled,
  &&&.ui.disabled.input {
    opacity: 1;
    background-color: #f8f8f8;
  }
`;
export const StyledEstimateHierarchy = styled(EstimateHierarchy)`
  color: ${({ theme }) => theme.estimateHierachy?.color};
`;

export const StyledEstimateHierarchyList = styled(List)`
  &&&.ui.divided.list > .item {
    border-color: ${({ theme }) => theme.estimateHierachy?.borderColor};
  }
`;

export const StyledHierarchyList = styled(List)`
  &&&.ui.divided.list > .item {
    border-color: ${({ theme }) => theme.hierachy?.borderColor};
  }
`;

// Pagination
export const StyledPagination = styled(Pagination)`
  &&&.ui.pagination.menu {
    background-color: ${({ theme }) => theme.pagination?.backgroundColor};
  }

  &&&.ui.menu {
    border-color: ${({ theme }) => theme.pagination?.borderColor};
  }

  &&&.ui.menu .item {
    color: ${({ theme }) => theme.pagination?.color};
  }

  &&&.ui.pagination.menu .active.item {
    background-color: ${({ theme }) => theme.pagination?.backgroundColorActive};
  }

  &&&.ui.menu .item:before {
    background: ${({ theme }) => theme.pagination?.borderColor};
  }
`;

export const StyledGridRow = styled(GridRow)`
  &&&.row {
    padding-top: ${(props) => props.paddingTop || '0rem'};
    padding-bottom: ${(props) => props.paddingBottom || '0rem'};
  }
  padding-left: ${(props) => props.paddingLeft || '0rem'};
`;

// Styled Full Width Div For Grid
export const StyledGridFullWidthRow = styled.div`
  background-color: ${({ theme }) => theme.grid?.fullWidthRowBackgroundColor};
  /* we want the border to be kept within the row height */
  box-sizing: border-box;
  /* get the row to fill the available height */
  height: 100%;
  /* grid sets white-space to one line, need to reset for wrapping the text */
  white-space: normal;
  padding: 11px 11px 11px 18px;
  color: ${({ theme }) => theme.grid?.fullWidthRowColor};
  font-weight: bold;
`;

export const StyledTable = styled(Table)`
  &&& {
    background-color: ${({ theme }) => theme.table?.backgroundColor};
    color: ${({ theme }) => theme.color};
    border-color: ${({ theme }) => theme.table?.borderColor};
  }

  &&& th {
    background-color: ${({ theme }) => theme.table?.backgroundColor};
    color: ${({ theme }) => theme.table?.color};
    border-color: ${({ theme }) => theme.table?.borderColor};
  }

  &&& td {
    border-color: ${({ theme }) => theme.table?.borderColor};
    color: ${({ theme }) => theme.table?.color};
  }

  &&& th.disabled {
    color: #000000 !important;
  }

  &&& td.disabled {
    color: #000000 !important;
  }
`;

// Monthly Usage Input
export const StyledMonthlyUsageInput = styled(Input)`
  &&& input[type='number'] {
    text-align: center;
    color: ${({ theme }) => theme.table?.color};
    background-color: ${({ theme }) => theme.input?.backgroundColor};
    border-width: 0px;
    border-radius: 0px;
    -moz-appearance: textfield;
  }

  &&& input::-webkit-outer-spin-button,
  &&& input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

// Breadcrumbs
export const StyledBreadcrumbs = styled(Breadcrumbs)`
  &&&.ui.breadcrumb .section,
  .divider {
    color: ${({ theme }) => {
      return theme.breadcrumbs?.color;
    }};
  }
`;

export const StyledDimmer = styled(Dimmer)`
  &&&.dimmer {
    background-color: ${({ theme }) => {
      return theme?.dimmer?.backgroundColor ?? 'rgba(220, 220, 220, 0.21) !important';
    }};
  }
`;

export const StyledLoader = styled(Loader)`
  &&&.loader {
    color: ${({ theme }) => {
      return theme.loader?.color;
    }};
    width: 100%;
    height: 100%;
    z-index: 999999999 !important;
  }
`;

export const StyledDiv = styled.div`
  width: ${(props) => props.width || 'auto'};
`;

// todo:: rethink about &&& and Theming
