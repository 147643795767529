// @flow
import React from 'react';

import { DownloadButton } from '../../presentational/buttons/DownloadButton';

export const GridDownloadButton = ({ gridRef, fileName, downloadAllCols }) => {
  const onBtnExport = () => {
    gridRef.current.api.exportDataAsCsv({
      exportedRows: 'all',
      allColumns: downloadAllCols,
      fileName: fileName,
      skipColumnGroupHeaders: true,
      processHeaderCallback: (params) => {
        const colDef = params.column.getColDef();
        const field = colDef.field;
        const headerSuffix = colDef?.headerSuffix;
        const headerNameToRet = field;
        if (headerSuffix) {
          return `${field} ${headerSuffix}`;
        }
        return headerNameToRet;
      },
    });
  };

  return <DownloadButton onClick={onBtnExport} />;
};
