// @flow
import React, { useContext } from 'react';
import { List } from 'semantic-ui-react';

import { HIERARCHY_LEVELS_TO_ICON_COLORS_MAP } from '../../../constants';
import { FolderIcon } from '../../presentational/icons/FolderIcon';
import { OpenCloseHierarchyTreeContext } from '../../../contexts/OpenCloseHierarchyTreeContext';

import { EstimateHierarchyOrganizationListItem } from './EstimateHierarchyOrganizationListItem';
import { highlightMatchingPortionInTextAndReturnSpan } from './utils';

export const EstimateHierarchyAccountListItem = ({ accountId, tree, searchTerm }) => {
  const { getIfAccountIsOpened, toggleOpenValueAtPathInOpenCloseTree } = useContext(
    OpenCloseHierarchyTreeContext
  );

  const account = tree[accountId];

  if (!account) {
    return null;
  }
  const { accountName } = account;
  const organizationsIdsList = Object.keys(account.organizations);

  const isAccountOpened = getIfAccountIsOpened(accountId);

  return (
    <List.Item
      key={accountId}
      onClick={(e) => {
        e.stopPropagation();
        toggleOpenValueAtPathInOpenCloseTree(accountId, null, null);
      }}
      style={{ cursor: 'pointer' }}
    >
      <FolderIcon
        color={HIERARCHY_LEVELS_TO_ICON_COLORS_MAP.get('Account')}
        opened={isAccountOpened}
      />
      <List.Content>
        <List.Header>
          {highlightMatchingPortionInTextAndReturnSpan(accountName, searchTerm)}
        </List.Header>
        <List.Description>
          {highlightMatchingPortionInTextAndReturnSpan(accountId, searchTerm)}
        </List.Description>
        {isAccountOpened &&
          organizationsIdsList.map((orgId) => {
            return (
              <EstimateHierarchyOrganizationListItem
                account={account}
                accountId={accountId}
                key={orgId}
                organizationId={orgId}
                searchTerm={searchTerm}
                tree={tree}
              />
            );
          })}
      </List.Content>
    </List.Item>
  );
};

// todo:: Remove the duplication of style={{ cursor: 'pointer' }} across all files
