// @flow
import React, { useContext } from 'react';
import { getIn, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import {
  CLUSTER_CLUSTER_LINKING_FORM,
  CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG,
} from '../../../constants';
import { ClusterInputTable } from '../cluster-input-table/ClusterInputTable';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { toastError } from '../../presentational/notifications/utils';
import { useUpdateClusterMutation } from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { isAnythingEditedOnClusterPageBesidesTheGivenForm } from '../../views/utils';

export const ClusterLinkingUsageContainer = () => {
  const rowsConfig = [
    {
      backendName: 'is_enabled',
      displayName: 'Active',
      isBooleanField: true,
    },
    {
      backendName: 'link_count',
      displayName: 'Number of Links',
    },
    {
      backendName: 'avg_link_write_mbps',
      displayName: 'Total average link write (MBps)',
    },
    {
      backendName: 'avg_link_read_mbps',
      displayName: 'Total average link read (MBps)',
    },
  ];
  const { estimateId, clusterId } = useParams();
  const [updateCluster] = useUpdateClusterMutation();
  const estimate = useContext(EstimateContext);

  const { values, initialValues } = useFormikContext();

  const shouldFormBeDisabled = isAnythingEditedOnClusterPageBesidesTheGivenForm(
    values,
    initialValues,
    CLUSTER_CLUSTER_LINKING_FORM
  );

  return (
    <PaddedAndRaisedSegment disabled={shouldFormBeDisabled}>
      <ClusterInputTable
        disabled={shouldFormBeDisabled}
        keyNameInValues={CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG}
        rowsConfig={rowsConfig}
        tableName="Cluster Linking Monthly Usage"
        updateFunc={async () => {
          const valuesToSendToBackend = getIn(
            values,
            CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG
          );
          const clusterLinkingInputs = Object.values(valuesToSendToBackend.months);

          const payload = {
            id: clusterId,
            estimate_id: estimateId,
            estimate_version: estimate?.inputs?.estimate_version,
            cluster_linking: { inputs: clusterLinkingInputs },
          };
          const { error } = await updateCluster({
            estimateId,
            clusterId,
            payload,
          });
          if (error) {
            toastError(error);
          }
        }}
      />
    </PaddedAndRaisedSegment>
  );
};
