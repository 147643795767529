// @flow
import { Icon } from 'semantic-ui-react';
import React from 'react';

export const ellipsisItemIcon = {
  content: <Icon name="ellipsis horizontal" />,
  icon: true,
};
export const firstItemIcon = {
  content: <Icon name="angle double left" />,
  icon: true,
};
export const lastItemIcon = {
  content: <Icon name="angle double right" />,
  icon: true,
};
export const nextItemIcon = {
  content: <Icon name="angle right" />,
  icon: true,
};
export const prevItemIcon = {
  content: <Icon name="angle left" />,
  icon: true,
};
