// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import {
  FieldArrayGrid,
  FieldArrayGridConfigItemContext,
} from '../../../formik-utils/FieldArrayGrid';
import {
  CUSTOM_DISCOUNTS_JSON_BACKEND_NAME,
  METRICS_LEVEL_DISCOUNTS_CONFIG_ARRAY_BACKEND_NAME,
} from '../../../constants';
import { METRICS_LEVEL_DISCOUNTS_TOP_LEVEL_CONFIG_ARRAY_CONFIG } from '../../../configuration/custom-discounts';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { getIfAllMetricsAreFrozen } from '../utils';

import { MetricsLevelDiscountsMetricConfigurationItem } from './MetricsLevelDiscountsMetricConfigurationItem';

export const MetricsLevelDiscountsTopLevelContainer = ({ readOnlyMode }) => {
  const estimate = useContext(EstimateContext);
  const { values: formikValues } = useFormikContext();
  const pathToFollowInValues = [
    CUSTOM_DISCOUNTS_JSON_BACKEND_NAME,
    METRICS_LEVEL_DISCOUNTS_CONFIG_ARRAY_BACKEND_NAME,
  ];

  const allMetricsAreFrozen = getIfAllMetricsAreFrozen(formikValues);

  return (
    <>
      <FieldArrayGrid
        addHeaders={false}
        addRowButtonDisabled={!allMetricsAreFrozen}
        columnsConfiguration={METRICS_LEVEL_DISCOUNTS_TOP_LEVEL_CONFIG_ARRAY_CONFIG}
        disabled={readOnlyMode}
        gridName="New Metric"
        pathToFollowInValues={pathToFollowInValues}
      >
        {(arrayHelpers, row, isCurrentlyBeingEdited, index, allRows, readOnlyMode) => (
          <FieldArrayGridConfigItemContext.Provider
            value={{
              allRows,
              arrayHelpers,
              columnsConfiguration: METRICS_LEVEL_DISCOUNTS_TOP_LEVEL_CONFIG_ARRAY_CONFIG,
              estimate,
              index,
              isCurrentlyBeingEdited,
              pathToFollowInValues: pathToFollowInValues,
              rowValues: row,
              readOnlyMode,
            }}
          >
            <MetricsLevelDiscountsMetricConfigurationItem readOnlyMode={readOnlyMode} />
          </FieldArrayGridConfigItemContext.Provider>
        )}
      </FieldArrayGrid>
    </>
  );
};
