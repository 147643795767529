// @flow
import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import { DeleteButton } from '../presentational/buttons/DeleteButton';
import FlinkPoolDeleteWarningModal from '../views/flink-pools-summary/FlinkPoolDeleteWarningModal';

export const FlinkPoolDeleteContainer = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const { values } = useFormikContext();
  const { flinkPoolId } = useParams();

  return (
    <>
      <DeleteButton
        onClick={() => {
          setDeleteModalOpen(true);
        }}
        size="large"
      />
      <FlinkPoolDeleteWarningModal
        flinkPoolId={flinkPoolId}
        flinkPoolName={values?.name}
        isOpen={isDeleteModalOpen}
        setOpen={setDeleteModalOpen}
      />
    </>
  );
};
