// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';
import { CLUSTER_AVAILABILITY_BACKEND_NAME, MZ, SZ } from '../../../constants';
import { getDropdownOptions } from '../../stream-governance/utils';
import { SelectField } from '../../../formik-utils/FormFields';
import { ClusterContext } from '../../../contexts/ClusterContext';

import { useClusterInputsDependencies } from './utils';

export const ClusterAvailabilityConfigurationContainer = ({ disabled }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const clusterAvailabilityConfig = CLUSTER_DETAILS_CONFIG_MAP.get(
    CLUSTER_AVAILABILITY_BACKEND_NAME
  );

  const cluster = useContext(ClusterContext);
  const dataUniverse = cluster?.cluster_configs?.cluster_params;

  useClusterInputsDependencies(
    clusterAvailabilityConfig,
    dataUniverse,
    values,
    setFieldValue,
    setFieldTouched
  );

  const clusterAvailabilityOptions = getDropdownOptions(
    dataUniverse,
    values,
    clusterAvailabilityConfig,
    false,
    true,
    false,
    false,
    [SZ, MZ]
  );

  return (
    <SelectField
      disabled={disabled}
      fieldDisplayName={clusterAvailabilityConfig.displayName}
      fieldName={clusterAvailabilityConfig.backendFieldName}
      options={clusterAvailabilityOptions}
      placeholder={null}
    />
  );
};
