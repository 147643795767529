// @flow
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DataFetcherContainer } from '../../presentational/DataFetcherContainer';
import {
  useGetStreamGovernanceMonthlySpendSummaryQuery,
  useGetStreamGovernancePerEnvironmentSpendSummaryQuery,
} from '../../../service-definitions/estimates';
import { TabsContainer } from '../../presentational/TabsContainer';

import { StreamGovernancePerEnvSpendSummaryContainer } from './StreamGovernancePerEnvSpendSummaryContainer';
import { StreamGovernanceMonthlySummaryContainer } from './StreamGovernanceMonthlySummaryContainer';

export const TotalSpendContainer = ({ estimateId }) => {
  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId },
          {
            skip: !estimateId,
          },
        ],
        [estimateId]
      )}
      dataFetchingFunction={useCallback(useGetStreamGovernancePerEnvironmentSpendSummaryQuery, [])}
    >
      {(data) => {
        return <StreamGovernancePerEnvSpendSummaryContainer data={data} />;
      }}
    </DataFetcherContainer>
  );
};

export const MonthlySpendContainer = () => {
  const { estimateId } = useParams();

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId },
          {
            skip: !estimateId,
          },
        ],
        [estimateId]
      )}
      dataFetchingFunction={useCallback(useGetStreamGovernanceMonthlySpendSummaryQuery, [])}
    >
      {(data) => {
        return <StreamGovernanceMonthlySummaryContainer monthlySpendData={data} />;
      }}
    </DataFetcherContainer>
  );
};

export const StreamGovernanceConfigurationOutputContainer = () => {
  const tabPanes = [
    {
      menuItem: 'Total Spend',
      render: () => <StreamGovernancePerEnvSpendSummaryContainer />,
    },
    {
      menuItem: 'Monthly Spend',
      render: () => <MonthlySpendContainer />,
    },
  ];

  return <TabsContainer tabPanes={tabPanes} />;
};
