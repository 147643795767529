// @flow
import React, { useContext } from 'react';

import { ClusterContext } from '../../../contexts/ClusterContext';
import { ESTIMATE_CLUSTER_PRICING_SUMMARY } from '../../../constants';
import { getPlainObjectFromBackEndSpendSummaryFormat } from '../../../common-utils/utils';
import { TotalSpendSummaryContainer } from '../../common/TotalSpendSummaryContainer';

export const ClusterTotalSpendSummaryContainer = ({ disabled }) => {
  const cluster = useContext(ClusterContext);
  const inputDataSource = cluster.spend_summary;
  const ordering = ['Kafka', 'Connectors', 'ksqlDB', 'Cluster Linking', 'Total'];

  const inputDataSourceFormattedAndOrdered = getPlainObjectFromBackEndSpendSummaryFormat(
    inputDataSource,
    ordering
  );

  return (
    <TotalSpendSummaryContainer
      disabled={disabled}
      gridLabel={ESTIMATE_CLUSTER_PRICING_SUMMARY}
      inputDataSourceFormattedAndOrdered={inputDataSourceFormattedAndOrdered}
    />
  );
};
