// @flow
import { Dropdown } from 'semantic-ui-react';
import React from 'react';

import { ExternalLink } from '../presentational/ExternalLink';

export const ExternalLinkDropdownItem = ({ href, text }) => (
  <Dropdown.Item>
    <ExternalLink href={href} text={text} />
  </Dropdown.Item>
);
