// @flow

export const getLinkForCluster = (estimateId, clusterId) => {
  if (!clusterId) {
    return `/estimate/${estimateId}/cluster/`;
  }
  return `/estimate/${estimateId}/cluster/${clusterId}`;
};

export const getLinkForFlinkPool = (estimateId, flinkPoolId) => {
  return `/estimate/${estimateId}/flink-pools/${flinkPoolId}`;
};

export const getLinkForEstimate = (estimateId) => `/estimate/${estimateId}`;

export const getLinkForStreamGovernance = (estimateId) =>
  `/estimate/${estimateId}/stream-governance/`;

export const getLinkForHomePage = () => `/`;
