// @flow

import React from 'react';
import { Icon } from 'semantic-ui-react';

import { StyledButton } from '../../../common-utils/styledComponents';

export const CopyButton = ({ disabled, onClick, floated = 'right' }) => (
  <StyledButton
    compact={true}
    data-testid="copy-button"
    disabled={disabled}
    floated={floated}
    icon={<Icon name="copy alternate outline" />}
    onClick={onClick}
    primary={true}
    size="large"
    type="button"
  />
);
