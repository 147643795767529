// @flow
import { ClusterNameRenderer } from './ClusterNameRenderer';
import { ClusterNameRendererWithError } from './ClusterNameRendererWithError';

export const ClusterNameRendererWithValidation = (props) => {
  if (props?.data?.validation_error) {
    return ClusterNameRendererWithError(props);
  }
  return ClusterNameRenderer(props);
};
