// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { Spacer } from '../../presentational/spacing/Spacer';
import { ResetFormButton } from '../../presentational/buttons/ResetFormButton';
import {
  extractRelevantFieldValuesFromForm,
  isFormEditedBasedOnCurrentValues,
  resetFormValuesToInitialValues,
} from '../../../common-utils/utils';
import { SaveFormButton } from '../../presentational/buttons/SaveFormButton';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { TCO_INPUTS_JSON_CONFIG } from '../../../configuration/tco-estimate-inputs';
import { toastError } from '../../presentational/notifications/utils';
import { useUpdateEstimateMutation } from '../../../service-definitions/estimates';
import { isAnythingEditedOnEstimatePageBesidesTheGivenForm } from '../../views/utils';
import { ESTIMATE_TCO_DETAILS_FORM } from '../../../constants';

import { TCOEstimateLevelInputsGrid } from './TCOEstimateLevelInputsGrid';

export const EstimateTCOInputsTopLevelContainer = () => {
  const { estimateId } = useParams();
  const estimate = useContext(EstimateContext);
  const { values, isValid, initialValues, resetForm } = useFormikContext();
  const [updateEstimateTCOInputDetails] = useUpdateEstimateMutation();

  const isTheFormEdited = isFormEditedBasedOnCurrentValues(values, initialValues, [
    TCO_INPUTS_JSON_CONFIG,
  ]);

  const shouldSaveBeDisabled = !isValid || !isTheFormEdited;
  const shouldResetBeDisabled = !isTheFormEdited;

  const shouldFormBeDisabled = isAnythingEditedOnEstimatePageBesidesTheGivenForm(
    values,
    initialValues,
    ESTIMATE_TCO_DETAILS_FORM
  );

  return (
    <>
      <PaddedAndRaisedSegment disabled={shouldFormBeDisabled}>
        <TCOEstimateLevelInputsGrid />
        <Spacer y={40} />

        <ResetFormButton
          disabled={shouldResetBeDisabled}
          onClick={() => {
            resetFormValuesToInitialValues(
              values,
              [TCO_INPUTS_JSON_CONFIG],
              initialValues,
              resetForm
            );
          }}
        />

        <Spacer x={1} />

        <SaveFormButton
          disabled={shouldSaveBeDisabled}
          onClick={async () => {
            const payload = extractRelevantFieldValuesFromForm([TCO_INPUTS_JSON_CONFIG], values);
            payload.estimate_version = estimate?.inputs?.estimate_version;

            const { error } = await updateEstimateTCOInputDetails({
              estimateId,
              payload,
            });
            if (error) {
              toastError(error);
            }
          }}
        />
      </PaddedAndRaisedSegment>
    </>
  );
};
