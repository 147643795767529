// @flow
import React, { useContext, useState } from 'react';

import { YearSelector } from '../../presentational/year-selector/YearSelector';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { EstimateContext } from '../../../contexts/EstimateContext';
import {
  getMonthName,
  getStartAndEndMonthsForGivenYear,
  getYearName,
} from '../../cluster/cluster-input-table/utils';
import { getIfArrayContainDuplicates } from '../../../common-utils/utils';
import { StyledH3 } from '../../presentational/headings/StyledH3';

import { MonthlyConsumptionGraphContainer } from './MonthlyConsumptionGraphContainer';

const getCurrentYearValuesAsArrayFromMonthsObj = (monthsObj, yearNumber) => {
  const toRet = [];
  const { startMonthNumber, endMonthNumber } = getStartAndEndMonthsForGivenYear(yearNumber);

  for (let i = startMonthNumber; i <= endMonthNumber; i++) {
    const monthData = monthsObj[getMonthName(i)];
    if (monthData) {
      toRet.push(monthData);
    }
  }

  return toRet;
};

const createCumulativeSpendsInMonthlySpends = (
  numberOfMonths,
  clusterNameIncludingTotalArray,
  monthsObj
) => {
  for (let monthNumber = 2; monthNumber <= numberOfMonths; monthNumber++) {
    const monthName = getMonthName(monthNumber);
    const previousMonthName = getMonthName(monthNumber - 1);
    for (const field of clusterNameIncludingTotalArray) {
      monthsObj[monthName][field] += monthsObj[previousMonthName][field];
    }
  }
};

const getMonthlySpendsFormatted = (data) => {
  const monthlySpends = {};
  const clusterNameIncludingTotalArray = [];

  for (const row of data) {
    if (
      ['Stream Governance', 'Support', 'Cost', 'Cost (with burst capacity)'].includes(row.field)
    ) {
      continue;
    }

    const field = row.field;

    // Total Spend should be equal to Total + Bursts
    const fieldToUse = field === 'Total' || field === 'Bursts' ? 'Total Spend' : field;

    if (!clusterNameIncludingTotalArray.includes(fieldToUse)) {
      clusterNameIncludingTotalArray.push(fieldToUse);
    }

    for (const yearDataElem of row.yearlies) {
      const yearNumber = Number(yearDataElem.year);
      const yearName = getYearName(yearNumber);
      for (const month of yearDataElem.months) {
        const monthNumber = Number(month.month);
        const monthName = getMonthName(monthNumber);
        if (!monthlySpends[monthName]) {
          monthlySpends[monthName] = {
            month: monthName,
            year: yearName,
          };
        }

        if (monthlySpends[monthName][fieldToUse] == null) {
          monthlySpends[monthName][fieldToUse] = 0;
        }

        monthlySpends[monthName][fieldToUse] += Number(month.spend_discounted);
      }
    }
  }

  return { monthlySpends, clusterNameIncludingTotalArray };
};

export const MonthlyConsumptionGraphTopLevelContainer = ({ data, disabled }) => {
  const estimate = useContext(EstimateContext);
  const totalYears = estimate?.deal_duration?.deal_duration_years ?? 0;
  const numberOfMonths = estimate.deal_duration.deal_duration_months;
  const [selectedYear, setSelectedYear] = useState(1);

  const clusterNames = data.map((x) => x.field);
  const areThereDuplicatesInClusterNames = getIfArrayContainDuplicates(clusterNames);

  if (areThereDuplicatesInClusterNames) {
    return (
      <PaddedAndRaisedSegment disabled={disabled}>
        <StyledH3 text="Graph could not built because there were duplicates in cluster/flink-pool names! Please remove duplicates and try again!" />
      </PaddedAndRaisedSegment>
    );
  }

  const { monthlySpends, clusterNameIncludingTotalArray } = getMonthlySpendsFormatted(
    data,
    clusterNameIncludingTotalArray
  );

  createCumulativeSpendsInMonthlySpends(
    numberOfMonths,
    clusterNameIncludingTotalArray,
    monthlySpends
  );

  const clusterMonthlyCumulativeConsumption = {};
  for (let yearNumber = 1; yearNumber <= totalYears; yearNumber++) {
    clusterMonthlyCumulativeConsumption[getYearName(yearNumber)] =
      getCurrentYearValuesAsArrayFromMonthsObj(monthlySpends, yearNumber);
  }

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <YearSelector
        disabled={false}
        onChange={(year) => setSelectedYear(year)}
        selectedYearIndex={selectedYear}
        totalYears={totalYears}
      />
      <MonthlyConsumptionGraphContainer
        data={clusterMonthlyCumulativeConsumption[`Year ${selectedYear}`]}
      />
    </PaddedAndRaisedSegment>
  );
};
