// @flow
import { boolean, number, object, string } from 'yup';

import {
  CLUSTER_AVAILABILITY,
  CLUSTER_AVAILABILITY_BACKEND_NAME,
  CLUSTER_AVAILABILITY_LABEL_BACKEND_NAME,
  CLUSTER_AVAILABLE_CONNECTORS_LIST,
  CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME,
  CLUSTER_CLUSTER_LINKING_FORM,
  CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_CONNECTORS_FORM,
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_DETAILS_FORM,
  CLUSTER_ENABLED,
  CLUSTER_ENABLED_BACKEND_NAME,
  CLUSTER_EXISTING_STORAGE_BACKEND_NAME,
  CLUSTER_EXISTING_STORAGE_DISPLAY_NAME,
  CLUSTER_FOLLOWER_FETCH,
  CLUSTER_FOLLOWER_FETCH_BACKEND_NAME,
  CLUSTER_KAFKA_FORM,
  CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KSQLDB_FORM,
  CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_NAME,
  CLUSTER_NAME_BACKEND_NAME,
  CLUSTER_NETWORKING_TYPE,
  CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
  CLUSTER_NETWORKING_TYPE_LABEL_BACKEND_NAME,
  CLUSTER_PARTITIONS,
  CLUSTER_PARTITIONS_BACKEND_NAME,
  CLUSTER_PROVIDER,
  CLUSTER_PROVIDER_BACKEND_NAME,
  CLUSTER_PROVIDER_LABEL_BACKEND_NAME,
  CLUSTER_REGION,
  CLUSTER_REGION_BACKEND_NAME,
  CLUSTER_REGION_LABEL_BACKEND_NAME,
  CLUSTER_RETENTION,
  CLUSTER_RETENTION_BACKEND_NAME,
  CLUSTER_RETENTION_INFINITE,
  CLUSTER_RETENTION_INFINITE_BACKEND_NAME,
  CLUSTER_SLA_BACKEND_NAME,
  CLUSTER_SLA_DISPLAY_COL_MAPPING,
  CLUSTER_SLA_DISPLAY_NAME,
  CLUSTER_TCO_INPUT_FORM,
  CLUSTER_TYPE,
  CLUSTER_TYPE_BACKEND_NAME,
  CLUSTER_TYPE_LABEL_BACKEND_NAME,
  CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST,
  CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST_BACKEND_NAME,
  CONNECTOR_LABEL,
  ENABLE,
  MAX_INPUT_VALUE_FOR_ANY_FIELD,
  RATE_CARD_BACKEND_NAME,
  TCO_INPUTS_JSON_BACKEND_NAME,
} from '../constants';
import {
  CHECKBOX_INPUT_TYPE,
  SELECT_INPUT_TYPE,
  TEXT_INPUT_TYPE,
  TEXT_NUMBER_INPUT_TYPE,
} from '../formik-utils/consts';
import {
  clusterCalculationsDataInputTransformationFunc,
  clusterMonthlyDataInputTransformationFunc,
  connectorsMonthlyDataInputTransformationFunc,
  connectorsMonthlyInputsValidationFunc,
} from '../components/cluster/cluster-input-table/utils';
import { getMonthlyInputsValidationFuncForType } from '../components/cluster/cluster-details-inputs/kafka-monthly-inputs-validation';

import {
  convertConfigArrayToMap,
  createFormNameToFieldsMap,
  getIfPartitionsConfiguredAreWithinLimits,
  getValidationSchemaFromColsConfig,
} from './utils';
import { TCO_CLUSTER_LEVEL_INPUTS_CONFIG } from './tco-cluster-inputs';

export const CLUSTER_DETAILS_CONFIG = [
  {
    displayName: CLUSTER_NAME,
    backendFieldName: CLUSTER_NAME_BACKEND_NAME,
    icon: null,
    validation: string().label(CLUSTER_NAME).required(),
    inputType: TEXT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
  },
  {
    displayName: ENABLE,
    backendFieldName: CLUSTER_ENABLED_BACKEND_NAME,
    icon: null,
    validation: boolean().label(CLUSTER_ENABLED).required(),
    inputType: CHECKBOX_INPUT_TYPE,
  },
  {
    displayName: CLUSTER_PARTITIONS,
    backendFieldName: CLUSTER_PARTITIONS_BACKEND_NAME,
    icon: null,
    validation: ({ estimate }) => {
      return number()
        .min(0)
        .integer()
        .label('Number of Partitions')
        .required()
        .typeError('You must specify a number')
        .test(
          'max',
          'Number of Partitions must be within the limits',
          (value, { createError, path, parent }) => {
            const clusterType = parent[CLUSTER_TYPE_BACKEND_NAME];
            const rateCardVersion = estimate.inputs[RATE_CARD_BACKEND_NAME];
            const { partitionLimit, isError } = getIfPartitionsConfiguredAreWithinLimits(
              clusterType,
              rateCardVersion,
              value
            );
            if (isError) {
              return createError({
                message: `Number of Partitions must be less than or equal to ${partitionLimit}`,
                path: path,
              });
            }
            return true;
          }
        );
    },
    inputType: TEXT_NUMBER_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
  },
  {
    displayName: CLUSTER_EXISTING_STORAGE_DISPLAY_NAME,
    backendFieldName: CLUSTER_EXISTING_STORAGE_BACKEND_NAME,
    icon: null,
    validation: number()
      .min(0)
      .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
      .label(CLUSTER_EXISTING_STORAGE_DISPLAY_NAME)
      .required()
      .typeError('You must specify a number'),
    inputType: TEXT_NUMBER_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
  },
  {
    displayName: CLUSTER_RETENTION,
    backendFieldName: CLUSTER_RETENTION_BACKEND_NAME,
    icon: null,
    validation: ({ estimate }) => {
      const dealLengthInDays = estimate.deal_duration.deal_duration_days;
      return number()
        .min(0)
        .label('Retention Days')
        .required()
        .max(dealLengthInDays)
        .typeError('You must specify a number');
    },
    inputType: TEXT_NUMBER_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
  },
  {
    displayName: CLUSTER_RETENTION_INFINITE,
    backendFieldName: CLUSTER_RETENTION_INFINITE_BACKEND_NAME,
    icon: null,
    inputType: CHECKBOX_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
  },
  {
    displayName: CLUSTER_TYPE,
    backendFieldName: CLUSTER_TYPE_BACKEND_NAME,
    displayNameCol: CLUSTER_TYPE_LABEL_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
    validation: string().required().label(CLUSTER_TYPE).nullable(),
    dependentFields: [
      CLUSTER_PROVIDER_BACKEND_NAME,
      CLUSTER_AVAILABILITY_BACKEND_NAME,
      CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
    ],
  },
  {
    displayName: CLUSTER_PROVIDER,
    backendFieldName: CLUSTER_PROVIDER_BACKEND_NAME,
    displayNameCol: CLUSTER_PROVIDER_LABEL_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
    validation: string().required().label(CLUSTER_PROVIDER).nullable(),
    parentFieldsInGroup: [CLUSTER_TYPE_BACKEND_NAME],
    dependentFields: [CLUSTER_AVAILABILITY_BACKEND_NAME, CLUSTER_NETWORKING_TYPE_BACKEND_NAME],
  },
  {
    displayName: CLUSTER_REGION,
    backendFieldName: CLUSTER_REGION_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
    validation: string().required().label(CLUSTER_REGION).nullable(),
    parentFieldsInGroup: [CLUSTER_PROVIDER_BACKEND_NAME],
    displayNameCol: CLUSTER_REGION_LABEL_BACKEND_NAME,
  },
  {
    displayName: CLUSTER_AVAILABILITY,
    backendFieldName: CLUSTER_AVAILABILITY_BACKEND_NAME,
    displayNameCol: CLUSTER_AVAILABILITY_LABEL_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
    validation: string().required().label(CLUSTER_AVAILABILITY).nullable(),
    parentFieldsInGroup: [CLUSTER_TYPE_BACKEND_NAME, CLUSTER_PROVIDER_BACKEND_NAME],
    dependentFields: [CLUSTER_NETWORKING_TYPE_BACKEND_NAME],
  },
  {
    displayName: CLUSTER_NETWORKING_TYPE,
    backendFieldName: CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
    displayNameCol: CLUSTER_NETWORKING_TYPE_LABEL_BACKEND_NAME,
    validation: string().required().label(CLUSTER_NETWORKING_TYPE).nullable(),
    parentFieldsInGroup: [
      CLUSTER_TYPE_BACKEND_NAME,
      CLUSTER_PROVIDER_BACKEND_NAME,
      CLUSTER_AVAILABILITY_BACKEND_NAME,
    ],
  },
  {
    displayName: CLUSTER_SLA_DISPLAY_NAME,
    backendFieldName: CLUSTER_SLA_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
    displayNameCol: CLUSTER_SLA_DISPLAY_COL_MAPPING,
    parentFieldsInGroup: [CLUSTER_TYPE_BACKEND_NAME, CLUSTER_AVAILABILITY_BACKEND_NAME],
    validation: string().required().label(CLUSTER_SLA_DISPLAY_NAME).nullable(),
  },
  {
    displayName: CLUSTER_FOLLOWER_FETCH,
    backendFieldName: CLUSTER_FOLLOWER_FETCH_BACKEND_NAME,
    icon: null,
    inputType: CHECKBOX_INPUT_TYPE,
    belongingForm: CLUSTER_DETAILS_FORM,
  },
  {
    displayName: 'calculations',
    backendFieldName: 'calculations',
    inputTransformationFunc: clusterCalculationsDataInputTransformationFunc,
  },
  {
    displayName: CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
    backendFieldName: CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
    belongingForm: CLUSTER_KAFKA_FORM,
    inputTransformationFunc: clusterMonthlyDataInputTransformationFunc,
    validation: getMonthlyInputsValidationFuncForType(CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG),
  },
  {
    displayName: CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG,
    backendFieldName: CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG,
    belongingForm: CLUSTER_KSQLDB_FORM,
    inputTransformationFunc: clusterMonthlyDataInputTransformationFunc,
    validation: getMonthlyInputsValidationFuncForType(CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG),
  },
  {
    displayName: CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG,
    backendFieldName: CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG,
    belongingForm: CLUSTER_CLUSTER_LINKING_FORM,
    inputTransformationFunc: clusterMonthlyDataInputTransformationFunc,
    validation: getMonthlyInputsValidationFuncForType(
      CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG
    ),
  },
  {
    displayName: CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
    backendFieldName: CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
    belongingForm: CLUSTER_CONNECTORS_FORM,
    inputTransformationFunc: connectorsMonthlyDataInputTransformationFunc,
    validation: connectorsMonthlyInputsValidationFunc,
  },
  {
    displayName: CLUSTER_AVAILABLE_CONNECTORS_LIST,
    backendFieldName: CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME,
    displayNameCol: CONNECTOR_LABEL,
    parentFieldsInGroup: [
      CLUSTER_TYPE_BACKEND_NAME,
      CLUSTER_PROVIDER_BACKEND_NAME,
      CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
    ],
  },
  {
    displayName: CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST,
    backendFieldName: CONNECTOR_AVAILABLE_NETWORKING_TYPES_LIST_BACKEND_NAME,
    parentFieldsInGroup: [CLUSTER_TYPE_BACKEND_NAME, CLUSTER_PROVIDER_BACKEND_NAME],
    displayNameCol: CLUSTER_NETWORKING_TYPE_LABEL_BACKEND_NAME,
  },
  {
    displayName: TCO_INPUTS_JSON_BACKEND_NAME,
    backendFieldName: TCO_INPUTS_JSON_BACKEND_NAME,
    belongingForm: CLUSTER_TCO_INPUT_FORM,
    validation: object({ ...getValidationSchemaFromColsConfig(TCO_CLUSTER_LEVEL_INPUTS_CONFIG) }),
  },
];

export const CLUSTER_DETAILS_CONFIG_MAP = convertConfigArrayToMap(CLUSTER_DETAILS_CONFIG);
export const CLUSTER_DETAILS_FORM_NAMES_TO_FIELDS_MAP =
  createFormNameToFieldsMap(CLUSTER_DETAILS_CONFIG);
