// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import {
  AWS,
  AZURE,
  FLINK_POOL_PROVIDER_BACKEND_NAME,
  FLINK_POOL_REGION_BACKEND_NAME,
  GCP,
} from '../../../constants';
import { getDropdownOptions } from '../../stream-governance/utils';
import { SelectField } from '../../../formik-utils/FormFields';
import { FLINK_POOL_DETAILS_CONFIG_MAP } from '../../../configuration/flink-pool-details';
import { FlinkPoolContext } from '../../../contexts/FlinkPoolContext';

export const FlinkPoolProviderContainer = ({ disabled }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const flinkPoolProviderConfig = FLINK_POOL_DETAILS_CONFIG_MAP.get(
    FLINK_POOL_PROVIDER_BACKEND_NAME
  );

  const flinkPool = useContext(FlinkPoolContext);
  const dataUniverse = flinkPool?.flink_pool_configs?.flink_pool_params ?? [];

  const flinkPoolProviderOptions = getDropdownOptions(
    dataUniverse,
    values,
    flinkPoolProviderConfig,
    false,
    true,
    false,
    false,
    [AWS, AZURE, GCP]
  );

  return (
    <SelectField
      disabled={disabled}
      fieldDisplayName={flinkPoolProviderConfig.displayName}
      fieldName={flinkPoolProviderConfig.backendFieldName}
      onChange={() => {
        setFieldTouched(FLINK_POOL_REGION_BACKEND_NAME, true);
        setFieldValue(FLINK_POOL_REGION_BACKEND_NAME, null);
      }}
      options={flinkPoolProviderOptions}
      placeholder={null}
    />
  );
};
