// @flow

import { Grid } from 'semantic-ui-react';
import React from 'react';

import { Spacer } from '../../presentational/spacing/Spacer';

import { GlobalDiscountNoteContainer } from './GlobalDiscountNoteContainer';
import { GlobalDiscountsUpdateButtonContainer } from './GlobalDiscountsUpdateButtonContainer';
import { GlobalDiscountLabelContainer } from './GlobalDiscountLabelContainer';
import { GlobalDiscountsTextFieldContainer } from './GlobalDiscountsTextFieldContainer';

export const GlobalDiscountContainer = ({ readOnlyMode }) => {
  return (
    <Grid columns={4} stackable={true}>
      <Spacer y={12} />
      <Grid.Column textAlign="middle" width={2}>
        <GlobalDiscountLabelContainer />
      </Grid.Column>
      <Grid.Column textAlign="middle" width={2}>
        <GlobalDiscountsTextFieldContainer readOnlyMode={readOnlyMode} />
      </Grid.Column>
      <Grid.Column textAlign="middle" width={1}>
        <GlobalDiscountsUpdateButtonContainer readOnlyMode={readOnlyMode} />
      </Grid.Column>
      <Grid.Column textAlign="middle" width={5}>
        <GlobalDiscountNoteContainer />
      </Grid.Column>
    </Grid>
  );
};
