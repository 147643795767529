// @flow
import React, { useContext } from 'react';

import { FLINK_POOL_PRICING_SUMMARY } from '../../../constants';
import { getPlainObjectFromBackEndSpendSummaryFormat } from '../../../common-utils/utils';
import { FlinkPoolContext } from '../../../contexts/FlinkPoolContext';
import { TotalSpendSummaryContainer } from '../../common/TotalSpendSummaryContainer';

export const FlinkPoolTotalSpendSummaryContainer = ({ disabled }) => {
  const flinkPoolDetails = useContext(FlinkPoolContext);
  const inputDataSource = flinkPoolDetails.spend_summary;
  const inputDataSourceFormattedAndOrdered = getPlainObjectFromBackEndSpendSummaryFormat(
    inputDataSource,
    ['Flink', 'Total']
  );

  return (
    <TotalSpendSummaryContainer
      disabled={disabled}
      gridLabel={FLINK_POOL_PRICING_SUMMARY}
      inputDataSourceFormattedAndOrdered={inputDataSourceFormattedAndOrdered}
      removeTotalBannerRow={true}
      removeZeroRows={false}
    />
  );
};
