// @flow
import { createGlobalStyle } from 'styled-components';

const dark = [
  '#111517',
  '#1a1f23',
  '#20262b',
  '#2b343b',
  '#343e46',
  '#2285D0',
  '#5d656b',
  '#6d757a',
  '#dfe1e2',
];

export const getThemeDetails = (theme) => {
  let themeColors;
  switch (theme) {
    case 'dark':
      themeColors = dark;
      break;
    default:
      themeColors = [];
  }

  if (theme === 'dark') {
    return {
      colors: themeColors,
      header: {
        color: `${themeColors[8]}!important`,
      },
      table: {
        backgroundColor: `${themeColors[2]}!important`,
        color: `${themeColors[8]}!important`,
        borderColor: `${themeColors[4]}!important`,
      },
      toggle: {
        backgroundColorOn: `${themeColors[5]}!important`,
        backgroundColorOff: `${themeColors[5]}4D!important`,
      },
      segment: {
        backgroundColor: `${themeColors[2]}!important`,
        borderColor: `${themeColors[8]}!important`,
      },
      label: {
        backgroundColor: 'transparent!important',
        backgroundColorAttached: `${themeColors[3]}!important`,
        backgroundColorInput: 'transparent!important',
        color: `${themeColors[8]}!important`,
      },
      yearSelector: {
        backgroundColor: `${themeColors[3]}!important`,
        color: `${themeColors[8]}!important`,
        navigationBackgroundColor: `${themeColors[4]}!important`,
        borderWidth: '0px!important',
      },
      input: {
        backgroundColor: `${themeColors[3]}!important`,
        color: `${themeColors[8]}!important`,
        borderColor: `${themeColors[4]}!important`,
      },
      dropDown: {
        backgroundColor: `${themeColors[3]}!important`,
        color: `${themeColors[8]}!important`,
        borderColor: `${themeColors[4]}!important`,
        backgroundColorSelectedItem: `${themeColors[4]}!important`,
      },
      breadcrumbs: {
        color: `${themeColors[8]}!important`,
      },
      tab: {
        color: `${themeColors[8]}!important`,
        borderColor: `${themeColors[3]}!important`,
        borderColorActiveItem: `${themeColors[7]}!important`,
      },
      dimmer: {
        backgroundColor: 'rgba(220, 220, 220, 0.11) !important',
      },
      loader: {
        color: `${themeColors[8]}!important`,
      },
      accordion: {
        backgroundColor: `${themeColors[1]}!important`,
        borderColor: `${themeColors[3]}!important`,
        border: `1px solid ${themeColors[3]}!important`,
        color: `${themeColors[8]}!important`,
      },
      modal: {
        backgroundColor: `${themeColors[2]}!important`,
        color: `${themeColors[8]}!important`,
        borderColor: `${themeColors[3]}!important`,
      },
      popup: {
        backgroundColor: `${themeColors[0]}!important`,
        color: `${themeColors[8]}!important`,
        borderColor: `${themeColors[3]}!important`,
        boxShadow: `1px 1px 0 0 ${themeColors[3]}!important`,
      },
      formikErrorMessage: {
        backgroundColor: '#9c2b2e!important',
        color: `${themeColors[8]}!important`,
        borderWidth: '1px',
        borderColor: '#e84e4f!important',
        boxShadow: '-1px -1px 0 0 #e84e4f!important',
      },
      estimateHierachy: {
        color: `${themeColors[8]}!important`,
        borderColor: `${themeColors[4]}!important`,
      },
      hierachy: {
        color: `${themeColors[8]}!important`,
        borderColor: `${themeColors[4]}!important`,
      },
      span: {
        color: `${themeColors[8]}!important`,
      },
      pagination: {
        backgroundColor: `${themeColors[3]}!important`,
        backgroundColorActive: `${themeColors[1]}!important`,
        color: `${themeColors[8]}!important`,
        borderColor: `${themeColors[7]}!important`,
      },
      datePicker: {
        backgroundColor: `${themeColors[3]}!important`,
        backgroundColorDatePicker: `${themeColors[2]}!important`,
        color: `${themeColors[8]}!important`,
      },
      message: {
        backgroundColor: `${themeColors[3]}!important`,
        color: `${themeColors[8]}!important`,
      },
      clusterGraph: {
        backgroundColor: `${themeColors[2]}!important`,
        color: `${themeColors[8]}!important`,
        borderColor: `${themeColors[7]}!important`,
      },
      grid: {
        className: 'ag-theme-alpine-dark',
        fullWidthRowBackgroundColor: '#c1c1ab42',
        fullWidthRowColor: '',
      },
      externalLink: {
        color: `#4183C4 !important`,
      },
    };
  } else {
    return {
      grid: {
        className: 'ag-theme-alpine',
        fullWidthRowBackgroundColor: '#00000014',
        fullWidthRowColor: 'black',
      },
      externalLink: {
        color: `#4183C4 !important`,
      },
    };
  }
};

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.backgroundColor};
    transition: all 0.11s linear;
  }
`;
