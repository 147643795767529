// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { PaddedAndRaisedSegment } from '../presentational/PaddedAndRaisedSegment';
import { FieldArrayGrid, FieldArrayGridConfigItemContext } from '../../formik-utils/FieldArrayGrid';
import {
  CREDITS_AND_BURSTS_CONFIG_ARRAY_BACKEND_NAME,
  CREDITS_AND_BURSTS_JSON_BACKEND_NAME,
  ESTIMATE_CNBC_DETAILS_FORM,
} from '../../constants';
import { CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG } from '../../configuration/credits-and-bursts';
import { EstimateContext } from '../../contexts/EstimateContext';
import { isAnythingEditedOnEstimatePageBesidesTheGivenForm } from '../views/utils';

import { CreditAndBurstsConfigurationItem } from './CreditAndBurstsConfigurationItem';

export const CreditsAndBurstsContainer = ({ readOnlyMode }) => {
  const estimate = useContext(EstimateContext);
  const { values, initialValues } = useFormikContext();
  const readOnlyModeFromProps = readOnlyMode;

  const disabled = isAnythingEditedOnEstimatePageBesidesTheGivenForm(
    values,
    initialValues,
    ESTIMATE_CNBC_DETAILS_FORM
  );

  const pathToFollowInValues = [
    CREDITS_AND_BURSTS_JSON_BACKEND_NAME,
    CREDITS_AND_BURSTS_CONFIG_ARRAY_BACKEND_NAME,
  ];

  return (
    <>
      <PaddedAndRaisedSegment disabled={disabled}>
        <FieldArrayGrid
          columnsConfiguration={CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG}
          disabled={disabled || readOnlyMode}
          gridName="Credits And Bursts"
          pathToFollowInValues={pathToFollowInValues}
        >
          {(arrayHelpers, row, isCurrentlyBeingEdited, index, allRows, readOnlyMode) => (
            <FieldArrayGridConfigItemContext.Provider
              value={{
                allRows,
                arrayHelpers,
                columnsConfiguration: CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG,
                estimate,
                index,
                isCurrentlyBeingEdited,
                pathToFollowInValues: pathToFollowInValues,
                rowValues: row,
                readOnlyMode,
              }}
            >
              <CreditAndBurstsConfigurationItem readOnlyMode={readOnlyModeFromProps} />
            </FieldArrayGridConfigItemContext.Provider>
          )}
        </FieldArrayGrid>
      </PaddedAndRaisedSegment>
    </>
  );
};
