// @flow
import React from 'react';
import { Grid } from 'semantic-ui-react';

import { EstimateAccountDetailsContainer } from '../estimate-account-details/EstimateAccountDetailsContainer';
import { EstimateCommitDetailsContainer } from '../estimate-commit-details/EstimateCommitDetailsContainer';

export const EstimateDetailsContainer = () => {
  return (
    <>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={7}>
            <EstimateAccountDetailsContainer />
          </Grid.Column>
          <Grid.Column width={9}>
            <EstimateCommitDetailsContainer />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
