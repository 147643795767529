// @flow
import { Table } from 'semantic-ui-react';
import React from 'react';

import { YearSelector } from '../../presentational/year-selector/YearSelector';

import { addAdditionalDummyMonthsIfRequired } from './utils';

export const ClusterInputTableMonthHeaders = ({
  selectedYear,
  setSelectedYear,
  totalYears,
  months,
  disabled,
}) => {
  const monthsToUse = addAdditionalDummyMonthsIfRequired(selectedYear, months);
  return (
    <Table.Header>
      <Table.Row disabled={disabled} textAlign="center">
        <Table.HeaderCell textAlign="center">
          <YearSelector
            disabled={disabled}
            onChange={(year) => setSelectedYear(year)}
            selectedYearIndex={selectedYear}
            totalYears={totalYears}
          />
        </Table.HeaderCell>
        {monthsToUse.map((month) => {
          return <Table.HeaderCell key={month.name}>{month.name}</Table.HeaderCell>;
        })}
      </Table.Row>
    </Table.Header>
  );
};
