// @flow
import React, { useCallback, useContext, useMemo } from 'react';
import { Grid } from 'semantic-ui-react';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridSelectField,
  isAnyOfDependenciesNotSet,
} from '../../../formik-utils/FieldArrayGrid';
import { DataFetcherContainer } from '../../presentational/DataFetcherContainer';
import { IS_ROW_FROZEN, RATE_CARD_BACKEND_NAME } from '../../../constants';
import { getDropdownOptions } from '../../stream-governance/utils';
import { getDimensionsColumnConfig } from '../utils';
import { useGetDependentDimensionsCombinationsQuery } from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';

import { DimensionsConfigDiscountContainer } from './DimensionsConfigDiscountContainer';

export const DependentDimensionsDropdownContainer = () => {
  const estimate = useContext(EstimateContext);
  const { columnsConfiguration, parentContext, rowValues, readOnlyMode } = useContext(
    FieldArrayGridConfigItemContext
  );

  const dimensionColsConfig = getDimensionsColumnConfig(columnsConfiguration);

  const rateCardVersion = estimate.inputs[RATE_CARD_BACKEND_NAME];
  const metricName = parentContext.metricName;

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          {
            metricName: metricName,
            rateCardVersion: rateCardVersion,
          },
        ],
        [metricName, rateCardVersion]
      )}
      dataFetchingFunction={useCallback(useGetDependentDimensionsCombinationsQuery, [])}
    >
      {(dataUniverse) => {
        if (!dataUniverse) {
          return null;
        }
        const dimensionCombinations = dataUniverse?.dimension_combinations ?? [];
        const newDimCombs = [];

        // todo:: Discuss this with BE team and ensure this is done in BE
        for (const dimComb of dimensionCombinations) {
          const temp = { ...dimComb };
          for (const key of Object.keys(temp)) {
            if (key.includes('_name')) {
              const newKey = key.replace('_name', '');
              temp[newKey] = temp[key];
            }
          }
          newDimCombs.push(temp);
        }

        return (
          <>
            {dimensionColsConfig.map((colConfig) => {
              const dropdownOptions = getDropdownOptions(
                newDimCombs,
                rowValues,
                colConfig,
                false,
                false,
                true,
                true
              );

              return (
                <>
                  <Grid.Column width={3}>
                    <FieldArrayGridSelectField
                      columnConfig={colConfig}
                      disabled={
                        readOnlyMode ||
                        isAnyOfDependenciesNotSet(colConfig, rowValues) ||
                        rowValues[IS_ROW_FROZEN]
                      }
                      key={colConfig.backendFieldName}
                      options={dropdownOptions}
                    />
                  </Grid.Column>
                </>
              );
            })}
            <Grid.Column width={2}>
              <DimensionsConfigDiscountContainer />
            </Grid.Column>
          </>
        );
      }}
    </DataFetcherContainer>
  );
};
