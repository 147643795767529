// @flow
import React from 'react';

import { TextField } from '../../../formik-utils/FormFields';
import { FLINK_POOL_DETAILS_CONFIG_MAP } from '../../../configuration/flink-pool-details';
import { FLINK_POOL_NAME_BACKEND_NAME } from '../../../constants';

export const FlinkPoolNameContainer = ({ disabled }) => {
  const { backendFieldName, displayName, icon } = FLINK_POOL_DETAILS_CONFIG_MAP.get(
    FLINK_POOL_NAME_BACKEND_NAME
  );
  return (
    <TextField
      disabled={disabled}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      icon={icon}
    />
  );
};
