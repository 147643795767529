// @flow
import React from 'react';

import { CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG_MAP } from '../../configuration/credits-and-bursts';
import { CREDITS_AND_BURSTS_CREDITS_AMOUNT_BACKEND_NAME } from '../../constants';
import { FieldArrayGridTextField } from '../../formik-utils/FieldArrayGrid';

export const CreditsAmountContainer = () => {
  const cnbcCreditsAmountConfig = CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG_MAP.get(
    CREDITS_AND_BURSTS_CREDITS_AMOUNT_BACKEND_NAME
  );

  return <FieldArrayGridTextField columnConfig={cnbcCreditsAmountConfig} />;
};
