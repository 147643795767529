// @flow
import React from 'react';

import { CLUSTER_ID } from '../../../constants';
import { ClusterEnabledContainer } from '../../cluster/cluster-details-inputs/ClusterEnabledContainer';

export const ClusterEnabledCellRenderer = (props) => {
  if (props.value == null) {
    return null;
  }
  const clusterId = props.data[CLUSTER_ID];
  return <ClusterEnabledContainer addLabel={false} checked={props.value} clusterId={clusterId} />;
};
