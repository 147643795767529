// @flow
import { boolean, string } from 'yup';

import {
  ENABLE,
  ENABLED,
  FLINK_POOL_DETAILS_FORM,
  FLINK_POOL_ENABLED_BACKEND_NAME,
  FLINK_POOL_MONTHLY_INPUTS_FORM,
  FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG,
  FLINK_POOL_NAME,
  FLINK_POOL_NAME_BACKEND_NAME,
  FLINK_POOL_PROVIDER,
  FLINK_POOL_PROVIDER_BACKEND_NAME,
  FLINK_POOL_PROVIDER_LABEL_BACKEND_NAME,
  FLINK_POOL_REGION,
  FLINK_POOL_REGION_BACKEND_NAME,
  FLINK_POOL_REGION_LABEL_BACKEND_NAME,
} from '../constants';
import { CHECKBOX_INPUT_TYPE, SELECT_INPUT_TYPE, TEXT_INPUT_TYPE } from '../formik-utils/consts';
import { clusterMonthlyDataInputTransformationFunc } from '../components/cluster/cluster-input-table/utils';
import { getMonthlyInputsValidationFuncForType } from '../components/cluster/cluster-details-inputs/kafka-monthly-inputs-validation';

import { convertConfigArrayToMap, createFormNameToFieldsMap } from './utils';

export const FLINK_POOL_DETAILS_CONFIG = [
  {
    displayName: FLINK_POOL_NAME,
    backendFieldName: FLINK_POOL_NAME_BACKEND_NAME,
    icon: null,
    validation: string().label(FLINK_POOL_NAME).required(),
    inputType: TEXT_INPUT_TYPE,
    belongingForm: FLINK_POOL_DETAILS_FORM,
  },
  {
    displayName: ENABLE,
    backendFieldName: FLINK_POOL_ENABLED_BACKEND_NAME,
    icon: null,
    validation: boolean().label(ENABLED).required(),
    inputType: CHECKBOX_INPUT_TYPE,
  },
  {
    displayName: FLINK_POOL_PROVIDER,
    backendFieldName: FLINK_POOL_PROVIDER_BACKEND_NAME,
    displayNameCol: FLINK_POOL_PROVIDER_LABEL_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: FLINK_POOL_DETAILS_FORM,
    validation: string().required().label(FLINK_POOL_PROVIDER).nullable(),
  },
  {
    displayName: FLINK_POOL_REGION,
    backendFieldName: FLINK_POOL_REGION_BACKEND_NAME,
    icon: null,
    inputType: SELECT_INPUT_TYPE,
    belongingForm: FLINK_POOL_DETAILS_FORM,
    validation: string().required().label(FLINK_POOL_REGION).nullable(),
    parentFieldsInGroup: [FLINK_POOL_PROVIDER_BACKEND_NAME],
    displayNameCol: FLINK_POOL_REGION_LABEL_BACKEND_NAME,
  },

  {
    displayName: FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG,
    backendFieldName: FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG,
    belongingForm: FLINK_POOL_MONTHLY_INPUTS_FORM,
    inputTransformationFunc: clusterMonthlyDataInputTransformationFunc,
    validation: getMonthlyInputsValidationFuncForType(FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG),
  },
];

export const FLINK_POOL_DETAILS_CONFIG_MAP = convertConfigArrayToMap(FLINK_POOL_DETAILS_CONFIG);
export const FLINK_POOL_DETAILS_FORM_NAMES_TO_FIELDS_MAP =
  createFormNameToFieldsMap(FLINK_POOL_DETAILS_CONFIG);
