// @flow
import React, { useContext } from 'react';

import { EstimateContext } from '../../contexts/EstimateContext';
import { StyledSpan } from '../../common-utils/styledComponents';

import { SecondaryTopBar } from './SecondaryTopBar';
import { getEstimateSections } from './utils';

export const StreamGovernanceBreadcrumbs = () => {
  const estimate = useContext(EstimateContext);

  return (
    <SecondaryTopBar
      sectionsToShow={[
        ...getEstimateSections(estimate),
        <StyledSpan key="streamGovernanceConfiguration">
          Stream Governance Configuration
        </StyledSpan>,
      ]}
    />
  );
};
