// @flow
import React from 'react';

import { EstimateContextProvider } from '../../contexts/EstimateContextProvider';
import { EstimateBreadcrumbs } from '../breadcrumbs/EstimateBreadcrumbs';
import { ShowLoaderIfAnyQueryIsPending } from '../presentational/ShowLoaderIfAnyQueryIsPending';
import { UserContextProvider } from '../../contexts/UserContextProvider';

import EstimateTopLevelContainerForm from './EstimateTopLevelContainerForm';

const EstimateTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <EstimateContextProvider>
          <EstimateBreadcrumbs />
          <EstimateTopLevelContainerForm />
        </EstimateContextProvider>
      </UserContextProvider>
    </>
  );
};

export default EstimateTopLevelContainer;
