// @flow
export const getFormattedOptionsFromOptionsArray = (options) => {
  const optionsToUse = [];

  for (const option of options) {
    const currOptionToPush = {
      key: option.name,
      text: option.display_name,
      value: option.name,
    };
    if (option.shouldNotBeSelectable) {
      currOptionToPush.disabled = true;
    }
    optionsToUse.push(currOptionToPush);
  }

  return optionsToUse;
};
