// @flow
import React, { useCallback, useMemo } from 'react';
import { FieldArray, Formik } from 'formik';

import { ConfirmModal } from '../presentational/modals/ConfirmModal';
import { StyledContainer } from '../../common-utils/styledComponents';
import {
  useGetEstimateSharedWithUsersInfoQuery,
  useShareEstimateMutation,
} from '../../service-definitions/estimates';
import { DataFetcherContainer } from '../presentational/DataFetcherContainer';
import { Spacer } from '../presentational/spacing/Spacer';

import { SharingConfigLevelErrorMessagesContainer } from './SharingConfigLevelErrorMessagesContainer';
import { handleShareSubmit, validationSchema } from './utils';
import { EstimateShareEmailIdsTable } from './EstimateShareEmailIdsTable';
import { AddEmailContainer } from './AddEmailContainer';

export const EstimateShareModal = ({ isOpen, setOpen, estimateName, estimateId }) => {
  const header = `Share Estimate ${estimateName}`;

  const [shareEstimate] = useShareEstimateMutation();

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId },
          {
            refetchOnMountOrArgChange: true,
            skip: !estimateId || !isOpen,
          },
        ],
        [estimateId, isOpen]
      )}
      dataFetchingFunction={useCallback(useGetEstimateSharedWithUsersInfoQuery, [])}
    >
      {(data) => {
        // todo::Move this to outside by changing all hooks to Variables - Check at other places too
        if (!isOpen) {
          return null;
        }
        const initialValues = {
          shared_with_emails: data?.shared_users ?? [],
        };

        return (
          <StyledContainer>
            <Formik
              autoComplete="off"
              initialValues={initialValues}
              onSubmit={async (values) => {
                await handleShareSubmit(values, shareEstimate, estimateId);
                setOpen(false);
              }}
              validateOnMount={true}
              validationSchema={validationSchema}
            >
              {(shareEstimateFormik) => (
                <ConfirmModal
                  body={
                    <>
                      <FieldArray
                        name="shared_with_emails"
                        render={(arrayHelpers) => {
                          return (
                            <>
                              <EstimateShareEmailIdsTable arrayHelpers={arrayHelpers} />
                              <Spacer y={40} />
                              <AddEmailContainer arrayHelpers={arrayHelpers} />
                            </>
                          );
                        }}
                      />
                      <Spacer y={20} />
                      <SharingConfigLevelErrorMessagesContainer />
                    </>
                  }
                  centered={false}
                  disabled={!shareEstimateFormik.isValid}
                  header={header}
                  isOpen={isOpen}
                  okButtonText="Share"
                  onClickHandlerForCancel={() => {
                    shareEstimateFormik.resetForm();
                    setOpen(false);
                  }}
                  onClickHandlerForOK={async () => {
                    shareEstimateFormik.submitForm();
                  }}
                />
              )}
            </Formik>
          </StyledContainer>
        );
      }}
    </DataFetcherContainer>
  );
};

// todo::Replace this grid with FieldArray grid when BE implements the Role functionality
