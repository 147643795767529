// @flow
import { string, mixed, number, object, array } from 'yup';

import {
  MAX_INPUT_VALUE_FOR_ANY_FIELD,
  STREAM_GOVERNANCE_ADVANCED_PACKAGE_NAME,
  STREAM_GOVERNANCE_CLOUD_PROVIDER_BACKEND_NAME,
  STREAM_GOVERNANCE_CLOUD_PROVIDER_LABEL_BACKEND_NAME,
  STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_BACKEND_NAME,
  STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_LABEL_BACKEND_NAME,
  STREAM_GOVERNANCE_CONFIG_ARRAY_BACKEND_NAME,
  STREAM_GOVERNANCE_ENV_BACKEND_NAME,
  STREAM_GOVERNANCE_ESSENTIALS_PACKAGE_NAME,
  STREAM_GOVERNANCE_JSON_BACKEND_NAME,
  STREAM_GOVERNANCE_NUMBER_OF_RULES_BACKEND_NAME,
  STREAM_GOVERNANCE_NUMBER_OF_SCHEMAS_BACKEND_NAME,
  STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME,
} from '../constants';
import { SELECT_INPUT_TYPE, TEXT_NUMBER_INPUT_TYPE } from '../formik-utils/consts';

import { convertConfigArrayToMap, getValidationSchemaFromColsConfig } from './utils';

export const STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG = [
  {
    backendFieldName: STREAM_GOVERNANCE_ENV_BACKEND_NAME,
    displayName: 'Environment Name',
    hidden: false,
    columnWidth: 2,
    colDefaultValue: null,
    validation: string()
      .label('Environment Name')
      .min(1)
      .max(200)
      .required()
      .matches(
        /^[a-zA-Z0-9_]+$/,
        'Only alphabets, numbers and underscores are allowed in Environment Name '
      )
      .nullable()
      .test(
        'thereAreSomeDuplicateEnvNames',
        'This environment name is already taken. Please use a different one. Note - Environment Names are case insensitive.',
        (value, { from }) => {
          const parentFormValues = from[1].value;
          const envNames = parentFormValues[STREAM_GOVERNANCE_CONFIG_ARRAY_BACKEND_NAME].map(
            (x) => x[STREAM_GOVERNANCE_ENV_BACKEND_NAME]
          );
          return new Set(envNames).size === envNames.length;
        }
      ),
  },
  {
    backendFieldName: STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME,
    hidden: false,
    displayName: 'Package Name',
    columnWidth: 2,
    colDefaultValue: null,
    type: SELECT_INPUT_TYPE,
    validation: string().required().label('Package Name').nullable(),
    enableOnlyIfSet: [STREAM_GOVERNANCE_ENV_BACKEND_NAME],
    dependentFields: [
      STREAM_GOVERNANCE_CLOUD_PROVIDER_BACKEND_NAME,
      STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_BACKEND_NAME,
    ],
  },
  {
    backendFieldName: STREAM_GOVERNANCE_CLOUD_PROVIDER_BACKEND_NAME,
    hidden: false,
    displayName: 'Cloud Provider',
    columnWidth: 2,
    colDefaultValue: null,
    type: SELECT_INPUT_TYPE,
    validation: string().required().label('Cloud Provider').nullable(),
    enableOnlyIfSet: [STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME],
    dependentFields: [STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_BACKEND_NAME],
    parentFieldsInGroup: [STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME],
    displayNameCol: STREAM_GOVERNANCE_CLOUD_PROVIDER_LABEL_BACKEND_NAME,
  },
  {
    backendFieldName: STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_BACKEND_NAME,
    hidden: false,
    displayName: 'Region',
    columnWidth: 3,
    colDefaultValue: null,
    type: SELECT_INPUT_TYPE,
    validation: string().required().label('Region').nullable(),
    enableOnlyIfSet: [STREAM_GOVERNANCE_CLOUD_PROVIDER_BACKEND_NAME],
    displayNameCol: STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_LABEL_BACKEND_NAME,
    parentFieldsInGroup: [
      STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME,
      STREAM_GOVERNANCE_CLOUD_PROVIDER_BACKEND_NAME,
    ],
  },
  {
    backendFieldName: STREAM_GOVERNANCE_NUMBER_OF_SCHEMAS_BACKEND_NAME,
    hidden: false,
    displayName: '# of Schemas',
    columnWidth: 2,
    colDefaultValue: null,
    type: TEXT_NUMBER_INPUT_TYPE,
    validation: mixed().when(STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME, {
      is: (sgPackageName) =>
        sgPackageName && sgPackageName.toUpperCase() === STREAM_GOVERNANCE_ESSENTIALS_PACKAGE_NAME,
      then: number()
        .integer()
        .label('Number Of Schemas')
        .typeError('Number Of Schemas is required!')
        .min(1)
        .max(
          20000,
          'Number of schemas must be less than or equal to 20,000 (Hard limit for schemas per environment).'
        )
        .required(),
      otherwise: mixed().notRequired(),
    }),
    enableOnlyIfSet: [STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_BACKEND_NAME],
  },
  {
    backendFieldName: STREAM_GOVERNANCE_NUMBER_OF_RULES_BACKEND_NAME,
    hidden: false,
    displayName: '# of Rules',
    columnWidth: 2,
    colDefaultValue: 0,
    type: TEXT_NUMBER_INPUT_TYPE,
    validation: mixed().when(STREAM_GOVERNANCE_PACKAGE_BACKEND_NAME, {
      is: (sgPackageName) =>
        sgPackageName && sgPackageName.toUpperCase() === STREAM_GOVERNANCE_ADVANCED_PACKAGE_NAME,
      then: number()
        .integer()
        .label('Number Of Rules')
        .typeError('Number Of Rules is required!')
        .min(0)
        .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
        .required(),
      otherwise: mixed().notRequired(),
    }),
    enableOnlyIfSet: [STREAM_GOVERNANCE_CLOUD_PROVIDER_REGION_BACKEND_NAME],
  },
];

export const STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG_MAP = convertConfigArrayToMap(
  STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG
);

export const streamGovernanceValidationSchema = object({
  [STREAM_GOVERNANCE_JSON_BACKEND_NAME]: object({
    [STREAM_GOVERNANCE_CONFIG_ARRAY_BACKEND_NAME]: array(
      object({ ...getValidationSchemaFromColsConfig(STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG) })
    )
      .notRequired()
      .default([]),
  }),
});
