// @flow
import React, { useContext } from 'react';
import { Formik } from 'formik';

import { AccordionsList } from '../presentational/accordions/AccordionsList';
import { StreamGovernanceBreadcrumbs } from '../breadcrumbs/StreamGovernanceBreadcrumbs';
import { EstimateContextProvider } from '../../contexts/EstimateContextProvider';
import {
  convertConfigArrayToFrontEndFormat,
  getAccordianPanelDetailsObject,
} from '../../common-utils/utils';
import { StyledContainer } from '../../common-utils/styledComponents';
import { StreamGovernanceContextProvider } from '../../contexts/StreamGovernanceContextProvider';
import { ShowLoaderIfAnyQueryIsPending } from '../presentational/ShowLoaderIfAnyQueryIsPending';
import { Spacer } from '../presentational/spacing/Spacer';
import { EstimateContext } from '../../contexts/EstimateContext';
import { streamGovernanceValidationSchema } from '../../configuration/stream-governance';
import { Form } from '../../formik-utils/formikSUIWrapper';
import { StreamGovernanceContext } from '../../contexts/StreamGovernanceContext';
import { UserContextProvider } from '../../contexts/UserContextProvider';

import { StreamGovernanceConfigurationInputContainer } from './inputs/StreamGovernanceConfigurationInputContainer';
import { StreamGovernanceConfigurationOutputContainer } from './summaries/StreamGovernanceConfigurationOutputContainer';
import { StreamGovernanceEnabledContainer } from './StreamGovernanceEnabledContainer';

const StreamGovernanceForm = () => {
  const estimate = useContext(EstimateContext);
  const streamGovernanceData = useContext(StreamGovernanceContext);

  let configArray = streamGovernanceData?.stream_governance_inputs?.sg_configs ?? [];
  configArray = convertConfigArrayToFrontEndFormat(configArray);

  const data = {
    stream_governance_json: { stream_governance_config_array: configArray },
    enable_stream_governance: estimate?.inputs?.stream_governance_enabled,
  };

  const accordionPanels = [
    getAccordianPanelDetailsObject(
      'Stream Governance Input',
      <StreamGovernanceConfigurationInputContainer />
    ),
    getAccordianPanelDetailsObject(
      'Stream Governance Output',
      <StreamGovernanceConfigurationOutputContainer />
    ),
  ];

  return (
    <Formik
      enableReinitialize={true}
      initialValues={data}
      onSubmit={() => {}}
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={streamGovernanceValidationSchema}
    >
      {() => (
        <Form autoComplete="off">
          <StyledContainer>
            <StreamGovernanceBreadcrumbs />
            <StreamGovernanceEnabledContainer
              checked={estimate?.inputs?.stream_governance_enabled}
            />
            <Spacer y={10} />
            <AccordionsList panels={accordionPanels} />
          </StyledContainer>
        </Form>
      )}
    </Formik>
  );
};
export const StreamGovernanceTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <EstimateContextProvider>
          <StreamGovernanceContextProvider>
            <StreamGovernanceForm />
          </StreamGovernanceContextProvider>
        </EstimateContextProvider>
      </UserContextProvider>
    </>
  );
};
