// @flow
import { object, array, number } from 'yup';

import {
  CREDITS_AND_BURSTS_BURSTS_AMOUNT_BACKEND_NAME,
  CREDITS_AND_BURSTS_CONFIG_ARRAY_BACKEND_NAME,
  CREDITS_AND_BURSTS_CREDITS_AMOUNT_BACKEND_NAME,
  CREDITS_AND_BURSTS_JSON_BACKEND_NAME,
  CREDITS_AND_BURSTS_YEAR_NUMBER_BACKEND_NAME,
  CREDITS_AND_BURSTS_MAX_CONFIGURABLE_LIMIT,
} from '../constants';
import {
  convertConfigArrayToFrontEndFormat,
  getTotalCommitmentDollarsDiscountedForTheGivenYear,
} from '../common-utils/utils';
import { SELECT_INPUT_TYPE, TEXT_NUMBER_INPUT_TYPE } from '../formik-utils/consts';

import { convertConfigArrayToMap } from './utils';

export const cnbcValidationSchemaFunc = (estimate) => {
  const totalYears = Number(estimate?.deal_duration?.deal_duration_years ?? 0);

  return object({
    [CREDITS_AND_BURSTS_CONFIG_ARRAY_BACKEND_NAME]: array(
      object({
        [CREDITS_AND_BURSTS_YEAR_NUMBER_BACKEND_NAME]: number()
          .label('Year Number')
          .typeError('Year Number is required!')
          .min(1, 'Value should be greater than or equal to 1')
          .max(
            totalYears,
            `This year is outside of the range of the estimate (  >=1 and <= ${totalYears} ). Please delete this one or choose a year within the range! `
          )
          .required()
          .test(
            'thereAreSomeDuplicateYears',
            'This year is already configured. Please use a different one or delete this one.',
            (value, { from }) => {
              const parentFormValues = from[1].value;
              const years = parentFormValues[CREDITS_AND_BURSTS_CONFIG_ARRAY_BACKEND_NAME].map(
                (x) => x[CREDITS_AND_BURSTS_YEAR_NUMBER_BACKEND_NAME]
              );
              return new Set(years).size === years.length;
            }
          ),

        [CREDITS_AND_BURSTS_CREDITS_AMOUNT_BACKEND_NAME]: number()
          .integer()
          .label('Credits Amount')
          .typeError('Credits Amount is required!')
          .min(0)
          .max(
            CREDITS_AND_BURSTS_MAX_CONFIGURABLE_LIMIT,
            'Cannot add more than 1 billion of credits!'
          )
          .required()
          .test(
            'testCreditsLessThanDiscountedCommitAmountForTheYear',
            'The credit amount cannot be greater than the discounted commit amount for this year!',
            function (value) {
              const yearNumber = this.parent[CREDITS_AND_BURSTS_YEAR_NUMBER_BACKEND_NAME];

              const commitmentDollarsDiscountedForTheGivenYear =
                getTotalCommitmentDollarsDiscountedForTheGivenYear(estimate, yearNumber);

              return value <= commitmentDollarsDiscountedForTheGivenYear;
            }
          ),

        [CREDITS_AND_BURSTS_BURSTS_AMOUNT_BACKEND_NAME]: number()
          .integer()
          .label('Bursts Amount')
          .typeError('Bursts Amount is required!')
          .min(0)
          .max(
            CREDITS_AND_BURSTS_MAX_CONFIGURABLE_LIMIT,
            'Cannot add more than 1 billion of bursts!'
          )
          .required(),
      })
    )
      .notRequired()
      .default([]),
  });
};

export const CREDITS_AND_BURSTS_JSON_CONFIG = {
  backendFieldName: CREDITS_AND_BURSTS_JSON_BACKEND_NAME,
  inputTransformationFunc: (x) => {
    // todo:: Get these as numbers from the backend
    let newRows = x.cnbc_configs.map((elem) => ({
      ...elem,
      credit_amount: Number(elem.credit_amount),
      burst_amount: Number(elem.burst_amount),
    }));
    newRows = convertConfigArrayToFrontEndFormat(newRows);
    return { cnbc_configs: newRows };
  },
  validation: cnbcValidationSchemaFunc,
};

export const CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG = [
  {
    backendFieldName: CREDITS_AND_BURSTS_YEAR_NUMBER_BACKEND_NAME,
    displayName: 'Year',
    hidden: false,
    columnWidth: 2,
    colDefaultValue: null,
    type: SELECT_INPUT_TYPE,
  },
  {
    backendFieldName: CREDITS_AND_BURSTS_CREDITS_AMOUNT_BACKEND_NAME,
    hidden: false,
    displayName: 'Credits',
    columnWidth: 2,
    colDefaultValue: 0,
    icon: 'dollar',
    type: TEXT_NUMBER_INPUT_TYPE,
  },
  {
    backendFieldName: CREDITS_AND_BURSTS_BURSTS_AMOUNT_BACKEND_NAME,
    hidden: false,
    displayName: 'Burst Capacity',
    columnWidth: 2,
    colDefaultValue: 0,
    icon: 'dollar',
    type: TEXT_NUMBER_INPUT_TYPE,
  },
];

export const CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG_MAP = convertConfigArrayToMap(
  CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG
);
