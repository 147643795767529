// @flow
import { Divider, Grid } from 'semantic-ui-react';
import React from 'react';

import { StyledBreadcrumbs, StyledContainer } from '../../common-utils/styledComponents';

import { LastUpdatedMessage } from './LastUpdatedMessage';

export const SecondaryTopBar = ({ sectionsToShow }) => {
  return (
    <>
      <StyledContainer>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column textAlign="left">
              <StyledBreadcrumbs sections={sectionsToShow} />
            </Grid.Column>
            <Grid.Column textAlign="right">
              <LastUpdatedMessage />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
      </StyledContainer>
    </>
  );
};
