// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridUpdateButton,
} from '../../../formik-utils/FieldArrayGrid';
import {
  useCreateCustomDiscountsMetricsLevelDiscountsMutation,
  useUpdateCustomDiscountsMetricsLevelDiscountsMutation,
} from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';
import {
  DEFAULT_VALUE_FOR_DB_ROW_ID,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_MLD_ID_BACKEND_NAME,
  RATE_CARD_BACKEND_NAME,
  ROW_ID,
} from '../../../constants';

export const DimensionsConfigUpdateButtonContainer = () => {
  const { estimateId } = useParams();
  const estimate = useContext(EstimateContext);

  const [updateCustomDiscountsMetricsLevelDiscounts] =
    useUpdateCustomDiscountsMetricsLevelDiscountsMutation();

  const [createCustomDiscountsMetricsLevelDiscounts] =
    useCreateCustomDiscountsMetricsLevelDiscountsMutation();

  const { columnsConfiguration, rowValues } = useContext(FieldArrayGridConfigItemContext);

  const payload = {
    estimate_id: estimateId,
    estimate_version: estimate?.inputs?.estimate_version,
    ...rowValues,
    metric_name: rowValues.metricName,
    discount: Number(rowValues.discount),
    rate_card_version: estimate.inputs[RATE_CARD_BACKEND_NAME],
    id: rowValues[ROW_ID] === DEFAULT_VALUE_FOR_DB_ROW_ID ? '-1' : rowValues[ROW_ID],
    metricsDiscountId: rowValues[ROW_ID] === DEFAULT_VALUE_FOR_DB_ROW_ID ? '-1' : rowValues[ROW_ID],
  };

  const updateFuncParams = {
    estimateId,
    metricsDiscountId:
      rowValues[ROW_ID] === DEFAULT_VALUE_FOR_DB_ROW_ID
        ? null
        : rowValues[METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_MLD_ID_BACKEND_NAME],
    payload,
  };

  return (
    <FieldArrayGridUpdateButton
      columnsConfig={columnsConfiguration}
      errorMessage="There was an error in updating the discount configuration, please contact #cloud-commitment-estimator channel!"
      updateFunc={
        rowValues[ROW_ID] === DEFAULT_VALUE_FOR_DB_ROW_ID
          ? createCustomDiscountsMetricsLevelDiscounts
          : updateCustomDiscountsMetricsLevelDiscounts
      }
      updateFuncParams={updateFuncParams}
    />
  );
};
