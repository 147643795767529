// @flow

import React from 'react';
import { Icon } from 'semantic-ui-react';

import { StyledButton } from '../../../common-utils/styledComponents';

export const DeleteButton = ({ disabled, onClick, floated = 'right', size = 'mini' }) => (
  <StyledButton
    compact={true}
    data-testid="delete-button"
    disabled={disabled}
    floated={floated}
    icon={<Icon id="trash_alternate_outline" name="trash alternate outline" />}
    negative={true}
    onClick={onClick}
    size={size}
    type="button"
  />
);
