// @flow
import React, { useContext } from 'react';

import {
  HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
  STREAM_GOVERNANCE,
  STREAM_GOVERNANCE_SUMMARY,
  STREAM_GOVERNANCE_SUMMARY_GRID_ENABLED,
  STREAM_GOVERNANCE_SUMMARY_GRID_NAME,
} from '../../../constants';
import { DataGrid } from '../grid-utils/DataGrid';
import { CurrencyZeroDPRenderer } from '../grid-utils/renderers';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { DEFAULT_COLUMN_DEFINITION_FOR_SIMPLIFIED_GRID } from '../grid-utils/constants';
import { StreamGovernanceEnabledContainer } from '../../stream-governance/StreamGovernanceEnabledContainer';

import {
  STREAM_GOVERNANCE_SUMMARY_GRID_ENABLED_BACKEND_NAME,
  STREAM_GOVERNANCE_SUMMARY_GRID_TOTAL_SPEND_AT_LIST,
  STREAM_GOVERNANCE_SUMMARY_GRID_TOTAL_SPEND_AT_LIST_BACKEND_NAME,
  STREAM_GOVERNANCE_SUMMARY_GRID_TOTAL_SPEND_DISCOUNTED,
  STREAM_GOVERNANCE_SUMMARY_GRID_TOTAL_SPEND_DISCOUNTED_BACKEND_NAME,
} from './consts';
import { StreamGovernanceLinkRenderer } from './StreamGovernanceLinkRenderer';

export const EnabledCellRenderer = (props) => {
  if (props.value == null) {
    return null;
  }
  // Make this common list cluster enabled/disabled
  return <StreamGovernanceEnabledContainer addLabel={false} checked={props.value} />;
};

export const StreamGovernanceSummaryGridSegment = ({ disabled, Header }) => {
  const estimate = useContext(EstimateContext);

  const columnDefs = [
    {
      field: STREAM_GOVERNANCE_SUMMARY_GRID_NAME,
      cellRenderer: StreamGovernanceLinkRenderer,
    },
    {
      field: STREAM_GOVERNANCE_SUMMARY_GRID_ENABLED,
      cellRenderer: EnabledCellRenderer,
      backEndFieldName: STREAM_GOVERNANCE_SUMMARY_GRID_ENABLED_BACKEND_NAME,
    },
    {
      field: STREAM_GOVERNANCE_SUMMARY_GRID_TOTAL_SPEND_AT_LIST,
      backEndFieldName: STREAM_GOVERNANCE_SUMMARY_GRID_TOTAL_SPEND_AT_LIST_BACKEND_NAME,
      cellRenderer: CurrencyZeroDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
    {
      field: STREAM_GOVERNANCE_SUMMARY_GRID_TOTAL_SPEND_DISCOUNTED,
      backEndFieldName: STREAM_GOVERNANCE_SUMMARY_GRID_TOTAL_SPEND_DISCOUNTED_BACKEND_NAME,
      cellRenderer: CurrencyZeroDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
  ];

  const streamGovernanceSummary = estimate.stream_governance;
  const streamGovernanceSummaryFormatted = { ...streamGovernanceSummary };
  streamGovernanceSummaryFormatted.Name = STREAM_GOVERNANCE;

  const inputDataSource = [streamGovernanceSummaryFormatted];

  const defaultColDef = {
    ...DEFAULT_COLUMN_DEFINITION_FOR_SIMPLIFIED_GRID,
    minWidth: 300,
    maxWidth: 450,
  };

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      {Header}
      <DataGrid
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        inputDataSource={inputDataSource}
        label={STREAM_GOVERNANCE_SUMMARY}
      />
    </PaddedAndRaisedSegment>
  );
};
