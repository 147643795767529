// @flow

import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { EstimateContext } from '../../../contexts/EstimateContext';
import {
  FLINK_POOL_ENABLED,
  FLINK_POOL_ENABLED_BACKEND_NAME_FOR_SUMMARY,
  FLINK_POOL_ID,
  FLINK_POOL_ID_BACKEND_NAME,
  FLINK_POOL_NAME,
  FLINK_POOL_NAME_BACKEND_NAME,
  FLINK_POOL_PROVIDER,
  FLINK_POOL_PROVIDER_BACKEND_NAME,
  FLINK_POOL_REGION,
  FLINK_POOL_REGION_BACKEND_NAME,
  FLINK_POOL_TOTAL_SPEND_AT_LIST,
  FLINK_POOL_TOTAL_SPEND_AT_LIST_BACKEND_NAME,
  FLINK_POOL_TOTAL_SPEND_DISCOUNTED,
  FLINK_POOL_TOTAL_SPEND_DISCOUNTED_BACKEND_NAME,
  HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
} from '../../../constants';
import { ENABLED_DISABLED_FILTER_PARAMS } from '../grid-utils/constants';
import { CurrencyZeroDPRenderer } from '../grid-utils/renderers';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { PrimaryButton } from '../../presentational/buttons/PrimaryButton';
import { DataGrid } from '../grid-utils/DataGrid';
import { isAnythingEditedOnEstimatePage } from '../utils';

import { FlinkPoolNameRenderer } from './FlinkPoolNameRenderer';
import { FlinkPoolDeleteRenderer } from './FlinkPoolDeleteRenderer';
import { FlinkPoolCopyRenderer } from './FlinkPoolCopyRenderer';
import { FlinkPoolEnabledCellRenderer } from './FlinkPoolEnabledCellRenderer';
import { FlinkPoolAddModal } from './FlinkPoolAddModal';

export const FlinkSummaryContainer = () => {
  const { estimateId } = useParams();
  const [isFlinkPoolAddModalOpen, setFlinkPoolAddModalOpen] = useState(false);
  const { values, initialValues, isValid } = useFormikContext();
  const estimate = useContext(EstimateContext);

  const columnDefs = [
    {
      field: FLINK_POOL_ID,
      backEndFieldName: FLINK_POOL_ID_BACKEND_NAME,
      hide: true,
    },
    {
      field: FLINK_POOL_NAME,
      cellRenderer: FlinkPoolNameRenderer,
      backEndFieldName: FLINK_POOL_NAME_BACKEND_NAME,
    },
    {
      field: FLINK_POOL_ENABLED,
      filter: true,
      filterParams: ENABLED_DISABLED_FILTER_PARAMS,
      cellRenderer: FlinkPoolEnabledCellRenderer,
      backEndFieldName: FLINK_POOL_ENABLED_BACKEND_NAME_FOR_SUMMARY,
      // maxWidth: 140,
    },
    {
      field: FLINK_POOL_PROVIDER,
      backEndFieldName: FLINK_POOL_PROVIDER_BACKEND_NAME,
    },
    {
      field: FLINK_POOL_REGION,
      backEndFieldName: FLINK_POOL_REGION_BACKEND_NAME,
      tooltipField: FLINK_POOL_REGION,
      // maxWidth: 180,
    },
    {
      field: FLINK_POOL_TOTAL_SPEND_AT_LIST,
      backEndFieldName: FLINK_POOL_TOTAL_SPEND_AT_LIST_BACKEND_NAME,
      cellRenderer: CurrencyZeroDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
    {
      field: FLINK_POOL_TOTAL_SPEND_DISCOUNTED,
      backEndFieldName: FLINK_POOL_TOTAL_SPEND_DISCOUNTED_BACKEND_NAME,
      cellRenderer: CurrencyZeroDPRenderer,
      headerSuffix: HEADER_SUFFIX_FOR_DOLLAR_COLUMNS,
    },
    {
      field: '',
      filter: false,
      cellRenderer: FlinkPoolCopyRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
    {
      field: '',
      filter: false,
      cellRenderer: FlinkPoolDeleteRenderer,
      suppressSizeToFit: true,
      maxWidth: 60,
      sortable: false,
    },
  ];

  const disabled = isAnythingEditedOnEstimatePage(values, initialValues);

  const inputDataSource = estimate?.flink_pool_summary ?? [];
  const areAnyFlinkPoolsConfigured = inputDataSource.length > 0;

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <PrimaryButton
        disabled={disabled || !isValid || !estimateId}
        onClick={() => {
          setFlinkPoolAddModalOpen(true);
        }}
        size="mini"
        text="Add Flink Pool"
      />

      <FlinkPoolAddModal isOpen={isFlinkPoolAddModalOpen} setOpen={setFlinkPoolAddModalOpen} />

      {areAnyFlinkPoolsConfigured && (
        <DataGrid
          columnDefs={columnDefs}
          downloadAllCols={false}
          inputDataSource={inputDataSource}
          label="Flink Pools ( FP ) Summary"
          sizeColumnsToFitInitially={true}
          tooltipShowDelay={0}
        />
      )}
    </PaddedAndRaisedSegment>
  );
};
