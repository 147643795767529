// @flow
import { useParams } from 'react-router-dom';
import React, { useCallback, useMemo } from 'react';

import { DataFetcherContainer } from '../components/presentational/DataFetcherContainer';
import { useGetStreamGovernanceCompleteDataQuery } from '../service-definitions/estimates';

import { StreamGovernanceContext } from './StreamGovernanceContext';

export const StreamGovernanceContextProvider = ({ children }) => {
  const { estimateId } = useParams();

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          {
            estimateId: estimateId,
          },
          {
            refetchOnMountOrArgChange: true,
            skip: !estimateId,
          },
        ],
        [estimateId]
      )}
      dataFetchingFunction={useCallback(useGetStreamGovernanceCompleteDataQuery, [])}
    >
      {(data) => {
        return (
          <StreamGovernanceContext.Provider value={data}>
            {children}
          </StreamGovernanceContext.Provider>
        );
      }}
    </DataFetcherContainer>
  );
};
