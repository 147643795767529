// @flow
import React from 'react';

import { FLINK_POOL_ID } from '../../../constants';
import { FlinkPoolEnabledContainer } from '../../flink-pool-top-level/flink-pool-details-inputs-section/FlinkPoolEnabledContainer';

export const FlinkPoolEnabledCellRenderer = (props) => {
  if (props.value == null) {
    return null;
  }
  const flinkPoolId = props.data[FLINK_POOL_ID];
  return (
    <FlinkPoolEnabledContainer addLabel={false} checked={props.value} flinkPoolId={flinkPoolId} />
  );
};
