// @flow
import React, { useContext } from 'react';

import { EstimateContext } from '../../contexts/EstimateContext';
import { ClusterContext } from '../../contexts/ClusterContext';
import { StyledSpan } from '../../common-utils/styledComponents';

import { SecondaryTopBar } from './SecondaryTopBar';
import { getEstimateSections } from './utils';

export const ClusterBreadcrumbs = () => {
  const estimate = useContext(EstimateContext);
  const cluster = useContext(ClusterContext);
  const clusterName = cluster?.cluster_name;

  return (
    <SecondaryTopBar
      sectionsToShow={[
        ...getEstimateSections(estimate),
        <StyledSpan key="cluster"> Cluster </StyledSpan>,
        <StyledSpan key="clusterName"> {clusterName} </StyledSpan>,
      ]}
    />
  );
};

// todo:: Upgrade react-router-dom to 6.11.2 and use "useMatches" for breadcrumbs
