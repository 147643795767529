// @flow
import React from 'react';

import { StyledDimmer } from '../../common-utils/styledComponents';

import { InlineLoader } from './InlineLoader';

export const LoadingIndicator = () => (
  <StyledDimmer active={true} page={true} size="massive">
    <InlineLoader />
  </StyledDimmer>
);
