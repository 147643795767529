// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridDeleteButton,
} from '../../../formik-utils/FieldArrayGrid';
import { useDeleteStreamGovernanceConfigurationMutation } from '../../../service-definitions/estimates';
import { STREAM_GOVERNANCE_DB_ROW_ID_BACKEND_NAME } from '../../../constants';
import { EstimateContext } from '../../../contexts/EstimateContext';

export const DeleteButtonContainer = () => {
  const [deleteSGConfiguration] = useDeleteStreamGovernanceConfigurationMutation();
  const { estimateId } = useParams();
  const estimate = useContext(EstimateContext);
  const { rowValues } = useContext(FieldArrayGridConfigItemContext);

  const deleteFuncParams = {
    payload: {
      estimate_version: estimate?.inputs?.estimate_version,
    },
    estimateId: estimateId,
    streamGovernanceId: rowValues[STREAM_GOVERNANCE_DB_ROW_ID_BACKEND_NAME],
  };

  return (
    <FieldArrayGridDeleteButton
      deleteFunc={deleteSGConfiguration}
      deleteFuncParams={deleteFuncParams}
    />
  );
};
