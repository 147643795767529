// @flow
import React from 'react';

import { CLUSTER_ID, NAME } from '../../../constants';
import { DeleteRenderer } from '../grid-utils/renderers';

import ClusterDeleteWarningModal from './ClusterDeleteWarningModal';

export const ClusterDeleteRenderer = ({ data }) => {
  return (
    <DeleteRenderer>
      {(isDeleteModalOpen, setDeleteModalOpen) => {
        return (
          <ClusterDeleteWarningModal
            clusterId={data[CLUSTER_ID]}
            clusterName={data[NAME]}
            isOpen={isDeleteModalOpen}
            setOpen={setDeleteModalOpen}
          />
        );
      }}
    </DeleteRenderer>
  );
};
