// @flow
import { number, object } from 'yup';

import {
  BASIC_CLUSTER_TYPE,
  CLUSTER_CLIENT_CONNECTION_ATTEMPTS_LIMITS_MAP,
  CLUSTER_CLIENT_REQUESTS_LIMITS_MAP,
  CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_CONNECTED_CLIENTS_COUNT_LIMITS_MAP,
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_READ_THROUGHPUT_LIMITS_MAP,
  CLUSTER_TYPE_BACKEND_NAME,
  CLUSTER_WRITE_THROUGHPUT_LIMITS_MAP,
  FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG,
  MAX_INPUT_VALUE_FOR_ANY_FIELD,
  RATE_CARD_BACKEND_NAME,
  RATE_CARD_VERSION_4_11_2024,
  STANDARD_CLUSTER_TYPE,
} from '../../../constants';
import { getMonthName } from '../cluster-input-table/utils';

const getWriteThroughputValidationSchema = () => {
  return number()
    .required()
    .min(0)
    .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
    .label('This field')
    .typeError('Required Field')
    .test(
      'max',
      'Write Throughput must be within the limits',
      (value, { createError, path, from }) => {
        const clusterInputs = from[3].value;
        const clusterType = clusterInputs[CLUSTER_TYPE_BACKEND_NAME];
        const writeThroughputLimit = CLUSTER_WRITE_THROUGHPUT_LIMITS_MAP.get(clusterType);
        const isError = value > writeThroughputLimit;
        if (isError) {
          return createError({
            message: `Write Throughput must be less than or equal to ${writeThroughputLimit}`,
            path: path,
          });
        }
        return true;
      }
    );
};
const getReadThroughputValidationSchema = () => {
  return number()
    .required()
    .min(0)
    .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
    .label('This field')
    .typeError('Required Field')
    .test(
      'max',
      'Read Throughput must be within the limits',
      (value, { createError, path, from }) => {
        const clusterInputs = from[3].value;
        const clusterType = clusterInputs[CLUSTER_TYPE_BACKEND_NAME];
        const readThroughputLimit = CLUSTER_READ_THROUGHPUT_LIMITS_MAP.get(clusterType);
        const isError = value > readThroughputLimit;
        if (isError) {
          return createError({
            message: `Read Throughput must be less than or equal to ${readThroughputLimit}`,
            path: path,
          });
        }
        return true;
      }
    );
};

const getConnectedClientCountValidationSchema = (estimate) => {
  return number()
    .integer()
    .required()
    .min(0)
    .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
    .label('This field')
    .typeError('Required Field')
    .test(
      'max',
      'Connected Clients Count must be within the limits',
      (value, { createError, path, from }) => {
        const clusterInputs = from[3].value;
        const clusterType = clusterInputs[CLUSTER_TYPE_BACKEND_NAME];
        let connectedClientCountLimit = CLUSTER_CONNECTED_CLIENTS_COUNT_LIMITS_MAP.get(clusterType);

        const rateCardVersion = estimate.inputs[RATE_CARD_BACKEND_NAME];
        // todo::Needs to be read from BE after the BE is ready with the change where the limits are passed from BE
        if (rateCardVersion === RATE_CARD_VERSION_4_11_2024) {
          if (clusterType === BASIC_CLUSTER_TYPE) {
            connectedClientCountLimit = 1000;
          }
          if (clusterType === STANDARD_CLUSTER_TYPE) {
            connectedClientCountLimit = 10000;
          }
        }

        const isError = value > connectedClientCountLimit;
        if (isError) {
          return createError({
            message: `Connected Clients Count must be less than or equal to ${connectedClientCountLimit}`,
            path: path,
          });
        }
        return true;
      }
    );
};

const getClientConnectionAttemptsPerSecSchema = (estimate) => {
  return number()
    .integer()
    .required()
    .min(0)
    .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
    .label('This field')
    .typeError('Required Field')
    .test(
      'max',
      'Client Connection Attempts must be within the limits',
      (value, { createError, path, from }) => {
        const clusterInputs = from[3].value;
        const clusterType = clusterInputs[CLUSTER_TYPE_BACKEND_NAME];
        let connectionAttemptsLimit =
          CLUSTER_CLIENT_CONNECTION_ATTEMPTS_LIMITS_MAP.get(clusterType);
        const rateCardVersion = estimate.inputs[RATE_CARD_BACKEND_NAME];
        if (rateCardVersion === RATE_CARD_VERSION_4_11_2024) {
          if (clusterType === BASIC_CLUSTER_TYPE) {
            connectionAttemptsLimit = 250;
          }
          if (clusterType === STANDARD_CLUSTER_TYPE) {
            connectionAttemptsLimit = 500;
          }
        }

        const isError = value > connectionAttemptsLimit;
        if (isError) {
          return createError({
            message: `Client Connection Attempts Count must be less than or equal to ${connectionAttemptsLimit}`,
            path: path,
          });
        }
        return true;
      }
    );
};

const getClientRequestsPerSecSchema = (estimate) => {
  return number()
    .integer()
    .required()
    .min(0)
    .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
    .label('This field')
    .typeError('Required Field')
    .test(
      'max',
      'Client Requests must be within the limits',
      (value, { createError, path, from }) => {
        const clusterInputs = from[3].value;
        const clusterType = clusterInputs[CLUSTER_TYPE_BACKEND_NAME];
        let clientRequestsLimit = CLUSTER_CLIENT_REQUESTS_LIMITS_MAP.get(clusterType);
        const rateCardVersion = estimate.inputs[RATE_CARD_BACKEND_NAME];
        if (rateCardVersion === RATE_CARD_VERSION_4_11_2024) {
          if (clusterType === BASIC_CLUSTER_TYPE) {
            clientRequestsLimit = 5000;
          }
          if (clusterType === STANDARD_CLUSTER_TYPE) {
            clientRequestsLimit = 15000;
          }
        }
        const isError = value > clientRequestsLimit;
        if (isError) {
          return createError({
            message: `Client Requests must be less than or equal to ${clientRequestsLimit}`,
            path: path,
          });
        }
        return true;
      }
    );
};

const getKafkaValidationSchema = (estimate) =>
  object({
    write_throughput_peak_mbps: number()
      .min(0)
      .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
      .label('This field')
      .typeError('Required Field')
      .required(),
    read_throughput_peak_mbps: number()
      .min(0)
      .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
      .label('This field')
      .typeError('Required Field'),
    write_throughput_avg_mbps: getWriteThroughputValidationSchema(),
    read_throughput_avg_mbps: getReadThroughputValidationSchema(),
    connected_client_count: getConnectedClientCountValidationSchema(estimate),
    client_connection_attempts_per_sec: getClientConnectionAttemptsPerSecSchema(estimate),
    client_requests_per_sec: getClientRequestsPerSecSchema(estimate),
  });

const ksqlDBValidationSchema = object({
  csu_count: number()
    .integer()
    .min(0)
    .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
    .label('This field')
    .typeError('Required Field'),
});

const flinkPoolValidationSchema = object({
  cfu_count: number().integer().min(0).max(50).label('This field').typeError('Required Field'),
});

const clusterLinkingValidationSchema = object({
  link_count: number()
    .integer()
    .min(0)
    .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
    .label('This field')
    .typeError('Required Field'),
  avg_link_read_mbps: number()
    .min(0)
    .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
    .label('This field')
    .typeError('Required Field'),
  avg_link_write_mbps: number()
    .min(0)
    .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
    .label('This field')
    .typeError('Required Field'),
});

const connectorsValidationSchema = object({
  task_count: number()
    .integer()
    .min(0)
    .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
    .label('This field')
    .typeError('Required Field'),
  throughput_average_mbps: number()
    .min(0)
    .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
    .label('This field')
    .typeError('Required Field'),
});

const getSchemaForMonth = (inputType, estimate) => {
  switch (inputType) {
    case CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG:
      return getKafkaValidationSchema(estimate);
    case CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG:
      return ksqlDBValidationSchema;
    case CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG:
      return clusterLinkingValidationSchema;
    case CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG:
      return connectorsValidationSchema;
    case FLINK_POOL_MONTHLY_INPUTS_JSON_CONFIG:
      return flinkPoolValidationSchema;
    default:
      throw 'unknown input type provided';
  }
};

export const getMonthlyInputsValidationFuncForType =
  (inputType) =>
  ({ estimate }) => {
    const numberOfMonths = estimate.deal_duration.deal_duration_months;
    const schemaObject = {};
    for (let i = 1; i <= numberOfMonths; i++) {
      schemaObject[getMonthName(i)] = getSchemaForMonth(inputType, estimate);
    }
    return object({ months: object(schemaObject) });
  };
