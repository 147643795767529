// @flow
import React from 'react';

import { StyledHeader } from '../../../common-utils/styledComponents';

export const BaseHeading = ({ as, text, inline }) => (
  <StyledHeader as={as} style={inline ? { display: 'inline' } : null}>
    {text}
  </StyledHeader>
);
