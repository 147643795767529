// @flow
import React, { useState } from 'react';
import { useFormikContext } from 'formik';

import { DateField } from '../../formik-utils/FormFields';
import { DEAL_START_DATE_BACKEND_NAME, DEAL_END_DATE_BACKEND_NAME } from '../../constants';
import { ESTIMATE_COMMIT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-commit-details';

import EstimateDateChangeWarningModal from './EstimateDateChangeWarningModal';

export const DealStartDateContainer = () => {
  const { values, initialValues } = useFormikContext();
  const [isDateChangeWarningModalOpen, setDateChangeWarningModalOpen] = useState(false);

  const dealStartDateConfig = ESTIMATE_COMMIT_DETAILS_CONFIG_MAP.get(DEAL_START_DATE_BACKEND_NAME);

  return (
    <>
      <EstimateDateChangeWarningModal
        isOpen={isDateChangeWarningModalOpen}
        setOpen={setDateChangeWarningModalOpen}
      />

      <DateField
        fieldDisplayName={dealStartDateConfig.displayName}
        fieldName={dealStartDateConfig.backendFieldName}
        maxDate={values[DEAL_END_DATE_BACKEND_NAME]}
        onChange={(event, value) => {
          if (value !== initialValues[DEAL_START_DATE_BACKEND_NAME]) {
            setDateChangeWarningModalOpen(true);
          }
        }}
      />
    </>
  );
};
