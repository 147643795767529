// @flow
import React from 'react';

import { StyledDropdownWithAccessibility } from '../../../common-utils/styledComponents';
import { getDropdownOptionsFromArray } from '../../../common-utils/utils';
import { getFormattedOptionsFromOptionsArray } from '../../../formik-utils/utils';
import { TCO_AVAILABLE_PROVIDERS_LIST } from '../../../constants';

export const TCOFilterComponent = ({ handleChange }) => {
  const dropdownOptionsForTCO = getDropdownOptionsFromArray(TCO_AVAILABLE_PROVIDERS_LIST);
  const formattedOptions = getFormattedOptionsFromOptionsArray(dropdownOptionsForTCO);

  return (
    <StyledDropdownWithAccessibility
      className="icon"
      data-testid="tco-filter"
      fluid={true}
      icon="filter"
      labeled={true}
      multiple={true}
      onChange={handleChange}
      options={formattedOptions}
      text="Filter..."
    />
  );
};
