// @flow
import React from 'react';

import { CLUSTER_FOLLOWER_FETCH_BACKEND_NAME } from '../../../constants';
import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';
import { CheckBoxField } from '../../../formik-utils/FormFields';

export const ClusterFollowerFetchContainer = ({ disabled }) => {
  const clusterFollowerFetchConfig = CLUSTER_DETAILS_CONFIG_MAP.get(
    CLUSTER_FOLLOWER_FETCH_BACKEND_NAME
  );
  return (
    <>
      <CheckBoxField
        disabled={disabled}
        displayCheckBoxBelowLabel={true}
        fieldDisplayName={clusterFollowerFetchConfig.displayName}
        fieldName={clusterFollowerFetchConfig.backendFieldName}
        icon={clusterFollowerFetchConfig.icon}
      />
    </>
  );
};
