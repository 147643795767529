// @flow
import React from 'react';
import { getIn, useFormikContext } from 'formik';

import { TabsContainer } from '../../presentational/TabsContainer';
import {
  CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG,
} from '../../../constants';
import { WarningIcon } from '../../presentational/icons/WarningIcon';
import { Tooltip } from '../../presentational/Tooltip';

import { KafkaUsageContainer } from './KafkaUsageContainer';
import { KSQLDBUsageContainer } from './KSQLDBUsageContainer';
import { ClusterLinkingUsageContainer } from './ClusterLinkingUsageContainer';
import { ConnectorsTopLevelContainer } from './ConnectorsTopLevelContainer';

export const ClusterUsageTabsContainer = () => {
  const { errors } = useFormikContext();

  const areAnyErrorsPresentInThroughputTab =
    getIn(errors, CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG) != null;

  const areAnyErrorsPresentInConnectorsTab =
    getIn(errors, CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG) != null;

  const areAnyErrorsPresentInKSQLDBTab =
    getIn(errors, CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG) != null;

  const areAnyErrorsPresentInClusterLinkingTab =
    getIn(errors, CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG) != null;

  const tabPanes = [
    {
      menuItem: {
        key: 'Throughput',
        icon: areAnyErrorsPresentInThroughputTab ? <WarningIcon /> : null,
        content: <>Throughput</>,
      },
      render: () => <KafkaUsageContainer />,
    },
    {
      menuItem: {
        key: 'Connectors',
        icon: areAnyErrorsPresentInConnectorsTab ? <WarningIcon /> : null,
        content: <>Connectors</>,
      },
      render: () => <ConnectorsTopLevelContainer />,
    },
    {
      menuItem: {
        key: 'ksqlDB',
        icon: areAnyErrorsPresentInKSQLDBTab ? <WarningIcon /> : null,
        content: <>ksqlDB</>,
      },
      render: () => <KSQLDBUsageContainer />,
    },
    {
      menuItem: {
        key: 'Cluster Linking',
        icon: areAnyErrorsPresentInClusterLinkingTab ? <WarningIcon /> : null,
        content: (
          <>
            Cluster Linking
            <Tooltip
              link="https://confluentinc.atlassian.net/wiki/spaces/CS/pages/3292040846/Commit+Estimator+Cluster+Linking+Configuration"
              text="Configuration Guide"
            />
          </>
        ),
      },
      render: () => <ClusterLinkingUsageContainer />,
    },
  ];
  return <TabsContainer tabPanes={tabPanes} />;
};
