// @flow
import React from 'react';

import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';
import { CLUSTER_NAME_BACKEND_NAME } from '../../../constants';
import { TextField } from '../../../formik-utils/FormFields';

export const ClusterNameContainer = ({ disabled }) => {
  const clusterNameConfig = CLUSTER_DETAILS_CONFIG_MAP.get(CLUSTER_NAME_BACKEND_NAME);
  return (
    <TextField
      disabled={disabled}
      fieldDisplayName={clusterNameConfig.displayName}
      fieldName={clusterNameConfig.backendFieldName}
      icon={clusterNameConfig.icon}
    />
  );
};
