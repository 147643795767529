// @flow
import { useParams } from 'react-router-dom';
import { getIn, useFormikContext } from 'formik';
import React, { useContext } from 'react';

import {
  CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME,
  CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME_ORIGINAL,
  CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_IS_FROZEN_BACKEND_NAME,
} from '../../../constants';
import { UpdateFormButton } from '../../presentational/buttons/UpdateFormButton';
import { useUpdateEstimateMutation } from '../../../service-definitions/estimates';
import { toastError } from '../../presentational/notifications/utils';
import { EstimateContext } from '../../../contexts/EstimateContext';

const getIfGlobalDiscountShouldBeDisabled = (readOnlyMode, formik) => {
  //  if we are in RO Mode, return true to disable it unconditionally
  if (readOnlyMode) {
    return true;
  }

  const currentValue = getIn(formik.values, CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME);

  // if the field has not been set yet, return true to disable it
  if (currentValue == null) {
    return true;
  }

  const initialValue = getIn(formik.initialValues, CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME);
  const isTheValueUnChanged = Number(currentValue) === Number(initialValue);

  // if the field has not been touched yet, return true to disable it
  if (isTheValueUnChanged) {
    return true;
  }

  // if the formik form is invalid, return true to disable it
  return !formik.isValid;
};

const handleGlobalDiscountUpdate = async (formik, estimateId, updateEstimateDetails, estimate) => {
  const globalDiscount = getIn(formik.values, CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME);

  const payload = {
    global_discount: globalDiscount,
    estimate_version: estimate?.inputs?.estimate_version,
  };

  const { error } = await updateEstimateDetails({
    estimateId,
    payload,
  });

  if (error) {
    toastError(
      error,
      'There was an error in updating the discount, please contact #cloud-commitment-estimator channel!'
    );
  } else {
    await formik.setFieldValue(CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_IS_FROZEN_BACKEND_NAME, true);
    await formik.setFieldValue(
      CUSTOM_DISCOUNTS_GLOBAL_DISCOUNT_BACKEND_NAME_ORIGINAL,
      globalDiscount
    );
  }
};

export const GlobalDiscountsUpdateButtonContainer = ({ readOnlyMode }) => {
  const { estimateId } = useParams();
  const estimate = useContext(EstimateContext);
  const formik = useFormikContext();
  const [updateEstimateDetails] = useUpdateEstimateMutation();

  return (
    <UpdateFormButton
      disabled={getIfGlobalDiscountShouldBeDisabled(readOnlyMode, formik)}
      onClick={() =>
        handleGlobalDiscountUpdate(formik, estimateId, updateEstimateDetails, estimate)
      }
    />
  );
};
