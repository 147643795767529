// @flow
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ClusterConfigurationsSummaryContainer } from '../../views/cluster-detailed-configuration/ClusterConfigurationsSummaryContainer';
import { DataFetcherContainer } from '../../presentational/DataFetcherContainer';
import { useGetClusterConfigurationSummaryQuery } from '../../../service-definitions/estimates';

export const ClusterConfigurationSummaryContainer = ({ disabled }) => {
  const { estimateId, clusterId } = useParams();
  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId, clusterId: clusterId },
          {
            skip: !estimateId || !clusterId,
          },
        ],
        [clusterId, estimateId]
      )}
      dataFetchingFunction={useCallback(useGetClusterConfigurationSummaryQuery, [])}
    >
      {(data) => {
        const clusterConfigsArray =
          data && data.cluster_input_summary ? [data.cluster_input_summary] : [];
        return (
          <ClusterConfigurationsSummaryContainer
            addLinkToClusterName={false}
            clusterConfigsArray={clusterConfigsArray}
            disabled={disabled}
          />
        );
      }}
    </DataFetcherContainer>
  );
};
