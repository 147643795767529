// @flow
import React from 'react';
import { getIn, useFormikContext } from 'formik';

import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { ClusterInputTable } from '../cluster-input-table/ClusterInputTable';
import { isAnyOfTheClusterRelatedFormsEdited } from '../cluster-details-inputs/utils';

const getAllKeysSentByBE = (values) => {
  const calculations = getIn(values, 'calculations');
  return Object.keys(calculations?.months?.['Month 1'] ?? {});
};

export const ClusterCalculationsContainer = () => {
  const { values, initialValues } = useFormikContext();
  const disabled = isAnyOfTheClusterRelatedFormsEdited(values, initialValues);
  const allKeysSentByBE = getAllKeysSentByBE(values);

  const rowsConfig = [
    {
      backendName: 'ckus',
      displayName: 'CKUs',
      showOrHideFunc: () => allKeysSentByBE.includes('ckus'),
    },
    {
      backendName: 'eckus',
      displayName: 'ECKUs',
      showOrHideFunc: () => allKeysSentByBE.includes('eckus'),
    },
    {
      backendName: 'storage_minus_replication_gbs',
      displayName: 'Storage Excl. Replication (GB)',
    },
    {
      backendName: 'storage_including_replication_gbs',
      displayName: 'Storage After Replication (GB)',
    },
  ];

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <ClusterInputTable
        isReadOnly={true}
        keyNameInValues="calculations"
        rowsConfig={rowsConfig}
        tableName="Cluster Calculations"
      />
    </PaddedAndRaisedSegment>
  );
};
