// @flow
import React from 'react';
import { useFormikContext } from 'formik';

import { ESTIMATE_COMMIT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-commit-details';
import {
  MARKETPLACE_BACKEND_NAME,
  MARKETPLACE_NONE,
  PAYMENT_SCHEDULE_ARREARS_BACKEND_NAME,
  PAYMENT_SCHEDULE_ARREARS_DISPLAY_NAME,
  PAYMENT_SCHEDULE_BACKEND_NAME,
  PAYMENT_SCHEDULE_PREPAID_BACKEND_NAME,
  PAYMENT_SCHEDULE_PREPAID_DISPLAY_NAME,
} from '../../constants';
import { SelectField } from '../../formik-utils/FormFields';

const getPaymentScheduleOptions = (values) => {
  const paymentScheduleOptions = [
    {
      name: PAYMENT_SCHEDULE_PREPAID_BACKEND_NAME,
      display_name: PAYMENT_SCHEDULE_PREPAID_DISPLAY_NAME,
    },
  ];

  if (values[MARKETPLACE_BACKEND_NAME].toLowerCase() === MARKETPLACE_NONE.toLowerCase()) {
    paymentScheduleOptions.push({
      name: PAYMENT_SCHEDULE_ARREARS_BACKEND_NAME,
      display_name: PAYMENT_SCHEDULE_ARREARS_DISPLAY_NAME,
    });
  }

  return paymentScheduleOptions;
};

export const PaymentScheduleContainer = () => {
  const { values } = useFormikContext();

  const paymentScheduleConfig = ESTIMATE_COMMIT_DETAILS_CONFIG_MAP.get(
    PAYMENT_SCHEDULE_BACKEND_NAME
  );

  return (
    <SelectField
      fieldDisplayName={paymentScheduleConfig.displayName}
      fieldName={paymentScheduleConfig.backendFieldName}
      options={getPaymentScheduleOptions(values)}
    />
  );
};
