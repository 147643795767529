// @flow
import React from 'react';

import { ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-account-details';
import { ESTIMATE_NAME_BACKEND_NAME } from '../../constants';
import { TextField } from '../../formik-utils/FormFields';

export const EstimateNameContainer = ({ disableOnFormErrors }) => {
  const estimateNameConfig = ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP.get(ESTIMATE_NAME_BACKEND_NAME);
  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      fieldDisplayName={estimateNameConfig.displayName}
      fieldName={estimateNameConfig.backendFieldName}
      icon={estimateNameConfig.icon}
    />
  );
};
