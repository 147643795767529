// @flow
import React, { useState } from 'react';

import {
  CopyRenderer,
  CurrencyZeroDPRenderer,
  DeleteRenderer,
  LinkRenderer,
  ShareRenderer,
  TimeStampRenderer,
} from '../../../views/grid-utils/renderers';
import { DataGrid } from '../../../views/grid-utils/DataGrid';
import { EstimateAddModal } from '../../EstimateAddModal';
import { useCopyEstimateMutation } from '../../../../service-definitions/estimates';
import { highlightMatchingPortionInTextAndReturnSpan } from '../utils';
import { Spacer } from '../../../presentational/spacing/Spacer';
import { toastError, toastSuccess } from '../../../presentational/notifications/utils';
import {
  ESTIMATE_NAME_BACKEND_NAME,
  IS_SFDC_INTEGRATION_LIVE,
  SFDC_ACCOUNT_ID_BACKEND_NAME,
  SFDC_ACCOUNT_NAME_BACKEND_NAME,
  SFDC_OPPORTUNITY_ID_BACKEND_NAME,
  SFDC_OPPORTUNITY_NAME_BACKEND_NAME,
  SFDC_ORGANISATION_NAME_BACKEND_NAME,
  SFDC_ORGANIZATION_ID_BACKEND_NAME,
  SFDC_QUOTE_NAME_BACKEND_NAME,
} from '../../../../constants';
import { EstimateShareModal } from '../../../estimate-sharing/EstimateShareModal';

import { AddNewEstimateButton } from './AddEstimateButton';
import { EstimateDeleteWarningModal } from './EstimateDeleteWarningModal';

const getNewEstimateDefaultValues = (account, organization, opportunity) => {
  const defaults = {
    [ESTIMATE_NAME_BACKEND_NAME]: '',
    [SFDC_ACCOUNT_NAME_BACKEND_NAME]: account.accountName,
    [SFDC_ACCOUNT_ID_BACKEND_NAME]: account.accountId,
    [SFDC_ORGANISATION_NAME_BACKEND_NAME]: organization.orgName,
    [SFDC_ORGANIZATION_ID_BACKEND_NAME]: organization.orgId,
    [SFDC_OPPORTUNITY_ID_BACKEND_NAME]: opportunity.oppId,
    [SFDC_OPPORTUNITY_NAME_BACKEND_NAME]: opportunity.oppName,
  };

  if (IS_SFDC_INTEGRATION_LIVE) {
    defaults[SFDC_QUOTE_NAME_BACKEND_NAME] = '';
  }

  return defaults;
};

export const EstimatesInsideOpportunityGrid = ({
  account,
  organization,
  opportunity,
  searchTerm,
}) => {
  const [isEstimateAddModalOpen, setEstimateAddModalOpen] = useState(false);

  const inputDataSource = opportunity.estimates;

  // todo::Move these components to their standalone components
  const EstimateNameRenderer = ({ data }) => {
    return (
      <LinkRenderer
        pathName={`/estimate/${data['Estimate Id']}`}
        text={highlightMatchingPortionInTextAndReturnSpan(data.Estimate, searchTerm)}
      />
    );
  };

  const EstimateCopyRenderer = ({ data }) => {
    const [copyEstimate] = useCopyEstimateMutation();
    return (
      <CopyRenderer
        onClickHandler={async () => {
          const { error } = await copyEstimate({
            estimateId: data['Estimate Id'],
          });

          if (error) {
            toastError(error);
          } else {
            toastSuccess('Estimate copy was successful!');
          }
        }}
      />
    );
  };

  const EstimateShareRenderer = ({ data }) => {
    return (
      <ShareRenderer>
        {(isShareModalOpen, setShareModalOpen) => {
          return (
            <EstimateShareModal
              estimateId={data['Estimate Id']}
              estimateName={data.Estimate}
              isOpen={isShareModalOpen}
              setOpen={setShareModalOpen}
            />
          );
        }}
      </ShareRenderer>
    );
  };

  const EstimateDeleteRenderer = ({ data }) => {
    return (
      <DeleteRenderer>
        {(isDeleteModalOpen, setDeleteModalOpen) => {
          return (
            <EstimateDeleteWarningModal
              estimateId={data['Estimate Id']}
              estimateName={data.Estimate}
              estimateVersion={data['Estimate Version']}
              isOpen={isDeleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
            />
          );
        }}
      </DeleteRenderer>
    );
  };

  const columnDefs = [
    {
      field: 'Estimate Id',
      backEndFieldName: 'id',
      hide: true,
    },
    {
      field: 'Estimate Version',
      backEndFieldName: 'version',
      hide: true,
    },
    {
      field: 'Estimate',
      backEndFieldName: 'name',
      cellRenderer: EstimateNameRenderer,
      minWidth: 250,
    },
    {
      field: 'Total Commitment',
      backEndFieldName: 'total_commitment_dollars_discounted',
      cellRenderer: CurrencyZeroDPRenderer,
    },
    {
      field: 'Deal Start Date',
      backEndFieldName: 'start_date',
    },
    {
      field: 'Deal End Date',
      backEndFieldName: 'end_date',
    },
    {
      field: 'Created At',
      backEndFieldName: 'created_at',
      cellRenderer: TimeStampRenderer,
    },
    {
      field: 'Updated At',
      backEndFieldName: 'updated_at',
      cellRenderer: TimeStampRenderer,
    },
    {
      field: '',
      suppressSizeToFit: true,
      maxWidth: 50,
      cellRenderer: EstimateShareRenderer,
      filter: false,
      sortable: false,
    },
    {
      field: '',
      suppressSizeToFit: true,
      maxWidth: 50,
      cellRenderer: EstimateCopyRenderer,
      filter: false,
      sortable: false,
    },
    {
      field: '',
      suppressSizeToFit: true,
      maxWidth: 50,
      cellRenderer: EstimateDeleteRenderer,
      filter: false,
      sortable: false,
    },
  ];

  return (
    <>
      <DataGrid
        columnDefs={columnDefs}
        downloadAllCols={false}
        inputDataSource={inputDataSource}
        label="Estimates Summary"
      />

      <Spacer y={5} />

      <AddNewEstimateButton
        onClick={(e) => {
          e.stopPropagation();
          setEstimateAddModalOpen(true);
        }}
      />

      <EstimateAddModal
        defaultValues={getNewEstimateDefaultValues(account, organization, opportunity)}
        isOpen={isEstimateAddModalOpen}
        onClose={() => setEstimateAddModalOpen(false)}
      />
    </>
  );
};
