// @flow
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const getInvalidatesTagsFunc = (arrayOfTagsToInvalidate) => (result, error) => {
  // Don't invalidate the cache if a update conflict occurs (HTTP 409).
  // This prevents the latest `version` being fetched via a requery and
  // stops further updates until the browser is refreshed by the user
  if (error?.status === 409) {
    return [];
  }
  return arrayOfTagsToInvalidate;
};

export const estimatesApi = createApi({
  reducerPath: 'estimatesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${window?._env_?.API_URL}/api/v1/`,
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem('okta-token-storage')).accessToken.accessToken;
      headers.set('Authorization', token ? `Bearer ${token}` : '');
      return headers;
    },
  }),
  tagTypes: ['Estimates'],
  endpoints: (builder) => {
    return {
      // **************** Estimate Related ***************** ///

      // Get Estimate
      getEstimate: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `estimates/${estimateId}`,
          };
        },
        providesTags: ['Estimates'],
      }),

      // Delete Estimate
      deleteEstimate: builder.mutation({
        query: ({ estimateId, body }) => {
          return {
            url: `estimates/${estimateId}`,
            method: 'DELETE',
            body,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // Copy Estimate
      copyEstimate: builder.mutation({
        query: ({ estimateId }) => {
          return {
            url: `estimates/${estimateId}/copy`,
            method: 'POST',
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // Add Estimate
      addEstimate: builder.mutation({
        query: (body) => {
          return {
            url: 'estimates',
            method: 'POST',
            body,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // Get EstimateAccountDetails
      getEstimateAccountDetails: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `estimate-account-details/${estimateId}`,
          };
        },
        providesTags: ['Estimates'],
      }),

      // Update EstimateAccountDetails
      updateEstimate: builder.mutation({
        query: ({ estimateId, payload }) => {
          return {
            url: `estimates/${estimateId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // Upload To SFDC
      uploadDataToSFDC: builder.mutation({
        query: ({ estimateId }) => {
          return {
            url: `estimates/${estimateId}/upload?destination=SFDC`,
            method: 'POST',
          };
        },
      }),

      // Estimate Commit Details
      getEstimateCommitDetails: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `estimate-commit-details/${estimateId}`,
          };
        },
        providesTags: ['Estimates'],
      }),

      updateEstimateCommitDetails: builder.mutation({
        query: ({ estimateId, payload }) => {
          return {
            url: `estimate-commit-details/${estimateId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      getAllDataRequiredByEstimateCommitDetailsTab: builder.query({
        async queryFn(args, queryApi, extraOptions, fetchWithBQ) {
          const estimateId = 21;
          const estimateCommitDetails = await fetchWithBQ(`estimate-commit-details/${estimateId}`);
          if (estimateCommitDetails.error) {
            return { error: estimateCommitDetails.error };
          }

          const rcVersionsData = await fetchWithBQ('get-available-rate-card-versions');
          if (rcVersionsData.error) {
            return { error: rcVersionsData.error };
          }

          const supportTiers = await fetchWithBQ('get-available-support-tiers');
          if (supportTiers.error) {
            return { error: supportTiers.error };
          }

          const marketPlaces = await fetchWithBQ('get-available-marketplaces');
          if (marketPlaces.error) {
            return { error: marketPlaces.error };
          }

          const dealTypes = await fetchWithBQ('get-available-deal-types');
          if (dealTypes.error) {
            return { error: dealTypes.error };
          }

          return {
            data: {
              estimateCommitDetails: estimateCommitDetails.data,
              rcVersions: rcVersionsData.data,
              supportTiers: supportTiers.data,
              marketPlaces: marketPlaces.data,
              dealTypes: dealTypes.data,
              isUserAdmin: true,
              maxDiscount: 10,
              estimateAnnualSpend: 100,
            },
          };
        },
      }),

      // ************************ Clusters Related *******************************//

      getCluster: builder.query({
        query: ({ estimateId, clusterId }) => {
          return {
            url: `views/estimates/${estimateId}/clusters/${clusterId}/summary?query=ALL,CLUSTER_LINKING,CONNECTORS,KSQLDB`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getClusterDedicatedSurveyInputs: builder.query({
        query: ({ estimateId, clusterId }) => {
          return {
            url: `views/estimates/${estimateId}/clusters/${clusterId}/summary?query=DEDICATED_CLUSTER_SURVEY`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getClusterInputCombinationsData: builder.query({
        query: ({ rateCardVersion }) => {
          return {
            url: `rate-card-versions/${rateCardVersion}/cluster-input-combinations`,
          };
        },
        providesTags: ['Estimates'],
      }),

      // Add Cluster
      addCluster: builder.mutation({
        query: ({ estimateId, payload }) => {
          return {
            url: `estimates/${estimateId}/clusters`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // Copy Cluster
      copyCluster: builder.mutation({
        query: ({ estimateId, clusterId, estimateVersion }) => {
          return {
            url: `estimates/${estimateId}/clusters/${clusterId}/copy`,
            method: 'POST',
            body: { estimate_version: estimateVersion },
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // Update Cluster
      updateCluster: builder.mutation({
        query: ({ estimateId, clusterId, payload }) => {
          return {
            url: `estimates/${estimateId}/clusters/${clusterId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // Enable Cluster
      enableCluster: builder.mutation({
        query: ({ estimateId, clusterId, payload }) => {
          return {
            url: `estimates/${estimateId}/clusters/${clusterId}/enable`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // Disable Cluster
      disableCluster: builder.mutation({
        query: ({ estimateId, clusterId, payload }) => {
          return {
            url: `estimates/${estimateId}/clusters/${clusterId}/disable`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // Delete Cluster
      deleteCluster: builder.mutation({
        query: ({ estimateId, clusterId, payload }) => {
          return {
            url: `estimates/${estimateId}/clusters/${clusterId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      getRegions: builder.query({
        query: ({ provider }) => {
          return {
            url: `providers/${provider}/regions`,
          };
        },
      }),

      getConnectors: builder.query({
        query: ({ rateCardVersion }) => {
          return {
            url: `connectortypes?rate_card=${rateCardVersion}`,
          };
        },
      }),

      deleteConnector: builder.mutation({
        query: ({ estimateId, clusterId, connectorId, payload }) => {
          return {
            url: `estimates/${estimateId}/clusters/${clusterId}/connectors/${connectorId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      getClusterMonthlySpendSummary: builder.query({
        query: ({ estimateId, clusterId }) => {
          return {
            url: `views/estimates/${estimateId}/clusters/${clusterId}/summary?query=CLUSTER_MONTHLY_SPEND`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getClusterConfigurationSummary: builder.query({
        query: ({ estimateId, clusterId }) => {
          return {
            url: `views/estimates/${estimateId}/clusters/${clusterId}/summary?query=CLUSTER_INPUTS_SUMMARY`,
          };
        },
        providesTags: ['Estimates'],
      }),

      // *********************** Credits & Bursts Related *********************** //

      getCreditsAndBurstsConfiguration: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `estimates/${estimateId}/creditsandbursts`,
          };
        },
        providesTags: ['Estimates', 'CNBC'],
      }),

      createCreditsAndBurstsConfiguration: builder.mutation({
        query: ({ estimateId, payload }) => {
          return {
            url: `estimates/${estimateId}/creditsandbursts`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates', 'CNBC']),
      }),

      updateCreditsAndBurstsConfiguration: builder.mutation({
        query: ({ estimateId, payload, cnbcRowId }) => {
          return {
            url: `estimates/${estimateId}/creditsandbursts/${cnbcRowId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates', 'CNBC']),
      }),

      deleteCreditsAndBurstsConfiguration: builder.mutation({
        query: ({ payload, estimateId, cnbcRowId }) => {
          return {
            url: `estimates/${estimateId}/creditsandbursts/${cnbcRowId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates', 'CNBC']),
      }),

      // *********************** Stream Governance Related *********************** //

      getStreamGovernancePackagesAlongWithSupportedProvidersAndRegionsList: builder.query({
        query: () => {
          return {
            url: `stream-governance-packages`,
          };
        },
      }),

      updateStreamGovernanceConfiguration: builder.mutation({
        query: ({ estimateId, streamGovernanceId, payload }) => {
          return {
            url: `estimates/${estimateId}/streamgovernance/${streamGovernanceId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      createStreamGovernanceConfiguration: builder.mutation({
        query: ({ estimateId, payload }) => {
          return {
            url: `estimates/${estimateId}/streamgovernance`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      deleteStreamGovernanceConfiguration: builder.mutation({
        query: ({ payload, estimateId, streamGovernanceId }) => {
          return {
            url: `estimates/${estimateId}/streamgovernance/${streamGovernanceId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      toggleStreamGovernanceConfiguration: builder.mutation({
        query: ({ payload, estimateId }) => {
          return {
            url: `estimates/${estimateId}/toggle-stream-governance-config`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      getStreamGovernanceCompleteData: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `/views/estimates/${estimateId}/stream_governance/summary?query=INPUTS,PACKAGES,TOTAL_SPEND_PER_ENV`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getStreamGovernanceConfiguration: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `estimates/${estimateId}/stream-governance-config`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getStreamGovernancePerEnvironmentSpendSummary: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `estimates/${estimateId}/stream-governance-per-env-spend-summary`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getStreamGovernanceMonthlySpendSummary: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `/views/estimates/${estimateId}/stream_governance/summary?query=ANNUAL_SUMMARIES`,
          };
        },
        providesTags: ['Estimates'],
      }),

      // *********************** Stream Governance Related *********************** //

      // ********************* MLD Related *********************** //

      updateCustomDiscountsGlobalDiscount: builder.mutation({
        query: ({ estimateId, rateCardVersion, payload }) => {
          return {
            url: `estimates/${estimateId}/rate-card-versions/${rateCardVersion}/metrics-discounts`,
            method: 'PATCH',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      getCustomizableMetricsList: builder.query({
        query: ({ rateCardVersion }) => {
          return {
            url: `custom-discounts/metrics?rate-card-version=${rateCardVersion}`,
          };
        },
      }),

      deleteCustomDiscountsMetricsLevelDiscount: builder.mutation({
        query: ({ estimateId, payload }) => {
          return {
            url: `estimates/${estimateId}/custom-discounts/metrics-discount`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      deleteCustomDiscountsConfigLevelDiscount: builder.mutation({
        query: ({ estimateId, metricsDiscountId, payload }) => {
          return {
            url: `estimates/${estimateId}/custom-discounts/metrics-discount/${metricsDiscountId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      getDependentDimensions: builder.query({
        query: ({ metricName, rateCardVersion }) => {
          return {
            url: `custom-discounts/metrics/${metricName}/dependent-dimensions?rate-card-version=${rateCardVersion}`,
          };
        },
      }),

      getDependentDimensionsCombinations: builder.query({
        query: ({ metricName, rateCardVersion }) => {
          return {
            url: `custom-discounts/metrics/${metricName}/dimension-combinations?rate-card-version=${rateCardVersion}`,
          };
        },
      }),

      createCustomDiscountsMetricsLevelDiscounts: builder.mutation({
        query: ({ estimateId, payload }) => {
          return {
            url: `estimates/${estimateId}/custom-discounts/metrics-discount`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      updateCustomDiscountsMetricsLevelDiscounts: builder.mutation({
        query: ({ estimateId, metricsDiscountId, payload }) => {
          return {
            url: `estimates/${estimateId}/custom-discounts/metrics-discount/${metricsDiscountId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // /////////////// **************** VIEWS Related ***************** ////////////////

      getEstimateLevelView: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `views/estimates/${estimateId}?query=ALL,ANNUAL_SUMMARIES,FLINK_POOL_SUMMARY`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getSFDCFieldsInfo: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `estimates/${estimateId}/sfdc-fields-info`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getEstimateAnnualSummaryInfo: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `/views/estimates/${estimateId}?query=ANNUAL_SUMMARIES,DEAL_DURATION`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getEstimateClustersConfigurationSummaryInfo: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `/views/estimates/${estimateId}?query=CLUSTER_INPUTS_SUMMARY`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getEstimateCloudMonthlySpendSummary: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `/views/estimates/${estimateId}?query=CLOUD_MONTHLY_SPEND`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getEstimateClusterMonthlySpendCumulativeSpendSummary: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `estimates/${estimateId}/estimate-cluster-monthly-cumulative-consumption`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getEstimateCustomViewData: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `/views/estimates/${estimateId}?query=CUSTOM_VIEWS`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getEstimateTCOViewData: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `/views/estimates/${estimateId}?query=TCO`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getClusterTCOViewData: builder.query({
        query: ({ estimateId, clusterId }) => {
          return {
            url: `/views/estimates/${estimateId}/clusters/${clusterId}/summary?query=TCO`,
          };
        },
        providesTags: ['Estimates'],
      }),

      // **************** Estimate Hierarchy Related ***************** ///
      getEstimatesHierarchy: builder.query({
        query: ({ query, limit }) => {
          return {
            url: 'views/estimatehierarchy',
            params: { query, limit },
          };
        },
        providesTags: ['Estimates'],
      }),

      // **************** User Related ***************** ///
      getUserInfo: builder.query({
        query: () => {
          return {
            url: `users/profile`,
          };
        },
        providesTags: ['User'],
      }),

      // ************** Estimate Level Sharing/Requesting ********** //
      getEstimateSharedWithUsersInfo: builder.query({
        query: ({ estimateId }) => {
          return {
            url: `views/estimates/${estimateId}?query=SHARED_USERS`,
          };
        },
        providesTags: ['Estimates_Shared_Users'],
      }),

      shareEstimate: builder.mutation({
        query: ({ estimateId, body }) => {
          return {
            url: `estimates/${estimateId}/share`,
            method: 'POST',
            body,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates', 'Estimates_Shared_Users']),
      }),

      requestEstimate: builder.mutation({
        query: ({ estimateId }) => {
          return {
            url: `estimates/${estimateId}/request`,
            method: 'POST',
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // Flink Pool Related

      // Add Flink Pool
      addFlinkPool: builder.mutation({
        query: ({ estimateId, payload }) => {
          return {
            url: `estimates/${estimateId}/flink-pools`,
            method: 'POST',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // Copy Flink Pool
      copyFlinkPool: builder.mutation({
        query: ({ estimateId, flinkPoolId, estimateVersion }) => {
          return {
            url: `estimates/${estimateId}/flink-pools/${flinkPoolId}/copy`,
            method: 'POST',
            body: { estimate_version: estimateVersion },
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // Delete Flink Pool
      deleteFlinkPool: builder.mutation({
        query: ({ estimateId, flinkPoolId, payload }) => {
          return {
            url: `estimates/${estimateId}/flink-pools/${flinkPoolId}`,
            method: 'DELETE',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      getFlinkPool: builder.query({
        query: ({ estimateId, flinkPoolId }) => {
          return {
            url: `views/estimates/${estimateId}/flink-pools/${flinkPoolId}/summary?query=ALL`,
          };
        },
        providesTags: ['Estimates'],
      }),

      // Update FlinkPool
      updateFlinkPool: builder.mutation({
        query: ({ estimateId, flinkPoolId, payload }) => {
          return {
            url: `estimates/${estimateId}/flink-pools/${flinkPoolId}`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      getFlinkPoolMonthlySpendSummary: builder.query({
        query: ({ estimateId, flinkPoolId }) => {
          return {
            url: `views/estimates/${estimateId}/flink-pools/${flinkPoolId}/summary?query=FLINK_POOL_MONTHLY_SPEND`,
          };
        },
        providesTags: ['Estimates'],
      }),

      getFlinkPoolConfigurationSummary: builder.query({
        query: ({ estimateId, flinkPoolId }) => {
          return {
            url: `views/estimates/${estimateId}/flink-pools/${flinkPoolId}/summary?query=FLINK_POOL_INPUTS_SUMMARY`,
          };
        },
        providesTags: ['Estimates'],
      }),

      // Enable Flink Pool
      enableFlinkPool: builder.mutation({
        query: ({ estimateId, flinkPoolId, payload }) => {
          return {
            url: `estimates/${estimateId}/flink-pools/${flinkPoolId}/enable`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // Disable Flink Pool
      disableFlinkPool: builder.mutation({
        query: ({ estimateId, flinkPoolId, payload }) => {
          return {
            url: `estimates/${estimateId}/flink-pools/${flinkPoolId}/disable`,
            method: 'PUT',
            body: payload,
          };
        },
        invalidatesTags: getInvalidatesTagsFunc(['Estimates']),
      }),

      // End of config
    };
  },
});

export const {
  // Estimate Related
  useAddEstimateMutation,
  useGetEstimateQuery,
  useGetEstimateAccountDetailsQuery,
  useUpdateEstimateMutation,
  useUploadDataToSFDCMutation,
  useGetEstimateCommitDetailsQuery,
  useCopyEstimateMutation,
  useDeleteEstimateMutation,
  useUpdateEstimateCommitDetailsMutation,
  useGetAllDataRequiredByEstimateCommitDetailsTabQuery,

  // Clusters Related
  useGetClusterQuery,
  useGetClusterDedicatedSurveyInputsQuery,
  useGetClusterInputCombinationsDataQuery,
  useAddClusterMutation,
  useUpdateClusterMutation,
  useEnableClusterMutation,
  useDisableClusterMutation,
  useDeleteClusterMutation,
  useDeleteConnectorMutation,
  useCopyClusterMutation,
  useGetRegionsQuery,
  useGetConnectorsQuery,
  useGetClusterMonthlySpendSummaryQuery,
  useGetClusterConfigurationSummaryQuery,

  // Credits & Bursts Related
  useGetCreditsAndBurstsConfigurationQuery,
  useCreateCreditsAndBurstsConfigurationMutation,
  useUpdateCreditsAndBurstsConfigurationMutation,
  useDeleteCreditsAndBurstsConfigurationMutation,

  // Stream Governance Related
  useGetStreamGovernanceCompleteDataQuery,
  useGetStreamGovernancePackagesAlongWithSupportedProvidersAndRegionsListQuery,
  useUpdateStreamGovernanceConfigurationMutation,
  useCreateStreamGovernanceConfigurationMutation,
  useDeleteStreamGovernanceConfigurationMutation,
  useToggleStreamGovernanceConfigurationMutation,
  useGetStreamGovernanceConfigurationQuery,
  useGetStreamGovernancePerEnvironmentSpendSummaryQuery,
  useGetStreamGovernanceMonthlySpendSummaryQuery,

  // Custom Discounts Related
  useUpdateCustomDiscountsGlobalDiscountMutation,
  useGetCustomizableMetricsListQuery,
  useGetDependentDimensionsQuery,
  useGetDependentDimensionsCombinationsQuery,
  useUpdateCustomDiscountsMetricsLevelDiscountsMutation,
  useCreateCustomDiscountsMetricsLevelDiscountsMutation,
  useDeleteCustomDiscountsMetricsLevelDiscountMutation,
  useDeleteCustomDiscountsConfigLevelDiscountMutation,

  // Views
  useGetEstimateLevelViewQuery,
  useGetSFDCFieldsInfoQuery,
  useGetEstimateAnnualSummaryInfoQuery,
  useGetEstimateClustersConfigurationSummaryInfoQuery,
  useGetEstimateCloudMonthlySpendSummaryQuery,
  useGetEstimateClusterMonthlySpendCumulativeSpendSummaryQuery,
  useGetEstimateCustomViewDataQuery,
  useGetEstimateTCOViewDataQuery,
  useGetClusterTCOViewDataQuery,

  // Estimate Hierarchy Related
  useGetEstimatesHierarchyQuery,

  // User Related
  useGetUserInfoQuery,

  // ************** Estimate Level Sharing/Requesting ********** //
  useGetEstimateSharedWithUsersInfoQuery,
  useShareEstimateMutation,
  useRequestEstimateMutation,

  // Flink Pool Related
  useCopyFlinkPoolMutation,
  useDeleteFlinkPoolMutation,
  useGetFlinkPoolQuery,
  useUpdateFlinkPoolMutation,
  useGetFlinkPoolMonthlySpendSummaryQuery,
  useGetFlinkPoolConfigurationSummaryQuery,
  useEnableFlinkPoolMutation,
  useDisableFlinkPoolMutation,
  useAddFlinkPoolMutation,
} = estimatesApi;

// todo::Think about tweaking the keepUnusedDataFor time in RTK Query and also think about cache expiration time
// To test it out, follow the steps:
// Go to 10/1 RC, check the network tab for MLd and then shift to 8/1
