// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { getIfCustomDiscountsEnabled, getIfCustomerViewIsEnabled } from '../../common-utils/utils';
import { GLOBAL_DISCOUNT_APPLIED_BACKEND_NAME } from '../../constants';
import { WarningMessageContainer } from '../presentational/messages/WarningMessageContainer';
import { EstimateContext } from '../../contexts/EstimateContext';
import { InfoMessageContainer } from '../presentational/messages/InfoMessageContainer';

export const DiscountWarningContainer = () => {
  const estimateData = useContext(EstimateContext);
  const { values, errors } = useFormikContext();

  const estimateAnnualSpend = Number(estimateData?.avg_spend ?? 0);
  const maxDiscount = Number(estimateData?.max_discount ?? 0);
  const estimateAnnualSpendThresholdForContactingDSMTeam = Number(
    estimateData?.annual_commit_threshold_for_discount ?? 0
  );

  const currentDiscount = values[GLOBAL_DISCOUNT_APPLIED_BACKEND_NAME];
  const isDiscountApprovalRequired = currentDiscount > maxDiscount && currentDiscount <= 100;

  const shouldShowWarning =
    !getIfCustomerViewIsEnabled(estimateData?.inputs) &&
    !getIfCustomDiscountsEnabled(estimateData?.inputs);

  const isSpendThresholdForDSMReached =
    estimateAnnualSpend > estimateAnnualSpendThresholdForContactingDSMTeam;

  return (
    <>
      {isDiscountApprovalRequired && shouldShowWarning && (
        <WarningMessageContainer
          message={
            isSpendThresholdForDSMReached
              ? 'Contact Deal Strategy Management team'
              : `Please work with Deals Desk for approval at this discount level. \n Maximum Discount: ${maxDiscount}%`
          }
        />
      )}
      {!isDiscountApprovalRequired &&
        !errors[GLOBAL_DISCOUNT_APPLIED_BACKEND_NAME] &&
        shouldShowWarning && (
          <InfoMessageContainer
            message={
              isSpendThresholdForDSMReached
                ? 'For discount, contact Deal Strategy Management team'
                : `Maximum Discount: ${maxDiscount}%`
            }
          />
        )}
    </>
  );
};
