// @flow
import React from 'react';
import styled from 'styled-components';

export const ExternalLink = ({ href, text, className = null }) => (
  <>
    <a
      className={className}
      href={href}
      rel="noreferrer"
      style={{ textDecoration: 'underline' }}
      target="_blank"
    >
      {text}
    </a>
  </>
);

export const StyledExternalLink = styled(ExternalLink)`
  &&& {
    color: ${({ theme }) => theme.externalLink?.color};
    opacity: 1 !important;
  }
`;
