// @flow
import { useParams } from 'react-router-dom';
import React from 'react';

import { LinkRenderer } from '../grid-utils/renderers';
import { STREAM_GOVERNANCE } from '../../../constants';

export const StreamGovernanceLinkRenderer = () => {
  const { estimateId } = useParams();
  return (
    <LinkRenderer pathName={`/estimate/${estimateId}/stream-governance`} text={STREAM_GOVERNANCE} />
  );
};
