// @flow

import React from 'react';

import { StyledButton } from '../../../common-utils/styledComponents';

export const AddButton = ({ disabled, onClick }) => (
  <StyledButton
    circular={true}
    compact={true}
    data-testid="add-button"
    disabled={disabled}
    icon="add"
    onClick={onClick}
    positive={true}
    size="tiny"
  />
);
