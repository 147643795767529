// @flow
import { Grid } from 'semantic-ui-react';
import React from 'react';
import { useFormikContext } from 'formik';

import { StyledSpan } from '../../../../common-utils/styledComponents';
import {
  DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_NAME,
  DEDICATED_SURVEY_INPUTS_REASON_BACKEND_FIELD_NAME,
} from '../../../../constants';

import { DedicatedSurveyRenewalContainer } from './DedicatedSurveyRenewalContainer';
import { DedicatedSurveyTheatreContainer } from './DedicatedSurveyTheatreContainer';
import { DedicatedSurveyIndustryContainer } from './DedicatedSurveyIndustryContainer';
import { DedicatedSurveyReasonSelectionContainer } from './DedicatedSurveyReasonSelectionContainer';
import { DedicatedSurveyReasonInputContainer } from './DedicatedSurveyReasonInputContainer';

export const DedicatedSurveyQuestionsContainer = () => {
  const { values } = useFormikContext();
  const reasonsInputValues = values?.[DEDICATED_SURVEY_INPUTS_REASON_BACKEND_FIELD_NAME] ?? [];
  const shouldShowOtherReasonInputRow = reasonsInputValues.includes(
    DEDICATED_SURVEY_INPUTS_OTHER_REASON_BACKEND_NAME
  );

  return (
    <Grid verticalAlign="middle">
      <Grid.Row>
        <Grid.Column width={5}>
          <StyledSpan className="ui tiny header">Renewal</StyledSpan>
        </Grid.Column>
        <Grid.Column width={11}>
          <DedicatedSurveyRenewalContainer />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <StyledSpan className="ui tiny header">Theatre</StyledSpan>
        </Grid.Column>
        <Grid.Column width={11}>
          <DedicatedSurveyTheatreContainer />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <StyledSpan className="ui tiny header">Industry</StyledSpan>
        </Grid.Column>
        <Grid.Column width={11}>
          <DedicatedSurveyIndustryContainer />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={5}>
          <StyledSpan className="ui tiny header">
            Why is the customer not using Enterprise Clusters?
          </StyledSpan>
        </Grid.Column>
        <Grid.Column width={11}>
          <DedicatedSurveyReasonSelectionContainer />
        </Grid.Column>
      </Grid.Row>

      {shouldShowOtherReasonInputRow && (
        <Grid.Row>
          <Grid.Column width={5}>
            <StyledSpan className="ui tiny header">Other Reason</StyledSpan>
          </Grid.Column>
          <Grid.Column width={11}>
            <DedicatedSurveyReasonInputContainer />
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};
