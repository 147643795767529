// @flow
import React, { useContext } from 'react';

import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridTextField,
} from '../../../formik-utils/FieldArrayGrid';
import { getDiscountColumnConfig } from '../utils';

export const DimensionsConfigDiscountContainer = () => {
  const { columnsConfiguration } = useContext(FieldArrayGridConfigItemContext);
  const discountColumnConfig = getDiscountColumnConfig(columnsConfiguration);
  return <FieldArrayGridTextField columnConfig={discountColumnConfig} disableOnFormErrors={null} />;
};
