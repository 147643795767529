// @flow

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { useDeleteFlinkPoolMutation } from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { ConfirmModal } from '../../presentational/modals/ConfirmModal';
import { toastError, toastSuccess } from '../../presentational/notifications/utils';

export const FlinkPoolDeleteWarningModal = ({ isOpen, setOpen, flinkPoolName, flinkPoolId }) => {
  const [deleteFlinkPool] = useDeleteFlinkPoolMutation();
  const estimate = useContext(EstimateContext);
  const { push } = useHistory();

  const header = `Delete Flink Pool ${flinkPoolName}`;
  const body = <p>Are you sure you want to delete this Flink Pool?</p>;

  const estimateId = estimate?.inputs?.id;

  const payload = {
    estimate_id: estimateId,
    id: flinkPoolId,
    estimate_version: estimate?.inputs?.estimate_version,
  };

  const onClickYesHandler = async () => {
    const { error } = await deleteFlinkPool({
      estimateId: estimateId,
      flinkPoolId: flinkPoolId,
      payload,
    });
    if (error) {
      toastError(error);
    } else {
      toastSuccess('Successfully deleted the Flink Pool!');
      push(`/estimate/${estimateId}`);
    }
  };

  return (
    <ConfirmModal
      body={body}
      header={header}
      isOpen={isOpen}
      onClickHandlerForCancel={() => setOpen(false)}
      onClickHandlerForOK={onClickYesHandler}
    />
  );
};

export default FlinkPoolDeleteWarningModal;
