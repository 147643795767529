// @flow
import React from 'react';

import { ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP } from '../../configuration/estimate-account-details';
import { SFDC_ORGANIZATION_ID_BACKEND_NAME } from '../../constants';
import { TextField } from '../../formik-utils/FormFields';

export const EstimateOrganisationIDContainer = ({ disableOnFormErrors }) => {
  const estimateOrganisationIDConfig = ESTIMATE_ACCOUNT_DETAILS_CONFIG_MAP.get(
    SFDC_ORGANIZATION_ID_BACKEND_NAME
  );
  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      fieldDisplayName={estimateOrganisationIDConfig.displayName}
      fieldName={estimateOrganisationIDConfig.backendFieldName}
      icon={estimateOrganisationIDConfig.icon}
    />
  );
};
