// @flow

import { Button, Icon } from 'semantic-ui-react';
import React from 'react';

import { StyledButton } from '../../../common-utils/styledComponents';

export const UpdateFormButton = ({ disabled, onClick }) => (
  <StyledButton
    animated={true}
    circular={true}
    compact={true}
    data-testid="update-form-button"
    disableOnFormErrors={true}
    disabled={disabled}
    onClick={onClick}
    primary={true}
    size="mini"
  >
    <Button.Content visible={true}>
      <Icon flipped="vertically" name="level down alternate" rotated="clockwise" size="large" />
    </Button.Content>
    <Button.Content hidden={true}>Update</Button.Content>
  </StyledButton>
);
