// @flow

import { number, object, string } from 'yup';
import React from 'react';

import { SELECT_INPUT_TYPE } from '../formik-utils/consts';
import {
  MAX_INPUT_VALUE_FOR_ANY_FIELD,
  TCO_COST_PER_BUSINESS_DOWNTIME_HOUR_BACKEND_FIELD_NAME,
  TCO_COST_PER_BUSINESS_DOWNTIME_HOUR_DISPLAY_NAME,
  TCO_CSP_DISCOUNT_APPLIED_BACKEND_FIELD_NAME,
  TCO_CSP_DISCOUNT_APPLIED_DISPLAY_NAME,
  TCO_INPUTS_JSON_BACKEND_NAME,
  TCO_OVER_PROVISION_PERCENTAGE_BACKEND_FIELD_NAME,
  TCO_OVER_PROVISION_PERCENTAGE_DISPLAY_NAME,
  TCO_REGION_BACKEND_FIELD_NAME,
  TCO_REGION_DISPLAY_NAME,
  TCO_STORAGE_UTILIZATION_BUFFER_BACKEND_FIELD_NAME,
  TCO_STORAGE_UTILIZATION_BUFFER_DISPLAY_NAME,
} from '../constants';
import { getPercentageValidation } from '../common-utils/utils';
import { Tooltip } from '../components/presentational/Tooltip';

import { convertConfigArrayToMap, getValidationSchemaFromColsConfig } from './utils';
import { discountValidation } from './estimate-commit-details';

export const TCO_ESTIMATE_LEVEL_INPUTS_CONFIG = [
  {
    displayName: TCO_CSP_DISCOUNT_APPLIED_DISPLAY_NAME,
    backendFieldName: TCO_CSP_DISCOUNT_APPLIED_BACKEND_FIELD_NAME,
    validation: discountValidation,
    inputType: SELECT_INPUT_TYPE,
    icon: 'percent',
    pathToFollowInValues: [TCO_INPUTS_JSON_BACKEND_NAME],
    tooltip: (
      <Tooltip
        flowing={false}
        text={`CSP discount refers to the percentage discount that customers anticipate they will receive from their selected Cloud Service Provider (such as AWS, GCP, Azure, etc.).\n \nThe default expectation is set at 10%.`}
      />
    ),
  },
  {
    displayName: TCO_COST_PER_BUSINESS_DOWNTIME_HOUR_DISPLAY_NAME,
    backendFieldName: TCO_COST_PER_BUSINESS_DOWNTIME_HOUR_BACKEND_FIELD_NAME,
    validation: number()
      .min(0)
      .max(MAX_INPUT_VALUE_FOR_ANY_FIELD)
      .label(TCO_COST_PER_BUSINESS_DOWNTIME_HOUR_DISPLAY_NAME)
      .required()
      .typeError('You must specify a number'),
    inputType: SELECT_INPUT_TYPE,
    icon: 'dollar',
    pathToFollowInValues: [TCO_INPUTS_JSON_BACKEND_NAME],
    tooltip: (
      <Tooltip
        flowing={false}
        text={`Cost per Business downtime refers to the cost a customer incurs per hour if their system is down. \n \nWe acknowledge each unplanned outage, or hour of downtime, can have a different business impact which makes setting an average very difficult. \n \nFor guidance, Gartner routinely uses $5,600 per minute ($336,000 per hour) as their average impact to the business. \n \nPlease estimate based on potential revenue tied to Data Streaming related services. e.g. If the company generates $1B annually from Data Streaming use cases, then the hourly business cost would be $1B / 365 days / 24 hours = ~$114k.`}
      />
    ),
  },
  {
    displayName: TCO_OVER_PROVISION_PERCENTAGE_DISPLAY_NAME,
    backendFieldName: TCO_OVER_PROVISION_PERCENTAGE_BACKEND_FIELD_NAME,
    validation: getPercentageValidation(TCO_OVER_PROVISION_PERCENTAGE_DISPLAY_NAME),
    inputType: SELECT_INPUT_TYPE,
    icon: 'percent',
    pathToFollowInValues: [TCO_INPUTS_JSON_BACKEND_NAME],
    tooltip: (
      <Tooltip
        flowing={false}
        text={`"Overprovision Percentage" refers to the extent by which customer’s infrastructure is provisioned beyond its actual or anticipated usage. \n \nOver time many Kafka environments grow based on ease of expansion rather than required capacity design. \n\nConfluent has found this to be true in many customers Kafka environments but of course there are some customers where this is not true too. \n\nPlease work with the customer to adjust this data point to better reflect the estimated amount of over provisioned infrastructure in use. \n\nThe default used for Mission Critical based setups is 30%, and the default used for non-Mission Critical based setups is 60%.`}
      />
    ),
  },
  {
    displayName: TCO_STORAGE_UTILIZATION_BUFFER_DISPLAY_NAME,
    backendFieldName: TCO_STORAGE_UTILIZATION_BUFFER_BACKEND_FIELD_NAME,
    validation: getPercentageValidation(TCO_STORAGE_UTILIZATION_BUFFER_DISPLAY_NAME, 99.99),
    inputType: SELECT_INPUT_TYPE,
    icon: 'percent',
    pathToFollowInValues: [TCO_INPUTS_JSON_BACKEND_NAME],
    tooltip: (
      <Tooltip
        flowing={false}
        text={`"Storage Utilization Buffer" refers to an additional capacity required beyond the current storage needs. This buffer is maintained to accommodate potential increases in data storage requirements, unexpected spikes in usage etc. \n\nTo help us estimate current/expected storage cost please input what percentage of customer's storage devices is expected to remain empty under normal operations. \n\nBest practice is to have 40% of a device available as a buffer.`}
      />
    ),
  },
  {
    displayName: TCO_REGION_DISPLAY_NAME,
    backendFieldName: TCO_REGION_BACKEND_FIELD_NAME,
    validation: string().label(TCO_REGION_DISPLAY_NAME).required(),
    inputType: SELECT_INPUT_TYPE,
    pathToFollowInValues: [TCO_INPUTS_JSON_BACKEND_NAME],
    tooltip: (
      <Tooltip
        flowing={false}
        text={`Please select the most appropriate region for your TCO comparison. \n\nSelecting a region will adjust OSK/MSK infrastructure costs and the Salary used for Operations estimates. \n\nDefault: AMER`}
      />
    ),
  },
];

export const TCO_ESTIMATE_LEVEL_INPUTS_CONFIG_MAP = convertConfigArrayToMap(
  TCO_ESTIMATE_LEVEL_INPUTS_CONFIG
);

export const TCO_INPUTS_JSON_CONFIG = {
  backendFieldName: TCO_INPUTS_JSON_BACKEND_NAME,
  validation: object({ ...getValidationSchemaFromColsConfig(TCO_ESTIMATE_LEVEL_INPUTS_CONFIG) }),
};
