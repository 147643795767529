// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import {
  DEFAULT_VALUE_FOR_DB_ROW_ID,
  IS_ROW_FROZEN,
  IS_ROW_OPENED_FOR_EDITING,
  ROW_ID,
} from '../../../constants';
import { AddButton } from '../../presentational/buttons/AddButton';
import { FieldArrayGridConfigItemContext } from '../../../formik-utils/FieldArrayGrid';
import { getIfAllMetricsAreFrozen, getIfAnyMetricIsBeingEdited } from '../utils';

const getIfMetricNameAddButtonShouldBeDisabled = (readOnlyMode, rowValues, formikValues) => {
  if (readOnlyMode) {
    return true;
  }

  if (rowValues?.metricName == null) {
    return true;
  }

  if (rowValues.dimensions_config_array.length === 0) {
    return false;
  }

  if (getIfAnyMetricIsBeingEdited(formikValues)) {
    return true;
  }

  return getIfAllMetricsAreFrozen(formikValues) === false;
};

export const MetricNameAddButtonContainer = ({ metricDimensionRefs }) => {
  const formik = useFormikContext();
  const { rowValues, readOnlyMode } = useContext(FieldArrayGridConfigItemContext);
  const disabled = getIfMetricNameAddButtonShouldBeDisabled(readOnlyMode, rowValues, formik.values);

  return (
    <AddButton
      disabled={disabled}
      onClick={() => {
        const newRow = {
          [IS_ROW_FROZEN]: false,
          [IS_ROW_OPENED_FOR_EDITING]: true,
          [ROW_ID]: DEFAULT_VALUE_FOR_DB_ROW_ID,
        };
        for (const colConfig of metricDimensionRefs.current.dimensionColumnsConfiguration) {
          newRow[colConfig.backendFieldName] = colConfig.colDefaultValue;
        }
        return metricDimensionRefs.current.dimensionArrayHelpers.push(newRow);
      }}
    />
  );
};
