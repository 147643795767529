// @flow

import React from 'react';

import { BaseModal } from './BaseModal';
import { ModalCancelButton } from './ModalCancelButton';
import { ModalOKButton } from './ModalOKButton';

export const ConfirmModal = ({
  body,
  header,
  isOpen,
  onClickHandlerForCancel,
  onClickHandlerForOK,
  okButtonText = 'OK',
  cancelButtonText = 'Cancel',
  fullScreen = false,
  disabled = false,
  okButtonNegative = true,
  cancelButtonNegative = false,
  centered = null,
}) => (
  <BaseModal
    actions={
      <>
        <ModalOKButton
          content={okButtonText}
          disabled={disabled}
          okButtonNegative={okButtonNegative}
          onClick={onClickHandlerForOK}
        />
        <ModalCancelButton
          cancelButtonNegative={cancelButtonNegative}
          content={cancelButtonText}
          onClick={onClickHandlerForCancel}
        />
      </>
    }
    body={body}
    centered={centered}
    fullScreen={fullScreen}
    header={header}
    isOpen={isOpen}
  />
);
