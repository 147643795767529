// @flow
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DataFetcherContainer } from '../../presentational/DataFetcherContainer';
import { useGetFlinkPoolConfigurationSummaryQuery } from '../../../service-definitions/estimates';

import { FlinkPoolConfigurationsSummaryContainer } from './FlinkPoolConfigurationsSummaryContainer';

export const FlinkPoolConfigurationSummaryContainer = ({ disabled }) => {
  const { estimateId, flinkPoolId } = useParams();
  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId, flinkPoolId },
          {
            skip: !estimateId || !flinkPoolId,
          },
        ],
        [flinkPoolId, estimateId]
      )}
      dataFetchingFunction={useCallback(useGetFlinkPoolConfigurationSummaryQuery, [])}
      nameOfFunction="useGetFlinkPoolConfigurationSummaryQuery"
    >
      {(data) => {
        const flinkPoolConfigsArray =
          data && data.flink_pool_input_summary ? [data.flink_pool_input_summary] : [];

        return (
          <FlinkPoolConfigurationsSummaryContainer
            addLinkToFlinkPoolName={false}
            disabled={disabled}
            flinkPoolConfigsArray={flinkPoolConfigsArray}
          />
        );
      }}
    </DataFetcherContainer>
  );
};
