// @flow
import { useParams } from 'react-router-dom';
import React from 'react';

import { WarningIcon } from '../../presentational/icons/WarningIcon';

import { ClusterNameLinkRenderer } from './ClusterNameLinkRenderer';

export const ClusterNameRendererWithError = ({ data }) => {
  const { estimateId } = useParams();

  return (
    <>
      <WarningIcon />
      <ClusterNameLinkRenderer data={data} estimateId={estimateId} />
    </>
  );
};
