// @flow
import { Legend, Line, YAxis } from 'recharts';
import React from 'react';

import { CustomizedYAxisTick } from './CustomizedYAxisTick';

export const getFormattedCurrencyValue = (value) =>
  new Intl.NumberFormat('en-US').format(Math.round(value));

const staticColorsForClusterConsumptionGraph = [
  '#FF0100',
  '#EA41F2',
  '#1931C9',
  '#FE8606',
  '#f7e305',
  '#176926',
  '#b5651d',
  '#1ffff8',
];

const staticColorsForClusterTCOGraph = [
  '#87CEEB',
  '#CF9FFF',
  '#86ac86',
  '#1931C9',
  '#FE8606',
  '#f7e305',
  '#176926',
  '#b5651d',
  '#1ffff8',
];

export const getColorFromSetOfColorsOrARandomColor = (index, staticColors) => {
  if (index <= staticColors.length - 1) {
    return staticColors[index];
  } else {
    return `hsl(${Math.random() * 360}, 100%, 75%)`;
  }
};

export const getColorForTheGivenYearForTCOGraph = (index) => {
  return getColorFromSetOfColorsOrARandomColor(index, staticColorsForClusterTCOGraph);
};

export const getColorForCluster = (clusterIndex) => {
  return getColorFromSetOfColorsOrARandomColor(
    clusterIndex,
    staticColorsForClusterConsumptionGraph
  );
};

export const getClusterColorsMap = (clusterNames) => {
  const clustersColorMap = new Map();

  for (let i = 0; i <= clusterNames.length - 1; i++) {
    clustersColorMap.set(clusterNames[i], getColorForCluster(i));
  }

  return clustersColorMap;
};

export const getTotalSpendLine = (dataSetProps) => (
  <Line
    activeDot={true}
    animationDuration={400}
    dataKey="Total Spend"
    fillOpacity={Number(dataSetProps.hover === 'Total Spend' || !dataSetProps.hover ? 1 : 0.6)}
    hide={dataSetProps['Total Spend'] === true}
    key="Total Spend"
    stroke="#00AFBA"
    style={{ cursor: 'pointer' }}
    type="monotone"
  />
);

export const getClusterLine = (clusterName, dataSetProps, clusterColorsMap) => (
  <Line
    activeDot={true}
    animationDuration={400}
    dataKey={clusterName}
    fillOpacity={Number(dataSetProps.hover === clusterName || !dataSetProps.hover ? 1 : 0.6)}
    hide={dataSetProps[clusterName] === true}
    key={clusterName}
    stroke={clusterColorsMap.get(clusterName)}
    style={{ cursor: 'pointer' }}
    type="monotone"
  />
);

export const getLegend = (dataSetProps, setDatasetProps) => {
  const handleLegendMouseEnter = (e) => {
    if (!dataSetProps[e.dataKey]) {
      setDatasetProps({ ...dataSetProps, hover: e.dataKey });
    }
  };

  const handleLegendMouseLeave = () => {
    setDatasetProps({ ...dataSetProps, hover: null });
  };

  const selectDataset = (e) => {
    setDatasetProps({
      ...dataSetProps,
      [e.dataKey]: !dataSetProps[e.dataKey],
      hover: null,
    });
  };

  return (
    <Legend
      height={36}
      onClick={selectDataset}
      onMouseOut={handleLegendMouseLeave}
      onMouseOver={handleLegendMouseEnter}
      verticalAlign="top"
    />
  );
};

export const getYAxis = () => (
  <YAxis
    allowDecimals={true}
    domain={['auto', 'auto']}
    interval={0}
    scale="auto"
    tick={<CustomizedYAxisTick />}
    type="number"
  />
);

export const tooltipCurrencyFormatter = (value) => `$${getFormattedCurrencyValue(value)}`;

export const getInitialDataSetProps = (allDataKeys, initiallySelectedDatasetName = null) => {
  return allDataKeys.reduce(
    (dataSetKey, key) => {
      if (initiallySelectedDatasetName) {
        dataSetKey[key] = key !== initiallySelectedDatasetName;
      } else {
        dataSetKey[key] = true;
      }
      return dataSetKey;
    },
    { hover: null }
  );
};
