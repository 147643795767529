// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import {
  CLUSTER_NETWORKING_TYPE_BACKEND_NAME,
  INTERNET,
  PEERED_VPC,
  PRIVATE_LINK,
  TRANSIT_GATEWAY,
} from '../../../constants';
import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';
import { getDropdownOptions } from '../../stream-governance/utils';
import { SelectField } from '../../../formik-utils/FormFields';
import { ClusterContext } from '../../../contexts/ClusterContext';

import { disableFollowerFetchOption } from './utils';

export const ClusterNetworkingTypeContainer = ({ disabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const clusterNetworkingTypeConfig = CLUSTER_DETAILS_CONFIG_MAP.get(
    CLUSTER_NETWORKING_TYPE_BACKEND_NAME
  );

  const cluster = useContext(ClusterContext);
  const dataUniverse = cluster?.cluster_configs?.cluster_params;

  const clusterNetworkingTypeOptions = getDropdownOptions(
    dataUniverse,
    values,
    clusterNetworkingTypeConfig,
    false,
    true,
    false,
    false,
    [INTERNET, PEERED_VPC, TRANSIT_GATEWAY, PRIVATE_LINK]
  );

  return (
    <SelectField
      disabled={disabled}
      fieldDisplayName={clusterNetworkingTypeConfig.displayName}
      fieldName={clusterNetworkingTypeConfig.backendFieldName}
      onChange={() => {
        disableFollowerFetchOption(setFieldValue);
      }}
      options={clusterNetworkingTypeOptions}
      placeholder={null}
    />
  );
};
