// @flow
import React, { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import { CommitSummaryContainer } from '../estimate-commit-summary/CommitSummaryContainer';
import { SFDCFieldsContainer } from '../sfdc-fields/SFDCFieldsContainer';
import { AnnualSummaryContainer } from '../estimate-annual-summaries/AnnualSummaryContainer';
import { ClusterConfigurationsSummaryContainer } from '../cluster-detailed-configuration/ClusterConfigurationsSummaryContainer';
import { CustomViewsSummaryContainer } from '../fact-table-view/CustomViewsSummaryContainer';
import { MonthlyConsumptionGraphTopLevelContainer } from '../clusters-consumption-graph/MonthlyConsumptionGraphTopLevelContainer';
import { TabsContainer } from '../../presentational/TabsContainer';
import { DataFetcherContainer } from '../../presentational/DataFetcherContainer';
import {
  useGetEstimateCloudMonthlySpendSummaryQuery,
  useGetEstimateClustersConfigurationSummaryInfoQuery,
  useGetEstimateCustomViewDataQuery,
} from '../../../service-definitions/estimates';
import { getIfCustomerViewIsEnabled } from '../../../common-utils/utils';
import { MonthlySpendSummaryContainer } from '../../common/MonthlySpendSummaryContainer';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { EstimateLevelTCOViewsDataWrapper } from '../tco-views/EstimateLevelTCOViewsDataWrapper';
import { isAnythingEditedOnEstimatePage } from '../utils';
import { isAnyErrorPresentInClusterConfigurationBasedOnClusterSummaryResponse } from '../clusters-summary/utils';

export const EstimateSummaryTopLevelContainer = () => {
  const { estimateId } = useParams();
  const estimateData = useContext(EstimateContext);
  const { values, initialValues } = useFormikContext();

  // todo:: Extends this logic in future to Flink. SG etc;
  const disabled =
    isAnythingEditedOnEstimatePage(values, initialValues) ||
    isAnyErrorPresentInClusterConfigurationBasedOnClusterSummaryResponse(estimateData);

  const tabPanes = [
    {
      menuItem: 'Commit Summary',
      render: () => <CommitSummaryContainer disabled={disabled} />,
    },
    {
      menuItem: 'SFDC Fields',
      render: () => <SFDCFieldsContainer disabled={disabled} />,
    },
    {
      menuItem: 'Annual Summaries',
      render: () => <AnnualSummaryContainer disabled={disabled} />,
    },
    {
      menuItem: 'Consumption Graph',
      render: () => <ConsumptionGraphDataWrapper disabled={disabled} estimateId={estimateId} />,
    },
    {
      menuItem: 'Cloud Monthly Spend',
      render: () => <CloudMonthlySpendDataWrapper disabled={disabled} estimateId={estimateId} />,
    },
    {
      menuItem: 'Cluster Configurations',
      render: () => (
        <ClusterConfigurationsDataWrapper disabled={disabled} estimateId={estimateId} />
      ),
    },
    {
      menuItem: 'Custom Views',
      render: () => <CustomViewsDataWrapper disabled={disabled} estimateId={estimateId} />,
    },
  ];

  if (!getIfCustomerViewIsEnabled(estimateData?.inputs)) {
    tabPanes.push({
      menuItem: 'TCO (MSK/OSK)',
      render: () => (
        <EstimateLevelTCOViewsDataWrapper disabled={disabled} estimateId={estimateId} />
      ),
    });
  }

  return <TabsContainer tabPanes={tabPanes} />;
};

const ConsumptionGraphDataWrapper = ({ estimateId, disabled = false }) => {
  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId },
          {
            skip: !estimateId,
          },
        ],
        [estimateId]
      )}
      dataFetchingFunction={useCallback(useGetEstimateCloudMonthlySpendSummaryQuery, [])}
    >
      {(data) => {
        const monthlySpendSummary = data.estimate_cloud_monthly_spend;

        return (
          <MonthlyConsumptionGraphTopLevelContainer
            data={monthlySpendSummary}
            disabled={disabled}
          />
        );
      }}
    </DataFetcherContainer>
  );
};
const CloudMonthlySpendDataWrapper = ({ estimateId, disabled = false }) => {
  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId },
          {
            skip: !estimateId,
          },
        ],
        [estimateId]
      )}
      dataFetchingFunction={useCallback(useGetEstimateCloudMonthlySpendSummaryQuery, [])}
    >
      {(data) => {
        const monthlySpendSummary = data.estimate_cloud_monthly_spend;
        return (
          <MonthlySpendSummaryContainer
            disabled={disabled}
            monthlySpendSummary={monthlySpendSummary}
          />
        );
      }}
    </DataFetcherContainer>
  );
};
const ClusterConfigurationsDataWrapper = ({ estimateId, disabled = false }) => {
  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId },
          {
            skip: !estimateId,
          },
        ],
        [estimateId]
      )}
      dataFetchingFunction={useCallback(useGetEstimateClustersConfigurationSummaryInfoQuery, [])}
    >
      {(data) => {
        const clusterConfigsArray = data?.cluster_configurations ?? [];
        return (
          <ClusterConfigurationsSummaryContainer
            clusterConfigsArray={clusterConfigsArray}
            disabled={disabled}
          />
        );
      }}
    </DataFetcherContainer>
  );
};
const CustomViewsDataWrapper = ({ estimateId, disabled = false }) => {
  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { estimateId: estimateId },
          {
            skip: !estimateId,
          },
        ],
        [estimateId]
      )}
      dataFetchingFunction={useCallback(useGetEstimateCustomViewDataQuery, [])}
    >
      {(data) => {
        return <CustomViewsSummaryContainer data={data} disabled={disabled} />;
      }}
    </DataFetcherContainer>
  );
};
