// @flow

import { useFormikContext } from 'formik';
import React, { useContext } from 'react';

import { FLINK_POOL_REGION_BACKEND_NAME } from '../../../constants';
import { getDropdownOptions } from '../../stream-governance/utils';
import { SelectField } from '../../../formik-utils/FormFields';
import { FLINK_POOL_DETAILS_CONFIG_MAP } from '../../../configuration/flink-pool-details';
import { FlinkPoolContext } from '../../../contexts/FlinkPoolContext';
import { getFormattedRegionsUniverse } from '../../../common-utils/utils';

export const FlinkPoolRegionsContainer = ({ disabled }) => {
  const { values } = useFormikContext();
  const flinkPoolRegionsConfig = FLINK_POOL_DETAILS_CONFIG_MAP.get(FLINK_POOL_REGION_BACKEND_NAME);

  const flinkPool = useContext(FlinkPoolContext);
  const dataUniverse = flinkPool?.flink_pool_configs?.region_details ?? [];

  const dataUniverseFormatted = getFormattedRegionsUniverse(dataUniverse);

  const flinkPoolRegionsOptions = getDropdownOptions(
    dataUniverseFormatted,
    values,
    flinkPoolRegionsConfig,
    false,
    true,
    false,
    false,
    [],
    true
  );

  return (
    <SelectField
      disabled={disabled}
      fieldDisplayName={flinkPoolRegionsConfig.displayName}
      fieldName={flinkPoolRegionsConfig.backendFieldName}
      options={flinkPoolRegionsOptions}
      placeholder={null}
    />
  );
};
