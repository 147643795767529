// @flow

import { Icon } from 'semantic-ui-react';
import React from 'react';

import { StyledPopup, StyledSpan } from '../../common-utils/styledComponents';

import { Spacer } from './spacing/Spacer';
import { ExternalLink } from './ExternalLink';

export const Tooltip = ({ text, link, flowing = true }) => {
  const content = link ? (
    <ExternalLink href={link} text={text} />
  ) : (
    <StyledSpan style={{ whiteSpace: 'pre-wrap', float: 'left' }}>{text}</StyledSpan>
  );

  return (
    <StyledPopup
      content={content}
      flowing={flowing}
      hoverable={true}
      size="large"
      trigger={
        <sup data-testid="trigger">
          <Spacer x={0.25} />
          <Icon fitted={true} name="info circle" />
        </sup>
      }
      wide="very"
    />
  );
};
