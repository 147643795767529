// @flow
import React from 'react';

import { StyledMessage } from '../../../common-utils/styledComponents';
import { Spacer } from '../spacing/Spacer';
import { ExternalLink } from '../ExternalLink';

export const BaseMessageContainer = ({ message, link, key = '', color = '' }) => (
  <StyledMessage
    color={color}
    content={
      <>
        {message}
        {link && (
          <>
            <Spacer x={5} />
            <ExternalLink href={link} text="Read More" />
          </>
        )}
      </>
    }
    key={key}
    size="mini"
  />
);
