// @flow
import React from 'react';
import { CartesianGrid, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

import { StyledLineChart } from './StyledLineChart';
import { CustomizedXAxisTick } from './CustomizedXAxisTick';
import {
  getClusterColorsMap,
  getClusterLine,
  getInitialDataSetProps,
  getLegend,
  getTotalSpendLine,
  getYAxis,
  tooltipCurrencyFormatter,
} from './utils';

export const MonthlyConsumptionGraphContainer = ({ data }) => {
  const allDataKeys = data.length > 0 ? Object.keys(data[0]) : [];
  const dataSetKeyNames = allDataKeys.filter((x) => x !== 'month' && x !== 'year');
  const clusterNames = dataSetKeyNames.filter((x) => x !== 'Total Spend');
  const clusterColorsMap = getClusterColorsMap(clusterNames);

  const [dataSetProps, setDatasetProps] = React.useState(
    getInitialDataSetProps(dataSetKeyNames, 'Total Spend')
  );

  return (
    <ResponsiveContainer height={450} minWidth="0" width="99%">
      <StyledLineChart
        data={data}
        margin={{
          top: 5,
          right: 50,
          left: 50,
          bottom: 20,
        }}
        width="100%"
      >
        <XAxis dataKey="month" height={110} tick={<CustomizedXAxisTick />} />
        {getYAxis()}
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip formatter={tooltipCurrencyFormatter} />
        {getLegend(dataSetProps, setDatasetProps)}
        {clusterNames.map((clusterName) => {
          return getClusterLine(clusterName, dataSetProps, clusterColorsMap);
        })}
        {getTotalSpendLine(dataSetProps)}
      </StyledLineChart>
    </ResponsiveContainer>
  );
};

// Note: This file cannot be refactored and broken down into components because of a limitation of recharts
// That's the reason we have used functions
