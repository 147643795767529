// @flow
import { Icon } from 'semantic-ui-react';
import React from 'react';

export const DeleteIcon = ({ onClick, disabled }) => (
  <Icon
    color="red"
    data-testid="delete-icon"
    disabled={disabled}
    name="delete"
    onClick={onClick}
    style={{ cursor: 'pointer' }}
  />
);
