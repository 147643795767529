// @flow
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';

import ClusterDeleteWarningModal from '../../views/clusters-summary/ClusterDeleteWarningModal';
import { DeleteButton } from '../../presentational/buttons/DeleteButton';

export const ClusterDeleteContainer = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const { values } = useFormikContext();
  const { clusterId } = useParams();

  return (
    <>
      <DeleteButton
        onClick={() => {
          setDeleteModalOpen(true);
        }}
        size="large"
      />
      <ClusterDeleteWarningModal
        clusterId={clusterId}
        clusterName={values?.name}
        isOpen={isDeleteModalOpen}
        setOpen={setDeleteModalOpen}
      />
    </>
  );
};
