// @flow
import React, { useContext } from 'react';
import { getIn, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import { CLUSTER_KSQLDB_FORM, CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG } from '../../../constants';
import { ClusterInputTable } from '../cluster-input-table/ClusterInputTable';
import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { toastError } from '../../presentational/notifications/utils';
import { useUpdateClusterMutation } from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { isAnythingEditedOnClusterPageBesidesTheGivenForm } from '../../views/utils';

export const KSQLDBUsageContainer = () => {
  const { estimateId, clusterId } = useParams();
  const [updateCluster] = useUpdateClusterMutation();
  const estimate = useContext(EstimateContext);

  const rowsConfig = [
    {
      backendName: 'is_enabled',
      displayName: 'Active',
      isBooleanField: true,
    },
    {
      backendName: 'csu_count',
      displayName: 'CSUs',
    },
  ];

  const { values, initialValues } = useFormikContext();

  const shouldFormBeDisabled = isAnythingEditedOnClusterPageBesidesTheGivenForm(
    values,
    initialValues,
    CLUSTER_KSQLDB_FORM
  );

  return (
    <PaddedAndRaisedSegment disabled={shouldFormBeDisabled}>
      <ClusterInputTable
        disabled={shouldFormBeDisabled}
        keyNameInValues={CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG}
        rowsConfig={rowsConfig}
        tableName="KSQLDB Monthly Usage"
        updateFunc={async () => {
          const valuesToSendToBackend = getIn(values, CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG);
          const ksqlDBInputs = Object.values(valuesToSendToBackend.months);

          const payload = {
            id: clusterId,
            estimate_id: estimateId,
            estimate_version: estimate?.inputs?.estimate_version,
            ksqldb: { inputs: ksqlDBInputs },
          };
          const { error } = await updateCluster({
            estimateId,
            clusterId,
            payload,
          });
          if (error) {
            toastError(error);
          }
        }}
      />
    </PaddedAndRaisedSegment>
  );
};
