// @flow
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { object, string } from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import { useAddClusterMutation } from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { StyledContainer } from '../../../common-utils/styledComponents';
import { ConfirmModal } from '../../presentational/modals/ConfirmModal';
import { Form } from '../../../formik-utils/formikSUIWrapper';
import { TextField } from '../../../formik-utils/FormFields';
import { toastError } from '../../presentational/notifications/utils';

export const ClusterAddModal = ({ isOpen, setOpen }) => {
  const { estimateId } = useParams();
  const [addCluster] = useAddClusterMutation();
  const estimate = useContext(EstimateContext);
  const { push } = useHistory();

  const initialValues = {
    cluster_name: '',
  };

  const validationSchema = object({
    cluster_name: string().label('Cluster Name').required(),
  });

  return (
    <StyledContainer>
      <Formik
        autoComplete="off"
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {(addClusterFormik) => (
          <ConfirmModal
            body={
              <StyledContainer>
                <Form autoComplete="off">
                  <TextField
                    disableOnFormErrors={false}
                    fieldDisplayName="Cluster Name"
                    fieldName="cluster_name"
                  />
                </Form>
              </StyledContainer>
            }
            cancelButtonNegative={true}
            disabled={!addClusterFormik.isValid}
            header="Add New Cluster"
            isOpen={isOpen}
            okButtonNegative={false}
            okButtonText="Continue"
            onClickHandlerForCancel={() => setOpen(false)}
            onClickHandlerForOK={async () => {
              const { error, data } = await addCluster({
                estimateId,
                payload: {
                  name: addClusterFormik.values.cluster_name,
                  estimate_version: estimate?.inputs?.estimate_version,
                },
              });

              if (error) {
                toastError(error);
              } else {
                push(`/estimate/${estimateId}/cluster/${data.id}`);
              }
              setOpen(false);
            }}
          />
        )}
      </Formik>
    </StyledContainer>
  );
};
