// @flow
import React, { useCallback, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import { DataFetcherContainer } from '../components/presentational/DataFetcherContainer';
import { useGetUserInfoQuery } from '../service-definitions/estimates';

import { UserContext } from './UserContext';

export const UserContextProvider = ({ children }) => {
  const { authState } = useOktaAuth();
  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(() => [], [])}
      dataFetchingFunction={useCallback(useGetUserInfoQuery, [])}
    >
      {(data) => {
        const dataToUse = { ...data };
        dataToUse.userEmail = authState?.idToken?.claims?.email;

        return <UserContext.Provider value={dataToUse}>{children}</UserContext.Provider>;
      }}
    </DataFetcherContainer>
  );
};
