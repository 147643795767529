// @flow
import React, { useContext } from 'react';
import { Formik } from 'formik';

import { ClusterContext } from '../../../contexts/ClusterContext';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { CLUSTER_TOP_LEVEL_CONFIG } from '../../../configuration/cluster-top-level-config';
import { getFormikFormInputsFromColConfigAndInputSource } from '../../../common-utils/utils';
import { StyledContainer } from '../../../common-utils/styledComponents';
import { PromptIfFormHasUnSavedChanges } from '../../presentational/PromptIfFormHasUnSavedChanges';
import { Form } from '../../../formik-utils/formikSUIWrapper';
import {
  CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME,
  CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG,
  CLUSTER_RETENTION_BACKEND_NAME,
  CLUSTER_RETENTION_INFINITE_BACKEND_NAME,
  TCO_INPUTS_JSON_BACKEND_NAME,
} from '../../../constants';
import { ClusterEnabledContainer } from '../cluster-details-inputs/ClusterEnabledContainer';
import { Spacer } from '../../presentational/spacing/Spacer';
import FormListener from '../../../formik-utils/FormListener';
import { ValidateFormOnMount } from '../../../formik-utils/ValidateFormOnMount';

import { ClusterAccordionsContainer } from './ClusterAccordionsContainer';
import { ClusterDeleteContainer } from './ClusterDeleteContainer';

export const ClusterTopLevelContainerForm = () => {
  const estimate = useContext(EstimateContext);
  const cluster = useContext(ClusterContext);
  const retentionInputs = cluster.inputs.retention;
  const clusterInputsSource = {
    ...cluster.inputs,
    [CLUSTER_RETENTION_INFINITE_BACKEND_NAME]: !retentionInputs.is_finite_retention,
    [CLUSTER_RETENTION_BACKEND_NAME]: retentionInputs[CLUSTER_RETENTION_BACKEND_NAME],
    id: cluster.inputs.id,
    name: cluster.cluster_name,
    isEnabled: cluster.is_enabled,
    calculations: { inputs: cluster.calculations },
    [CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG]: cluster[CLUSTER_KAFKA_MONTHLY_INPUTS_JSON_CONFIG],
    [CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG]: cluster[CLUSTER_KSQLDB_MONTHLY_INPUTS_JSON_CONFIG],
    [CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG]: {
      connectors: cluster?.[CLUSTER_CONNECTORS_MONTHLY_INPUTS_JSON_CONFIG],
    },
    [CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG]:
      cluster[CLUSTER_CLUSTER_LINKING_MONTHLY_INPUTS_JSON_CONFIG],
    [CLUSTER_AVAILABLE_CONNECTORS_LIST_BACKEND_NAME]: 'activemqsource',
    // todo:: Remove the hardcoding of ActiveMQSource
    [TCO_INPUTS_JSON_BACKEND_NAME]: cluster?.[TCO_INPUTS_JSON_BACKEND_NAME] ?? {},
  };

  const { initialValues, initialTouched, validationSchema } =
    getFormikFormInputsFromColConfigAndInputSource(CLUSTER_TOP_LEVEL_CONFIG, clusterInputsSource, {
      estimate,
      cluster,
    });

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnBlur={true}
        validateOnChange={false}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form autoComplete="off">
            <ValidateFormOnMount />
            <FormListener formik={formik} />
            <PromptIfFormHasUnSavedChanges />
            <ClusterEnabledContainer />
            <ClusterDeleteContainer />
            <Spacer y={10} />
            <ClusterAccordionsContainer />
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};
