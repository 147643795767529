// @flow
import React from 'react';
import { useFormikContext } from 'formik';

import { ConfirmModal } from '../presentational/modals/ConfirmModal';
import { RATE_CARD_BACKEND_NAME } from '../../constants';
import { StaticTable } from '../presentational/StaticTable';

import {
  CLUSTER_MAPPING_TABLE_FROM_NEW_TO_OLD,
  CLUSTER_MAPPING_TABLE_FROM_OLD_TO_NEW,
} from './constants';

const EstimateRateCardChangeWarningModal = ({ isOpen, setOpen }) => {
  const { initialValues, setFieldValue } = useFormikContext();

  const header = 'Change in Rate Card';

  const body = (
    <>
      <p>
        <strong>WARNING:</strong> The billing model for Basic and Standard clusters was changed with
        the launch of eCKU based pricing. This pricing is available on the rate card effective on
        4/11/2024.
        <br /> <br />
        Switching rate cards between the 4/11/2024 and older rate cards (8/1/2020, 10/1/2020, and
        3/5/2020) will result in changes to Basic and Standard clusters that may affect the total
        commit calculation.
        <br /> <br />
        Please see the tables below for the details.
        <br />
        <StaticTable tableContents={CLUSTER_MAPPING_TABLE_FROM_OLD_TO_NEW} />
        <StaticTable tableContents={CLUSTER_MAPPING_TABLE_FROM_NEW_TO_OLD} />
        <strong>Note:</strong> Enterprise and Dedicated cluster pricing is same across these rate
        cards and will not be impacted.
      </p>

      <p>
        If you dont want this behaviour, then, please click
        <strong> No, revert my changes! </strong>
        to go back to the previous state!
      </p>
    </>
  );

  return (
    <ConfirmModal
      body={body}
      cancelButtonText="No, revert my changes!"
      header={header}
      isOpen={isOpen}
      okButtonText="Yes, I understand and want to proceed!"
      onClickHandlerForCancel={async () => {
        await setFieldValue(RATE_CARD_BACKEND_NAME, initialValues[RATE_CARD_BACKEND_NAME]);
        setOpen(false);
      }}
      onClickHandlerForOK={() => {
        setOpen(false);
      }}
    />
  );
};

export default EstimateRateCardChangeWarningModal;
