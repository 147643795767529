// @flow
import React from 'react';

import { getFormattedCurrencyValue } from './utils';

export const CustomizedYAxisTick = ({ payload, x, y }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text dy={20} fill="#999" textAnchor="end" x={0} y={0}>
        {getFormattedCurrencyValue(payload.value)}
      </text>
    </g>
  );
};
