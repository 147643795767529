// @flow

import React from 'react';

import { EstimateContextProvider } from '../../../contexts/EstimateContextProvider';
import { ClusterContextProvider } from '../../../contexts/ClusterContextProvider';
import { ClusterBreadcrumbs } from '../../breadcrumbs/ClusterBreadcrumbs';
import { ShowLoaderIfAnyQueryIsPending } from '../../presentational/ShowLoaderIfAnyQueryIsPending';
import { UserContextProvider } from '../../../contexts/UserContextProvider';
import { ConnectorsContextProvider } from '../../../contexts/ConnectorsContextProvider';

import { ClusterTopLevelContainerForm } from './ClusterTopLevelContainerForm';

const ClusterTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <EstimateContextProvider>
          <ConnectorsContextProvider>
            <ClusterContextProvider>
              <ClusterBreadcrumbs />
              <ClusterTopLevelContainerForm />
            </ClusterContextProvider>
          </ConnectorsContextProvider>
        </EstimateContextProvider>
      </UserContextProvider>
    </>
  );
};

export default ClusterTopLevelContainer;
