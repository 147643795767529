// @flow
const oktaAuthConfig = {
  issuer: `${window._env_.OKTA_BASE_URL}/oauth2/default`,
  clientId: window._env_.OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/oauth2/code/okta`,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
};

export { oktaAuthConfig };
