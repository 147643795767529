// @flow
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { object } from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import { useAddFlinkPoolMutation } from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { StyledContainer } from '../../../common-utils/styledComponents';
import { ConfirmModal } from '../../presentational/modals/ConfirmModal';
import { Form } from '../../../formik-utils/formikSUIWrapper';
import { TextField } from '../../../formik-utils/FormFields';
import { toastError } from '../../presentational/notifications/utils';
import { FLINK_POOL_DETAILS_CONFIG_MAP } from '../../../configuration/flink-pool-details';
import { FLINK_POOL_NAME_BACKEND_NAME } from '../../../constants';
import { getLinkForFlinkPool } from '../../links/utils';

export const FlinkPoolAddModal = ({ isOpen, setOpen }) => {
  const { estimateId } = useParams();
  const [addFlinkPool] = useAddFlinkPoolMutation();
  const estimate = useContext(EstimateContext);
  const { push } = useHistory();

  const flinkPoolNameConfig = FLINK_POOL_DETAILS_CONFIG_MAP.get(FLINK_POOL_NAME_BACKEND_NAME);

  const initialValues = {
    [FLINK_POOL_NAME_BACKEND_NAME]: '',
  };

  const validationSchema = object({
    [FLINK_POOL_NAME_BACKEND_NAME]: flinkPoolNameConfig.validation,
  });

  return (
    <StyledContainer>
      <Formik
        autoComplete="off"
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {(addFlinkPoolFormik) => (
          <ConfirmModal
            body={
              <StyledContainer>
                <Form autoComplete="off">
                  <TextField
                    disableOnFormErrors={false}
                    fieldDisplayName={flinkPoolNameConfig.displayName}
                    fieldName={flinkPoolNameConfig.backendFieldName}
                    icon={flinkPoolNameConfig.icon}
                  />
                </Form>
              </StyledContainer>
            }
            cancelButtonNegative={true}
            disabled={!addFlinkPoolFormik.isValid}
            header="Add New Flink Pool"
            isOpen={isOpen}
            okButtonNegative={false}
            okButtonText="Continue"
            onClickHandlerForCancel={() => setOpen(false)}
            onClickHandlerForOK={async () => {
              const { error, data } = await addFlinkPool({
                estimateId,
                payload: {
                  estimate_id: estimateId,
                  name: addFlinkPoolFormik.values[FLINK_POOL_NAME_BACKEND_NAME],
                  estimate_version: estimate?.inputs?.estimate_version,
                },
              });

              if (error) {
                toastError(error);
              } else {
                push(getLinkForFlinkPool(estimateId, data.id));
              }
              setOpen(false);
            }}
          />
        )}
      </Formik>
    </StyledContainer>
  );
};
