// @flow

import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import {
  CREDITS_AND_BURSTS_ENABLED_BACKEND_NAME,
  DEFAULT_FE_ERROR_MESSAGE,
  ESTIMATE_LEVEL_TCO_INPUTS_SECTION_HEADER,
  ESTIMATE_SUMMARY,
  IS_SFDC_INTEGRATION_LIVE,
  STREAM_GOVERNANCE_SUMMARY,
} from '../../constants';
import {
  getAccordianPanelDetailsObject,
  getIfCustomDiscountsEnabled,
  getIfCustomerViewIsEnabled,
  getIfQuoteLineIdIsProvided,
  getIfUserIsAdminBasedOnGivenDetails,
} from '../../common-utils/utils';
import { AccordionsList } from '../presentational/accordions/AccordionsList';
import { CustomDiscountsTopLevelContainer } from '../custom-discounts/CustomDiscountsTopLevelContainer';
import { CreditsAndBurstsContainer } from '../credits-and-bursts/CreditsAndBurstsContainer';
import { ClustersSummaryContainer } from '../views/clusters-summary/ClustersSummaryContainer';
import { StreamGovernanceSummaryContainer } from '../views/stream-governance-summary/StreamGovernanceSummaryContainer';
import { EstimateSummaryTopLevelContainer } from '../views/estimate-summary/EstimateSummaryTopLevelContainer';
import { UserContext } from '../../contexts/UserContext';
import { EstimateContext } from '../../contexts/EstimateContext';
import { FlinkSummaryContainer } from '../views/flink-pools-summary/FlinkSummaryContainer.jsx';
import { EstimateTCOInputsTopLevelContainer } from '../tco/estimate-inputs/EstimateTCOInputsTopLevelContainer';
import { PrimaryButton } from '../presentational/buttons/PrimaryButton';
import { Spacer } from '../presentational/spacing/Spacer';
import { StyledLabel } from '../../common-utils/styledComponents';
import { isAnythingEditedOnEstimatePage } from '../views/utils';
import { useUploadDataToSFDCMutation } from '../../service-definitions/estimates';
import {
  getErrorStatusAndDetailFromErrorObject,
  toastError,
  toastSuccess,
} from '../presentational/notifications/utils';

import { EstimateDetailsContainer } from './EstimateDetailsContainer';

const EstimateAccordionsContainer = () => {
  const { estimateId } = useParams();
  const { values: formikValues, initialValues } = useFormikContext();
  const user = useContext(UserContext);
  const estimateData = useContext(EstimateContext);
  const isUserAdmin = getIfUserIsAdminBasedOnGivenDetails(user);
  const [uploadDataToSFDCFunc] = useUploadDataToSFDCMutation();

  const accordionPanels = [
    getAccordianPanelDetailsObject('Estimate Details', <EstimateDetailsContainer />),
  ];

  // Show Custom Discounts Panel only if it is enabled and when the Customer View is False
  const isCustomDiscountsEnabled = getIfCustomDiscountsEnabled(estimateData?.inputs);
  const isCustomerViewEnabled = getIfCustomerViewIsEnabled(estimateData?.inputs);
  const isQuoteLineIdProvided = getIfQuoteLineIdIsProvided(estimateData?.inputs);
  const isAnythingEditedOnEstimatePageVar = isAnythingEditedOnEstimatePage(
    formikValues,
    initialValues
  );

  if (isCustomDiscountsEnabled && !isCustomerViewEnabled) {
    accordionPanels.push(
      getAccordianPanelDetailsObject(
        <>
          <StyledLabel basic={true} content="Custom Discounts" />
          <Spacer x={10} />
          {isQuoteLineIdProvided && IS_SFDC_INTEGRATION_LIVE && (
            <PrimaryButton
              circular={true}
              compact={true}
              disabled={isAnythingEditedOnEstimatePageVar}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                const { error } = await uploadDataToSFDCFunc({
                  estimateId,
                });
                if (error) {
                  const { errorStatus, errorDetail } =
                    getErrorStatusAndDetailFromErrorObject(error);
                  let content = DEFAULT_FE_ERROR_MESSAGE;
                  if (errorStatus === 404 || errorStatus === 403) {
                    content = errorDetail;
                  }
                  toastError(error, content);
                } else {
                  toastSuccess('Successfully uploaded the data to SFDC!!');
                }
              }}
              size="mini"
              text="Push To SFDC"
            />
          )}
        </>,
        <CustomDiscountsTopLevelContainer readOnlyMode={!isUserAdmin} />,
        'MLD_Accordion'
      )
    );
  }

  // Show Credits And Bursts Panel only if it is enabled
  if (formikValues?.[CREDITS_AND_BURSTS_ENABLED_BACKEND_NAME]) {
    accordionPanels.push(
      getAccordianPanelDetailsObject(
        'Credits And Bursts',
        <CreditsAndBurstsContainer readOnlyMode={!isUserAdmin} />
      )
    );
  }

  // Clusters Summary
  accordionPanels.push(
    getAccordianPanelDetailsObject('Clusters Summary', <ClustersSummaryContainer />)
  );

  // Flink Summary
  accordionPanels.push(
    getAccordianPanelDetailsObject('Flink Pools ( FP ) Summary', <FlinkSummaryContainer />)
  );

  // StreamGovernance Summary
  accordionPanels.push(
    getAccordianPanelDetailsObject(STREAM_GOVERNANCE_SUMMARY, <StreamGovernanceSummaryContainer />)
  );

  if (!getIfCustomerViewIsEnabled(estimateData?.inputs)) {
    accordionPanels.push(
      getAccordianPanelDetailsObject(
        ESTIMATE_LEVEL_TCO_INPUTS_SECTION_HEADER,
        <EstimateTCOInputsTopLevelContainer />
      )
    );
  }

  // Estimate Summary
  accordionPanels.push(
    getAccordianPanelDetailsObject(ESTIMATE_SUMMARY, <EstimateSummaryTopLevelContainer />)
  );

  return <AccordionsList panels={accordionPanels} />;
};

export default EstimateAccordionsContainer;
