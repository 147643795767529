// @flow
import { Formik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Form } from '../../formik-utils/formikSUIWrapper';
import { StyledContainer } from '../../common-utils/styledComponents';
import { getFormikFormInputsFromColConfigAndInputSource } from '../../common-utils/utils';
import { ESTIMATE_ACCOUNT_DETAILS_CONFIG } from '../../configuration/estimate-account-details';
import { EstimateAccountDetailsGrid } from '../estimate-account-details/EstimateAccountDetailsGrid';
import { ConfirmModal } from '../presentational/modals/ConfirmModal';
import { useAddEstimateMutation } from '../../service-definitions/estimates';
import { toastError } from '../presentational/notifications/utils';
import { Spacer } from '../presentational/spacing/Spacer';

export const EstimateAddModal = ({ defaultValues, isOpen, onClose }) => {
  const [addEstimate] = useAddEstimateMutation();
  const { push } = useHistory();

  const { initialValues, validationSchema } = getFormikFormInputsFromColConfigAndInputSource(
    ESTIMATE_ACCOUNT_DETAILS_CONFIG,
    defaultValues,
    null
  );

  return (
    <StyledContainer data-testid="estimate-add-modal">
      <Formik
        autoComplete="off"
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        {(addEstimateFormik) => (
          <ConfirmModal
            body={
              <StyledContainer>
                <Form autoComplete="off">
                  <EstimateAccountDetailsGrid disableOnFormErrors={false} />
                </Form>
                <Spacer y={40} />
                Note: All fields are mandatory!
              </StyledContainer>
            }
            cancelButtonNegative={true}
            disabled={!addEstimateFormik.dirty || !addEstimateFormik.isValid}
            header="Add New Estimate"
            isOpen={isOpen}
            okButtonNegative={false}
            okButtonText="Continue"
            onClickHandlerForCancel={onClose}
            onClickHandlerForOK={async () => {
              const { error, data } = await addEstimate({
                ...addEstimateFormik.values,
              });

              if (error) {
                toastError(error);
              } else {
                push(`/estimate/${data.id}`);
              }
              onClose();
            }}
          />
        )}
      </Formik>
    </StyledContainer>
  );
};

// todo:: Think about separating out the page into multiple smaller forms
