// @flow
import React, { useContext } from 'react';
import { List } from 'semantic-ui-react';

import { HIERARCHY_LEVELS_TO_ICON_COLORS_MAP } from '../../../constants';
import { FolderIcon } from '../../presentational/icons/FolderIcon';
import { OpenCloseHierarchyTreeContext } from '../../../contexts/OpenCloseHierarchyTreeContext';

import { highlightMatchingPortionInTextAndReturnSpan } from './utils';
import { EstimatesInsideOpportunityGrid } from './estimates-grid/EstimatesInsideOpportunityGrid';

export const EstimateHierarchyOpportunityListItem = ({
  account,
  accountId,
  organization,
  organizationId,
  opportunityId,
  tree,
  searchTerm,
}) => {
  const opportunity = tree[accountId].organizations[organizationId].opportunities[opportunityId];
  const { oppName: opportunityName } = opportunity;

  const { toggleOpenValueAtPathInOpenCloseTree, getIfOpportunityIsOpened } = useContext(
    OpenCloseHierarchyTreeContext
  );
  const isOpportunityOpened = getIfOpportunityIsOpened(accountId, organizationId, opportunityId);

  return (
    <List.List key={opportunityId}>
      <List.Item
        key={opportunityId}
        onClick={(e) => {
          e.stopPropagation();
          toggleOpenValueAtPathInOpenCloseTree(accountId, organizationId, opportunityId);
        }}
        style={{ cursor: 'pointer' }}
      >
        <FolderIcon
          color={HIERARCHY_LEVELS_TO_ICON_COLORS_MAP.get('Opportunity')}
          opened={isOpportunityOpened}
        />
        <List.Content>
          <List.Header>
            {highlightMatchingPortionInTextAndReturnSpan(opportunityName, searchTerm)}
          </List.Header>
          <List.Description>
            {highlightMatchingPortionInTextAndReturnSpan(opportunityId, searchTerm)}
          </List.Description>
          {isOpportunityOpened && (
            <EstimatesInsideOpportunityGrid
              account={account}
              opportunity={opportunity}
              organization={organization}
              searchTerm={searchTerm}
            />
          )}
        </List.Content>
      </List.Item>
    </List.List>
  );
};
