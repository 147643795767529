// @flow
import {
  ALL_IN_SMALL_CASE,
  CUSTOM_DISCOUNTS_JSON_BACKEND_NAME,
  IS_ROW_FROZEN,
  IS_ROW_OPENED_FOR_EDITING,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_ARRAY_BACKEND_NAME,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DIMENSION_VALUES_BACKEND_NAME,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DISCOUNT_BACKEND_NAME,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_ID_BACKEND_NAME,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME,
  METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_MLD_ID_BACKEND_NAME,
  ROW_ID,
} from '../../constants';
import { TEXT_NUMBER_INPUT_TYPE } from '../../formik-utils/consts';
import { toastError } from '../presentational/notifications/utils';

export const getMetricNameOptions = (metricsList, rowValues, allRows) => {
  const chosenMetricName =
    rowValues[METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME];

  // Case 1: There is already a Metric Name which is selected. In this case, just return that single value for the UI to display it
  if (chosenMetricName) {
    const chosenMetricRow = metricsList.filter((x) => x.name === chosenMetricName)[0];
    // If the Metric is applicable to the chosen RC, then, just send back an array with that just one value
    if (chosenMetricRow) {
      return [
        {
          name: chosenMetricRow.name,
          display_name: chosenMetricRow.label,
        },
      ];
    } else {
      // Sometimes, when the UI is getting refreshed, the chosenMetricRow might be null because the UI is in transit
      // e.g.,select CKUs in 4/2; and Cluster Base in 3/5; then move from 4/2 -> 3/5 -> 4/2
      // while the UI is in transit mode, we can see that chosenMetricRow is null
      // Hence, send an empty array for the time being. The next render will take care of setting the right values
      return [];
    }
  }

  // Case 2: When Metric Name is not selected, get the list of all unused metrics and display those
  const usedMetrics = allRows.map(
    (x) => x[METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME]
  );
  const unusedMetricsList = metricsList.filter((x) => !usedMetrics.includes(x.name));
  return unusedMetricsList.map((x) => ({
    name: x.name,
    display_name: x.label,
  }));
};

export const metricNameDeleteOnClickHandler = async (
  rowValues,
  deleteFunc,
  index,
  arrayHelpers
) => {
  const dbRowIds = rowValues[METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_ARRAY_BACKEND_NAME].map(
    (x) => x.id
  );

  if (dbRowIds.length > 0) {
    const payload = {
      db_row_ids: dbRowIds,
    };

    const { error } = await deleteFunc({
      payload,
    });

    if (error) {
      toastError(
        error,
        'There was an error in deleting the metric, please contact #cloud-commitment-estimator channel!'
      );
    } else {
      arrayHelpers.remove(index);
    }
  } else {
    arrayHelpers.remove(index);
  }
};

export const getDependentDimensionsColumnConfig = (dependentDimensions, metricName) => {
  const columnConfig = [];
  const dimensionsSeenSoFar = [];
  const allDimensionNames = dependentDimensions.map((x) => x.name);

  for (const dependentDimension of dependentDimensions) {
    columnConfig.push({
      backendFieldName: dependentDimension.name,
      displayName: dependentDimension.label,
      // todo:Discuss this with Deepthi
      displayNameCol: `${dependentDimension.name}_label`,
      hidden: false,
      columnWidth: 3,
      colDefaultValue: null,
      parentFieldsInGroup: [...dimensionsSeenSoFar],
      enableOnlyIfSet: [...dimensionsSeenSoFar],
      dependentFields: allDimensionNames.filter(
        (x) => ![...dimensionsSeenSoFar, dependentDimension.name].includes(x)
      ),
    });
    dimensionsSeenSoFar.push(dependentDimension.name);
  }

  columnConfig.push({
    backendFieldName: METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DISCOUNT_BACKEND_NAME,
    displayName: 'Discount',
    hidden: false,
    columnWidth: 3,
    colDefaultValue: null,
    type: TEXT_NUMBER_INPUT_TYPE,
    icon: 'percent',
    enableOnlyIfSet: [...dimensionsSeenSoFar],
  });

  columnConfig.push({
    backendFieldName: METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME,
    displayName: 'Metric Name',
    hidden: true,
    colDefaultValue: metricName,
  });

  return columnConfig;
};

export const getDimensionsColumnConfig = (columnsConfiguration, excludeHidden = true) => {
  let filteredList = columnsConfiguration.filter(
    (x) => x.backendFieldName !== METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DISCOUNT_BACKEND_NAME
  );

  if (excludeHidden) {
    filteredList = filteredList.filter((x) => x.hidden !== true);
  }

  return filteredList;
};

export const getDiscountColumnConfig = (columnsConfiguration) =>
  columnsConfiguration.filter(
    (x) => x.backendFieldName === METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DISCOUNT_BACKEND_NAME
  )[0];

const doDimensionValuesConflictHelper = (dimValues1, dimValues2, index) => {
  if (index >= dimValues1.length) {
    return true;
  }

  const val1 = dimValues1[index];
  const val2 = dimValues2[index];

  if (val1 !== ALL_IN_SMALL_CASE && val2 !== ALL_IN_SMALL_CASE && val1 !== val2) {
    return false;
  }

  return doDimensionValuesConflictHelper(dimValues1, dimValues2, index + 1);
};

export const doDimensionValuesConflict = (dimValues1, dimValues2) => {
  if (!dimValues1 || !dimValues2 || dimValues1.length !== dimValues2.length) {
    throw 'Both the dimension values should be arrays and they need to be of the same length!';
  }
  return doDimensionValuesConflictHelper(dimValues1, dimValues2, 0);
};

export const formatMLDIntoFrontEndFormat = (metrics) => {
  const metricsToDimsObj = {};

  for (const elem of metrics) {
    const currMetricName = elem.metric_name;
    const currMetricId = elem.metric_id;
    const keyToUse = `${currMetricName}$$$$${currMetricId}`;

    if (!metricsToDimsObj[keyToUse]) {
      metricsToDimsObj[keyToUse] = [];
    }

    const currDimValuesObj = {
      [METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME]: currMetricName,
      [METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_MLD_ID_BACKEND_NAME]:
        elem[METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_MLD_ID_BACKEND_NAME],
      [ROW_ID]: elem[METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_MLD_ID_BACKEND_NAME],
      [IS_ROW_FROZEN]: true,
      [IS_ROW_OPENED_FOR_EDITING]: false,
      [METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DIMENSION_VALUES_BACKEND_NAME]:
        elem.dimensions.map((x) => x.dimension_name),
      [METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_DISCOUNT_BACKEND_NAME]: Number(
        elem.discount_value
      ),
    };

    for (const dim of elem.dimensions) {
      currDimValuesObj[dim.dimension] = dim.dimension_name;
    }
    metricsToDimsObj[keyToUse].push(currDimValuesObj);
  }

  const toRet = [];
  for (const key of Object.keys(metricsToDimsObj)) {
    toRet.push({
      [METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_NAME_BACKEND_NAME]: key.split('$$$$')[0],
      [METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_METRIC_ID_BACKEND_NAME]: key.split('$$$$')[1],
      [IS_ROW_FROZEN]: true,
      [IS_ROW_OPENED_FOR_EDITING]: false,
      [METRICS_LEVEL_DISCOUNTS_DIMENSIONS_CONFIG_ARRAY_BACKEND_NAME]: metricsToDimsObj[key],
    });
  }

  return toRet;
};

export const getIfAllMetricsAreFrozen = (values) => {
  const mldConfig = values?.[CUSTOM_DISCOUNTS_JSON_BACKEND_NAME] ?? {};
  const metricRows = mldConfig?.metrics_level_discounts_array ?? [];

  if (metricRows.length === 0) {
    return true;
  }

  for (const metricRow of metricRows) {
    const dimensionRows = metricRow?.dimensions_config_array ?? [];

    if (dimensionRows.length === 0) {
      return true;
    }

    if (!dimensionRows.every((dimensionRow) => dimensionRow?.isRowFrozen === true)) {
      return false;
    }
  }

  return true;
};

export const getIfAnyMetricIsBeingEdited = (values) => {
  const mldConfig = values?.[CUSTOM_DISCOUNTS_JSON_BACKEND_NAME] ?? {};
  const metricRows = mldConfig?.metrics_level_discounts_array ?? [];

  for (const metricRow of metricRows) {
    const dimensionRows = metricRow?.dimensions_config_array ?? [];
    if (dimensionRows.some((dimensionRow) => dimensionRow?.isRowOpenedForEditing === true)) {
      return true;
    }
  }

  return false;
};
