// @flow
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { useDeleteClusterMutation } from '../../../service-definitions/estimates';
import { EstimateContext } from '../../../contexts/EstimateContext';
import { ConfirmModal } from '../../presentational/modals/ConfirmModal';
import { toastError, toastSuccess } from '../../presentational/notifications/utils';

export const ClusterDeleteWarningModal = ({ isOpen, setOpen, clusterName, clusterId }) => {
  const [deleteCluster] = useDeleteClusterMutation();
  const estimate = useContext(EstimateContext);
  const { push } = useHistory();

  const header = `Delete Cluster ${clusterName}`;
  const body = <p>Are you sure you want to delete this Cluster?</p>;

  const estimateId = estimate?.inputs?.id;

  const payload = {
    estimate_id: estimateId,
    id: clusterId,
    estimate_version: estimate?.inputs?.estimate_version,
  };

  const onClickYesHandler = async () => {
    const { error } = await deleteCluster({
      estimateId: estimateId,
      clusterId: clusterId,
      payload,
    });
    if (error) {
      toastError(error);
    } else {
      toastSuccess('Successfully deleted the cluster!');
      push(`/estimate/${estimateId}`);
    }
  };

  return (
    <ConfirmModal
      body={body}
      header={header}
      isOpen={isOpen}
      onClickHandlerForCancel={() => setOpen(false)}
      onClickHandlerForOK={onClickYesHandler}
    />
  );
};

export default ClusterDeleteWarningModal;
