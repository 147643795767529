// @flow
import { Grid } from 'semantic-ui-react';
import React from 'react';

import { StyledGridRow } from '../../common-utils/styledComponents';
import { Spacer } from '../presentational/spacing/Spacer';
import { IS_SFDC_INTEGRATION_LIVE } from '../../constants';

import { EstimateNameContainer } from './EstimateNameContainer';
import { EstimateAccountIDContainer } from './EstimateAccountIDContainer';
import { EstimateAccountNameContainer } from './EstimateAccountNameContainer';
import { EstimateOrganisationIDContainer } from './EstimateOrganisationIDContainer';
import { EstimateOrganisationNameContainer } from './EstimateOrganisationNameContainer';
import { EstimateOpportunityIDContainer } from './EstimateOpportunityIDContainer';
import { EstimateOpportunityNameContainer } from './EstimateOpportunityNameContainer';
import { EstimateQuoteLineIDContainer } from './EstimateQuoteLineIDContainer';

export const EstimateAccountDetailsGrid = ({ disableOnFormErrors, showQuoteLineId = false }) => (
  <Grid columns={3} divided={true}>
    <Spacer y={12} />
    <StyledGridRow>
      <Grid.Column width={16}>
        <EstimateNameContainer disableOnFormErrors={disableOnFormErrors} />
      </Grid.Column>
    </StyledGridRow>

    <Spacer y={12} />

    <StyledGridRow>
      <Grid.Column>
        <EstimateAccountIDContainer disableOnFormErrors={disableOnFormErrors} />
        <EstimateAccountNameContainer disableOnFormErrors={disableOnFormErrors} />
      </Grid.Column>

      <Grid.Column>
        <EstimateOrganisationIDContainer disableOnFormErrors={disableOnFormErrors} />
        <EstimateOrganisationNameContainer disableOnFormErrors={disableOnFormErrors} />
      </Grid.Column>

      <Grid.Column>
        <EstimateOpportunityIDContainer disableOnFormErrors={disableOnFormErrors} />
        <EstimateOpportunityNameContainer disableOnFormErrors={disableOnFormErrors} />
      </Grid.Column>
    </StyledGridRow>

    {showQuoteLineId && IS_SFDC_INTEGRATION_LIVE && (
      <>
        <Spacer y={12} />
        <StyledGridRow>
          <Grid.Column>
            <EstimateQuoteLineIDContainer disableOnFormErrors={disableOnFormErrors} />
          </Grid.Column>
        </StyledGridRow>
        <Spacer y={30} />
      </>
    )}
  </Grid>
);
