// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import { PaddedAndRaisedSegment } from '../presentational/PaddedAndRaisedSegment';
import { Spacer } from '../presentational/spacing/Spacer';
import { SaveFormButton } from '../presentational/buttons/SaveFormButton';
import { useUpdateEstimateMutation } from '../../service-definitions/estimates';
import { ResetFormButton } from '../presentational/buttons/ResetFormButton';
import { ESTIMATE_ACCOUNT_DETAILS_CONFIG } from '../../configuration/estimate-account-details';
import {
  extractRelevantFieldValuesFromForm,
  isFormEditedBasedOnCurrentValues,
  resetFormValuesToInitialValues,
} from '../../common-utils/utils';
import { toastError } from '../presentational/notifications/utils';
import { EstimateContext } from '../../contexts/EstimateContext';
import { isAnythingEditedOnEstimatePageBesidesTheGivenForm } from '../views/utils';
import { ESTIMATE_ACCOUNT_DETAILS_FORM } from '../../constants';

import { EstimateAccountDetailsGrid } from './EstimateAccountDetailsGrid';

export const EstimateAccountDetailsContainer = () => {
  const { estimateId } = useParams();
  const estimate = useContext(EstimateContext);
  const { values, isValid, initialValues, resetForm } = useFormikContext();
  const [updateEstimateDetails] = useUpdateEstimateMutation();

  const shouldSaveBeDisabled =
    !isValid ||
    !isFormEditedBasedOnCurrentValues(values, initialValues, ESTIMATE_ACCOUNT_DETAILS_CONFIG);

  const shouldResetBeDisabled = !isFormEditedBasedOnCurrentValues(
    values,
    initialValues,
    ESTIMATE_ACCOUNT_DETAILS_CONFIG
  );

  const shouldFormBeDisabled = isAnythingEditedOnEstimatePageBesidesTheGivenForm(
    values,
    initialValues,
    ESTIMATE_ACCOUNT_DETAILS_FORM
  );

  return (
    <>
      <PaddedAndRaisedSegment disabled={shouldFormBeDisabled}>
        <EstimateAccountDetailsGrid disableOnFormErrors={null} showQuoteLineId={true} />
        <Spacer y={40} />

        <ResetFormButton
          disabled={shouldResetBeDisabled}
          onClick={() => {
            resetFormValuesToInitialValues(
              values,
              ESTIMATE_ACCOUNT_DETAILS_CONFIG,
              initialValues,
              resetForm
            );
          }}
        />

        <Spacer x={1} />

        <SaveFormButton
          disabled={shouldSaveBeDisabled}
          onClick={async () => {
            const payload = extractRelevantFieldValuesFromForm(
              ESTIMATE_ACCOUNT_DETAILS_CONFIG,
              values
            );
            payload.estimate_version = estimate?.inputs?.estimate_version;
            const { error } = await updateEstimateDetails({
              estimateId,
              payload,
            });
            if (error) {
              toastError(error);
            }
          }}
        />
      </PaddedAndRaisedSegment>
    </>
  );
};
