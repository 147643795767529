// @flow
import { useCallback, useEffect, useState } from 'react';
import debounce from 'just-debounce-it';
import { isEqual } from 'lodash';

const FormListener = ({ formik }) => {
  const { submitForm, values, initialValues, validateForm } = formik;
  const [lastValues, updateLastValues] = useState(values);
  // TODO react-hooks/exhaustive-deps warnings...
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedValidate = useCallback(
    // eslint-disable-next-line no-console
    debounce(() => validateForm().then(() => console.log('Form Validated')), 400),
    [submitForm]
  );
  useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, values);
    const valuesEqualInitialValues = values === initialValues;

    if (!valuesEqualLastValues) {
      updateLastValues(values);
    }

    if (!valuesEqualLastValues && !valuesEqualInitialValues) {
      debouncedValidate();
    }
    // TODO react-hooks/exhaustive-deps warnings
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastValues, values, initialValues, formik]);

  return null;
};

export default FormListener;
