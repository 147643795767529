// @flow
import { Table } from 'semantic-ui-react';
import React from 'react';
import { useFormikContext } from 'formik';

import { DeleteButton } from '../presentational/buttons/DeleteButton';
import { StyledTable } from '../../common-utils/styledComponents';

import { SharedEmailNameContainer } from './SharedEmailNameContainer';

export const EstimateShareEmailIdsTable = ({ arrayHelpers }) => {
  const { values } = useFormikContext();
  return (
    <StyledTable celled={true} color="blue" compact={false}>
      <Table.Body>
        {values.shared_with_emails.map((email, index) => {
          return (
            <Table.Row key={`Email--${index}`}>
              <Table.Cell width={6}>
                <SharedEmailNameContainer index={index} />
              </Table.Cell>
              <Table.Cell textAlign="left">
                <DeleteButton floated={null} onClick={() => arrayHelpers.remove(index)} />
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </StyledTable>
  );
};
