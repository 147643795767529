// @flow

import { CHECKBOX_INPUT_TYPE } from '../formik-utils/consts';
import { ESTIMATE_CUSTOMER_VIEW_ENABLED_BACKEND_NAME } from '../constants';

import { CUSTOM_DISCOUNTS_JSON_CONFIG } from './custom-discounts';
import { CREDITS_AND_BURSTS_JSON_CONFIG } from './credits-and-bursts';
import { ESTIMATE_ACCOUNT_DETAILS_CONFIG } from './estimate-account-details';
import { ESTIMATE_COMMIT_DETAILS_CONFIG } from './estimate-commit-details';
import { TCO_INPUTS_JSON_CONFIG } from './tco-estimate-inputs';

export const ESTIMATE_TOP_LEVEL_CONFIG = [
  ...ESTIMATE_ACCOUNT_DETAILS_CONFIG,
  ...ESTIMATE_COMMIT_DETAILS_CONFIG,
  CUSTOM_DISCOUNTS_JSON_CONFIG,
  CREDITS_AND_BURSTS_JSON_CONFIG,
  TCO_INPUTS_JSON_CONFIG,
  {
    displayName: ESTIMATE_CUSTOMER_VIEW_ENABLED_BACKEND_NAME,
    backendFieldName: ESTIMATE_CUSTOMER_VIEW_ENABLED_BACKEND_NAME,
    inputType: CHECKBOX_INPUT_TYPE,
  },
];
