// @flow
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import {
  BASIC_CLUSTER_TYPE,
  BASIC_V2_CLUSTER_TYPE,
  CLUSTER_TYPE_BACKEND_NAME,
  DEDICATED_CLUSTER_TYPE,
  ENTERPRISE_CLUSTER_TYPE,
  STANDARD_CLUSTER_TYPE,
  STANDARD_V2_CLUSTER_TYPE,
} from '../../../constants';
import { CLUSTER_DETAILS_CONFIG_MAP } from '../../../configuration/cluster-details';
import { getDropdownOptions } from '../../stream-governance/utils';
import { SelectField } from '../../../formik-utils/FormFields';
import { ClusterContext } from '../../../contexts/ClusterContext';

import { disableFollowerFetchOption, useClusterInputsDependencies } from './utils';

export const ClusterTypeContainer = ({ disabled }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const cluster = useContext(ClusterContext);
  const dataUniverse = cluster?.cluster_configs?.cluster_params;
  const clusterTypeConfig = CLUSTER_DETAILS_CONFIG_MAP.get(CLUSTER_TYPE_BACKEND_NAME);

  useClusterInputsDependencies(
    clusterTypeConfig,
    dataUniverse,
    values,
    setFieldValue,
    setFieldTouched
  );

  const clusterTypeOptions = getDropdownOptions(
    dataUniverse,
    values,
    clusterTypeConfig,
    false,
    true,
    false,
    false,
    [
      BASIC_CLUSTER_TYPE,
      BASIC_V2_CLUSTER_TYPE,
      STANDARD_CLUSTER_TYPE,
      STANDARD_V2_CLUSTER_TYPE,
      ENTERPRISE_CLUSTER_TYPE,
      DEDICATED_CLUSTER_TYPE,
    ]
  );

  return (
    <SelectField
      disabled={disabled}
      fieldDisplayName={clusterTypeConfig.displayName}
      fieldName={clusterTypeConfig.backendFieldName}
      onChange={() => {
        disableFollowerFetchOption(setFieldValue);
      }}
      options={clusterTypeOptions}
      placeholder={null}
    />
  );
};
