// @flow
import React, { useContext } from 'react';

import { PaddedAndRaisedSegment } from '../../presentational/PaddedAndRaisedSegment';
import { STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG } from '../../../configuration/stream-governance';
import {
  FieldArrayGrid,
  FieldArrayGridConfigItemContext,
} from '../../../formik-utils/FieldArrayGrid';
import {
  STREAM_GOVERNANCE_CONFIG_ARRAY_BACKEND_NAME,
  STREAM_GOVERNANCE_JSON_BACKEND_NAME,
} from '../../../constants';
import { EstimateContext } from '../../../contexts/EstimateContext';

import { StreamGovernanceConfigurationItem } from './StreamGovernanceConfigurationItem';

export const StreamGovernanceConfigurationInputContainer = () => {
  const estimate = useContext(EstimateContext);

  const pathToFollowInValues = [
    STREAM_GOVERNANCE_JSON_BACKEND_NAME,
    STREAM_GOVERNANCE_CONFIG_ARRAY_BACKEND_NAME,
  ];

  return (
    <>
      <PaddedAndRaisedSegment>
        <FieldArrayGrid
          columnsConfiguration={STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG}
          gridName="Stream Governance Config"
          pathToFollowInValues={pathToFollowInValues}
        >
          {(arrayHelpers, row, isCurrentlyBeingEdited, index, allRows, readOnlyMode) => (
            <FieldArrayGridConfigItemContext.Provider
              value={{
                allRows,
                arrayHelpers,
                columnsConfiguration: STREAM_GOVERNANCE_CONFIG_ARRAY_CONFIG,
                estimate,
                index,
                isCurrentlyBeingEdited,
                pathToFollowInValues: pathToFollowInValues,
                rowValues: row,
                readOnlyMode,
              }}
            >
              <StreamGovernanceConfigurationItem />
            </FieldArrayGridConfigItemContext.Provider>
          )}
        </FieldArrayGrid>
      </PaddedAndRaisedSegment>
    </>
  );
};
